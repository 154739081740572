import { TireQuoteItem } from 'noddi-async/src/types/shared/quotes';
import { create } from 'zustand';
import { createJSONStorage, devtools, persist } from 'zustand/middleware';

interface TireOfferState {
  selectedQuoteItem: TireQuoteItem | null;
  setSelectedQuoteItem: (quoteItem: TireQuoteItem) => void;
}

const initialStateValues = {
  selectedQuoteItem: null
};

export const useTireOfferStore = create<TireOfferState>()(
  persist(
    devtools((set) => ({
      ...initialStateValues,
      setSelectedQuoteItem: (quoteItem) => {
        const initializedQuoteItem = {
          ...quoteItem,
          quoteItemTires: quoteItem.quoteItemTires.map((tire) => ({
            ...tire,
            quantitySelected: tire.quantitySelected || tire.quantityDefault
          }))
        };
        set({ selectedQuoteItem: initializedQuoteItem });
      }
    })),
    {
      name: 'tireOfferStore',
      storage: createJSONStorage(() => sessionStorage)
    }
  )
);
