import { Circle, Svg, SvgProps } from 'react-native-svg';

const MenuDots = ({ color, ...props }: SvgProps) => (
  <Svg width={32} height={32} viewBox='0 0 32 32' fill='none' {...props}>
    <Circle cx={8.533} cy={16} r={2.133} stroke={color} strokeWidth={1.5} />
    <Circle cx={16} cy={16} r={2.133} stroke={color} strokeWidth={1.5} />
    <Circle cx={23.467} cy={16} r={2.133} stroke={color} strokeWidth={1.5} />
  </Svg>
);
export { MenuDots };
