import { Trans, t } from '@lingui/macro';
import { URLKeys, noddiAsync } from 'noddi-async';
import { AvailableBookingTimeWindow } from 'noddi-async/src/types';
import { invalidateQueryExactMatch } from 'noddi-async/src/utils';
import { NoddiDialog, NoddiFeedbackBox } from 'noddi-ui';
import { NoddiButton } from 'noddi-ui-common';
import { customerRoutes, getBookingTimeWindowDisplayText } from 'noddi-util';
import { useNavigate } from 'react-router-dom';

type Props = {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  bookingId: string;
  selectedTimeWindow: AvailableBookingTimeWindow | undefined;
};

const EditBookingTimeWindowDialog = ({ isOpen, setIsOpen, bookingId, selectedTimeWindow }: Props) => {
  const navigate = useNavigate();

  const {
    mutateAsync: updateBooking,
    isPending: isUpdateBookingPending,
    isError: isUpdateBookingError
  } = noddiAsync.usePatch({
    type: URLKeys.patchBooking,
    queryConfig: {
      onSuccess: async () => {
        await invalidateQueryExactMatch({
          urlKey: URLKeys.getUserBooking,
          input: { bookingId }
        });
        navigate(customerRoutes.myBookingDetails.getPath({ id: bookingId }));
      }
    }
  });

  if (!selectedTimeWindow) {
    return null;
  }

  return (
    <NoddiDialog title={t`Change booking time?`} open={isOpen} onClose={() => setIsOpen(false)}>
      <div className='flex flex-col gap-2'>
        <p>
          <Trans>You have selected</Trans>
          <b> {getBookingTimeWindowDisplayText(selectedTimeWindow)}</b>
        </p>
        <p className='mb-5'>
          <Trans>
            Changing the booking time is free. Rescheduling is available up to 24 hours before the start time.
          </Trans>
        </p>
        {isUpdateBookingError && (
          <NoddiFeedbackBox variant='error' title={t`We had trouble updating your booking. Please try again.`} />
        )}
        <div className='flex justify-end gap-2'>
          <NoddiButton
            onPress={async () =>
              await updateBooking({
                id: parseInt(bookingId),
                deliveryWindow: {
                  id: selectedTimeWindow.deliveryWindowId,
                  endsAt: selectedTimeWindow.endsAt,
                  startsAt: selectedTimeWindow.startsAt
                }
              })
            }
            loading={isUpdateBookingPending}
          >
            <Trans>Change time</Trans>
          </NoddiButton>
        </div>
      </div>
    </NoddiDialog>
  );
};

export default EditBookingTimeWindowDialog;
