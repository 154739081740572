import { Trans } from '@lingui/macro';
import { noddiAsync, URLKeys } from 'noddi-async';
import { useAuthContext } from 'noddi-provider';
import { BookingStatus } from 'noddi-ui';
import { AutoRecallCard } from '../../pages/AutoRecall/AutoRecallCard';
import { ErrorPageWithTranslations } from '../ErrorPageWithTrans';
import { NoddiLoadingScreenWithTrans } from '../NoddiLoadingScreenWithTrans';

export const AutoRecallHomepage = () => {
  const { currentUserGroupId } = useAuthContext();

  const {
    data: bookings,
    isPending: isBookingsPending,
    error: bookingsError
  } = noddiAsync.useGet({
    type: URLKeys.getPaginatedBookings,
    input: {
      userGroupIds: [currentUserGroupId as unknown as string],
      statusIds: [BookingStatus.RecallProposal],
      pageSize: 1,
      page: 1
    }
  });

  if (bookingsError) {
    return <ErrorPageWithTranslations apiError={bookingsError} />;
  }
  if (isBookingsPending) {
    return <NoddiLoadingScreenWithTrans />;
  }

  if (!bookings?.results.length) {
    return null;
  }

  return (
    <div className='flex flex-col gap-2 md:gap-4'>
      <h2 className='font-bold text-5 md:text-7'>
        <Trans>Suggested booking</Trans>
      </h2>
      <AutoRecallContent slug={bookings.results[0]!.slug} />
    </div>
  );
};

const AutoRecallContent = ({ slug }: { slug: string }) => {
  const {
    data: booking,
    isPending: isBookingPending,
    error: bookingError
  } = noddiAsync.useGet({
    type: URLKeys.getAutoRecallBooking,
    input: { slug }
  });

  if (isBookingPending) {
    return <NoddiLoadingScreenWithTrans />;
  }

  if (bookingError) {
    return <ErrorPageWithTranslations apiError={bookingError} />;
  }

  return <AutoRecallCard className='text-primary-black' booking={booking} slug={slug} />;
};
