import { TablePagination } from '@mui/material';
import { ChangeEvent } from 'react';

interface Props {
  page: number;
  rowsPerPageOptions?: number[];
  rowsPerPage: number;
  setPage: (page: number) => void;
  setRowsPerPage: (rowsPerPage: number) => void;
  hidden?: boolean;
  count: number;
  className?: string;
  translations: {
    rowsPerPage: string;
    of: string;
  };
}

export function NoddiTablePagination({
  page,
  rowsPerPageOptions = [10, 25, 50, 100],
  rowsPerPage,
  setPage,
  setRowsPerPage,
  hidden,
  count,
  className,
  translations
}: Props) {
  const pageChangeCallback = (_: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, newPage: number) => {
    setPage(newPage + 1);
  };
  const onRowsPerPageChangeCallback = (e: ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(e.target.value));
  };
  return (
    <TablePagination
      sx={{
        '& .MuiToolbar-root': {
          paddingLeft: 0
        }
      }}
      className={className}
      hidden={hidden}
      component='div'
      count={count}
      onPageChange={pageChangeCallback}
      onRowsPerPageChange={onRowsPerPageChangeCallback}
      page={page - 1}
      rowsPerPage={rowsPerPage}
      rowsPerPageOptions={rowsPerPageOptions}
      labelRowsPerPage={translations.rowsPerPage}
      labelDisplayedRows={({ from, to, count }) => `${from}-${to} ${translations.of} ${count}`}
    />
  );
}
