import { useLanguageContext } from 'noddi-provider';
import { NoddiLanguageSelector, NoddiLogo } from 'noddi-ui';
import { useNavigate } from 'react-router-dom';

import { noddiColors } from 'noddi-ui-common';
import { customerRoutes } from 'noddi-util';
import { getSupportedLocales } from '../../utils/translation';
import NavWrapper from './NavWrapper';

export const BasicHeaderNav = () => {
  const { currentLanguage, setCurrentLanguage } = useLanguageContext();
  const navigate = useNavigate();

  return (
    <NavWrapper>
      <div className='flex w-full items-center justify-between gap-6'>
        <button className='p-2' onClick={() => navigate(customerRoutes.homepage.getPath())}>
          <NoddiLogo color={noddiColors.primary.orange} width={67} height={19} />
        </button>
        <NoddiLanguageSelector
          supportedLanguages={getSupportedLocales()}
          currentLanguage={currentLanguage}
          setCurrentLanguage={setCurrentLanguage}
          dropDownOnRightSide
        />
      </div>
    </NavWrapper>
  );
};
