import { Trans } from '@lingui/macro';
import { CarSpec } from 'noddi-async/src/types/customerapp/booking/shared';
import { getCarDisplayName } from 'noddi-ui';
import { NoddiCarSvg } from '../../../components/Home/CarSvg';

export const CarIdentifierWithIcon = ({ car, hideTireHotelInfo }: { car: CarSpec; hideTireHotelInfo?: boolean }) => {
  return (
    <div className='flex items-center gap-4'>
      <NoddiCarSvg scale={0.5} />
      <div className='flex flex-col'>
        <span className='font-semibold'>{getCarDisplayName(car)}</span>
        {!hideTireHotelInfo && car.hasActiveTireHotelContract && (
          <span>
            <Trans>We have your tires stored with us</Trans>
          </span>
        )}
      </div>
    </div>
  );
};
