import { t, Trans } from '@lingui/macro';
import { tracking } from 'noddi-provider';
import { NoddiTextAreaInput } from 'noddi-ui';
import { NoddiButton, NoddiSelectableBox } from 'noddi-ui-common';
import { customerRoutes } from 'noddi-util';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ContentWrapper from '../../../components/Layouts/ContentWrapper';

const cancelledOptions = () => {
  return [
    {
      key: 'changed_plans',
      title: t`Changed my plans`,
      description: t`The time didn't work`
    },
    {
      key: 'price',
      title: t`Price`,
      description: t`Found a cheaper option`
    },
    {
      key: 'location',
      title: t`Location`,
      description: t`Location was not convenient`
    },
    {
      key: 'different_solution',
      title: t`Chose a different solution`,
      description: t`Used another service`
    },
    {
      key: 'other',
      title: t`Other`,
      description: t`Feel free to share more details`
    }
  ];
};

export const CancelledBooking = () => {
  const navigate = useNavigate();
  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);
  const [otherReason, setOtherReason] = useState<string>('');

  const getCanSubmit = () => {
    if (selectedOptions.includes('other')) {
      return selectedOptions.length > 0 && !!otherReason;
    }
    return selectedOptions.length > 0;
  };

  function onSubmit() {
    tracking.track('booking_cancelled_feedback', {
      reasons: selectedOptions,
      otherReason
    });
    navigate(customerRoutes.cancelledBookingFeedback.getPath());
  }

  const toggleOption = (key: string) => {
    setSelectedOptions((prev) => {
      if (prev.includes(key)) {
        return prev.filter((option) => option !== key);
      } else {
        return [...prev, key];
      }
    });
  };

  return (
    <ContentWrapper
      hideBackButton
      showNewBookingButtonVariant='secondary'
      title={t`Booking canceled`}
      showNewBookingButton
    >
      <div className='flex flex-col gap-4'>
        <span>
          <Trans>We're sorry you had to cancel! Would you like to share the reason with us?</Trans>
        </span>
        <div>
          <span>
            <Trans>Select one or more</Trans>
          </span>
          <div className='flex flex-col gap-2'>
            {cancelledOptions().map((option) => {
              const isSelected = selectedOptions.includes(option.key);
              return (
                <NoddiSelectableBox
                  key={option.key}
                  variant='secondary'
                  isSelected={isSelected}
                  setIsSelected={() => toggleOption(option.key)}
                >
                  <div className='flex flex-col'>
                    <p className='font-semibold'>{option.title}</p>
                    <p>{option.description}</p>
                  </div>
                </NoddiSelectableBox>
              );
            })}
            {selectedOptions.includes('other') && (
              <div>
                <span>{t`Please elaborate`}</span>
                <NoddiTextAreaInput
                  value={otherReason}
                  onChange={(event) => {
                    setOtherReason(event.target.value);
                  }}
                />
              </div>
            )}
          </div>
        </div>
        <div className='flex justify-end'>
          <NoddiButton onPress={onSubmit} disabled={!getCanSubmit()}>{t`Send in feedback`}</NoddiButton>
        </div>
      </div>
    </ContentWrapper>
  );
};
