import { TZDate } from '@date-fns/tz';
import { AvailableBookingTimeWindow } from 'noddi-async/src/types';
import { DateFormats, noddiFormatDate } from 'noddi-util';

export const getBookingTimeWindowDisplayText = (
  timeWindow: Pick<AvailableBookingTimeWindow, 'startsAt' | 'endsAt' | 'timeZone'>
) => {
  const startDate = new TZDate(timeWindow.startsAt, timeWindow.timeZone);
  const endDate = new TZDate(timeWindow.endsAt, timeWindow.timeZone);
  const startDateFormatted = noddiFormatDate(startDate, DateFormats.FULL_MONTH_YEAR_WITHOUT_COMMA);
  const startTimeFormatted = noddiFormatDate(startDate, DateFormats.TIME);
  const endTimeFormatted = noddiFormatDate(endDate, DateFormats.TIME);

  return `${startDateFormatted}, ${startTimeFormatted} - ${endTimeFormatted}`;
};

// This assumes the windows are always on HH.00 format, which is the case per 3/3/2025
export const getBookingTimeWindowDisplayTextShort = (
  timeWindow: Pick<AvailableBookingTimeWindow, 'startsAt' | 'endsAt' | 'timeZone'>
) => {
  const startDate = new TZDate(timeWindow.startsAt, timeWindow.timeZone);
  const endDate = new TZDate(timeWindow.endsAt, timeWindow.timeZone);
  const startDateFormatted = noddiFormatDate(startDate, DateFormats.FULL_MONTH_YEAR_WITHOUT_COMMA);
  const startTimeFormatted = noddiFormatDate(startDate, DateFormats.HOURS);
  const endTimeFormatted = noddiFormatDate(endDate, DateFormats.HOURS);

  return `${startDateFormatted}, ${startTimeFormatted} - ${endTimeFormatted}`;
};
