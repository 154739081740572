import { Stack, SxProps, Typography } from '@mui/material';
import { ReactNode } from 'react';

import { useIsMobile } from '../../hooks';

interface Props {
  title?: string;
  description?: string;
  node?: ReactNode;
  sx?: SxProps;
  titleVariant?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
}

export function NoddiHeader({ title, description, node, sx, titleVariant = 'h4' }: Props) {
  const isMobile = useIsMobile();
  return (
    <Stack
      direction={isMobile ? 'column' : 'row'}
      justifyContent={isMobile ? 'start' : 'space-between'}
      alignItems={isMobile ? 'flex-start' : 'center'}
      gap={isMobile ? '1rem' : '2rem'}
      sx={sx}
    >
      <div className='flex flex-col gap-2'>
        {title && <Typography variant={titleVariant}>{title}</Typography>}
        {description && <span className='max-w-[700px] text-secondary-black'>{description}</span>}
      </div>
      {node}
    </Stack>
  );
}
