import { t } from '@lingui/macro';
import { WheelSetProps } from 'noddi-async/src/types';
import { TireOfferV2 } from 'noddi-async/src/types/shared/quotes';
import { getCarDisplayName, TireMeasurement } from 'noddi-ui';
import getCommonTranslations from '../../commonTranslations';

function hasWheelMeasurementsData(wheels: WheelSetProps['wheels']) {
  return wheels.some((wheel) => wheel.measurements.length > 0);
}

export const TireOfferMeasurements = ({ tireOffer }: { tireOffer: TireOfferV2 }) => {
  const tireMeasurements = tireOffer.car.wheelSets.find(
    (wheelSet) => wheelSet.type.label === tireOffer.carWheelSet.type.label
  );

  if (!tireMeasurements) {
    return null;
  }

  if (!hasWheelMeasurementsData(tireMeasurements.wheels)) {
    return null;
  }

  return (
    <TireMeasurement
      HeaderElement={
        <div>
          <p className='font-bold text-primary-darkPurple'>{getCarDisplayName(tireOffer.car)}</p>
          <p className='text-primary-darkPurple'>
            {tireOffer.carWheelSet.type.label === 'winter' ? t`Winter tires` : t`Summer tires`}
          </p>
        </div>
      }
      tireType={tireOffer.carWheelSet.type.label === 'winter' ? 'winter' : 'summer'}
      wheels={tireOffer.carWheelSet.wheels}
      translations={getCommonTranslations().wheelMeasurement}
    />
  );
};
