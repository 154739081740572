import { Trans, t } from '@lingui/macro';
import { Form, Formik } from 'formik';
import { URLKeys, noddiAsync } from 'noddi-async';
import { AddressOutput } from 'noddi-async/src/types';
import { NoddiFormPhoneInput, NoddiFormTextInput, useNoddiToast } from 'noddi-ui';
import { NoddiButton } from 'noddi-ui-common';
import { isPhoneNumberValid } from 'noddi-ui/src/components/Domains/Login/isPhoneNumberValid';
import { emailRegex } from 'noddi-util';
import * as Yup from 'yup';
import { ApiErrorMessageWithTrans } from '../../../../components/ApiErrorMessageWithTrans';
const getValidationSchema = () => {
  return Yup.object({
    email: Yup.string()
      .required(t`This field is required`)
      .email(t`Must provide a valid email address`)
      .matches(emailRegex, t`Must provide a valid email address`),
    phoneNumber: Yup.string()
      .required(t`Required`)
      .test(t`Is valid`, t`Phone number is not valid`, (value: string) => isPhoneNumberValid(value ?? ''))
  });
};

interface NoServiceOfferedFormProps {
  address: AddressOutput;
  setIsFormSubmitted: (isFormSubmitted: boolean) => void;
}
export const NoServiceOfferedForm = ({ address, setIsFormSubmitted }: NoServiceOfferedFormProps) => {
  const { noddiToast } = useNoddiToast();

  const { mutateAsync: sendDeliveryNotification, error: sendDeliveryNotificationError } = noddiAsync.usePost({
    type: URLKeys.postDeliveryNotificationRequest,
    queryConfig: {
      onSuccess: () => {
        setIsFormSubmitted(true);
      },
      onError: () => {
        noddiToast.error(t`Failed to register your details, please try again`);
      }
    }
  });

  return (
    <Formik
      initialValues={{
        email: '',
        phoneNumber: ''
      }}
      onSubmit={async (values) => {
        const payload = {
          ...values,
          addressId: address.id
        };
        return await sendDeliveryNotification(payload);
      }}
      enableReinitialize
      validationSchema={getValidationSchema()}
    >
      {({ isSubmitting, submitForm }) => (
        <Form className='flex flex-col gap-4'>
          <h2>
            <Trans>Enter your details, and we'll notify you when we're in your area!</Trans>
          </h2>
          <div className='grid grid-cols-1 gap-4 md:grid-cols-2'>
            <NoddiFormTextInput name='email' label={t`Email`} type='email' />
            <NoddiFormPhoneInput name='phoneNumber' label={t`Phone number`} />
          </div>

          {sendDeliveryNotificationError && <ApiErrorMessageWithTrans error={sendDeliveryNotificationError} />}

          <div className='flex justify-end'>
            <NoddiButton loading={isSubmitting} variant='secondary' onPress={submitForm}>
              <Trans>Notify Me</Trans>
            </NoddiButton>
          </div>
        </Form>
      )}
    </Formik>
  );
};
