import { Path, Svg, SvgProps } from 'react-native-svg';

const DownArrowAlt = ({ color, ...props }: SvgProps) => (
  <Svg width={32} height={32} viewBox='0 0 32 32' fill='none' {...props}>
    <Path
      stroke={color}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeWidth={1.5}
      d='M23.467 12.8 16 19.2l-7.467-6.4'
    />
  </Svg>
);
export { DownArrowAlt };
