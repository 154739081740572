import { t, Trans } from '@lingui/macro';
import { NoddiBasicCard, NoddiDialog } from 'noddi-ui';
import { cn, NoddiButton } from 'noddi-ui-common';
import {
  formatCurrencyAmount,
  getBookingTimeWindowDisplayText,
  getBookingTimeWindowDisplayTextShort
} from 'noddi-util';

import { useState } from 'react';
import { useBookingTimeWindow } from '../BookingStore';
import { TimeWindowModalContent } from '../Steps/TimeWindows/TimeWindowModal';
export const SelectedTimeWindow = () => {
  const selectedTimeWindow = useBookingTimeWindow();
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  if (!selectedTimeWindow) {
    return null;
  }

  return (
    <>
      <NoddiDialog
        sx={{ zIndex: 1400 }}
        open={isDialogOpen}
        onClose={() => {
          setIsDialogOpen(false);
        }}
        title={t`Selected time window`}
      >
        <div className='flex flex-col gap-4'>
          <NoddiBasicCard className={cn('flex flex-col gap-2 bg-secondary-lightPurple/50 shadow-none')}>
            <div className='flex justify-between'>
              <span className='font-bold'>
                <Trans>Time</Trans>
              </span>
              <span> {getBookingTimeWindowDisplayText(selectedTimeWindow)}</span>
            </div>
            {!!selectedTimeWindow.deliveryWindowFee?.amount && (
              <div className='flex justify-between'>
                <span className='font-bold'>
                  <Trans>Delivery cost</Trans>
                </span>
                <span>
                  {formatCurrencyAmount(
                    selectedTimeWindow.deliveryWindowFee.amount,
                    0,
                    selectedTimeWindow.deliveryWindowFee.currency
                  )}
                </span>
              </div>
            )}
          </NoddiBasicCard>
          <TimeWindowModalContent />
        </div>
      </NoddiDialog>

      <NoddiButton
        variant='secondary'
        onPress={() => {
          setIsDialogOpen(true);
        }}
      >
        {getBookingTimeWindowDisplayTextShort(selectedTimeWindow)}
      </NoddiButton>
    </>
  );
};
