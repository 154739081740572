import { Path, Svg, SvgProps } from 'react-native-svg';

const Download = ({ color, ...props }: SvgProps) => (
  <Svg width={30} height={30} viewBox='0 0 30 30' fill='none' {...props}>
    <Path
      stroke={color}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeWidth={1.5}
      d='M6 18c0 2.828 0 4.243.879 5.121C7.757 24 9.172 24 12 24h6c2.828 0 4.243 0 5.121-.879C24 22.243 24 20.828 24 18M15 6v13m0 0 4-4.375M15 19l-4-4.375'
    />
  </Svg>
);

export { Download };
