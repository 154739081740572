import { noddiAsync, URLKeys } from 'noddi-async';
import { BookingAddress } from 'noddi-async/src/types';
import {
  AddressSearch,
  getLongAddressName,
  NoddiBasicCard,
  NoddiIconButton,
  NoddiLinearProgressLoader
} from 'noddi-ui';
import { NoddiIcon } from 'noddi-ui-common';
import getCommonTranslations from '../../../commonTranslations';
import { AddressError } from '../../BookingFlow/Steps/Address/AddressError';
import { NoServiceOffered } from '../../BookingFlow/Steps/Address/NoServiceOffered';

type AddressPickerProps = {
  setSelectedAddress: (address?: BookingAddress) => void;
  selectedAddress?: BookingAddress;
  isLoading?: boolean;
};

export const AddressPicker = ({ setSelectedAddress, selectedAddress, isLoading }: AddressPickerProps) => {
  const {
    mutateAsync: createAddressFromPlaceId,
    reset: resetCreateAddressFromPlaceId,
    isPending,
    error: addressError,
    data: createdAddress
  } = noddiAsync.usePost({
    type: URLKeys.postAddressCreatedFromPlaceId,
    queryConfig: {
      onSuccess: ({ data: { isInDeliveryArea, ...rest } }) => {
        if (isInDeliveryArea) {
          setSelectedAddress(rest);
        }
      }
    }
  });

  const displayAddress = selectedAddress ?? createdAddress?.data;

  const isAddressOutOfDeliveryArea = createdAddress?.data && !createdAddress.data.isInDeliveryArea;
  return (
    <>
      {displayAddress ? (
        <div className='flex flex-col gap-2'>
          <NoddiBasicCard className='flex items-center justify-between gap-3 rounded-md pl-2'>
            <div className='flex items-center gap-2'>
              <NoddiIcon name='LocationPin' size='large' />
              <span>{getLongAddressName(displayAddress)}</span>
            </div>
            <NoddiIconButton
              iconName='Cross'
              iconSize='medium'
              variant='destructive'
              onClick={() => {
                resetCreateAddressFromPlaceId();
                setSelectedAddress(undefined);
              }}
            />
          </NoddiBasicCard>
        </div>
      ) : (
        <AddressSearch
          translations={getCommonTranslations().addressSearch}
          onSelect={async (addressSuggestion) => {
            await createAddressFromPlaceId({ placeId: addressSuggestion.placeId });
          }}
        />
      )}
      {(isPending || isLoading) && <NoddiLinearProgressLoader />}
      <AddressError addressError={addressError} />
      {isAddressOutOfDeliveryArea && (
        <NoServiceOffered
          translations={{
            noServiceOfferedNotice: getCommonTranslations().noServiceOfferedNotice
          }}
          address={createdAddress.data}
        />
      )}
    </>
  );
};
