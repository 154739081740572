import {
  useBookingAddress,
  useBookingComment,
  useBookingSelectedSalesItemsCars,
  useBookingTimeWindow
} from '../BookingStore';

import { noddiAsync, NoddiAsyncReturnType, URLKeys } from 'noddi-async';

import { useAuthContext } from 'noddi-provider';
import { useGetConfirmBookingMutationKey } from './useGetConfirmBookingKey';
import { getSalesItemCarsPayload } from './utils';

export const useCreateBooking = ({
  onMutate,
  onSuccess
}: {
  onMutate?: () => void;
  onSuccess?: (data: NoddiAsyncReturnType<typeof URLKeys.postCreateBooking, typeof URLKeys.postCreateBooking>) => void;
}) => {
  const confirmBookingMutationKey = useGetConfirmBookingMutationKey();
  const selectedAddress = useBookingAddress();
  const bookingTimeWindow = useBookingTimeWindow();
  const comment = useBookingComment();
  const selectedSalesItemsCars = useBookingSelectedSalesItemsCars();

  const { currentUserGroupId: userGroupId, userData } = useAuthContext();

  const {
    mutateAsync: mutateAsyncCreateBooking,
    isPending,
    error
  } = noddiAsync.usePost({
    type: URLKeys.postCreateBooking,
    queryConfig: {
      onMutate: () => {
        onMutate?.();
      },
      onSuccess: (data) => {
        onSuccess?.(data);
      },
      mutationKey: confirmBookingMutationKey
    }
  });

  const onSubmit = async () => {
    await mutateAsyncCreateBooking({
      cars: getSalesItemCarsPayload(selectedSalesItemsCars),
      addressId: selectedAddress!.id,
      deliveryWindow: {
        id: bookingTimeWindow!.deliveryWindowId,
        endsAt: bookingTimeWindow!.endsAt,
        startsAt: bookingTimeWindow!.startsAt
      },
      userId: userData!.user.id,
      userGroupId: userGroupId!,
      commentsUser: comment ?? '',
      // TODO | New booking
      notificationsToOverride: []
    });
  };

  return {
    createNewBooking: onSubmit,
    isPending,
    error
  };
};
