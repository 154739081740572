import { Path, Svg, SvgProps } from 'react-native-svg';

const FileUpload = (props: SvgProps) => (
  <Svg width={30} height={30} viewBox='0 0 30 30' fill='none' {...props}>
    <Path
      stroke='#1C274C'
      strokeWidth={1.5}
      d='M5 15c0-4.714 0-7.071 1.464-8.536C7.93 5 10.286 5 15 5c4.714 0 7.071 0 8.535 1.464C25 7.93 25 10.286 25 15'
    />
    <Path
      stroke='#1C274C'
      strokeWidth={1.5}
      d='M5 17c0-2.8 0-4.2.545-5.27A5 5 0 0 1 7.73 9.545C8.8 9 10.2 9 13 9h4c2.8 0 4.2 0 5.27.545a5 5 0 0 1 2.185 2.185C25 12.8 25 14.2 25 17c0 2.8 0 4.2-.545 5.27a5 5 0 0 1-2.185 2.185C21.2 25 19.8 25 17 25h-4c-2.8 0-4.2 0-5.27-.545a5 5 0 0 1-2.185-2.185C5 21.2 5 19.8 5 17Z'
    />
    <Path
      stroke='#1C274C'
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeWidth={1.5}
      d='M15 20v-6m0 0 2 2.5M15 14l-2 2.5'
    />
  </Svg>
);
export { FileUpload };
