import { ApiErrorMessage, cn, NoddiBasicCard, NoddiCircularLoader, useIsMobile } from 'noddi-ui';

import { IconName, NoddiIcon } from 'noddi-ui-common';

import { noddiAsync, URLKeys } from 'noddi-async';
import { useBookingAddress } from '../../BookingStore';
import { BOOKING_CATEGORY_ENUM, bookingCategoryLabelToEnum, translateBookingCategories } from '../../helpers/utils';

type Variant = {
  backgroundImage: string;
  iconName: IconName;
};

export const variants = (isMobile: boolean): Record<BOOKING_CATEGORY_ENUM, Variant> => ({
  [BOOKING_CATEGORY_ENUM.PACKAGES]: {
    backgroundImage: `/assets/svgs/booking/serviceCards/${isMobile ? 'mobile' : 'desktop'}/car_care.svg`,
    iconName: 'Heart'
  },
  [BOOKING_CATEGORY_ENUM.WASHING_SERVICES]: {
    backgroundImage: `/assets/svgs/booking/serviceCards/${isMobile ? 'mobile' : 'desktop'}/washing_package.svg`,
    iconName: 'Drop'
  },
  [BOOKING_CATEGORY_ENUM.WHEEL_SERVICES]: {
    backgroundImage: `/assets/svgs/booking/serviceCards/${isMobile ? 'mobile' : 'desktop'}/wheel_service.svg`,
    iconName: 'WheelChange'
  }
});

type ServiceCategoryHeaderProps = {
  priceFrom?: string;
  isExpanded: boolean;
  numberOfSelectedSalesItems: number;
  category: string;
};

export const ServiceCategoryHeader = ({
  priceFrom,
  isExpanded,
  numberOfSelectedSalesItems,
  category
}: ServiceCategoryHeaderProps) => {
  const bookingAddress = useBookingAddress();

  const {
    data: bookingCategories,
    error: bookingCategoriesError,
    isPending: isBookingCategoriesPending
  } = noddiAsync.useGet({
    type: URLKeys.getBookingCategoriesForNewBooking,
    input: { addressId: bookingAddress?.id },
    queryConfig: { enabled: !!bookingAddress?.id }
  });

  const isMobile = useIsMobile();
  const categoryType = bookingCategoryLabelToEnum(category);
  const serviceName = translateBookingCategories(category);

  const variant = variants(isMobile)[categoryType];

  if (bookingCategoriesError) {
    return <ApiErrorMessage error={bookingCategoriesError} />;
  }
  if (isBookingCategoriesPending) {
    return (
      <NoddiBasicCard className='flex w-full items-center justify-center'>
        <NoddiCircularLoader />
      </NoddiBasicCard>
    );
  }

  const brand = bookingCategories.find((item) => item.slug === category)?.brand;
  return (
    <NoddiBasicCard
      className={cn(
        'relative flex w-full items-center justify-between gap-2 overflow-hidden bg-no-repeat shadow-none duration-300'
      )}
    >
      <div className='flex gap-2'>
        {numberOfSelectedSalesItems > 0 ? (
          <div className='mt-1 flex size-8 items-center justify-center rounded-full bg-primary-purple text-primary-white'>
            {numberOfSelectedSalesItems}
          </div>
        ) : (
          <div className='mt-0.5'>
            <NoddiIcon name={variant.iconName} size='large' />
          </div>
        )}
        <div className='flex flex-col gap-1'>
          <span className='text-6'>{serviceName}</span>
          {priceFrom && <span className='font-semibold duration-300'>{priceFrom}</span>}
        </div>
      </div>
      <div className='flex flex-col items-end gap-2'>
        <NoddiIcon name={isExpanded ? 'AltArrowUp' : 'AltArrowDown'} />
        {!!brand && <img className='mr-1 w-20' src={brand.logo} alt={brand.name} />}
      </div>
    </NoddiBasicCard>
  );
};
