import { Trans } from '@lingui/macro';
import { cn } from 'noddi-ui';
import { NoddiButton } from 'noddi-ui-common';
import { PropsWithChildren } from 'react';
import { useNavigate } from 'react-router-dom';

interface HomeContentWrapperProps {
  title?: string;
  subtitle?: string;
  widerScreen?: boolean;
  className?: string;
  hideBackButton?: boolean;
}

export const AutoRecallContentWrapper = ({
  children,
  title,
  subtitle,
  widerScreen,
  className,
  hideBackButton
}: PropsWithChildren<HomeContentWrapperProps>) => {
  const hasContent = !!title || !!subtitle;
  const navigate = useNavigate();

  return (
    <div
      className={cn(
        'mx-auto mt-8 flex max-w-2xl flex-col px-6 pb-20 sm:px-10 md:mt-14',
        widerScreen && 'md:max-w-screen-lg md:px-10',
        className
      )}
    >
      {!hideBackButton && (
        <NoddiButton
          startIcon='ArrowLeft'
          onPress={() => {
            navigate(-1);
          }}
          variant='ghost'
          className='-ml-3.5'
        >
          <Trans>Back</Trans>
        </NoddiButton>
      )}
      {hasContent && (
        <div className='mb-4 flex flex-col gap-2'>
          {title && <h1 className='font-bold text-5 text-primary-darkPurple md:text-8'>{title}</h1>}
          {subtitle && <p className=' text-primary-darkPurple'>{subtitle}</p>}
        </div>
      )}
      {children}
    </div>
  );
};
