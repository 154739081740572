import { Path, Svg, SvgProps } from 'react-native-svg';

const TrashBin = (props: SvgProps) => (
  <Svg width={30} height={30} viewBox='0 0 30 30' fill='none' {...props}>
    <Path
      stroke='#1C274C'
      strokeLinecap='round'
      strokeWidth={1.5}
      d='M23.5 9h-17M21.833 11.5l-.46 6.9c-.177 2.654-.265 3.982-1.13 4.79-.865.81-2.196.81-4.856.81h-.774c-2.66 0-3.991 0-4.856-.81-.865-.809-.954-2.136-1.13-4.79l-.46-6.9M12.5 14l.5 5M17.5 14l-.5 5'
    />
    <Path
      stroke='#1C274C'
      strokeWidth={1.5}
      d='M9.5 9h.11a2 2 0 0 0 1.864-1.423l.097-.291c.083-.249.125-.373.18-.479a1.5 1.5 0 0 1 1.094-.788c.117-.019.248-.019.51-.019h3.29c.262 0 .393 0 .51.019a1.5 1.5 0 0 1 1.094.788c.055.106.097.23.18.479l.097.291A2 2 0 0 0 20.5 9'
    />
  </Svg>
);
export { TrashBin };
