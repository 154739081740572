import { Trans } from '@lingui/macro';
import { NoddiBasicCard } from 'noddi-ui';
import { useNavigate } from 'react-router-dom';

import { NoddiDummyButton } from 'noddi-ui-common';
import { customerRoutes } from 'noddi-util';
import { NoddiCarSvg } from './CarSvg';

interface CarItemCardProps {
  carId: number;
  carName: string;
  licensePlate: string;
}
const CarItemCard = ({ carName, licensePlate, carId }: CarItemCardProps) => {
  const navigate = useNavigate();
  const onClick = () => navigate(customerRoutes.myCarDetails.getPath({ carId }));

  return (
    <NoddiBasicCard onClick={onClick} className='flex flex-col justify-center gap-3'>
      <div className='flex flex-row justify-between gap-5'>
        <div className='flex flex-col text-5'>
          <p className='font-bold'>{carName}</p>
          <p>{licensePlate}</p>
        </div>
        <div className='flex flex-col items-center justify-between gap-2'>
          <NoddiCarSvg />
          <NoddiDummyButton endIcon='ArrowRight' className='-mb-2 -mr-4' variant='link'>
            <Trans>See details</Trans>
          </NoddiDummyButton>
        </div>
      </div>
    </NoddiBasicCard>
  );
};

export default CarItemCard;
