import { t, Trans } from '@lingui/macro';
import { noddiAsync, URLKeys } from 'noddi-async';
import { AutoRecallBooking, BookingAddress } from 'noddi-async/src/types';
import { invalidateQueryExactMatch } from 'noddi-async/src/utils';
import { tracking } from 'noddi-provider';
import { NoddiBasicCard } from 'noddi-ui';
import { NoddiButton } from 'noddi-ui-common';
import { customerRoutes } from 'noddi-util';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ErrorPageWithTranslations } from '../../../components/ErrorPageWithTrans';
import { NoddiLoadingScreenWithTrans } from '../../../components/NoddiLoadingScreenWithTrans';
import { salesItemStepPath } from '../../BookingFlow/helpers/bookingFlowPaths';
import { useAutoRecallActions, useAutoRecallMetaData } from '../AutoRecallStore';
import { AutoRecallContentWrapper } from '../ContentWrapper';
import { usePopulateBookingStore } from '../hooks/usePopulateBookingStore';
import { AddressPicker } from './AddressPicker';

export const AutoRecallAddress = () => {
  const navigate = useNavigate();
  const { bookingId, slug } = useAutoRecallMetaData();
  const { setHasInvalidTimeWindow } = useAutoRecallActions();
  const [selectedAddress, setSelectedAddress] = useState<BookingAddress>();
  const populateBookingStore = usePopulateBookingStore();

  const {
    mutateAsync: updateRecallBooking,
    isPending: isUpdateRecallBookingPending,
    error: updateRecallBookingError
  } = noddiAsync.usePatch({
    type: URLKeys.patchRecallBooking,
    queryConfig: {
      onSuccess: async () => {
        tracking.track('autoRecallEditAddress');
        await invalidateQueryExactMatch({
          urlKey: URLKeys.getAutoRecallBooking,
          input: { slug: slug! }
        });
        navigate(customerRoutes.autoRecallLandingPage.getPath({ slug: slug! }));
      }
    }
  });

  const {
    data: potentialConflicts,
    isLoading,
    error
  } = noddiAsync.useGet({
    type: URLKeys.getPotentialConflictsOnAddressChange,
    input: {
      bookingId: bookingId!,
      addressId: selectedAddress?.id
    },
    queryConfig: {
      enabled: !!selectedAddress?.id
    }
  });

  const {
    data: booking,
    error: bookingError,
    isPending: isBookingPending
  } = noddiAsync.useGet({
    type: URLKeys.getAutoRecallBooking,
    input: { slug: slug! }
  });

  useEffect(() => {
    const updateAddress = async () => {
      if (selectedAddress && bookingId && potentialConflicts) {
        const { conflictingSalesItems, isDeliveryWindowConflicting } = potentialConflicts;
        if (!conflictingSalesItems.length) {
          // For autoRecalls we dont care if the time window is invalid, as we send them to picker later
          setHasInvalidTimeWindow(isDeliveryWindowConflicting);

          await updateRecallBooking({
            id: bookingId,
            addressId: selectedAddress.id
          });
        }
      }
    };

    updateAddress();
  }, [potentialConflicts, selectedAddress, bookingId, updateRecallBooking, setHasInvalidTimeWindow]);

  if (updateRecallBookingError || error || bookingError) {
    return <ErrorPageWithTranslations apiError={[updateRecallBookingError, error, bookingError]} />;
  }

  if (isBookingPending) {
    return <NoddiLoadingScreenWithTrans />;
  }

  const handleEditServices = (booking: AutoRecallBooking) => {
    populateBookingStore(booking);
    navigate(salesItemStepPath);
  };

  return (
    <AutoRecallContentWrapper title={t`Where do you need our help?`}>
      <div className='flex flex-col gap-4'>
        <AddressPicker
          isLoading={isLoading || isUpdateRecallBookingPending}
          setSelectedAddress={async (address) => {
            setSelectedAddress(address);
          }}
        />
        {potentialConflicts && !!potentialConflicts.conflictingSalesItems.length && (
          <NoddiBasicCard className='flex flex-col gap-4'>
            <div className='flex flex-col gap-1'>
              <span>
                <Trans>Some items in your booking cannot be delivered to this address</Trans>🥲{' '}
                <Trans>Please select a different address or remove the following services</Trans>:
              </span>
            </div>
            <ul className='flex flex-col gap-1'>
              {potentialConflicts.conflictingSalesItems.map((item, idx) => (
                <li key={idx}>
                  <p className='font-bold'>{item.name}</p>
                </li>
              ))}
            </ul>

            <NoddiButton
              startIcon='Edit'
              className='w-full'
              variant='secondary'
              onPress={() => handleEditServices(booking)}
            >
              <Trans>Edit services</Trans>
            </NoddiButton>
          </NoddiBasicCard>
        )}
      </div>
    </AutoRecallContentWrapper>
  );
};
