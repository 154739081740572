import { Trans, t } from '@lingui/macro';
import { Typography } from '@mui/material';
import { NoddiCollapseCard } from 'noddi-ui';
import { noddiColors } from 'noddi-ui-common';

export default function RelevantQuestionsForBooking() {
  const info = [
    t`You don't have to be home when we arrive. If you have an interior wash, we need access to unlock the car. If you leave the key out, it is at your own risk.`,
    t`You will receive a text message when we are on our way to you, typically 10 to 30 minutes before arrival. This also includes your technician's mobile number in case you need to get in touch.`,
    t`Payment is made digitally according to the agreement on this page, by credit card or Vipps.`,
    t`You can make changes to your agreement free of charge until 6:00 PM the day before. After this, a change fee may apply.`,
    t`If you have ordered a tire change and have not added carrying assistance, you must place the tires less than 10 meters away from the car, on ground level. We can help you carry them, but there will be a small additional fee.`
  ];

  return (
    <NoddiCollapseCard
      backgroundColor={noddiColors.primary.white}
      header={
        <Typography variant='h6'>
          <Trans>Information about your agreement with Noddi</Trans>
        </Typography>
      }
      collapseBody={
        <>
          {info.map((item, index) => (
            <Typography key={index} variant='body1' style={{ marginTop: 4 }}>
              <b style={{ color: noddiColors.primary.orange }}>{index + 1}.&nbsp;</b>
              {item}
            </Typography>
          ))}
        </>
      }
    />
  );
}
