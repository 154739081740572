import { plural, t, Trans } from '@lingui/macro';
import { noddiAsync, URLKeys } from 'noddi-async';
import { quoteItemStatus } from 'noddi-async/src/types/shared/quotes';
import { invalidateQueryExactMatch } from 'noddi-async/src/utils';
import { NoddiBasicCard, NoddiDialog, NoddiIconButton, useIsMobile } from 'noddi-ui';
import { NoddiButton } from 'noddi-ui-common';
import { customerRoutes } from 'noddi-util';
import { useState } from 'react';
import { useNavigate } from 'react-router';
import TiresSvg from '../../pages/TireOffer/TiresSvg';
import { ApiErrorMessageWithTrans } from '../ApiErrorMessageWithTrans';
import { NoddiCircularLoaderWithTrans } from '../NoddiCircularLoaderWithTrans';
interface TireSetOffersProps {
  userGroupId: number;
}

export const TireSetOffers = ({ userGroupId }: TireSetOffersProps) => {
  const isMobile = useIsMobile();
  const [dialogOpen, setDialogOpen] = useState(false);
  const navigate = useNavigate();
  const {
    isPending: isTireSetOffersPending,
    data: tireSetOffers,
    error: tireSetOffersError
  } = noddiAsync.useGet({
    type: URLKeys.getCarTireQuotes,
    input: { userGroupIds: [userGroupId], status: quoteItemStatus.PENDING }
  });

  const {
    mutateAsync: postRejectTireOfferQuote,
    isPending: isPostRejectTireOfferQuotePending,
    error: postRejectTireOfferQuoteError
  } = noddiAsync.usePost({
    type: URLKeys.postRejectTireOfferQuote,
    queryConfig: {
      onSuccess: async () => {
        await invalidateQueryExactMatch({ urlKey: URLKeys.getCarTireQuotes, input: { userGroupIds: [userGroupId] } });
        setDialogOpen(false);
      }
    }
  });

  if (isTireSetOffersPending) {
    return <NoddiCircularLoaderWithTrans />;
  }

  if (tireSetOffersError || tireSetOffers.length === 0) {
    return null;
  }

  const hasMultipleOffers = tireSetOffers.length > 1;

  function onOfferClick() {
    if (hasMultipleOffers) {
      navigate(customerRoutes.tireOfferHomePage.getPath());
    } else if (tireSetOffers?.length === 1 && tireSetOffers[0]?.slug) {
      navigate(customerRoutes.tireOfferInfo.getPath({ slug: tireSetOffers[0].slug }));
    }
  }

  const tirePluralMessage = plural(tireSetOffers.length, {
    one: 'Our measurements show that your tires may soon be, or are already, below the legal tread depth.',
    other:
      'Our measurements show that you have multiple tire sets where your tires may soon be, or are already, below the legal tread depth.'
  });

  const hasSelectedOrRejectedAllOffers = tireSetOffers.every((offer) => offer.isSelected || offer.isRejected);

  if (hasSelectedOrRejectedAllOffers) {
    return null;
  }

  return (
    <>
      <NoddiBasicCard className='flex flex-col bg-secondary-lightPurple'>
        <div className='flex items-center justify-between gap-1 md:gap-4'>
          <p className='font-bold text-5 text-primary-darkPurple md:text-7'>
            <Trans>Time for new tires?</Trans>
          </p>
          <NoddiIconButton
            iconName='Cross'
            variant='ghost'
            onClick={() => {
              setDialogOpen(true);
            }}
          />
        </div>
        <div className='flex flex-col gap-4'>
          <div className='flex flex-col gap-2'>
            <p className='text-primary-darkPurple'>{tirePluralMessage}</p>
          </div>
          <div className='flex w-full items-end justify-between'>
            <div>
              <TiresSvg height={isMobile ? 50 : 98} width={isMobile ? 111 : 144} />
            </div>
            <NoddiButton onPress={onOfferClick} endIcon='ArrowRight'>
              <Trans>See more</Trans>
            </NoddiButton>
          </div>
        </div>
      </NoddiBasicCard>
      <NoddiDialog
        title={t`Are you sure you want to remove this offer?`}
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
      >
        <div className='flex flex-col gap-4'>
          <p>
            <Trans>
              We'll remove the tire offer from your homepage. Remember, safe tires are essential for staying safe on the
              road.
            </Trans>
          </p>
          <div className='flex justify-end gap-4'>
            <NoddiButton variant='secondary' onPress={() => setDialogOpen(false)}>
              <Trans>Close</Trans>
            </NoddiButton>
            <NoddiButton
              loading={isPostRejectTireOfferQuotePending}
              onPress={async () =>
                Promise.all(
                  tireSetOffers.map(
                    async (offer) =>
                      await postRejectTireOfferQuote({
                        tireQuoteId: offer.id,
                        comments: 'User rejected offer from homepage'
                      })
                  )
                )
              }
              variant='destructive'
            >
              <Trans>Yes, remove the offer</Trans>
            </NoddiButton>
          </div>
          {postRejectTireOfferQuoteError && <ApiErrorMessageWithTrans error={postRejectTireOfferQuoteError} />}
        </div>
      </NoddiDialog>
    </>
  );
};
