import { Trans } from '@lingui/macro';
import { NoddiButton, NoddiDummyButton } from 'noddi-ui-common';
import { GestureResponderEvent } from 'react-native';

export const SalesItemButtons = ({
  isUnavailable,
  description,
  isSelected,
  onShowFullDescriptionClick
}: {
  isUnavailable?: boolean;
  description: string;
  isSelected: boolean;
  onShowFullDescriptionClick: (event: GestureResponderEvent) => void;
}) => {
  if (isUnavailable) {
    return (
      <NoddiDummyButton startIcon='ArrowSquareRightCorner' className='-mr-3' size='small' variant='link'>
        <Trans>Why am I not able to select this?</Trans>
      </NoddiDummyButton>
    );
  }

  return (
    <div className='flex items-center gap-2'>
      {!!description && (
        <NoddiButton
          startIcon='ArrowSquareRightCorner'
          size='small'
          variant={isSelected ? 'lightLink' : 'link'}
          onPress={onShowFullDescriptionClick}
        >
          <Trans>Details</Trans>
        </NoddiButton>
      )}
      <NoddiDummyButton variant='secondary' startIcon={isSelected ? 'Minus' : 'Plus'} size='small'>
        {isSelected ? <Trans>Remove</Trans> : <Trans>Add</Trans>}
      </NoddiDummyButton>
    </div>
  );
};
