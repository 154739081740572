import { Money } from '../../adminapp';
import { WheelSetProps } from '../../customerapp';
import { CarGeneration, CarMinimalInfo, CarSize, Currency, Language, StorageFacility, UserGroup } from '../../shared';
import { TireFromInventorySupplier } from '../tireFromInventory';

interface CarInTireOffer {
  id: number;
  carGeneration: CarGeneration;
  licensePlateNumber: string;
  vinNumber: string;
  carSize?: CarSize;
  carSizeId: number;
  height?: number;
  m3?: number;
  weight?: number;
  countryCode: string;
  wheelSets: WheelSetProps[];
}

export interface TireOfferV2 {
  carWheelSet: WheelSetProps & { car: CarMinimalInfo };
  car: CarInTireOffer;
  createdAt: string;
  createdBy: number;
  genericItems: { name: string; id: number; price: Money; description: string }[];
  id: number;
  isPending: boolean;
  isRejected: boolean;
  isSelected: boolean;
  quoteItems: TireQuoteItem[];
  rejectedAt: string;
  selectedAt: string;
  slug: string;
  updatedAt: string;
  updatedBy: number;
  userGroup: UserGroup;
  views: TireOfferView[];
  status: QuoteItemStatusType;
}
export interface TireOfferMinimal {
  car: CarInTireOffer;
  createdAt: string;
  createdBy: number;
  id: number;
  isPending: boolean;
  isRejected: boolean;
  isSelected: boolean;
  rejectedAt: string;
  selectedAt: string;
  slug: string;
  updatedAt: string;
  updatedBy: number;
  userGroup: UserGroup;
  views: TireOfferView[];
  status: QuoteItemStatusType;
}

export const staggeredPositions = ['FRONT', 'REAR'] as const;
export type StaggeredPosition = (typeof staggeredPositions)[number];
export interface TireInQuoteItem {
  tireInventory: TireFromInventorySupplier;
  quantityDefault: number;
  quantityMax: number;
  quantityMin: number;
  priceAdHoc?: Money;
  quantitySelected?: number;
  staggeredPosition?: { value: StaggeredPosition; label: string };
  id: number;
  inventoryOrder?: InventoryOrder;
}

export interface TireQuoteItem {
  model: string;
  description: string;
  isRecommended: boolean;
  quoteItemTires: TireInQuoteItem[];
  id: number;
  isSelected: boolean;
}

export interface TireQuoteSalesItem {
  id: number;
  currency: Currency;
  description: string;
  name: string;
  price: number;
  shortDescription: string;
}

interface ViewedByUser {
  email: string;
  id: number;
  language: Language;
  name: string;
  phoneNumber: string;
}

interface TireOfferView {
  viewedAt: string;
  viewedBy?: ViewedByUser;
}

export type TireOfferSeason = 'SUMMER' | 'WINTER';
export interface TireQuotePayload {
  carId: number;
  season: TireOfferSeason;
  genericItemsIds: number[];
  quoteItems: {
    isRecommended: boolean;
    quoteItemTires: {
      tireInventoryId: number;
      quantityDefault: number;
      quantityMin: number;
      quantityMax: number;
      quantitySelected: number;
    }[];
  }[];
}

export interface CarTireQuoteFilters {
  carIds?: number[];
  season?: TireOfferSeason;
  genericItemsIds?: number[];
  userGroupIds?: number[];
  isStaggered?: boolean | null;
  isSelected?: boolean | null;
  isRejected?: boolean | null;
  status?: QuoteItemStatusType | null;
}

export interface InventoryOrderCreationPayload {
  description?: string;
  orderNumber: string;
  price: Money;
  trackingNumber?: string;
  vatPercentage: number;
  status: InventoryOrderStatus;
  storageFacilityId: number;
  estimatedDeliveryDate?: string;
}

export interface InventoryOrder {
  createdAt: string;
  description: string;
  id: number;
  orderNumber: string;
  price: Money;
  status: InventoryOrderStatus;
  trackingNumber: string;
  updatedAt: string;
  vatPercentage: number;
  estimatedDeliveryDate?: string;
  storageFacility: StorageFacility;
}

export const inventoryOrderStatuses = {
  ORDERED_AT_SUPPLIER: 'ORDERED_AT_SUPPLIER',
  IN_TRANSIT: 'IN_TRANSIT',
  RECEIVED: 'RECEIVED',
  INVENTORY_IN_STOCK: 'INVENTORY_IN_STOCK'
} as const;

export type InventoryOrderStatus = (typeof inventoryOrderStatuses)[keyof typeof inventoryOrderStatuses];

export const quoteItemStatus = {
  CREATED: 'CREATED',
  PENDING: 'PENDING',
  REJECTED: 'REJECTED',
  WITHDRAWN: 'WITHDRAWN',
  ACCEPTED: 'ACCEPTED',
  INVENTORY_ORDERED_AT_SUPPLIERS_IN_FULL: 'INVENTORY_ORDERED_AT_SUPPLIERS_IN_FULL',
  INVENTORY_ORDERED_AT_SUPPLIERS_PARTIAL: 'INVENTORY_ORDERED_AT_SUPPLIERS_PARTIAL',
  INVENTORY_RECEIVED_IN_FULL: 'INVENTORY_RECEIVED_IN_FULL',
  INVENTORY_RECEIVED_PARTIAL: 'INVENTORY_RECEIVED_PARTIAL',
  FULFILLED_WAITING_PAYMENT: 'FULFILLED_WAITING_PAYMENT',
  FULFILLED_WITH_PAYMENT: 'FULFILLED_WITH_PAYMENT'
} as const;

export const quoteItemStatusToLabel = {
  CREATED: 'Created',
  PENDING: 'Pending',
  REJECTED: 'Rejected',
  WITHDRAWN: 'Withdrawn',
  ACCEPTED: 'Accepted',
  INVENTORY_ORDERED_AT_SUPPLIERS_IN_FULL: 'Inventory ordered at suppliers in full',
  INVENTORY_ORDERED_AT_SUPPLIERS_PARTIAL: 'Inventory ordered at suppliers in partial',
  INVENTORY_RECEIVED_IN_FULL: 'Inventory received in full',
  INVENTORY_RECEIVED_PARTIAL: 'Inventory received in partial',
  FULFILLED_WAITING_PAYMENT: 'Fulfilled waiting payment',
  FULFILLED_WITH_PAYMENT: 'Fulfilled with payment'
} as const;

export const quoteItemStatusesOptions = Object.values(quoteItemStatus).map((status) => ({
  label: quoteItemStatusToLabel[status],
  value: status
}));

export type QuoteItemStatusType = (typeof quoteItemStatus)[keyof typeof quoteItemStatus];
