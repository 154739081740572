import { t } from '@lingui/macro';
import { NoddiCollapseCard } from 'noddi-ui';
import { formatCurrencyAmount } from 'noddi-util';
import { useState } from 'react';

import { SALES_ITEM_TYPE } from 'noddi-async/src/types';
import { SalesItemContainer, SalesItemWrapper } from '../../components/salesItems/SalesItemWrapper';
import { ServiceCategoryHeader } from '../../components/salesItems/ServiceCategoryHeader';
import { SalesItemMultipleCars } from './SalesItemFleet';
import { ServiceItemsMappedForFleetWithCars } from './SalesItemsFleetSelector';

type ServiceCategoryCollapseCardProps = {
  numberOfSelectedSalesItems: number;
  salesItems: ServiceItemsMappedForFleetWithCars[];
  category: string;
  salesItemType: SALES_ITEM_TYPE;
  mountAsExpanded?: boolean;
};

export const ServiceCategoryFleetCollapseCard = ({
  numberOfSelectedSalesItems,
  salesItemType,
  category,
  salesItems,
  mountAsExpanded
}: ServiceCategoryCollapseCardProps) => {
  const [isExpanded, setIsExpanded] = useState(false);

  // I guess we dont want the groups to say from 0 kr?
  const priceFrom = salesItems
    .filter((item) => item.price.amount > 0)
    .reduce((acc, item) => Math.min(acc, item.price.amount), Infinity);

  const currency = salesItems[0]?.price.currency;

  return (
    <NoddiCollapseCard
      sx={{ padding: 0, borderRadius: '16px' }}
      preventHandleExpand
      expandedChanged={(expanded) => setIsExpanded(expanded)}
      mountAsExpanded={mountAsExpanded}
      hideChevron
      header={
        <ServiceCategoryHeader
          numberOfSelectedSalesItems={numberOfSelectedSalesItems}
          priceFrom={`${t`from`} ${formatCurrencyAmount(priceFrom, 0, currency)}`}
          category={category}
          isExpanded={isExpanded}
        />
      }
      collapseBody={
        <SalesItemContainer>
          {salesItems.map((item) => (
            <SalesItemWrapper key={item.name}>
              <SalesItemMultipleCars type={salesItemType} salesItem={item} />
            </SalesItemWrapper>
          ))}
        </SalesItemContainer>
      }
    />
  );
};
