import { t, Trans } from '@lingui/macro';
import { noddiAsync, URLKeys } from 'noddi-async';
import { useAuthContext } from 'noddi-provider';
import { CouponCard, NoddiBasicCard } from 'noddi-ui';
import { useParams } from 'react-router-dom';

import getCommonTranslations from '../../commonTranslations';
import { BenefitsLogin } from '../../components/BenefitsLogin';
import { ErrorPageWithTranslations } from '../../components/ErrorPageWithTrans';
import ContentWrapper from '../../components/Layouts/ContentWrapper';
import NewBookingButton from '../../components/NewBookingButton/NewBookingButton';
import { NoddiLoadingScreenWithTrans } from '../../components/NoddiLoadingScreenWithTrans';
import { getImageSrc } from '../../utils/resolveCompanyLogo';
import ActivateCampaign from './ActivateCampaign';

const Coupons = () => {
  const data = useParams<{ slug: string }>();
  const { isLoggedIn, canActivateCouponsForNewUsersOnly } = useAuthContext();
  const safeSlug = data.slug?.toLocaleLowerCase();

  const {
    data: campaignData,
    isPending: isCampaignPending,
    error: campaignError
  } = noddiAsync.useGet({ type: URLKeys.getCampaignFromSlug, input: { slug: safeSlug } });

  if (isCampaignPending) {
    return <NoddiLoadingScreenWithTrans />;
  }

  if (campaignError) {
    return <ErrorPageWithTranslations apiError={campaignError} />;
  }

  const showOnlyForNewUsers = isLoggedIn && campaignData.isForNewUserOnly && !canActivateCouponsForNewUsersOnly;

  return (
    <ContentWrapper
      hideBackButton
      subtitle={campaignData.name}
      title={showOnlyForNewUsers ? undefined : `${t`Hurray`}🥳`}
    >
      <div className='text-primary-darkPurple'>
        {showOnlyForNewUsers ? (
          <NoddiBasicCard className='flex flex-col gap-4'>
            <p className='text-5'>
              <Trans>Your user cannot activate coupons from this campaign</Trans> 😭
            </p>
            <p>
              <Trans>This promotion is only for new users without existing coupons</Trans>
            </p>
            <NewBookingButton />
          </NoddiBasicCard>
        ) : (
          <div className='flex flex-col gap-4'>
            {getImageSrc(safeSlug) && <img src={getImageSrc(safeSlug)} alt='Campaign logo' />}

            {!isLoggedIn && <BenefitsLogin />}

            {isLoggedIn && <ActivateCampaign campaign={campaignData} />}
            <div className='grid grid-cols-1 gap-6 md:grid-cols-2'>
              {campaignData.couponGroup.couponTemplates.map((couponTemplate) => (
                <CouponCard
                  key={couponTemplate.id}
                  namePublic={couponTemplate.namePublic}
                  descriptionPublic={couponTemplate.descriptionPublic}
                  discountAmount={couponTemplate.discount?.amount}
                  currency={couponTemplate.discount?.currency}
                  discountPercentage={couponTemplate.discountPercentage}
                  translations={{
                    activated: getCommonTranslations().activated,
                    used: getCommonTranslations().used,
                    amount: getCommonTranslations().amount,
                    discount: getCommonTranslations().discount,
                    free: getCommonTranslations().free
                  }}
                />
              ))}
            </div>
          </div>
        )}
      </div>
    </ContentWrapper>
  );
};

export default Coupons;
