import { Trans } from '@lingui/macro';
import { ShoppingCart } from 'noddi-async/src/types';
import { cn, getCarDisplayName, NoddiBasicCard } from 'noddi-ui';
import { formatCurrencyAmount } from 'noddi-util';
import { NoddiCarSvg } from '../../components/Home/CarSvg';
type PriceSummaryProps = {
  shoppingCart: ShoppingCart;
};

export const PriceSummaryCardEdition = ({ shoppingCart }: PriceSummaryProps) => {
  const { cars, couponDiscounts, membershipDiscounts } = shoppingCart;

  // I guess backend could refactor to just give us total discount but whatever
  const discounts = [...couponDiscounts, ...membershipDiscounts];

  const totalDiscount = discounts.reduce((acc, discount) => acc + discount.discount.amount, 0);

  return (
    <div className='flex flex-col gap-2'>
      {cars.map((car) => (
        <NoddiBasicCard
          key={car.licensePlate.number}
          className={cn('flex flex-col gap-1 bg-secondary-lightPurple/50 shadow-none')}
        >
          <div className='flex items-center justify-between gap-4'>
            <h1 className='font-semibold'>{getCarDisplayName(car)}</h1>
            <NoddiCarSvg scale={0.5} />
          </div>

          <div className='flex flex-col'>
            {car.salesItems
              .toSorted((a, b) => b.price.amount - a.price.amount)
              .map(({ name, price }) => (
                <div key={`${car.licensePlate.number} - ${name}`} className='flex w-full justify-between gap-4'>
                  <span>{name}</span>
                  <span>{formatCurrencyAmount(price.amount, 0, price.currency)}</span>
                </div>
              ))}
          </div>
        </NoddiBasicCard>
      ))}

      <div className='px-5'>
        {discounts[0] !== undefined && (
          <div className='flex justify-between gap-4'>
            <span>
              <Trans>Discount</Trans>
            </span>
            <span>{formatCurrencyAmount(totalDiscount, 0, discounts[0].discount.currency)}</span>
          </div>
        )}
      </div>
    </div>
  );
};
