import { tracking, useAuthContext } from 'noddi-provider';

import { AvailableBookingTimeWindow } from 'noddi-async/src/types';
import { BookingTimeWindowPicker } from 'noddi-ui';
import getCommonTranslations from '../../../../commonTranslations';
import { GoogleTrackingService } from '../../../../services/GoogleTrackingService';
import { GtmEvents } from '../../../../types/gtmTracking';
import { mapToDeliveryWindow } from '../../../AutoRecall/TimeWindowPicker/TimeWindowPicker';
import {
  useBookingActions,
  useBookingAddress,
  useBookingSelectedSalesItemsCars,
  useBookingTimeWindow
} from '../../BookingStore';
import { NewBookingErrorPage } from '../../components/NewBookingErrorPage';
import { getSalesItemCarsPayload } from '../../helpers/utils';
import { WrapperWithModalButton } from './WrapperWithModalButton';

export const TimeWindowPicker = () => {
  const { isSuperUser } = useAuthContext();

  const selectedSalesItemsCars = useBookingSelectedSalesItemsCars();
  const selectedAddress = useBookingAddress();
  const selectedBookingTimeWindow = useBookingTimeWindow();
  const { setSelectedTimeWindow } = useBookingActions();

  const onSelect = (timeWindow: AvailableBookingTimeWindow) => {
    setSelectedTimeWindow(timeWindow);
    GoogleTrackingService.trackEvent(GtmEvents.addShippingInfo);
    GoogleTrackingService.trackEvent(GtmEvents.beginCheckout);
  };

  const selectedDeliveryWindow = mapToDeliveryWindow(selectedBookingTimeWindow);

  if (!selectedAddress) {
    return <NewBookingErrorPage />;
  }

  return (
    <WrapperWithModalButton>
      <BookingTimeWindowPicker
        address={selectedAddress}
        cars={getSalesItemCarsPayload(selectedSalesItemsCars)}
        onUnavailableSelect={(timeWindow) => {
          if (!isSuperUser) {
            return;
          }
          onSelect(timeWindow);
        }}
        selectedDeliveryWindow={selectedDeliveryWindow}
        onAvailableSelect={(timeWindow) => {
          if (
            selectedDeliveryWindow?.endsAt !== timeWindow.endsAt ||
            selectedDeliveryWindow?.startsAt !== timeWindow.startsAt
          ) {
            tracking.track('timeWindowChanged');
          }
          onSelect(timeWindow);
        }}
        translations={getCommonTranslations().timePicker}
      />
    </WrapperWithModalButton>
  );
};
