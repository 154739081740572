import { t, Trans } from '@lingui/macro';
import { BookingDataHome, BookingDataHomeBase } from 'noddi-async/src/types';
import { NoddiBasicCard, NoddiChip } from 'noddi-ui';
import { customerRoutes, DateFormats, noddiFormatDate } from 'noddi-util';
import { useNavigate } from 'react-router-dom';

import { TZDate } from '@date-fns/tz';
import { cn, noddiColors, NoddiDummyButton, NoddiIcon } from 'noddi-ui-common';

type BookingCardProps = {
  booking: BookingDataHome | BookingDataHomeBase;
  isUpcomingBooking?: boolean;
};

const BookingCard = ({ booking, isUpcomingBooking }: BookingCardProps) => {
  const navigate = useNavigate();

  const { address, bookingItems, deliveryWindowEndsAt, deliveryWindowStartsAt } = booking;
  const bookingTime = `${noddiFormatDate(new TZDate(deliveryWindowStartsAt, address.timeZone), DateFormats.TIME)} - ${noddiFormatDate(new TZDate(deliveryWindowEndsAt, address.timeZone), DateFormats.TIME)}`;
  const bookingDate = `${noddiFormatDate(
    new TZDate(deliveryWindowStartsAt, address.timeZone),
    DateFormats.FULL_WEEKDAY_MONTH_AND_YEAR
  )}`;

  const PaymentStatusChip = () => {
    const bookingData = booking as BookingDataHome;
    if (bookingData.isPaid) {
      return <NoddiChip variant='success' label={t`Paid`} />;
    } else if (bookingData.isInvoiced) {
      return <NoddiChip variant='warning' label={t`Invoiced`} />;
    } else {
      return <NoddiChip variant='destructive' label={t`Unpaid`} />;
    }
  };

  return (
    <NoddiBasicCard
      onClick={() => navigate(customerRoutes.myBookingDetails.getPath({ id: booking.id }))}
      className='relative flex flex-col gap-4'
    >
      {!isUpcomingBooking && (
        <div className='absolute right-5 top-4 flex justify-end gap-2'>
          <PaymentStatusChip />
        </div>
      )}
      <div className='flex flex-col gap-2'>
        {bookingItems.map(({ car: { make, model, licensePlateNumber }, orderLines }, bookingItemIdx) => (
          <div key={bookingItemIdx} className='flex flex-col gap-2'>
            <div>
              <p className={cn('font-bold text-5', bookingItemIdx === 0 && 'mr-26')}>{`${make} ${model}`}</p>
              <p className='text-5'>{licensePlateNumber}</p>
            </div>

            <div className='flex flex-wrap gap-x-2'>
              {orderLines.map(({ description }, serviceIndex) => (
                <span key={licensePlateNumber + serviceIndex}>
                  {description}
                  {serviceIndex < orderLines.length - 1 && ','}
                </span>
              ))}
            </div>
          </div>
        ))}
      </div>
      {isUpcomingBooking && (
        <div className='flex flex-col gap-1'>
          <div className='flex items-center gap-2'>
            <div>
              <NoddiIcon color={noddiColors.primary.darkPurple} name='ClockCircle' />
            </div>
            <p>{bookingTime}</p>
          </div>
          <div className='flex items-center gap-2'>
            <div>
              <NoddiIcon color={noddiColors.primary.darkPurple} name='Calendar' />
            </div>
            <p className='capitalize'>{bookingDate}</p>
          </div>
          <div className='flex items-center gap-2'>
            <div>
              <NoddiIcon name='LocationPin' color={noddiColors.primary.darkPurple} />
            </div>
            <p>
              {address.streetName} {address.streetNumber}
            </p>
          </div>
        </div>
      )}

      {!isUpcomingBooking && (
        <div className='flex items-center gap-2'>
          <div>
            <NoddiIcon color={noddiColors.primary.darkPurple} name='Calendar' />
          </div>
          <p className='capitalize'>{bookingDate}</p>
        </div>
      )}

      <div className='flex h-full items-end justify-end'>
        <NoddiDummyButton variant='link' className='-mb-2 -mr-4' endIcon='ArrowRight'>
          <Trans>See booking</Trans>
        </NoddiDummyButton>
      </div>
    </NoddiBasicCard>
  );
};

export default BookingCard;
