import { customerRoutes } from 'noddi-util';

export const addressStepPath = customerRoutes.newBookingAddress.getPath();
export const carStepPath = customerRoutes.newBookingCars.getPath();
export const salesItemStepPath = customerRoutes.newBookingPrimaryItems.getPath();
export const tierHotelCancelStepPath = customerRoutes.newBookingCancelTireHotel.getPath();
export const tierHotelPickupStepPath = customerRoutes.newBookingTierHotel.getPath();
export const addonsStepPath = customerRoutes.newBookingAddons.getPath();
export const timeWindowStepPath = customerRoutes.newBookingTimeWindow.getPath();
export const summaryStepPath = customerRoutes.newBookingSummary.getPath();
