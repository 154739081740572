import { Trans } from '@lingui/macro';
import { NoddiBasicCard } from 'noddi-ui';
import { noddiColors, NoddiDummyButton } from 'noddi-ui-common';
import { cn } from 'noddi-ui/src/helpers/utils';
import { NoddiCarSvg } from '../../../../components/Home/CarSvg';

type CarCardProps = {
  title: string;
  description?: string;
  isSelected: boolean;
  action: () => void;
};

export const SelectCarInBookingCard = ({ title, isSelected, description, action }: CarCardProps) => {
  return (
    <NoddiBasicCard
      onClick={action}
      className={cn('flex justify-between gap-4', isSelected && 'bg-primary-purple text-primary-white')}
    >
      <div className='flex items-center gap-4'>
        <NoddiCarSvg roofColor={isSelected ? noddiColors.primary.darkPurple : undefined} scale={0.5} />
        <div className='flex flex-col'>
          <span className='font-semibold'>{title}</span>
          {description && <span>{description}</span>}
        </div>
      </div>
      <NoddiDummyButton startIcon={isSelected ? 'Minus' : 'Plus'} variant='secondary' size='small'>
        {isSelected ? <Trans>Remove </Trans> : <Trans>Add </Trans>}
      </NoddiDummyButton>
    </NoddiBasicCard>
  );
};
