import { AddHome } from '../svg/AddHome';
import { Alert } from '../svg/Alert';
import { AltArrowDown } from '../svg/AltArrowDown';
import { AltArrowLeft } from '../svg/AltArrowLeft';
import { AltArrowRight } from '../svg/AltArrowRight';
import { AltArrowUp } from '../svg/AltArrowUp';
import { ArrowDown } from '../svg/ArrowDown';
import { ArrowLeft } from '../svg/ArrowLeft';
import { ArrowRight } from '../svg/ArrowRight';
import { ArrowSquareRightCorner } from '../svg/ArrowSquareRightCorner';
import { ArrowUp } from '../svg/ArrowUp';
import { Bill } from '../svg/Bill';
import { Calendar } from '../svg/Calendar';
import { CalendarFilled } from '../svg/CalendarFilled';
import { Camera } from '../svg/Camera';
import { Car } from '../svg/Car';
import { ChatRounded } from '../svg/ChatRounded';
import { Check } from '../svg/Check';
import { CheckRounded } from '../svg/CheckRounded';
import { CheckedCircle } from '../svg/CheckedCircle';
import { CheckedRectangle } from '../svg/CheckedRectangle';
import { ClockCircle } from '../svg/ClockCircle';
import { CloseRounded } from '../svg/CloseRounded';
import { Copy } from '../svg/Copy';
import { Cross } from '../svg/Cross';
import { Cup } from '../svg/Cup';
import { DottedClockCircle } from '../svg/DottedClockCircle';
import { DownArrowAlt } from '../svg/DownArrowAlt';
import { Download } from '../svg/Download';
import { Drop } from '../svg/Drop';
import { Earth } from '../svg/Earth';
import { Edit } from '../svg/Edit';
import { Exclamation } from '../svg/Exclamation';
import { Factory } from '../svg/Factory';
import { File } from '../svg/File';
import { FileUpload } from '../svg/FileUpload';
import { FilledCircle } from '../svg/FilledCircle';
import { FilledCircleChecked } from '../svg/FilledCircleChecked';
import { FilledCircleCross } from '../svg/FilledCircleCross';
import { Filter } from '../svg/Filter';
import { Gallery } from '../svg/Gallery';
import { Globe } from '../svg/Globe';
import { HamburgerMenu } from '../svg/HamburgerMenu';
import { Heart } from '../svg/Heart';
import { Home } from '../svg/Home';
import { HomeFilled } from '../svg/HomeFilled';
import { HourGlass } from '../svg/HourGlass';
import { Info } from '../svg/Info';
import { LeftArrowAlt } from '../svg/LeftArrowAlt';
import { LocationPin } from '../svg/LocationPin';
import { Logout } from '../svg/Logout';
import { Magnifier } from '../svg/Magnifier';
import { MapArrows } from '../svg/MapArrows';
import { MenuDots } from '../svg/MenuDots';
import { Minus } from '../svg/Minus';
import { Money } from '../svg/Money';
import { MoneyCase } from '../svg/MoneyCase';
import { OutlinedCircle } from '../svg/OutlinedCircle';
import { Pause } from '../svg/Pause';
import { Phone } from '../svg/Phone';
import { Plus } from '../svg/Plus';
import { PlusCircle } from '../svg/PlusCircle';
import { QrScan } from '../svg/QrScan';
import { QuestionMark } from '../svg/QuestionMark';
import { Rectangle } from '../svg/Rectangle';
import { RightArrowAlt } from '../svg/RightArrowAlt';
import { Ruler } from '../svg/Ruler';
import { Sad } from '../svg/Sad';
import { Settings } from '../svg/Settings';
import { ShoppingCart } from '../svg/ShoppingCart';
import { Square } from '../svg/Square';
import { Star } from '../svg/Star';
import { TrashBin } from '../svg/TrashBin';
import { UpArrowAlt } from '../svg/UpArrowAlt';
import { User } from '../svg/User';
import { UserCircle } from '../svg/UserCircle';
import { UserCircleFilled } from '../svg/UserCircleFilled';
import { Users } from '../svg/Users';
import { Warning } from '../svg/Warning';
import { Wheel } from '../svg/Wheel';
import { WheelAngle } from '../svg/WheelAngle';
import { WheelChange } from '../svg/WheelChange';

export const iconMap = {
  Cup,
  Filter,
  AddHome,
  LeftArrowAlt,
  DownArrowAlt,
  Exclamation,
  HourGlass,
  ArrowUp,
  ArrowDown,
  Alert,
  UpArrowAlt,
  ArrowLeft,
  ArrowRight,
  Download,
  ArrowSquareRightCorner,
  Bill,
  HamburgerMenu,
  ShoppingCart,
  Calendar,
  CalendarFilled,
  CheckRounded,
  ClockCircle,
  ChatRounded,
  CheckedRectangle,
  FilledCircleCross,
  CheckedCircle,
  Check,
  Cross,
  Camera,
  Drop,
  DottedClockCircle,
  Edit,
  Earth,
  File,
  FilledCircleChecked,
  FilledCircle,
  Factory,
  FileUpload,
  Info,
  Home,
  Heart,
  HomeFilled,
  QuestionMark,
  RightArrowAlt,
  Rectangle,
  Settings,
  Star,
  UserCircle,
  User,
  WheelAngle,
  AltArrowDown,
  AltArrowLeft,
  AltArrowRight,
  AltArrowUp,
  Warning,
  Magnifier,
  MoneyCase,
  Car,
  Wheel,
  Logout,
  LocationPin,
  PlusCircle,
  Plus,
  Phone,
  MapArrows,
  Minus,
  Globe,
  Gallery,
  TrashBin,
  Ruler,
  OutlinedCircle,
  UserCircleFilled,
  QrScan,
  CloseRounded,
  MenuDots,
  Users,
  Money,
  Copy,
  Square,
  Pause,
  WheelChange,
  Sad
};

export type IconName = keyof typeof iconMap;

export const sizeMap = {
  small: 16,
  medium: 24,
  large: 32,
  xLarge: 48,
  xxLarge: 64
} as const;

export type IconSize = keyof typeof sizeMap;
