import { Form, Formik } from 'formik';
import { URLKeys, noddiAsync } from 'noddi-async';
import { invalidateQueryExactMatch } from 'noddi-async/src/utils';
import { useAuthContext } from 'noddi-provider';

import {
  ApiErrorMessage,
  LoadingScreen,
  NoddiBasicCard,
  NoddiFormSelect,
  NoddiFormTextInput,
  SimpleGridLayout,
  commonTexts,
  getLanguagePropertiesFromCountryCode
} from 'noddi-ui';
import { NoddiButton } from 'noddi-ui-common';
import * as Yup from 'yup';
import { useNoddiToast } from '../../providers/toast';

const getValidationSchema = ({ validationText }: { validationText: string }) =>
  Yup.object({
    firstName: Yup.string().required(validationText),
    lastName: Yup.string().required(validationText)
  });

interface AccountProps {
  translations?: {
    profileUpdatedSuccessfully: string;
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber: string;
    save: string;
    language: string;
    validation: {
      required: string;
    };
  };
}

export const Account = ({ translations }: AccountProps) => {
  const { userData } = useAuthContext();
  const { noddiToast } = useNoddiToast();

  const texts = translations ?? {
    profileUpdatedSuccessfully: commonTexts.profileUpdatedSuccessfully,
    firstName: commonTexts.firstName,
    lastName: commonTexts.lastName,
    email: commonTexts.email,
    phoneNumber: commonTexts.phoneNumber,
    language: commonTexts.language,
    save: commonTexts.save,
    validation: {
      required: commonTexts.required
    }
  };

  const {
    data: user,
    error: userError,
    isPending: isUserPending
  } = noddiAsync.useGet({
    type: URLKeys.getUser,
    input: { id: userData?.user.id },
    queryConfig: {
      enabled: !!userData?.user.id
    }
  });

  const { mutateAsync: updateUser } = noddiAsync.usePatch({
    type: URLKeys.patchUser,
    queryConfig: {
      onSuccess: async () => {
        invalidateQueryExactMatch({ urlKey: URLKeys.getUser });
        noddiToast.success(texts.profileUpdatedSuccessfully);
      },
      onError: async (error) => {
        noddiToast.error(error.message);
      }
    }
  });

  const {
    data: languages,
    error: languagesError,
    isPending: isLanguagesPending
  } = noddiAsync.useGet({
    type: URLKeys.getLanguages
  });

  if (!userData || isUserPending || isLanguagesPending) {
    return <LoadingScreen />;
  }

  if (userError || languagesError) {
    return <ApiErrorMessage error={[userError, languagesError]} />;
  }

  const languageOptions = languages.map((language) => ({
    label: (
      <div className='flex items-center gap-2'>
        <span className='text-5'>{getLanguagePropertiesFromCountryCode(language.code).flag}</span>
        {getLanguagePropertiesFromCountryCode(language.code).name}
      </div>
    ),
    value: language.code
  }));

  return (
    <Formik
      initialValues={{
        email: user.email,
        firstName: user.firstName,
        lastName: user.lastName,
        phoneNumber: user.phoneNumber,
        language: user.language.code
      }}
      enableReinitialize
      validationSchema={getValidationSchema({ validationText: texts.validation.required })}
      onSubmit={async (values) => {
        const valuesToSubmit = {
          id: user.id,
          email: values.email,
          firstName: values.firstName,
          lastName: values.lastName,
          phoneNumber: values.phoneNumber,
          languageCode: values.language
        };

        await updateUser({
          ...valuesToSubmit
        });
      }}
    >
      {({ isValid, isSubmitting, submitForm }) => (
        <Form>
          <div className='flex w-full flex-col gap-4'>
            <NoddiBasicCard className='text-primary-darkPurple'>
              <SimpleGridLayout numColumns={2}>
                <NoddiFormTextInput name='firstName' label={texts.firstName} />
                <NoddiFormTextInput name='lastName' label={texts.lastName} />
                <NoddiFormTextInput name='email' label={texts.email} disabled />
                <NoddiFormTextInput name='phoneNumber' label={texts.phoneNumber} disabled />
                <NoddiFormSelect name='language' fullWidth label={texts.language} options={languageOptions} />
              </SimpleGridLayout>
            </NoddiBasicCard>

            <div className='flex w-full justify-end'>
              <NoddiButton disabled={!isValid} loading={isSubmitting} onPress={submitForm}>
                {texts.save}
              </NoddiButton>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};
