import { Money } from '../../adminapp';
import { AddressOutput, Brand } from '../address';
import { CarSize } from '../cars';

export interface SalesItemListItem {
  id: number;
  name: string;
  price: Money;
  carSizes: CarSize[];
}

export type SalesItemV2 = {
  salesItemId: number;
  name: string;
  description: string;
  shortDescription: string;
  price: Money;
  uiSortOrder: number;
  bookingCategorySlug: string | null;
  mutuallyExclusiveSalesItemIds: number[];
  isWheelPickupRequired: boolean;
  isAddonsAvailable: boolean;
};

export type TierHotelPickupSalesItemOption = {
  requiresPickupAddressSelection: boolean;
} & SalesItemV2;

export type SalesItemsCar = {
  licensePlate: LicensePlateInfo;
  make: string;
  model: string;
  salesItems: SalesItemV2[];
};

export type AvailableSalesItemsForBooking = {
  cars: SalesItemsCar[];
};

export interface ConflictingSalesItem {
  salesItemId: number;
  name: string;
}

export interface UnavailableSalesItem {
  salesItemId: number;
  conflictingSelectedSalesItems: ConflictingSalesItem[];
}

export enum BOOKING_TYPES {
  NORMAL = 'NORMAL',
  CANCEL_TIER_HOTEL = 'CANCEL_TIER_HOTEL',
  AUTO_RECALL = 'AUTO_RECALL'
}

export enum SALES_ITEM_TYPE {
  PRIMARY = 'PRIMARY',
  ADDON = 'ADDON',
  TIRE_HOTEL_PICKUP = 'TIRE_HOTEL_PICKUP',
  TIRE_HOTEL_CANCEL = 'TIRE_HOTEL_CANCEL'
}

export type LicensePlateInfo = {
  number: string;
  countryCode: string;
};

export interface ShoppingCartSalesItem {
  salesItemId: number;
  name: string;
  price: Money;
}

export interface ShoppingCartMembershipDiscount {
  membershipDiscountId: number;
  salesItemId: number;
  name: string;
  discount: Money;
}

export interface ShoppingCartCar {
  licensePlate: LicensePlateInfo;
  make: string;
  model: string;
  salesItems: ShoppingCartSalesItem[];
}

export interface ShoppingCartCouponDiscount {
  couponId: number;
  name: string;
  discount: Money;
}

export type ShoppingCart = {
  cars: ShoppingCartCar[];
  couponDiscounts: ShoppingCartCouponDiscount[];
  membershipDiscounts: ShoppingCartMembershipDiscount[];
  totalPrice: Money;
  deliveryWindowFee: Money | null;
};

export interface AutoRecallSalesItem {
  salesItemId: number;
  name: string;
  price: Money;
  isWheelPickupRequired: boolean;
  isAddonsAvailable: boolean;
  // This is BOOKING_TYPES enum in frontend -.-
  isPrimary: boolean;
  isAddon: boolean;
  isTireHotelPickup: boolean;
  isTireHotelCancel: boolean;
  // Todo get this from api
  // metaData?: SalesItemMetaData;
}

export type AutoRecallCars = {
  licensePlate: LicensePlateInfo;
  make: string;
  model: string;
  salesItems: AutoRecallSalesItem[];
  hasActiveTireHotelContract: boolean;
};

export type AutoRecallBooking = {
  bookingId: number;
  address: AddressOutput;
  deliveryWindowStartsAt: string;
  deliveryWindowEndsAt: string;
  cars: AutoRecallCars[];
  userFirstName: string;
  couponDiscounts: ShoppingCartCouponDiscount[];
  membershipDiscounts: ShoppingCartMembershipDiscount[];
  totalPrice: Money;
  deliveryWindowFee: Money | null;
  brand: Brand;
};

export type SelectedSalesItemsCarGrouped = {
  licensePlate: LicensePlateInfo;
  selectedSalesItemIds: number[];
  pickupAddressId?: number;
};

export type DeliveryWindow = {
  deliveryWindowId: number;
  startsAt: string;
  endsAt: string;
};

export type SelectedSalesItemsCar = {
  licensePlate: LicensePlateInfo;
  salesItemId: number;
  type: SALES_ITEM_TYPE;
  metaData?: SalesItemMetaData;
  isWheelPickupRequired: boolean;
  isAddonsAvailable: boolean;
};

export type SalesItemMetaData = {
  selectedTierHotelPickUpAddress: AddressOutput;
};

export type SalesItemOption = {
  id: number;
  name: string;
};
