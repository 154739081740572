import { AvailableBookingTimeWindow } from 'noddi-async/src/types';
import { useAuthContext } from 'noddi-provider';
import { cn, webButtonStyling } from 'noddi-ui-common';
import { formatCurrencyAmount } from 'noddi-util';
import { isTimeWindowUnavailable, parseTimeSlotFromPublicTimes } from './utils';

type TimeWindowCardProps = {
  timeWindow: AvailableBookingTimeWindow;
  translations: {
    soldOut: string;
  };
  onAvailableSelect: (bookingTimeWindow: AvailableBookingTimeWindow) => void;
  onUnavailableSelect?: (bookingTimeWindow: AvailableBookingTimeWindow) => void;
  selectedDeliveryWindow?: { startsAt: string; endsAt: string };
};

export const TimeWindowCard = ({
  timeWindow,
  onAvailableSelect,
  onUnavailableSelect,
  translations,
  selectedDeliveryWindow
}: TimeWindowCardProps) => {
  const { isSuperUser } = useAuthContext();

  const isUnavailable = isTimeWindowUnavailable({ timeWindow });

  const { startsAt, endsAt, timeZone, deliveryWindowFee } = timeWindow;

  const isSelected =
    !!selectedDeliveryWindow &&
    selectedDeliveryWindow.startsAt === startsAt &&
    selectedDeliveryWindow.endsAt === endsAt;

  const handleOnClick = () => {
    if (isUnavailable) {
      onUnavailableSelect?.(timeWindow);
    } else {
      onAvailableSelect(timeWindow);
    }
  };

  const priceText = isUnavailable
    ? translations.soldOut
    : formatCurrencyAmount(deliveryWindowFee.amount, 0, deliveryWindowFee.currency);

  const formattedSlot = parseTimeSlotFromPublicTimes({
    startsAt,
    endsAt,
    timeZone
  });

  return (
    <button
      onClick={handleOnClick}
      disabled={isUnavailable && !isSuperUser}
      className={cn(
        'flex h-16 w-[70px] flex-col items-center justify-center gap-1 rounded-xl bg-secondary-lightPurple p-2 text-secondary-black xs:w-[87px]',
        webButtonStyling,
        isUnavailable && 'border border-secondary-silverGray bg-primary-white opacity-40',
        isSelected && 'border-2 border-primary-darkPurple opacity-100 shadow-lg'
      )}
    >
      <span>{formattedSlot}</span>
      <span className='text-3'>{priceText}</span>
    </button>
  );
};
