import { ReactNode } from 'react';

interface SalesItemWrapperProps {
  children: ReactNode;
}

export const SalesItemWrapper = ({ children }: SalesItemWrapperProps) => {
  return (
    <div className='border-primary-purple pb-1 first:border-y-[1.5px] first:pt-1 [&:not(:last-child)]:border-b-[1.5px]'>
      {children}
    </div>
  );
};

export const SalesItemContainer = ({ children }: SalesItemWrapperProps) => {
  return <div className='mx-3 mb-3 flex flex-col gap-1'>{children}</div>;
};
