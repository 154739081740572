import { t } from '@lingui/macro';
import { Typography } from '@mui/material';
import { noddiColors, NoddiIcon } from 'noddi-ui-common';
import styled from 'styled-components';

const HasInvoicedContainer = styled.div`
  background-color: ${noddiColors.primary.white};
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  padding: 12px 20px;
  margin-top: 24px;
`;

export default function InvoicedInfo() {
  return (
    <HasInvoicedContainer>
      <div style={{ alignItems: 'center', display: 'flex' }}>
        <NoddiIcon name='Check' color={noddiColors.primary.orange} className='mr-1' />

        <Typography ml={2} fontWeight={500}>
          {t`The payment of this booking is handled by invoice`}
        </Typography>
      </div>
    </HasInvoicedContainer>
  );
}
