import { Circle, Path, Svg, SvgProps } from 'react-native-svg';

const Camera = ({ color, ...props }: SvgProps) => (
  <Svg viewBox='0 0 30 30' width={30} height={30} fill='none' {...props}>
    <Circle cx={15} cy={16} r={3} stroke={color} strokeWidth={1.5} />
    <Path
      stroke={color}
      strokeWidth={1.5}
      d='M12.778 24h4.444c3.121 0 4.682 0 5.803-.735a4.408 4.408 0 0 0 1.226-1.204c.749-1.1.749-2.633.749-5.697 0-3.065 0-4.597-.749-5.697a4.406 4.406 0 0 0-1.226-1.204c-.72-.473-1.622-.642-3.003-.702-.659 0-1.226-.49-1.355-1.125A2.064 2.064 0 0 0 16.634 6h-3.268c-.988 0-1.839.685-2.033 1.636-.129.635-.696 1.125-1.355 1.125-1.38.06-2.282.23-3.003.702a4.404 4.404 0 0 0-1.226 1.204C5 11.767 5 13.299 5 16.364c0 3.064 0 4.596.749 5.697.324.476.741.885 1.226 1.204C8.096 24 9.657 24 12.778 24Z'
    />
    <Path stroke={color} strokeLinecap='round' strokeWidth={1.5} d='M22 13h-1' />
  </Svg>
);
export { Camera };
