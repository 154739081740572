import { t } from '@lingui/macro';
import { Tire } from 'noddi-async/src/types/shared/tireFromInventory';

interface TireMetaDataProps {
  tire: Tire;
}

const InfoRow = ({ label }: { label: string }) => (
  <div className='flex items-center gap-2'>
    <div className='size-2 rounded-full bg-primary-purple' />
    {label}
  </div>
);

export const TireMetaData = ({ tire }: TireMetaDataProps) => {
  return (
    <div className='flex flex-col gap-1'>
      <InfoRow label={tire.season === 'SUMMER' ? t`Summer ☀️` : t`Winter ❄️`} />
      {tire.isIceGrip && <InfoRow label={t`Good ice grip`} />}
      {tire.classification === 'PREIMUM' && <InfoRow label={t`Premium tires`} />}
      {tire.extraLoad && <InfoRow label={t`Extra load`} />}
      {tire.soundClass && <InfoRow label={`${t`Sound class`} ${tire.soundClass}`} />}
      <InfoRow
        label={`${t`Max load per tire`} ${tire.loadIndex.weight.value} kg (${t`Load index`}: ${tire.loadIndex.index})`}
      />
      <InfoRow
        label={`${t`Max speed`} ${tire.speedIndex.speed.value} km/t (${t`Speed index`}: ${tire.speedIndex.index})`}
      />
    </div>
  );
};
