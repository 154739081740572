import { Trans } from '@lingui/macro';
import { tracking } from 'noddi-provider';
import { NoddiIconButton } from 'noddi-ui';
import { NoddiButton } from 'noddi-ui-common';
import { useEffect, useState } from 'react';
const beaconId = import.meta.env.VITE_HELPSCOUT_BEACON_ID;

interface HelpScoutChatProps {
  size?: 'small' | 'medium';
  className?: string;
  position?: 'left' | 'right';
}

const HelpScoutChat = ({ size = 'medium', className, position = 'left' }: HelpScoutChatProps) => {
  const [isOpenModal, setIsOpenModal] = useState(false);

  useEffect(() => {
    let loadHelpScout: HTMLScriptElement | null = null;
    let initializeBeacon: HTMLScriptElement | null = null;

    if (isOpenModal) {
      // Create the script element for loading the HelpScout Beacon script
      loadHelpScout = document.createElement('script');
      loadHelpScout.type = 'text/javascript';
      loadHelpScout.async = true;
      loadHelpScout.innerHTML = `!function(e,t,n){function a(){var e=t.getElementsByTagName("script")[0],n=t.createElement("script");n.type="text/javascript",n.async=!0,n.src="https://beacon-v2.helpscout.net",e.parentNode.insertBefore(n,e)}if(e.Beacon=n=function(t,n,a){e.Beacon.readyQueue.push({method:t,options:n,data:a})},n.readyQueue=[],"complete"===t.readyState)return a();e.attachEvent?e.attachEvent("onload",a):e.addEventListener("load",a,!1)}(window,document,window.Beacon||function(){});`;

      // Append the HelpScout script to the body
      document.body.appendChild(loadHelpScout);

      // Create the script to initialize the HelpScout Beacon
      initializeBeacon = document.createElement('script');
      initializeBeacon.type = 'text/javascript';
      initializeBeacon.innerHTML =
        "window.Beacon('init', '" +
        beaconId +
        "', { enableChat: true });" +
        `window.Beacon('config', {
          icon: 'question', // Customize the Beacon icon
          showBeacon: false, // Hide the Beacon launcher button,
          enableChat: true, 
          display: {
            position: "${position}"
          }
        });

        // Listen for the close event
        window.Beacon('on', 'close', function() {
          window.dispatchEvent(new Event('beaconClosed')); // Dispatch custom close event
        });`;

      // Append the second script to the document body
      document.body.appendChild(initializeBeacon);
    }

    // Add an event listener for the custom "beaconClosed" event
    const handleBeaconClose = () => {
      setIsOpenModal(false); // Set isOpenModal to false when Beacon closes
    };

    window.addEventListener('beaconClosed', handleBeaconClose);

    // Cleanup: Remove scripts and event listener when component unmounts or isOpenModal changes to false
    return () => {
      window.removeEventListener('beaconClosed', handleBeaconClose);
      //@ts-expect-error
      if (window.Beacon) {
        //@ts-expect-error
        window.Beacon('destroy'); // Clean up the HelpScout instance

        // Clean up the dynamically injected scripts from the DOM
        if (loadHelpScout && initializeBeacon) {
          document.body.removeChild(loadHelpScout);
          document.body.removeChild(initializeBeacon);
          loadHelpScout = null;
          initializeBeacon = null;
        }
      }
    };
  }, [isOpenModal, position]);

  // Custom function to open HelpScout chat when the custom button is clicked
  const openHelpScoutChat = () => {
    //@ts-expect-error
    if (window.Beacon) {
      //@ts-expect-error
      window.Beacon('toggle'); // Toggle the HelpScout chat
    }
  };

  const triggerHelpScoutChat = () => {
    tracking.track('helpScoutChatOpened');
    setIsOpenModal(true); // Set modal to true

    setTimeout(() => {
      openHelpScoutChat();
    }, 50);
  };

  if (!beaconId || isOpenModal) {
    return null;
  }

  return (
    <div className={className}>
      {size === 'small' ? (
        <NoddiIconButton variant='secondary' onClick={triggerHelpScoutChat} iconName='ChatRounded' />
      ) : (
        <NoddiButton startIcon='ChatRounded' variant='secondary' onPress={triggerHelpScoutChat}>
          <Trans>Chat</Trans>
        </NoddiButton>
      )}
    </div>
  );
};

export default HelpScoutChat;
