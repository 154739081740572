import { SALES_ITEM_TYPE } from 'noddi-async/src/types';
import {
  useBookingAddress,
  useBookingCars,
  useBookingSelectedSalesItemsCars,
  useBookingTimeWindow
} from '../BookingStore';

export const useIsStepValid = () => {
  const selectedAddress = useBookingAddress();
  const selectedCars = useBookingCars();
  const selectedSalesItemsCars = useBookingSelectedSalesItemsCars();
  const timeWindow = useBookingTimeWindow();

  const carsWithWheelPickup = selectedSalesItemsCars.filter((car) => car.isWheelPickupRequired);

  const isAddressStepValid = !!selectedAddress?.id;

  const isCarStepValid = !!selectedCars?.length;

  // All selected cars need to have at least one salesItem
  const isSalesItemStepValid = selectedCars.every((car) =>
    selectedSalesItemsCars.map((item) => item.licensePlate.number).includes(car.licensePlate.number)
  );

  // All cars with pick up required need to have a salesItem of type TIER_HOTEL_PICKUP
  const isTierHotelPickUpStepValid = carsWithWheelPickup.every((car) =>
    selectedSalesItemsCars.some(
      (selectedCar) =>
        selectedCar.licensePlate.number === car.licensePlate.number &&
        selectedCar.type === SALES_ITEM_TYPE.TIRE_HOTEL_PICKUP
    )
  );

  const isTimeWindowsStepValid = !!timeWindow?.deliveryWindowId;

  return {
    isAddressStepValid,
    isCarStepValid,
    isSalesItemStepValid,
    isTierHotelPickUpStepValid,
    isTimeWindowsStepValid
  };
};
