import { motion, useAnimation } from 'framer-motion';
import { cn } from 'noddi-ui';
import React, { ReactElement, useEffect, useState } from 'react';

interface RenderProp {
  progress: number;
  handleRestart: () => void;
}

interface AnimatedProgressBarProps {
  onFinish?: () => void;
  children?: (renderProps: RenderProp) => React.ReactNode;
  intervalDelayInSeconds?: number;
  finishedNumber?: number;
  isPaused?: boolean;
  actionButton: (renderProps: RenderProp) => React.ReactNode;
}

export function AnimatedProgressBar({
  onFinish,
  children,
  actionButton,
  intervalDelayInSeconds = 8,
  finishedNumber = 100,
  isPaused = false
}: AnimatedProgressBarProps): ReactElement {
  const [progress, setProgress] = useState(0);
  const controls = useAnimation();

  console.log('isPaused', isPaused);

  useEffect(() => {
    if (isPaused) {
      controls.stop();
      return;
    }

    const animate = async () => {
      await controls.start({
        width: `${finishedNumber}%`,
        transition: {
          duration: intervalDelayInSeconds,
          ease: 'easeOut'
        }
      });
      onFinish?.();
    };

    animate();
  }, [controls, intervalDelayInSeconds, onFinish, isPaused]);

  const isFinished = progress >= finishedNumber;

  const handleRestart = () => {
    setProgress(0);
    controls.set({ width: '0%' });
  };

  return (
    <>
      {children && children({ progress, handleRestart })}
      <div className='relative flex w-full flex-col items-center justify-center'>
        <div className='relative h-4 w-full overflow-hidden rounded-lg bg-primary-purple'>
          <motion.div
            className={cn('h-full rounded-lg bg-primary-darkPurple', isFinished && 'animate-pulse')}
            initial={{ width: '0%' }}
            animate={controls}
            onUpdate={(latest) => {
              const width = latest.width as string;
              setProgress(parseFloat(width));
            }}
          />
        </div>
        {actionButton && actionButton({ progress, handleRestart })}
      </div>
    </>
  );
}
