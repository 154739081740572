import { t, Trans } from '@lingui/macro';
import { noddiAsync, URLKeys } from 'noddi-async';
import { useAuthContext } from 'noddi-provider';
import { CouponCard, NoddiBasicCard, NoddiExternalLink } from 'noddi-ui';
import { useParams } from 'react-router-dom';

import { NoddiIcon } from 'noddi-ui-common';
import getCommonTranslations from '../../commonTranslations';
import { BenefitsLogin } from '../../components/BenefitsLogin';
import ContentWrapperWithQuery from '../../components/Layouts/ContentWrapperWithQuery';
import { REFERRAL_PROGRAM_INFO } from '../../constants/externalUrls';
import ActivateCampaign from '../Campaigns/ActivateCampaign';

const Referrals = () => {
  const params = useParams<{ slug: string }>();
  const { isLoggedIn, canActivateCouponsForNewUsersOnly, userData } = useAuthContext();
  const safeSlug = params.slug?.toLocaleLowerCase();

  const query = noddiAsync.useGet({ type: URLKeys.getCampaignFromSlug, input: { slug: safeSlug } });

  return (
    <ContentWrapperWithQuery
      query={query}
      subtitle={`${t`You have been invited by`} ${query.data?.name}`}
      title={`${t`Hurray`}🥳`}
      hideBackButton
      widerScreen
    >
      {({ data }) => {
        const isOwnReferralCode = params.slug === userData?.user.referralCode;
        const canNotActivateCoupons = isLoggedIn && data?.isForNewUserOnly && !canActivateCouponsForNewUsersOnly;

        return (
          <>
            {!isLoggedIn && <BenefitsLogin />}

            {canNotActivateCoupons && (
              <NoddiBasicCard className='mt-8 flex gap-2'>
                <NoddiIcon className='text-5' name='Cross' />
                {isOwnReferralCode ? (
                  <h2>
                    <Trans>You cannot activate your own referral code</Trans>
                  </h2>
                ) : (
                  <div className='flex flex-col gap-4'>
                    <h4>
                      <Trans>Your user cannot activate coupons from this campaign</Trans>
                    </h4>
                    <p>
                      <Trans>This promotion is only for new users without existing coupons</Trans>
                    </p>
                  </div>
                )}
              </NoddiBasicCard>
            )}

            {isLoggedIn && !canNotActivateCoupons && <ActivateCampaign campaign={data} />}

            <div className='mt-6 grid grid-cols-1 gap-6 md:grid-cols-2'>
              {data.couponGroup.couponTemplates.map((couponTemplate) => (
                <CouponCard
                  key={couponTemplate.id}
                  namePublic={couponTemplate.namePublic}
                  descriptionPublic={couponTemplate.descriptionPublic}
                  discountAmount={couponTemplate.discount?.amount}
                  currency={couponTemplate.discount?.currency}
                  discountPercentage={couponTemplate.discountPercentage}
                  translations={{
                    activated: getCommonTranslations().activated,
                    used: getCommonTranslations().used,
                    amount: getCommonTranslations().amount,
                    discount: getCommonTranslations().discount,
                    free: getCommonTranslations().free
                  }}
                />
              ))}
            </div>

            <NoddiExternalLink className='-mr-3 flex self-end' endIcon='ArrowRight' href={REFERRAL_PROGRAM_INFO}>
              Read more about our referral program
            </NoddiExternalLink>
          </>
        );
      }}
    </ContentWrapperWithQuery>
  );
};

export default Referrals;
