import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import { styled } from '@mui/material/styles';
import type { Theme } from '@mui/material/styles/createTheme';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Stack } from '@mui/system';
import { useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';

import { NoddiIconButton } from '../../../../molecules';
import { useFilteredNavItems } from '../useFilteredNavItems';
import { DrawerContent, PaperProps, SIDE_NAV_WIDTH } from './SideNav';
import { VerticalLayoutProps } from './VerticalLayoutProps';
import { useMobileNav } from './useMobileNav';

const COLLAPSE_WIDTH = 'md';

const VerticalLayoutRoot = styled('div')<{ alwaysShowMobileNav: boolean }>(({ theme, alwaysShowMobileNav }) => ({
  display: 'flex',
  flex: '1 1 auto',
  maxWidth: '100%',
  [theme.breakpoints.up(COLLAPSE_WIDTH)]: {
    paddingLeft: alwaysShowMobileNav ? 0 : SIDE_NAV_WIDTH
  }
}));

const VerticalLayoutContainer = styled('div')({
  display: 'flex',
  flex: '1 1 auto',
  flexDirection: 'column',
  width: '100%'
});

export const VerticalLayout = ({
  navItems,
  CustomNavElement,
  TopNav,
  LanguagePicker,
  customPathToProfile,
  hideProfile,
  alwaysShowMobileNav = false,
  translations,
  CustomerHeaderElement,
  disableUserGroupSelection
}: VerticalLayoutProps) => {
  const { pathname } = useLocation();
  const lgUp = useMediaQuery((theme: Theme) => theme.breakpoints.up(COLLAPSE_WIDTH));
  const mobileNav = useMobileNav();

  const filteredNavItems = useFilteredNavItems(navItems);

  //Close the MobileNav when clicked on the menu item (route change)
  useEffect(() => {
    if (!lgUp || alwaysShowMobileNav) {
      mobileNav.handleClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  return (
    <>
      {lgUp && !alwaysShowMobileNav ? (
        <Drawer anchor='left' open PaperProps={PaperProps()} variant='permanent'>
          <DrawerContent
            translations={translations}
            navItems={filteredNavItems}
            CustomerHeaderElement={CustomerHeaderElement}
            CustomNavElement={CustomNavElement}
            LanguagePicker={LanguagePicker}
            customPathToProfile={customPathToProfile}
            hideProfile={hideProfile}
            disableUserGroupSelection={disableUserGroupSelection}
          />
        </Drawer>
      ) : (
        <>
          <Stack p={1.5} position='absolute' right={0}>
            <NoddiIconButton iconName='HamburgerMenu' onClick={mobileNav.handleOpen} />
          </Stack>
          <Drawer
            anchor='left'
            onClose={mobileNav.handleClose}
            open={mobileNav.open}
            PaperProps={PaperProps()}
            variant='temporary'
          >
            <DrawerContent
              translations={translations}
              navItems={filteredNavItems}
              CustomerHeaderElement={CustomerHeaderElement}
              CustomNavElement={CustomNavElement}
              LanguagePicker={LanguagePicker}
              customPathToProfile={customPathToProfile}
              hideProfile={hideProfile}
              disableUserGroupSelection={disableUserGroupSelection}
            />
          </Drawer>
        </>
      )}

      <VerticalLayoutRoot alwaysShowMobileNav={alwaysShowMobileNav}>
        <VerticalLayoutContainer>
          <Box
            component='main'
            sx={{
              flexGrow: 1
            }}
          >
            <>
              {TopNav && <TopNav />}
              <Outlet />
            </>
          </Box>
        </VerticalLayoutContainer>
      </VerticalLayoutRoot>
    </>
  );
};
