import { Route, Routes } from 'react-router-dom';

import { customerRoutes } from 'noddi-util';
import Account, { EditAccount } from './Account';
import Bonus from './Bonus';
import { Bookings } from './Bookings';
import BookingDetails from './Bookings/[id]';
import EditBookingTimeWindow from './Bookings/[id]/EditBookingTimeWindow';
import CarDetailPage from './Cars/CarDetailPage';
import WheelMeasureMentPage from './Cars/WheelMeasureMentPage';
import { Error404PageWithTranslations } from './Error404';
import Membership from './Memberships';
import { TireHotel } from './TireHotel';
import TireOfferHomePage from './TireOfferHomePage';

export function HomeRouter() {
  return (
    <Routes>
      <Route path='/'>
        <Route path={customerRoutes.myBookingDetails.getBasePath()} element={<BookingDetails />} />

        <Route path={customerRoutes.myCarDetails.getBasePath()} element={<CarDetailPage />} />
        <Route path={customerRoutes.myCarWheelMeasurements.getBasePath()} element={<WheelMeasureMentPage />} />
        <Route path={customerRoutes.bonus.getBasePath()} element={<Bonus />} />
        <Route path={customerRoutes.homeError.getBasePath()} element={<Error404PageWithTranslations />} />
        <Route path={customerRoutes.editProfile.getBasePath()} element={<EditAccount />} />
        <Route path={customerRoutes.profile.getBasePath()} element={<Account />} />

        <Route path={customerRoutes.myMembershipPrograms.getBasePath()} element={<Membership />} />
        <Route path={customerRoutes.bookings.getBasePath()} element={<Bookings />} />

        <Route path={customerRoutes.tireHotel.getBasePath()} element={<TireHotel />} />
        <Route path={customerRoutes.tireOfferHomePage.getBasePath()} element={<TireOfferHomePage />} />
      </Route>
      <Route path='/'>
        <Route path={customerRoutes.editMyBookingTimeWindow.getBasePath()} element={<EditBookingTimeWindow />} />
      </Route>
    </Routes>
  );
}
