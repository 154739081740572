import { AvailableSalesItemsForBooking, SALES_ITEM_TYPE, SelectedSalesItemsCar } from 'noddi-async/src/types';
import { ServiceItemsMappedForFleetWithCars } from '../pages/BookingFlow/Steps/Services/SalesItemsFleetSelector';
import { compareLicensePlates } from '../pages/BookingFlow/helpers/utils';

export const getUniqueSalesItems = (data: AvailableSalesItemsForBooking): ServiceItemsMappedForFleetWithCars[] => {
  return Object.values(
    data.cars.reduce(
      (acc, car) => {
        car.salesItems.forEach((item) => {
          const {
            salesItemId,
            isAddonsAvailable,
            isWheelPickupRequired,
            mutuallyExclusiveSalesItemIds,
            name,
            description,
            uiSortOrder,
            shortDescription,
            bookingCategorySlug
          } = item;
          // CU-86c26e3jq
          // Todo - this is not clear. Now it just sets the first one found when mapping. Maybe change name to groupedDescription, groupedShortDescription, priceFrom?
          if (!acc[item.name]) {
            acc[item.name] = {
              name,
              description,
              uiSortOrder,
              price: item.price,
              shortDescription,
              bookingCategorySlug,
              cars: []
            };
          }
          acc[item.name]?.cars.push({
            ...car,
            salesItemId,
            isAddonsAvailable,
            isWheelPickupRequired,
            mutuallyExclusiveSalesItemIds
          });
        });
        return acc;
      },
      {} as Record<string, ServiceItemsMappedForFleetWithCars>
    )
  );
};

export const numberOfSelectedItemsFleets = ({
  salesItemsInCategory,
  selectedSalesItems
}: {
  salesItemsInCategory: ServiceItemsMappedForFleetWithCars[];
  selectedSalesItems: SelectedSalesItemsCar[];
}) =>
  salesItemsInCategory.reduce((acc, item) => {
    const reducedServices = item.cars.reduce((acc, car) => {
      const selectedSalesItemsCar = selectedSalesItems.find(
        (selectedSalesItemCar) =>
          selectedSalesItemCar.salesItemId === car.salesItemId &&
          selectedSalesItemCar.licensePlate.number === car.licensePlate.number
      );

      return selectedSalesItemsCar ? acc + 1 : acc;
    }, 0);

    return acc + reducedServices;
  }, 0);

interface SalesItemToRemove {
  licensePlate: SelectedSalesItemsCar['licensePlate'];
  salesItemId: SelectedSalesItemsCar['salesItemId'];
  type: SelectedSalesItemsCar['type'];
}

export const getOnlyPrimarySalesItemsForLicensePlate = ({
  selectedSalesItemsCars,
  licensePlate,
  salesItemId
}: {
  selectedSalesItemsCars: SelectedSalesItemsCar[];
  licensePlate: SelectedSalesItemsCar['licensePlate'];
  salesItemId: SelectedSalesItemsCar['salesItemId'];
}) =>
  selectedSalesItemsCars.filter(
    (item) =>
      !compareLicensePlates(item.licensePlate, licensePlate) ||
      (compareLicensePlates(item.licensePlate, licensePlate) &&
        item.type === SALES_ITEM_TYPE.PRIMARY &&
        item.salesItemId !== salesItemId)
  );

export const removeSalesItemConstructor = ({
  selectedSalesItemsCars,
  itemToRemove
}: {
  selectedSalesItemsCars: SelectedSalesItemsCar[];
  itemToRemove: SalesItemToRemove;
}) => {
  const { type, licensePlate, salesItemId } = itemToRemove;
  if (type !== SALES_ITEM_TYPE.PRIMARY) {
    const salesItemsExcludingCurrent = selectedSalesItemsCars.filter(
      (item) => !(compareLicensePlates(item.licensePlate, licensePlate) && item.salesItemId === salesItemId)
    );

    return {
      selectedSalesItemsCars: salesItemsExcludingCurrent,
      selectedTimeWindow: undefined
    };
  }

  // Remove all addons for the license plate when removing a primary sales item
  const filteredItems = getOnlyPrimarySalesItemsForLicensePlate({ selectedSalesItemsCars, licensePlate, salesItemId });

  return {
    selectedSalesItemsCars: filteredItems,
    selectedTimeWindow: undefined
  };
};

export const removeMultipleSalesItemsConstructor = ({
  selectedSalesItemsCars,
  itemsToRemove
}: {
  selectedSalesItemsCars: SelectedSalesItemsCar[];
  itemsToRemove: SalesItemToRemove[];
}) => {
  const updatedSalesItems = itemsToRemove.reduce(
    (acc, { type, licensePlate, salesItemId }) => {
      if (type !== SALES_ITEM_TYPE.PRIMARY) {
        return acc.filter(
          (item) => !(compareLicensePlates(item.licensePlate, licensePlate) && item.salesItemId === salesItemId)
        );
      } else {
        // Remove all addons for the license plate when removing a primary sales item
        return getOnlyPrimarySalesItemsForLicensePlate({
          selectedSalesItemsCars: acc,
          licensePlate,
          salesItemId
        });
      }
    },
    [...selectedSalesItemsCars]
  );

  return {
    selectedSalesItemsCars: updatedSalesItems,
    selectedTimeWindow: undefined
  };
};

export const getIsSalesItemAlreadyInState = ({
  selectedSalesItemsCars,
  licensePlate,
  salesItemId
}: {
  selectedSalesItemsCars: SelectedSalesItemsCar[];
  licensePlate: SelectedSalesItemsCar['licensePlate'];
  salesItemId: SelectedSalesItemsCar['salesItemId'];
}) => {
  return selectedSalesItemsCars.some(
    (selectedItem) =>
      compareLicensePlates(selectedItem.licensePlate, licensePlate) && selectedItem.salesItemId === salesItemId
  );
};

export const getNewSalesItemsInState = ({
  selectedSalesItemsCars,
  salesItemToAdd,
  mutuallyExclusiveSalesItemIds
}: {
  selectedSalesItemsCars: SelectedSalesItemsCar[];
  salesItemToAdd: SelectedSalesItemsCar;
  mutuallyExclusiveSalesItemIds: number[];
}) => {
  const preppedItems =
    salesItemToAdd.type === SALES_ITEM_TYPE.PRIMARY
      ? getOnlyPrimarySalesItemsForLicensePlate({
          selectedSalesItemsCars,
          salesItemId: salesItemToAdd.salesItemId,
          licensePlate: salesItemToAdd.licensePlate
        })
      : selectedSalesItemsCars;

  const filteredItems = preppedItems.filter(
    (item) =>
      !(
        compareLicensePlates(item.licensePlate, salesItemToAdd.licensePlate) &&
        mutuallyExclusiveSalesItemIds.includes(item.salesItemId)
      )
  );

  return [...filteredItems, salesItemToAdd];
};
