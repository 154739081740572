import { Path, Rect, Svg, SvgProps } from 'react-native-svg';

export type CarWheelPosition = 'FRONT_LEFT' | 'FRONT_RIGHT' | 'REAR_LEFT' | 'REAR_RIGHT';

interface CarWheelPositionProps extends SvgProps {
  color?: string;
  carSides: CarWheelPosition[];
}

export const CarWheelPosition = ({ carSides = [], ...rest }: CarWheelPositionProps) => (
  <Svg viewBox='0 0 20 33' width={20} height={33} fill='none' {...rest}>
    <Path stroke='#000' strokeLinecap='round' d='m3.5 7-1 1M16.5 7l1 1' />
    <Path
      stroke='#000'
      d='M3.922 3.49a2.5 2.5 0 0 1 2.13-2.395L9.63.561a2.5 2.5 0 0 1 .738 0l3.58.534a2.5 2.5 0 0 1 2.13 2.395l.421 13.518V30a2.5 2.5 0 0 1-2.5 2.5H6A2.5 2.5 0 0 1 3.5 30V17.008L3.922 3.49Z'
    />
    <Rect
      width={3}
      height={8}
      x={4}
      y={6.5}
      fill={carSides.includes('FRONT_LEFT') ? '#000' : '#fff'}
      stroke='#000'
      rx={0.5}
    />
    <Rect
      width={3}
      height={8}
      x={13}
      y={6.5}
      fill={carSides.includes('FRONT_RIGHT') ? '#000' : '#fff'}
      stroke='#000'
      rx={0.5}
    />
    <Rect
      width={3}
      height={8}
      x={4}
      y={21.5}
      fill={carSides.includes('REAR_LEFT') ? '#000' : '#fff'}
      stroke='#000'
      rx={0.5}
    />
    <Rect
      width={3}
      height={8}
      x={13}
      y={21.5}
      fill={carSides.includes('REAR_RIGHT') ? '#000' : '#fff'}
      stroke='#000'
      rx={0.5}
    />
  </Svg>
);
