import { AxiosResponse } from 'axios';

import {
  AutoRecallBooking,
  BookingDataHome,
  BookingDataHomeBase,
  BookingDetails,
  BookingInfo,
  BookingProps,
  CampaignPublic,
  CarDetailsProps,
  MembershipFromSlug,
  MembershipProgramProps,
  PaginatedInput,
  PaginatedResponse,
  PatchBookingParams,
  ReferralInfo,
  SelectedSalesItemsCarGrouped,
  ShoppingCart,
  TireHotelContract,
  TireHotelOptionsResponse,
  UserDataProps
} from '../types';
import { CarDetails, CarSpec } from '../types/customerapp/booking/shared';
import { FeedbackData } from '../types/customerapp/feedback';
import { NAFDateOfBirthPayload, NAFMembershipNumberPayload } from '../types/customerapp/naf';
import { apiVersion, FetchMethods, ResponseType } from './commonResourceTypes';
import { CommonAxiosResType, getNoddiUrl } from './resources';

export const CustomerURLKeys = {
  getUserBooking: 'getUserBooking',
  postGetShoppingCartForNewBooking: 'postGetShoppingCartForNewBooking',
  getAutoRecallBooking: 'getAutoRecallBooking',
  getBookingInfo: 'getBookingInfo',
  getCurrentEstimatedArrivalTime: 'getCurrentEstimatedArrivalTime',
  getCancelBooking: 'getCancelBooking',
  patchCancelRecallProposal: 'patchCancelRecallProposal',
  getUpcomingBookingsForUserGroup: 'getUpcomingBookingsForUserGroup',
  getCompletedBookingsForUserGroup: 'getCompletedBookingsForUserGroup',
  getPaginatedCarsForUserGroups: 'getPaginatedCarsForUserGroups',
  getPaginatedCarsForBookingUserGroups: 'getPaginatedCarsForBookingUserGroups',
  getUserGroupCar: 'getUserGroupCar',
  getCampaignFromSlug: 'getCampaignFromSlug',
  postActivateCampaign: 'postActivateCampaign',
  patchBooking: 'patchBooking',
  patchRecallBooking: 'patchRecallBooking',
  getBooking: 'getBooking',
  getMembershipPrograms: 'getMembershipPrograms',
  getTireHotelOptions: 'getTireHotelOptions',
  getTireHotelContracts: 'getTireHotelContracts',
  getReferrals: 'getReferrals',
  postImpersonateUser: 'postImpersonateUser',
  postFeedback: 'postFeedback',
  postDinteroSession: 'postDinteroSession',
  postNafMembership: 'postNafMembership',
  getIsNAFMember: 'getIsNAFMember',
  getMembershipFromSlug: 'getMembershipFromSlug',
  postActivateMembership: 'postActivateMembership',
  getVerifyMembershipCode: 'getVerifyMembershipCode',
  postConfirmTireQuoteOffer: 'postConfirmTireQuoteOffer',
  getTireSetOfferPageView: 'getTireSetOfferPageView'
} as const;

export type CustomerURLKeysType = keyof typeof CustomerURLKeys;

type InputDataOrVoid<Type extends CustomerURLKeysType> = InputData<Type> extends void ? void : InputData<Type>;

type InputData<urlKey extends CustomerURLKeysType> = CustomerServerRequests[urlKey]['input'];

export const getCustomerUrls: {
  [key in CustomerURLKeysType]: {
    getUrl: (input: InputDataOrVoid<key>) => string;
    getBody?: (input: InputDataOrVoid<key>) => Partial<InputData<key>>;
    method: FetchMethods;
    responseType?: ResponseType;
    handleRes?: (res: CommonAxiosResType<key>) => Promise<unknown>;
    getHeaders?: (input: InputDataOrVoid<key>) => Record<string, string>;
    useSuperUserToken?: boolean;
    skipConvertingToCamelCase?: boolean;
  };
} = {
  getUserBooking: {
    getUrl: (queryParams) =>
      getNoddiUrl({
        path: `${apiVersion}bookings/get-booking-for-user-group/`,
        queryParams
      }),
    method: FetchMethods.get
  },
  postGetShoppingCartForNewBooking: {
    getUrl: () =>
      getNoddiUrl({
        path: `${apiVersion}bookings/shopping-cart-for-new-booking/`
      }),
    getBody: (data) => data,
    method: FetchMethods.post
  },
  getUpcomingBookingsForUserGroup: {
    getUrl: ({ userGroupId, ...queryParams }) =>
      getNoddiUrl({
        path: `${apiVersion}user-groups/${userGroupId}/upcoming-bookings/`,
        queryParams
      }),
    method: FetchMethods.get
  },
  getCompletedBookingsForUserGroup: {
    getUrl: ({ userGroupId, ...queryParams }) =>
      getNoddiUrl({
        path: `${apiVersion}user-groups/${userGroupId}/completed-bookings/`,
        queryParams
      }),
    method: FetchMethods.get
  },
  getPaginatedCarsForUserGroups: {
    getUrl: ({ userGroupId, ...queryParams }) =>
      getNoddiUrl({
        path: `${apiVersion}user-groups/${userGroupId}/cars/`,
        queryParams
      }),
    method: FetchMethods.get
  },
  getPaginatedCarsForBookingUserGroups: {
    getUrl: ({ userGroupId, ...queryParams }) =>
      getNoddiUrl({
        path: `${apiVersion}user-groups/${userGroupId}/cars-for-new-booking/`,
        queryParams
      }),
    method: FetchMethods.get
  },
  getBookingInfo: {
    getUrl: ({ slug }) =>
      getNoddiUrl({
        path: `${apiVersion}bookings/info-from-slug/${slug}/`
      }),
    method: FetchMethods.get
  },
  getAutoRecallBooking: {
    getUrl: ({ slug }) =>
      getNoddiUrl({
        path: `${apiVersion}bookings/${slug}/for-recall-proposal/`
      }),
    method: FetchMethods.get
  },
  getCurrentEstimatedArrivalTime: {
    getUrl: ({ slug }) =>
      getNoddiUrl({
        path: `${apiVersion}bookings/info-from-slug/${slug}/current-estimated-arrival-window/`
      }),
    method: FetchMethods.get
  },
  getCancelBooking: {
    getUrl: ({ bookingId }) =>
      getNoddiUrl({
        path: `${apiVersion}bookings/${bookingId}/cancel/`
      }),
    method: FetchMethods.get
  },
  getTireHotelOptions: {
    getUrl: (queryParams) =>
      getNoddiUrl({
        path: `${apiVersion}cars/tire-hotel-options/`,
        queryParams
      }),
    method: FetchMethods.get
  },
  getUserGroupCar: {
    getUrl: ({ userGroupId, carId }) =>
      getNoddiUrl({
        path: `${apiVersion}user-groups/${userGroupId}/cars/${carId}/`
      }),
    method: FetchMethods.get
  },
  getCampaignFromSlug: {
    getUrl: ({ slug }) =>
      getNoddiUrl({
        path: `${apiVersion}campaigns/from-slug/${slug}/`
      }),
    method: FetchMethods.get
  },
  postActivateCampaign: {
    getUrl({ campaignId }) {
      return getNoddiUrl({
        path: `${apiVersion}campaigns/${campaignId}/activate/`
      });
    },
    getBody: (data) => data,
    method: FetchMethods.post
  },
  patchBooking: {
    getUrl: ({ id }) =>
      getNoddiUrl({
        path: `${apiVersion}bookings/${id}/`
      }),
    getBody: ({ deliveryWindow, commentsAdmin, commentsUser, commentsWorker, addressId, cars }) => ({
      addressId,
      deliveryWindow,
      commentsAdmin,
      commentsUser,
      commentsWorker,
      cars
    }),
    method: FetchMethods.patch
  },
  patchRecallBooking: {
    getUrl: ({ id }) =>
      getNoddiUrl({
        path: `${apiVersion}bookings/${id}/recall-proposal-update/
`
      }),
    getBody: ({ deliveryWindow, commentsAdmin, commentsUser, commentsWorker, addressId, cars }) => ({
      addressId,
      deliveryWindow,
      commentsAdmin,
      commentsUser,
      commentsWorker,
      cars
    }),
    method: FetchMethods.patch
  },
  patchCancelRecallProposal: {
    getUrl: ({ id }) =>
      getNoddiUrl({
        path: `${apiVersion}bookings/${id}/recall-proposal-reject/`
      }),
    method: FetchMethods.patch
  },
  getBooking: {
    getUrl: ({ bookingId }) =>
      getNoddiUrl({
        path: `${apiVersion}bookings/${bookingId}/`
      }),
    method: FetchMethods.get
  },
  getMembershipPrograms: {
    getUrl: (queryParams) =>
      getNoddiUrl({
        path: `${apiVersion}memberships/get-memberships-for-user-group/`,
        queryParams
      }),
    method: FetchMethods.get
  },
  getTireHotelContracts: {
    getUrl: ({ userGroupId }) =>
      getNoddiUrl({
        path: `${apiVersion}user-groups/${userGroupId}/tire-hotel-contracts/`
      }),
    method: FetchMethods.get
  },
  getReferrals: {
    getUrl: ({ userGroupId }) =>
      getNoddiUrl({
        path: `${apiVersion}user-groups/${userGroupId}/get-referral-rewards/`
      }),
    method: FetchMethods.get
  },
  postImpersonateUser: {
    getUrl: () =>
      getNoddiUrl({
        path: `${apiVersion}users/impersonate/`
      }),
    getBody: (data) => data,
    method: FetchMethods.post
  },
  postFeedback: {
    getUrl: () =>
      getNoddiUrl({
        path: `${apiVersion}service-feedbacks/`
      }),

    getBody: (data) => data,
    method: FetchMethods.post
  },
  postDinteroSession: {
    getUrl: ({ orderId }) =>
      getNoddiUrl({
        path: `${apiVersion}orders/${orderId}/create-dintero-session/`
      }),

    method: FetchMethods.post
  },
  postNafMembership: {
    getUrl: () =>
      getNoddiUrl({
        path: `${apiVersion}memberships/add-naf-membership/`
      }),
    getBody: (data) => data,
    method: FetchMethods.post
  },
  getIsNAFMember: {
    getUrl: (queryParams) =>
      getNoddiUrl({
        path: `${apiVersion}memberships/is-naf-member/`,
        queryParams
      }),
    method: FetchMethods.get
  },
  getMembershipFromSlug: {
    getUrl: ({ slug, ...queryParams }) =>
      getNoddiUrl({
        path: `${apiVersion}memberships/${slug}/`,
        queryParams
      }),
    method: FetchMethods.get
  },
  postActivateMembership: {
    getUrl: ({ slug }) =>
      getNoddiUrl({
        path: `${apiVersion}memberships/${slug}/activate/`
      }),
    getBody: (data) => data,
    method: FetchMethods.post
  },
  getVerifyMembershipCode: {
    getUrl: ({ slug, ...queryParams }) =>
      getNoddiUrl({
        path: `${apiVersion}memberships/${slug}/check-code/`,
        queryParams
      }),
    method: FetchMethods.get
  },
  postConfirmTireQuoteOffer: {
    getUrl: ({ quoteItemId }) =>
      getNoddiUrl({
        path: `${apiVersion}car-tire-quotes/${quoteItemId}/select/`
      }),
    getBody: (data) => data,
    method: FetchMethods.post
  },
  getTireSetOfferPageView: {
    getUrl: ({ slug }) =>
      getNoddiUrl({
        path: `${apiVersion}car-tire-quotes/${slug}/view/`
      }),
    method: FetchMethods.get
  }
} as const;

export interface CustomerServerRequests {
  getUserBooking: {
    input: { bookingId: string };
    output: BookingDetails;
  };
  postGetShoppingCartForNewBooking: {
    input: {
      addressId?: number;
      cars: SelectedSalesItemsCarGrouped[];
      userGroupId?: number;
      deliveryWindow?: { id: number; startsAt: string; endsAt: string };
    };
    output: AxiosResponse<ShoppingCart>;
  };
  getUpcomingBookingsForUserGroup: {
    input: PaginatedInput & { userGroupId: number };
    output: PaginatedResponse<BookingDataHomeBase[]>;
  };
  getCompletedBookingsForUserGroup: {
    input: PaginatedInput & { userGroupId: number };
    output: PaginatedResponse<BookingDataHome[]>;
  };
  getPaginatedCarsForUserGroups: {
    input: PaginatedInput & { userGroupId: number };
    output: PaginatedResponse<CarDetails[]>;
  };
  getPaginatedCarsForBookingUserGroups: {
    input: PaginatedInput & { userGroupId: number };
    output: PaginatedResponse<CarSpec[]>;
  };
  getBookingInfo: {
    input: { slug: string };
    output: BookingInfo;
  };
  getAutoRecallBooking: {
    input: { slug: string };
    output: AutoRecallBooking;
  };
  getCurrentEstimatedArrivalTime: {
    input: { slug: string };
    output: { start?: string; end?: string };
  };
  getCancelBooking: {
    input: { bookingId: number };
    output: undefined;
  };
  getUserGroupCar: {
    input: { userGroupId: number | undefined | null; carId: string };
    output: CarDetailsProps;
  };
  getCampaignFromSlug: {
    input: { slug?: string };
    output: CampaignPublic;
  };
  postActivateCampaign: {
    input: { campaignId: number; userGroupId: number };
    output: undefined;
  };
  patchBooking: {
    input: PatchBookingParams;
    output: AxiosResponse<BookingProps>;
  };
  patchRecallBooking: {
    input: PatchBookingParams;
    output: AxiosResponse<BookingProps>;
  };
  patchCancelRecallProposal: {
    input: { id: number };
    output: undefined;
  };
  getTireHotelOptions: {
    input: { vinNumbers: string[]; userGroupId?: number | null };
    output: TireHotelOptionsResponse[];
  };
  getBooking: {
    input: { bookingId: number };
    output: BookingProps;
  };
  getMembershipPrograms: {
    input: { userGroupId: number };
    output: MembershipProgramProps[];
  };
  getTireHotelContracts: {
    input: { userGroupId: number };
    output: TireHotelContract[];
  };
  getReferrals: {
    input: { userGroupId: number };
    output: ReferralInfo;
  };
  postImpersonateUser: {
    input: {
      userId: number;
    };
    output: AxiosResponse<UserDataProps>;
  };
  postFeedback: {
    input: FeedbackData;
    output: undefined;
  };
  postDinteroSession: {
    input: { orderId: number };
    output: AxiosResponse<{ sessionId: string; countryCode: 'NO' | 'SE' }>;
  };
  postNafMembership: {
    input: NAFDateOfBirthPayload | NAFMembershipNumberPayload;
    output: AxiosResponse<{ membershipAdded: boolean }>;
  };
  getIsNAFMember: {
    input: { userGroupId: number };
    output: { isMember: boolean };
  };
  getMembershipFromSlug: {
    input: { slug: string; userGroupId?: number };
    output: MembershipFromSlug;
  };
  postActivateMembership: {
    input: { userGroupId: number; authCode: string; slug: string };
    output: undefined;
  };
  getVerifyMembershipCode: {
    input: { slug: string; authCode: string };
    output: { isValid: boolean };
  };
  postConfirmTireQuoteOffer: {
    input: {
      comments: string;
      quoteItemId: number;
      quoteItemTires: { quoteItemTireId: number; quantity: number }[];
    };
    output: undefined;
  };
  getTireSetOfferPageView: {
    input: { slug: string };
    output: undefined;
  };
}
