import { Path, Svg, SvgProps } from 'react-native-svg';

const UpArrowAlt = ({ color, ...props }: SvgProps) => (
  <Svg width={32} height={32} viewBox='0 0 32 32' fill='none' {...props}>
    <Path
      stroke={color}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeWidth={1.5}
      d='M23.467 19.2 16 12.8l-7.467 6.4'
    />
  </Svg>
);
export { UpArrowAlt };
