const HOME_PATH = '/home';
const NEW_BOOKING_PATH = '/bookings/new';
const TIRE_OFFER_PATH = '/tire-offer';
const AUTO_RECALL_PATH = '/recall';

const appRoutesKeys = {
  home: 'home',
  myCarDetails: 'myCarDetails',
  myCarWheelMeasurements: 'myCarWheelMeasurements',
  bonus: 'bonus',
  myBookingDetails: 'myBookingDetails',
  login: 'login',
  confirmation: 'confirmation',
  homeError: 'homeError',
  profile: 'profile',
  editProfile: 'editProfile',
  bookings: 'bookings',
  myMembershipPrograms: 'myMembershipPrograms',
  myReferrals: 'myReferrals',
  tireHotel: 'tireHotel',
  tireOfferHomePage: 'tireOfferHomePage',
  membership: 'membership',
  paymentInfo: 'paymentInfo',
  referralsInfo: 'referralsInfo',
  campaignsInfo: 'campaignsInfo',
  bookingInfo: 'bookingInfo',
  editMyBookingTimeWindow: 'editMyBookingTimeWindow',
  homepage: 'homepage',
  companySignup: 'companySignup',
  companySignupSuccess: 'companySignupSuccess',
  tireOffer: 'tireOffer',
  tireOfferInfo: 'tireOfferInfo',
  tireOfferSelection: 'tireOfferSelection',
  tireOfferSummary: 'tireOfferSummary',
  tireOfferConfirmation: 'tireOfferConfirmation',
  newBooking: 'newBooking',
  newBookingAddress: 'newBookingAddress',
  newBookingCars: 'newBookingCars',
  newBookingPrimaryItems: 'newBookingPrimaryItems',
  newBookingCancelTireHotel: 'newBookingCancelTireHotel',
  newBookingAddons: 'newBookingAddons',
  newBookingTierHotel: 'newBookingTierHotel',
  newBookingTimeWindow: 'newBookingTimeWindow',
  newBookingSummary: 'newBookingSummary',
  newBookingBuilding: 'newBookingBuilding',
  autoRecall: 'autoRecall',
  autoRecallTimeWindowPicker: 'autoRecallTimeWindowPicker',
  autoRecallSummary: 'autoRecallSummary',
  autoRecallLandingPage: 'autoRecallLandingPage',
  autoRecallAddress: 'autoRecallAddress',
  cancelledBooking: 'cancelledBooking',
  cancelledBookingFeedback: 'cancelledBookingFeedback'
} as const;

type BaseRouteKeys = keyof typeof appRoutesKeys;

type StringOrNumber = string | number;

// Define the parameters for the routes
interface PathConfig {
  myBookingDetails: { id: StringOrNumber };
  expandBookingSummary: { id: StringOrNumber };
  editMyBookingTimeWindow: { id: StringOrNumber };
  myCarDetails: { carId: StringOrNumber };
  myCarWheelMeasurements: { carId: StringOrNumber };
  paymentInfo: { slug: StringOrNumber };
  referralsInfo: { slug: StringOrNumber };
  campaignsInfo: { slug: StringOrNumber };
  bookingInfo: { slug: StringOrNumber };
  confirmation: { bookingId: StringOrNumber };
  tireOfferInfo: { slug: StringOrNumber };
  tireOfferSelection: { slug: StringOrNumber };
  tireOfferSummary: { slug: StringOrNumber };
  tireOfferConfirmation: { action: 'declined' | 'accepted' };
  autoRecallLandingPage: { slug: StringOrNumber };
}

type InputData<Type extends BaseRouteKeys> = Type extends keyof PathConfig ? PathConfig[Type] : void;

type InputDataOrVoid<Type extends BaseRouteKeys> = InputData<Type> extends void ? void : InputData<Type>;

type RouteDefinition<key extends BaseRouteKeys> = {
  getPath(data: InputDataOrVoid<key>): string;
  getBasePath(): string;
  isBaseRouterPath?: boolean;
};
export const customerRoutes: {
  [key in BaseRouteKeys]: RouteDefinition<key>;
} = {
  login: {
    getPath: () => '/login',
    getBasePath: () => '/login'
  },
  homepage: {
    getPath: () => `/homepage`,
    getBasePath: () => '/homepage',
    isBaseRouterPath: true
  },
  home: {
    getPath: () => `${HOME_PATH}`,
    getBasePath: () => `${HOME_PATH}/*`
  },
  tireOfferInfo: {
    getPath: ({ slug }) => `${TIRE_OFFER_PATH}/info/${slug}`,
    getBasePath: () => `/info/:slug`
  },
  autoRecall: {
    getPath: () => `${AUTO_RECALL_PATH}/*`,
    getBasePath: () => `${AUTO_RECALL_PATH}/*`
  },
  autoRecallLandingPage: {
    getPath: ({ slug }) => `${AUTO_RECALL_PATH}/${slug}`,
    getBasePath: () => '/:slug'
  },
  autoRecallTimeWindowPicker: {
    getPath: () => `${AUTO_RECALL_PATH}/time`,
    getBasePath: () => '/time'
  },
  autoRecallAddress: {
    getPath: () => `${AUTO_RECALL_PATH}/address`,
    getBasePath: () => '/address'
  },
  autoRecallSummary: {
    getPath: () => `${AUTO_RECALL_PATH}/summary`,
    getBasePath: () => '/summary'
  },
  myBookingDetails: {
    getPath: ({ id }) => `${HOME_PATH}/bookings/${id}`,
    getBasePath: () => '/bookings/:id'
  },
  editMyBookingTimeWindow: {
    getPath: ({ id }) => `${HOME_PATH}/bookings/${id}/edit`,
    getBasePath: () => '/bookings/:id/edit'
  },
  myCarDetails: {
    getPath: ({ carId }) => `${HOME_PATH}/cars/${carId}`,
    getBasePath: () => '/cars/:carId'
  },
  myCarWheelMeasurements: {
    getPath: ({ carId }) => `${HOME_PATH}/cars/${carId}/wheel-measurements`,
    getBasePath: () => '/cars/:carId/wheel-measurements'
  },
  bonus: {
    getPath: () => `${HOME_PATH}/bonus`,
    getBasePath: () => '/bonus',
    isBaseRouterPath: true
  },
  homeError: {
    getPath: () => `${HOME_PATH}/error`,
    getBasePath: () => '/error'
  },
  tireOfferHomePage: {
    getPath: () => `${HOME_PATH}/tire-offer `,
    getBasePath: () => '/tire-offer',
    isBaseRouterPath: true
  },
  profile: {
    getPath: () => `${HOME_PATH}/profile`,
    getBasePath: () => '/profile',
    isBaseRouterPath: true
  },
  editProfile: {
    getPath: () => `${HOME_PATH}/profile/edit`,
    getBasePath: () => '/profile/edit'
  },
  myMembershipPrograms: {
    getPath: () => `${HOME_PATH}/membership-programs`,
    getBasePath: () => '/membership-programs',
    isBaseRouterPath: true
  },
  bookings: {
    getPath: () => `${HOME_PATH}/bookings`,
    getBasePath: () => '/bookings',
    isBaseRouterPath: true
  },
  myReferrals: {
    getPath: () => `${HOME_PATH}/referrals`,
    getBasePath: () => '/referrals',
    isBaseRouterPath: true
  },
  confirmation: {
    getPath: ({ bookingId }) => `/confirmation/booking-id/${bookingId}`,
    getBasePath: () => '/confirmation/booking-id/:bookingId',
    isBaseRouterPath: true
  },
  tireHotel: {
    getPath: () => `${HOME_PATH}/tire-hotel`,
    getBasePath: () => '/tire-hotel',
    isBaseRouterPath: true
  },
  membership: {
    getPath: () => `/memberships/:slug`,
    getBasePath: () => '/memberships/:slug'
  },
  paymentInfo: {
    getPath: ({ slug }) => `/b/payment/${slug}`,
    getBasePath: () => '/b/payment/:slug'
  },
  referralsInfo: {
    getPath: ({ slug }) => `/referrals/${slug}`,
    getBasePath: () => '/referrals/:slug'
  },
  campaignsInfo: {
    getPath: ({ slug }) => `/campaigns/${slug}`,
    getBasePath: () => '/campaigns/:slug'
  },
  bookingInfo: {
    getPath: ({ slug }) => `/b/${slug}`,
    getBasePath: () => '/b/:slug'
  },
  newBooking: {
    getPath: () => `${NEW_BOOKING_PATH}/*`,
    getBasePath: () => `${NEW_BOOKING_PATH}/*`
  },
  newBookingAddress: {
    getPath: () => `${NEW_BOOKING_PATH}/address`,
    getBasePath: () => `/address`
  },
  newBookingCars: {
    getPath: () => `${NEW_BOOKING_PATH}/cars`,
    getBasePath: () => `/cars`
  },
  newBookingPrimaryItems: {
    getPath: () => `${NEW_BOOKING_PATH}/services`,
    getBasePath: () => `/services`
  },
  newBookingCancelTireHotel: {
    getPath: () => `${NEW_BOOKING_PATH}/cancel-tire-hotel`,
    getBasePath: () => `/cancel-tire-hotel`
  },
  newBookingAddons: {
    getPath: () => `${NEW_BOOKING_PATH}/addons`,
    getBasePath: () => '/addons'
  },
  newBookingTierHotel: {
    getPath: () => `${NEW_BOOKING_PATH}/tier-hotel-pickup`,
    getBasePath: () => '/tier-hotel-pickup'
  },
  newBookingTimeWindow: {
    getPath: () => `${NEW_BOOKING_PATH}/time-window`,
    getBasePath: () => '/time-window'
  },
  newBookingSummary: {
    getPath: () => `${NEW_BOOKING_PATH}/summary`,
    getBasePath: () => '/summary'
  },
  newBookingBuilding: {
    getPath: () => `${NEW_BOOKING_PATH}/building`,
    getBasePath: () => '/building'
  },
  companySignup: {
    getPath: () => '/company-signup',
    getBasePath: () => '/company-signup'
  },
  companySignupSuccess: {
    getPath: () => '/company-signup/success',
    getBasePath: () => '/company-signup/success'
  },
  tireOffer: {
    getPath: () => `${TIRE_OFFER_PATH}/*`,
    getBasePath: () => `${TIRE_OFFER_PATH}/*`
  },

  tireOfferSelection: {
    getPath: ({ slug }) => `${TIRE_OFFER_PATH}/select/${slug}`,
    getBasePath: () => `/select/:slug`
  },
  tireOfferSummary: {
    getPath: ({ slug }) => `${TIRE_OFFER_PATH}/summary/${slug}`,
    getBasePath: () => `/summary/:slug`
  },
  tireOfferConfirmation: {
    getPath: ({ action }) => `${TIRE_OFFER_PATH}/confirmation/${action}`,
    getBasePath: () => '/confirmation/:action'
  },
  cancelledBooking: {
    getPath: () => `/bookings/cancelled`,
    getBasePath: () => '/bookings/cancelled'
  },
  cancelledBookingFeedback: {
    getPath: () => `/bookings/cancelled/feedback-completed`,
    getBasePath: () => '/bookings/cancelled/feedback-completed'
  }
};
