import { create } from 'zustand';
import { createJSONStorage, devtools, persist } from 'zustand/middleware';

interface BookingState {
  clearStore: () => void;

  // For now only relevant for AutoRecall bookings
  bookingMetaData: { slug?: string; bookingId?: number };
  setBookingMetaData: (bookingMetaData: { slug: string; bookingId: number }) => void;

  comment?: string;
  setComment: (comment: string) => void;

  hasInvalidTimeWindow?: boolean;
  setHasInvalidTimeWindow: (hasInvalidTimeWindow: boolean) => void;
}

// Initial state with empty values
const initialStateValues = {
  bookingMetaData: { slug: undefined, bookingId: undefined },
  comment: '',
  hasInvalidTimeWindow: false
};

const useAutoRecallStore = create<BookingState>()(
  persist(
    devtools((set) => ({
      ...initialStateValues,
      clearStore: () => set(initialStateValues),

      setBookingMetaData: (bookingMetaData) =>
        set({
          bookingMetaData
        }),

      setComment: (comment) => set({ comment }),

      setHasInvalidTimeWindow: (hasInvalidTimeWindow) => set({ hasInvalidTimeWindow })
    })),
    {
      name: 'autoRecall',
      storage: createJSONStorage(() => sessionStorage)
    }
  )
);

// Getters
export const useAutoRecallComment = () => useAutoRecallStore((state) => state.comment);
export const useAutoRecallMetaData = () => useAutoRecallStore((state) => state.bookingMetaData);
export const useAutoRecallHasInvalidTimeWindow = () => useAutoRecallStore((state) => state.hasInvalidTimeWindow);

// Actions
export const useAutoRecallActions = () => {
  const clearStore = useAutoRecallStore((state) => state.clearStore);
  const setComment = useAutoRecallStore((state) => state.setComment);
  const setBookingMetaData = useAutoRecallStore((state) => state.setBookingMetaData);
  const setHasInvalidTimeWindow = useAutoRecallStore((state) => state.setHasInvalidTimeWindow);

  return {
    clearStore,
    setComment,
    setBookingMetaData,
    setHasInvalidTimeWindow
  };
};
