import { Trans } from '@lingui/macro';
import { cn } from 'noddi-ui';
import { NoddiButton } from 'noddi-ui-common';
import { PropsWithChildren, ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';
import { useScrollToTop } from '../helpers/useScrollToTop';
import { FixedBottomBar } from './FixedBottomBar';
import { PublicAnnouncement } from './PublicAnnouncement/PublicAnnouncement';

interface BookingScreenProps {
  title: string;
  onNextClicked?: () => void;
  disableNextButton?: boolean;
  hideNextButton?: boolean;
  hideShoppingCart?: boolean;
  customBottomBar?: ReactNode;
  showSelectedCars?: boolean;
  showSelectedTimeWindow?: boolean;
  widerScreen?: boolean;
  hideBackButton?: boolean;
  nextButtonLoading?: boolean;
}

export const BookingScreen = ({
  title,
  children,
  onNextClicked,
  disableNextButton,
  customBottomBar,
  hideNextButton,
  hideShoppingCart,
  showSelectedCars,
  showSelectedTimeWindow,
  widerScreen,
  hideBackButton,
  nextButtonLoading
}: PropsWithChildren<BookingScreenProps>) => {
  useScrollToTop();

  return (
    <>
      <BookingWrapper widerScreen={widerScreen}>
        <PublicAnnouncement />
        <BookingHeader title={title} hideBackButton={hideBackButton} />
        {children}
      </BookingWrapper>
      {customBottomBar ?? (
        <FixedBottomBar
          hideNextButton={hideNextButton}
          disableNextButton={disableNextButton}
          hideShoppingCart={hideShoppingCart}
          onNextClicked={onNextClicked}
          showSelectedCars={showSelectedCars}
          showSelectedTimeWindow={showSelectedTimeWindow}
          nextButtonLoading={nextButtonLoading}
        />
      )}
    </>
  );
};

type BookingWrapperProps = {
  widerScreen?: boolean;
};

export const BookingWrapper = ({ children, widerScreen }: PropsWithChildren<BookingWrapperProps>) => {
  return (
    <div
      className={cn(
        'mx-auto mt-8 w-full max-w-2xl px-5 pb-26 md:mt-14 lg:px-0',
        widerScreen && 'max-w-screen-lg lg:px-10'
      )}
    >
      {children}
    </div>
  );
};

type BookingHeaderProps = {
  title: BookingScreenProps['title'];
  hideBackButton?: BookingScreenProps['hideBackButton'];
};
const BookingHeader = ({ title, hideBackButton }: BookingHeaderProps) => {
  const navigate = useNavigate();

  return (
    <div className='my-5 md:my-10'>
      {!hideBackButton && (
        <NoddiButton
          startIcon='ArrowLeft'
          onPress={() => {
            navigate(-1);
          }}
          variant='ghost'
          className='-ml-3.5'
        >
          <Trans>Back</Trans>
        </NoddiButton>
      )}
      <h1 className='text-7 sm:text-13'>{title}</h1>
    </div>
  );
};
