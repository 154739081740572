import { TZDate } from '@date-fns/tz';
import { addDays, isAfter, isBefore } from 'date-fns';
import { AvailableBookingTimeWindow } from 'noddi-async/src/types';
import { DateFormats, noddiFormatDate } from 'noddi-util';

export const isTimeWindowUnavailable = ({ timeWindow }: { timeWindow: AvailableBookingTimeWindow }) => {
  return timeWindow.isTimeWindowTooShort || timeWindow.isClosed;
};

export const parseTimeSlotFromPublicTimes = ({
  startsAt,
  endsAt,
  timeZone
}: {
  startsAt: string;
  endsAt: string;
  timeZone: string;
}) => {
  const startLocal = new TZDate(startsAt, timeZone);
  const endLocal = new TZDate(endsAt, timeZone);

  const startHour = noddiFormatDate(startLocal, DateFormats.HOURS);
  const endHour = noddiFormatDate(endLocal, DateFormats.HOURS);

  const formattedSlot = `${startHour.slice(0, 2)}-${endHour.slice(0, 2)}`;
  return formattedSlot;
};

export const getFromAndToDate = ({
  date,
  page,
  pageSize,
  tomorrow,
  maxDate
}: {
  date: TZDate;
  page: number;
  pageSize: number;
  tomorrow: TZDate;
  maxDate: TZDate;
}) => {
  let calculatedFromDate = addDays(date, page * pageSize);

  // Ensure fromDate is not before tomorrow
  if (isBefore(calculatedFromDate, tomorrow)) {
    calculatedFromDate = tomorrow;
  }

  // Calculate potential to date
  let calculatedToDate = addDays(calculatedFromDate, pageSize - 1);

  // If the calculated to date exceeds the max date
  if (isAfter(calculatedToDate, maxDate)) {
    calculatedToDate = maxDate;

    // Adjust the from date to show a full page if possible
    const daysToMax = Math.floor((maxDate.getTime() - tomorrow.getTime()) / (24 * 60 * 60 * 1000));

    // If there are enough days between tomorrow and maxToDate for a full page
    if (daysToMax >= pageSize - 1) {
      calculatedFromDate = addDays(maxDate, -(pageSize - 1));
    }
  }

  return {
    fromDate: calculatedFromDate,
    toDate: calculatedToDate
  };
};
