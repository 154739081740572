import { SelectedSalesItemsCar, TierHotelPickupSalesItemOption } from 'noddi-async/src/types';

import { TireHotelMultipleCars } from './TireHotelMultipleCars';

type Props = {
  carsWithTierPickupOptions: SelectedSalesItemsCar[];
  pickUpOptions: TierHotelPickupSalesItemOption[];
};

export const MultipleCarsWithPickUpOptions = ({ pickUpOptions, carsWithTierPickupOptions }: Props) => {
  const sortedPickUpOptions = pickUpOptions.toSorted((a, b) => a.uiSortOrder - b.uiSortOrder);

  return (
    <div className='flex flex-col gap-4'>
      {sortedPickUpOptions.map((pickUpSalesItem) => {
        return (
          <TireHotelMultipleCars
            tireHotelSalesItem={pickUpSalesItem}
            carsWithTierPickupOptions={carsWithTierPickupOptions}
            key={pickUpSalesItem.salesItemId}
          />
        );
      })}
    </div>
  );
};
