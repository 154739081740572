import { t, Trans } from '@lingui/macro';
import { TireQuoteItem } from 'noddi-async/src/types/shared/quotes';
import { NoddiBasicCard, NoddiDropdown } from 'noddi-ui';
import { NoddiButton } from 'noddi-ui-common';
import TireImage from '../../assets/images/tire.png';

import { customerRoutes, formatCurrencyAmount } from 'noddi-util';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ErrorPageWithTranslations } from '../../components/ErrorPageWithTrans';
import { TireMetaData } from '../../components/TireOffer/TireMetaData';
import { getDimensionsString, getQuantityOptions, getTirePrice } from '../../utils/tire';
import StaggeredTireSelectionCard from './StaggeredTireSelectionCard';
import { useTireOfferStore } from './TireOfferStore';

interface TireSelectionCardProps {
  quoteItem: TireQuoteItem;
  slug: string;
  setTireDimensionsModalOpen: (isOpen: boolean) => void;
}

const TireSelectionCard = ({ quoteItem, slug, setTireDimensionsModalOpen }: TireSelectionCardProps) => {
  const isStaggered = quoteItem.quoteItemTires.length === 2;

  const { setSelectedQuoteItem } = useTireOfferStore();
  const navigate = useNavigate();
  const [quantity, setQuantity] = useState(quoteItem.quoteItemTires[0]?.quantityDefault ?? 0);

  if (quoteItem.quoteItemTires.length === 0) {
    return <ErrorPageWithTranslations />;
  }

  if (isStaggered) {
    return <StaggeredTireSelectionCard quoteItem={quoteItem} slug={slug} />;
  }

  const tireOption = quoteItem.quoteItemTires[0]!;
  const quantityOptions = getQuantityOptions(tireOption.quantityMax);

  function onSelectTireOption() {
    const updatedQuoteItem = {
      ...quoteItem,
      quoteItemTires: quoteItem.quoteItemTires.map((tire) => ({
        ...tire,
        quantitySelected: quantity
      }))
    };
    setSelectedQuoteItem(updatedQuoteItem);
    navigate(customerRoutes.tireOfferSummary.getPath({ slug }));
  }

  const price = getTirePrice(tireOption);

  return (
    <NoddiBasicCard className='flex flex-col gap-3'>
      <div className='flex gap-3'>
        <img src={TireImage} className='h-[121px] w-[91px]' />
        <div className='mt-3 flex flex-col'>
          <p className='font-bold text-3'>{tireOption.tireInventory.tire.brand.name}</p>
          <p className='font-bold text-5'>{tireOption.tireInventory.tire.model}</p>
          <div className='flex items-center'>
            <p>{getDimensionsString(tireOption.tireInventory.tire)}</p>
            <NoddiButton
              className='pl-2'
              endIcon='AltArrowDown'
              variant='link'
              size='small'
              onPress={() => setTireDimensionsModalOpen(true)}
            >
              <Trans>Edit</Trans>
            </NoddiButton>
          </div>
        </div>
      </div>
      <TireMetaData tire={tireOption.tireInventory.tire} />
      <div>
        <p className='font-bold'>
          {t`Total`} {formatCurrencyAmount(price.amount * quantity, 0, price.currency)}
        </p>
        <p className='text-3'>
          {formatCurrencyAmount(price.amount, 0, price.currency)} <Trans>per tire</Trans>
        </p>
      </div>

      <div className='flex gap-4'>
        <NoddiDropdown
          options={quantityOptions}
          onSelect={(item) => setQuantity(item.value)}
          placeholder={`${quantity} ${t`pcs`}`}
        />
        <NoddiButton onPress={onSelectTireOption}>{t`Select`}</NoddiButton>
      </div>
    </NoddiBasicCard>
  );
};

export default TireSelectionCard;
