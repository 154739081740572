import { URLKeys, noddiAsync } from 'noddi-async';
import { UserListItem } from 'noddi-async/src/types';
import { useAuthContext } from 'noddi-provider';
import { NoddiBasicCard, NoddiTable, useIsWithinTailWindBreakpoint, usePagination } from 'noddi-ui';
import { useState } from 'react';

import { Trans } from '@lingui/macro';
import { NoddiButton, NoddiIcon } from 'noddi-ui-common';
import getCommonTranslations from '../../commonTranslations';
import { ApiErrorMessageWithTrans } from '../ApiErrorMessageWithTrans';

interface Props {
  handleClose: () => void;
  handleClearDataWhenImpersonating?: () => void;
}

export default function ChooseImpersonateUser({ handleClose, handleClearDataWhenImpersonating }: Props) {
  const [user, setUser] = useState<UserListItem | null>(null);
  const { updateImpersonatedData, clearImpersonatedUser, impersonatedUser } = useAuthContext();
  const isSm = useIsWithinTailWindBreakpoint('sm');

  const { page, pageSize, search, setPage, setPageSize, setSearch } = usePagination({
    initialPageSize: isSm ? 3 : 5
  });

  const { data: users, isPending: isUsersPending } = noddiAsync.useGet({
    type: URLKeys.getPaginatedUsers,
    input: { page, pageSize, search }
  });

  const {
    mutateAsync: impersonateUser,
    isPending,
    isSuccess: impersonationSuccess,
    error
  } = noddiAsync.usePost({
    type: URLKeys.postImpersonateUser,
    queryConfig: {
      onSuccess(data) {
        updateImpersonatedData(data.data);
        handleClearDataWhenImpersonating?.();
      }
    }
  });

  const tableItems = users?.results.map((item) => {
    return {
      renderItems: [item.name, item.email, item.phoneNumber],
      item
    };
  });

  return (
    <>
      {impersonationSuccess ? (
        <div className='flex flex-col gap-4'>
          <NoddiBasicCard className='w-75 shadow-xl'>
            <div className='flex gap-2'>
              <NoddiIcon name='User' />
              {user?.name}
            </div>
            <div className='flex gap-2'>
              <NoddiIcon name='Phone' />
              {user?.phoneNumber}
            </div>
          </NoddiBasicCard>
          <p>
            <Trans>You are now entering god mode👨 Good luck and be careful!</Trans>
          </p>
          <NoddiButton onPress={handleClose}>
            <Trans>Close</Trans>
          </NoddiButton>
        </div>
      ) : (
        <div className='flex flex-col gap-4'>
          <span>
            <Trans>Select a user from the list to view the application as them and take actions on their behalf</Trans>
          </span>
          <div className='flex flex-col gap-4'>
            <NoddiBasicCard className='w-75 shadow-xl'>
              <div className='flex gap-2'>
                <NoddiIcon name='User' />
                {user?.name}
              </div>
              <div className='flex gap-2'>
                <NoddiIcon name='Phone' />
                {user?.phoneNumber}
              </div>
            </NoddiBasicCard>
            <NoddiButton
              loading={isPending}
              disabled={!user}
              onPress={() => {
                if (impersonatedUser) {
                  clearImpersonatedUser();
                }
                impersonateUser({ userId: user!.id });
              }}
            >
              <Trans>Impersonate user</Trans>
            </NoddiButton>
          </div>
          {error && <ApiErrorMessageWithTrans error={error} />}
          <NoddiTable
            translations={getCommonTranslations().table}
            count={users?.count}
            items={tableItems}
            isLoading={isUsersPending}
            onRowClick={setUser}
            pageSize={pageSize}
            setPageSize={setPageSize}
            page={page}
            setPage={setPage}
            headers={['Name', 'Email', 'Phone number']}
            searchProps={{
              onSearch: (e) => {
                setPage(1);
                setSearch(e.target.value);
              },
              placeholder: 'Search user by name, email or phone number'
            }}
          />
        </div>
      )}
    </>
  );
}
