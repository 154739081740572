import { t, Trans } from '@lingui/macro';
import { NoddiBasicCard, useNoddiToast } from 'noddi-ui';
import { noddiColors, NoddiIcon } from 'noddi-ui-common';

const CopyReferralLink = ({ referralCode }: { referralCode: string }) => {
  const { noddiToast } = useNoddiToast();

  const referralLink = `${window.location.host}/referrals/${referralCode}`;

  const copyToClipboard = () => {
    navigator.clipboard.writeText(referralLink);
    noddiToast.success(t`Referral link was copied`);
  };

  return (
    <NoddiBasicCard
      onClick={copyToClipboard}
      className='flex flex-col gap-4 bg-secondary-lightPurple text-primary-darkPurple'
    >
      <p className='font-bold text-5'>
        <Trans>My referral link</Trans> 🎉
      </p>

      <div className='flex items-center gap-2'>
        <div className='w-full rounded-lg border border-primary-darkPurple px-3 py-1.5'>
          <p className='max-w-50 truncate xxs:max-w-none'>{referralLink}</p>
        </div>
        <div>
          <NoddiIcon color={noddiColors.primary.darkPurple} name='Copy' size='medium' />
        </div>
      </div>
    </NoddiBasicCard>
  );
};

export default CopyReferralLink;
