import { Trans } from '@lingui/macro';
import { noddiAsync, URLKeys } from 'noddi-async';
import { BookingInfo } from 'noddi-async/src/types';
import { OrderSummary } from 'noddi-ui';
import { NoddiButton, NoddiIcon } from 'noddi-ui-common';
import { formatCurrencyAmount } from 'noddi-util';
import getCommonTranslations from '../../commonTranslations';
import { ApiErrorMessageWithTrans } from '../../components/ApiErrorMessageWithTrans';
import PaymentForm from './PaymentForm';

interface JobCompletedAndPaymentLeftProps {
  bookingInfo: BookingInfo;
  orderId: number;
}

export const JobCompletedAndPaymentLeft = ({ bookingInfo, orderId }: JobCompletedAndPaymentLeftProps) => {
  const { carOrderLines, discountOrderLines, otherOrderLines } = bookingInfo.order.orderLines;
  const {
    mutateAsync: createDineroSession,
    isPending: isDineroSessionPending,
    isSuccess: isCreateDineroSessionComplete,
    data: dineroSession,
    error
  } = noddiAsync.usePost({
    type: URLKeys.postDinteroSession
  });

  return (
    <div>
      {bookingInfo.serviceWorkerNickname && (
        <div className='flex items-center gap-2'>
          <NoddiIcon name='UserCircle' size='large' />
          <p>
            <Trans>By</Trans> {bookingInfo.serviceWorkerNickname}
          </p>
        </div>
      )}
      <div className='mt-2'>
        <OrderSummary
          isPaid={bookingInfo.isPaid}
          isInvoiced={bookingInfo.isInvoiced}
          translations={getCommonTranslations().orderSummary}
          showHeader={false}
          carItemLines={carOrderLines}
          discountLines={discountOrderLines}
          otherOrderLines={otherOrderLines}
        />
      </div>

      {isCreateDineroSessionComplete ? (
        <PaymentForm sessionId={dineroSession.data.sessionId} countryCode={dineroSession.data.countryCode} />
      ) : (
        <div>
          {error && <ApiErrorMessageWithTrans error={error} />}
          <NoddiButton
            className='mt-2 w-full'
            endIcon='ArrowRight'
            loading={isDineroSessionPending}
            onPress={async () => {
              await createDineroSession({
                orderId
              });
            }}
          >
            <Trans>Pay</Trans> {formatCurrencyAmount(bookingInfo.order.amountDue, 0)}
          </NoddiButton>
        </div>
      )}
    </div>
  );
};
