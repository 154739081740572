import { Path, Svg, SvgProps } from 'react-native-svg';

const HamburgerMenu = ({ color, ...props }: SvgProps) => (
  <Svg width={32} height={32} viewBox='0 0 32 32' fill='none' {...props}>
    <Path
      stroke={color}
      strokeLinecap='round'
      strokeWidth={2}
      d='M24.533 10.667H7.467M24.533 16H7.467M24.533 21.333H7.467'
    />
  </Svg>
);

export { HamburgerMenu };
