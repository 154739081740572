import { Currency } from 'noddi-async/src/types';
import { cn } from 'noddi-ui-common/src/utils';
import { formatCurrencyAmount } from 'noddi-util';
import { NoddiBasicCard } from '../../../atoms';
import { commonTexts } from '../../../commonTexts';

type Props = {
  discountPercentage?: number;
  discountAmount?: number;
  currency?: Currency;
  translations?: {
    used: string;
    activated: string;
    amount: string;
    discount: string;
    free: string;
  };
  namePublic: string;
  descriptionPublic: string;
  className?: string;
};

export function CouponCard({
  discountAmount,
  discountPercentage,
  namePublic,
  descriptionPublic,
  translations,
  className,
  currency
}: Props) {
  const texts = translations ?? {
    activated: commonTexts.activated,
    used: commonTexts.used,
    amount: commonTexts.amount,
    discount: commonTexts.discount,
    free: 'Free'
  };
  return (
    <NoddiBasicCard className={cn('relative flex flex-col justify-between gap-4 text-primary-darkPurple', className)}>
      <div className='flex flex-col gap-2'>
        <span className='font-bold text-5'>{namePublic}</span>
        <span>{descriptionPublic}</span>
      </div>

      <div className='flex justify-center'>
        <div className='flex rounded-md border border-dashed border-primary-darkPurple px-4 py-2'>
          {discountAmount && currency ? (
            <div className='flex gap-2'>
              <span className='font-bold'>{formatCurrencyAmount(discountAmount, 0, currency)}</span>
            </div>
          ) : discountPercentage === 100 ? (
            <span className='font-bold'>{texts.free}</span>
          ) : (
            <div className='flex gap-2'>
              <span className='font-bold'>{texts.discount}</span>
              <span className='font-bold'>{discountPercentage} %</span>
            </div>
          )}
        </div>
      </div>
    </NoddiBasicCard>
  );
}
