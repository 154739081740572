import { t, Trans } from '@lingui/macro';
import { noddiAsync, URLKeys } from 'noddi-async';
import { TireInQuoteItem, TireOfferV2, TireQuoteItem } from 'noddi-async/src/types/shared/quotes';
import { invalidateQueryExactMatch } from 'noddi-async/src/utils';
import { useAuthContext } from 'noddi-provider';
import { NoddiBasicCard, NoddiDropdown, NoddiTextInput } from 'noddi-ui';
import { NoddiButton } from 'noddi-ui-common';
import { formatCurrencyAmount } from 'noddi-util';
import { useState } from 'react';
import { ApiErrorMessageWithTrans } from '../../components/ApiErrorMessageWithTrans';
import { NoddiCircularLoaderWithTrans } from '../../components/NoddiCircularLoaderWithTrans';
import { TireMetaData } from '../../components/TireOffer/TireMetaData';
import { getTirePrice } from '../../utils/tire';

interface ChosenTireQuoteItem {
  quantity: number;
  tireQuote: TireQuoteItem;
}

const TireQuoteItemDetails = ({ tireQuoteItem, quantity }: { tireQuoteItem: TireInQuoteItem; quantity: number }) => {
  const price = getTirePrice(tireQuoteItem);
  const formattedTotalPrice = formatCurrencyAmount(price.amount * quantity, 0, price.currency);
  const formattedPrice = formatCurrencyAmount(price.amount, 0, price.currency);
  return (
    <div className='flex flex-col gap-3'>
      <div>
        <p className='font-bold text-3'>{tireQuoteItem.tireInventory.tire.brand.name}</p>
        <p className='font-bold text-5'>{tireQuoteItem.tireInventory.tire.model}</p>
      </div>
      <TireMetaData tire={tireQuoteItem.tireInventory.tire} />
      <div className='flex flex-col gap-1'>
        <p>
          {formattedPrice} {t`per tire`}
        </p>
        <p className='font-bold'>
          {t`Total of`} {formattedTotalPrice}
        </p>
      </div>
    </div>
  );
};

function getQuantityOptions(quantityMax: number) {
  return Array.from({ length: quantityMax }, (_, i) => ({
    label: `${i + 1} stk`,
    value: i + 1
  }));
}

function getTotalTireSum(tireSetOffer: TireOfferV2, selectedTireQuoteItemId: number, quantity: number) {
  const genericItemsSum = tireSetOffer.genericItems.reduce((acc, item) => acc + item.price.amount, 0);
  const totalTireSum = tireSetOffer.quoteItems.reduce((acc, item) => {
    if (item.id === selectedTireQuoteItemId) {
      const firstTire = item.quoteItemTires[0];
      if (firstTire) {
        const price = getTirePrice(firstTire);
        return acc + price.amount * quantity;
      }
    }
    return acc;
  }, 0);
  return { genericItemsSum, totalTireSum };
}

interface PriceSummaryProps {
  tireSetOffer: TireOfferV2;
  selectedTireQuoteItemId: number;
  quantity: number;
}

const PriceSummary = ({ tireSetOffer, selectedTireQuoteItemId, quantity }: PriceSummaryProps) => {
  const { genericItemsSum, totalTireSum } = getTotalTireSum(tireSetOffer, selectedTireQuoteItemId, quantity);

  return (
    <div>
      <div className='flex justify-between '>
        <p>
          {quantity} {t`pcs`}
        </p>
        <p>{formatCurrencyAmount(totalTireSum, 0, tireSetOffer.genericItems[0]?.price.currency)}</p>
      </div>
      {tireSetOffer.genericItems.map(({ name, price, id }) => (
        <div className='flex justify-between' key={id}>
          <p>{name}</p>
          <p>{formatCurrencyAmount(price.amount, 0, price.currency)}</p>
        </div>
      ))}

      <div className='flex justify-between font-bold'>
        <p>
          <Trans>Total sum including VAT</Trans>
        </p>
        <p>{formatCurrencyAmount(genericItemsSum + totalTireSum, 0, tireSetOffer.genericItems[0]?.price.currency)}</p>
      </div>
    </div>
  );
};

interface ConfirmTireOfferProps {
  tireSetOfferId: number;
  setActiveTireSetOfferId: (tireSetOfferId: number | null) => void;
}

export const ConfirmTireOffer = ({ tireSetOfferId, setActiveTireSetOfferId }: ConfirmTireOfferProps) => {
  const [chosenTireQuoteItem, setChosenTireQuoteItem] = useState<ChosenTireQuoteItem | null>(null);
  const [quantity, setQuantity] = useState(4);
  const [userComment, setUserComment] = useState('');
  const { currentUserGroupId: userGroupId } = useAuthContext();

  const {
    mutateAsync: confirmTireQuoteOffer,
    isPending: isConfirmTireQuoteOfferPending,
    error: confirmTireQuoteOfferError,
    isSuccess: isConfirmTireQuoteOfferSuccess
  } = noddiAsync.usePost({
    type: URLKeys.postConfirmTireQuoteOffer
  });

  const {
    data: tireSetOffer,
    isPending: isTireSetOfferPending,
    error: tireSetOfferError
  } = noddiAsync.useGet({
    type: URLKeys.getCarTireQuoteById,
    input: { id: tireSetOfferId.toString() }
  });

  if (isTireSetOfferPending) {
    return <NoddiCircularLoaderWithTrans />;
  }

  if (tireSetOfferError) {
    return <ApiErrorMessageWithTrans error={tireSetOfferError} />;
  }

  const headerText = t`Your order is confirmed!`;
  const descriptionText = t`We will be in touch with you shortly when we have ordered the tires!`;

  return (
    <div>
      {isConfirmTireQuoteOfferSuccess ? (
        <div>
          <div>
            <p className='font-bold text-7 text-primary-darkPurple'>{headerText}</p>
            <p className='mt-7 font-bold text-5 text-primary-purple'>{descriptionText}</p>
            <p className='mt-4 text-primary-darkPurple'>
              <Trans>In the meantime, if you have any questions, contact us at hei@noddi.no</Trans>
            </p>
            <div className='mt-4 flex justify-end'>
              <NoddiButton
                onPress={() => {
                  setActiveTireSetOfferId(null);
                  invalidateQueryExactMatch({
                    urlKey: URLKeys.getCarTireQuotes,
                    input: { userGroupIds: [userGroupId!] }
                  });
                }}
              >
                <Trans>Close</Trans>
              </NoddiButton>
            </div>
          </div>
        </div>
      ) : chosenTireQuoteItem ? (
        <div className='mt-4 flex flex-col gap-3'>
          {chosenTireQuoteItem.tireQuote.quoteItemTires.map((tireQuoteItem) => (
            <div>
              <p className='font-bold text-3'>{tireQuoteItem.tireInventory.tire.brand.name}</p>
              <p className='font-bold text-5'>{tireQuoteItem.tireInventory.tire.model}</p>
            </div>
          ))}
          <PriceSummary
            tireSetOffer={tireSetOffer}
            selectedTireQuoteItemId={chosenTireQuoteItem.tireQuote.id}
            quantity={quantity}
          />
          <p className='text-5'>
            <Trans>Anything more we should know?</Trans>
          </p>

          <NoddiTextInput
            rows={2}
            onChange={(e) => setUserComment(e.target.value)}
            value={userComment ?? ''}
            placeholder={t`Optional note if any extra information is needed...`}
            multiline
          />

          <div className='flex flex-col'>
            {confirmTireQuoteOfferError && <ApiErrorMessageWithTrans error={confirmTireQuoteOfferError} />}
            <div className='flex justify-end'>
              <NoddiButton
                variant='success'
                className='mt-5 w-full md:w-fit'
                loading={isConfirmTireQuoteOfferPending}
                onPress={async () =>
                  await confirmTireQuoteOffer({
                    quoteItemId: chosenTireQuoteItem.tireQuote.id,
                    quoteItemTires: chosenTireQuoteItem.tireQuote.quoteItemTires.map((chosenTireQuoteItem) => ({
                      quoteItemTireId: chosenTireQuoteItem.id,
                      quantity
                    })),
                    comments: userComment
                  })
                }
              >
                <Trans>Confirm order</Trans>
              </NoddiButton>
            </div>
          </div>
        </div>
      ) : (
        <div className='flex flex-col gap-3'>
          {tireSetOffer.quoteItems.map((tireQuoteOption) => (
            <NoddiBasicCard key={tireQuoteOption.id} className='flex flex-col gap-3 bg-systemColors-purpleBg'>
              {tireQuoteOption.quoteItemTires.map((tireQuoteItem) => (
                <div key={tireQuoteItem.id} className='flex flex-col gap-3'>
                  <TireQuoteItemDetails tireQuoteItem={tireQuoteItem} quantity={quantity} />
                  <NoddiDropdown
                    options={getQuantityOptions(tireQuoteItem.quantityMax)}
                    onSelect={(item) => setQuantity(item.value)}
                    placeholder={`${quantity} ${t`pcs`}`}
                  />
                </div>
              ))}
              <div className='flex justify-end'>
                <NoddiButton
                  onPress={() =>
                    setChosenTireQuoteItem({
                      quantity,
                      tireQuote: tireQuoteOption
                    })
                  }
                >
                  <Trans>Select</Trans>
                </NoddiButton>
              </div>
            </NoddiBasicCard>
          ))}
        </div>
      )}
    </div>
  );
};
