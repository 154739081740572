import { NoddiAsyncError } from 'noddi-async/src/types';

const handleStringError = (error: string, title?: string) => {
  const errorToSend = new Error(error);
  errorToSend.name = title ?? 'UnknownError';
  return errorToSend;
};

const handleAxiosError = (error: NoddiAsyncError) => {
  const errorMessage = getErrorDetails(error);
  const errorCode = error?.status ?? 'UnknownCode';
  const errorInstance = new Error(errorMessage);
  errorInstance.name = `Axios Error: ${errorCode}`;

  return errorInstance;
};

const getProcessedError = (error: NoddiAsyncError | null | string, title?: string): Error[] => {
  if (!error) {
    return [];
  }
  if (typeof error === 'string') {
    return [handleStringError(error, title)];
  } else if (error.isAxiosError) {
    return [handleAxiosError(error)];
  }
  return [error];
};

export const getNoddiErrorsForCapturing = ({
  error,
  title
}: {
  error?: NoddiAsyncError | (NoddiAsyncError | null)[] | string;
  title?: string;
}): Error[] => {
  if (!error) {
    return [];
  }

  if (Array.isArray(error)) {
    return error.flatMap((err) => getProcessedError(err, title)); // Ensures always an array
  }

  return getProcessedError(error, title);
};

const getErrorDetails = (error: NoddiAsyncError): string =>
  error.response?.data.errors?.map((e) => e.detail).join(', ') ?? error.message;

export const getRawNoddiApiErrors = (error: NoddiAsyncError | (NoddiAsyncError | null)[]): string => {
  const nonNullErrors = Array.isArray(error) ? error.filter((e): e is NoddiAsyncError => e != null) : [error];

  return nonNullErrors.map(getErrorDetails).join(', ');
};

export const errorCodes = {
  invalid_phone_number: 'Phone number is not valid',
  invalid_credentials:
    'No user with the provided credentials was found - contact support if you think this is a mistake',
  invalid_verification_code: 'The verification code is not valid',
  invalid_code: 'The code is not valid',
  invalid: 'The email you provided is not a valid email',
  user_not_naf_member:
    'We could not find you in the NAF registers. You have to be a member to activate the benefits. If this is wrong, please contact hei@noddi.no',
  new_user_not_eligible_for_campaign:
    'This campaign is intended for new members only. Therefore, we will not activate the coupons for you',
  booking_time_window_not_bookable:
    'The chosen time window has unfortunately been taken. Please choose another time window in the previous step.',
  user_phone_or_email_already_exists:
    'It looks like there already exists a user with the given email. Contact hei@noddi.no if you are unable to log into your account',
  capacity_contribution_already_exists:
    'You have already added a availability for one of these dates for this license area',
  car_not_found: 'Could not find the vehicle that was searched for',
  credentials_invalid: 'The credentials you provided are incorrect',
  phone_number_verification_error: 'The phone number you provided is not valid',
  pin_code_verification_error: 'The verification code you provided is not valid',
  password_verification_failed: 'The passwords do not match',
  genericErrorMessage: 'Obs.. Something seems to have have crashed'
} as const;
