import { cva, type VariantProps } from 'class-variance-authority';
import { PropsWithChildren } from 'react';

import { ActivityIndicator, Pressable, PressableProps, Text, View } from 'react-native';
import { noddiColors } from '../../tailwind-design-preset';
import { NoddiIcon } from '../atoms';
import { IconName } from '../types/icons';
import { cn, isMobile, isWeb } from '../utils';

export const webButtonStyling = cn(
  'min-w-fit ring-offset-primary-white transition-transform focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-systemColors-grey focus-visible:ring-offset-2 enabled:active:scale-90 disabled:pointer-events-none'
);
// Don't add hover as effect persists on mobile after pressed, which makes the button look disabled. ( Can do for over md > )
export const buttonVariants = cva(
  cn('flex items-center justify-center rounded-lg disabled:opacity-50', isWeb && webButtonStyling, isWeb && 'w-fit'),
  {
    variants: {
      variant: {
        primary: cn('border border-primary-darkPurple bg-primary-darkPurple', isMobile && 'active:bg-primary-purple'),
        secondary: cn(
          'border border-primary-darkPurple bg-primary-white',
          isMobile && 'active:bg-primary-darkPurple30'
        ),
        ghost: cn(isMobile && 'active:bg-primary-darkPurple30'),
        success: cn('border border-signal-success bg-signal-success', isMobile && 'active:bg-signal-success/80'),
        destructive: cn('border border-signal-danger bg-signal-danger', isMobile && 'active:bg-signal-danger/80'),
        link: cn(isMobile && 'active:bg-primary-darkPurple30'),
        lightLink: cn(isMobile && 'active:bg-primary-purple90')
      },
      // Change h-10 for h-fit when we use correct font, bc now icons bigger than text so buttons have different size with icons and not.
      size: {
        default: 'h-10 px-4 py-2',
        small: cn('px-3 py-1', isWeb && 'h-fit')
      }
    },
    defaultVariants: {
      variant: 'primary',
      size: 'default'
    }
  }
);

export const textVariants = cva('', {
  variants: {
    variant: {
      primary: 'text-text-secondary',
      secondary: 'text-primary-darkPurple',
      ghost: 'text-text-primary',
      success: 'text-text-secondary',
      destructive: 'text-text-secondary',
      link: cn('text-primary-purple', isWeb && 'md:hover:underline'),
      lightLink: cn('text-text-secondary', isWeb && 'md:hover:underline')
    }
  },
  defaultVariants: {
    variant: 'primary'
  }
});

export const loadingVariants = cva('', {
  variants: {
    variant: {
      primary: noddiColors.primary.white,
      secondary: noddiColors.primary.darkPurple,
      ghost: noddiColors.primary.darkPurple,
      success: noddiColors.primary.white,
      destructive: noddiColors.primary.white,
      link: noddiColors.primary.darkPurple,
      lightLink: noddiColors.primary.darkPurple
    }
  },
  defaultVariants: {
    variant: 'primary'
  }
});

export const iconColorVariantsInNoddiButton = cva('', {
  variants: {
    variant: {
      primary: noddiColors.text.secondary,
      secondary: noddiColors.primary.darkPurple,
      ghost: noddiColors.primary.black,
      success: noddiColors.text.secondary,
      destructive: noddiColors.text.secondary,
      link: noddiColors.primary.purple,
      lightLink: noddiColors.text.secondary
    }
  },
  defaultVariants: {
    variant: 'primary'
  }
});
export type ButtonProps = PressableProps &
  VariantProps<typeof buttonVariants> & {
    loading?: boolean;
  } & ({ startIcon?: IconName; endIcon?: never } | { endIcon?: IconName; startIcon?: never });

export const NoddiButton = ({
  className,
  variant,
  size,
  loading,
  children,
  startIcon,
  endIcon,
  onPress,
  disabled,
  ...props
}: PropsWithChildren<ButtonProps>) => {
  const isSmall = size === 'small';
  const iconSize = isSmall ? 'small' : 'medium';
  const buttonClassName = cn(buttonVariants({ variant, size }), className);
  const textClassName = cn(textVariants({ variant }));
  const iconColor = iconColorVariantsInNoddiButton({ variant });
  const loadingColor = loadingVariants({ variant });

  return (
    <Pressable
      className={buttonClassName}
      accessibilityRole='button'
      onPress={onPress}
      disabled={disabled || loading}
      {...props}
    >
      {loading ? (
        <View className='flex items-center justify-center' accessible>
          <ActivityIndicator size='small' color={loadingColor} />
        </View>
      ) : (
        <View className='flex flex-row items-center justify-center gap-2'>
          {startIcon && (
            <View>
              <NoddiIcon color={iconColor} name={startIcon} size={iconSize} />
            </View>
          )}
          <Text numberOfLines={1} className={textClassName}>
            {children}
          </Text>
          {endIcon && (
            <View>
              <NoddiIcon color={iconColor} name={endIcon} size={iconSize} />
            </View>
          )}
        </View>
      )}
    </Pressable>
  );
};
