import { Box, Stack, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { debounce } from '@mui/material/utils';
import { Fragment } from 'react/jsx-runtime';

import { cn, noddiColors } from 'noddi-ui-common';
import { Link } from 'react-router-dom';
import { commonTexts } from '../../../commonTexts';
import { BaseTableProps, NoddiCardProps, TableProps } from '../../../types';
import { NoddiCard } from '../Cards';
import { NoddiCircularLoader } from '../Loaders';
import { NoddiSearchBar, NoddiTablePagination } from '../Navigation';
import NoddiActiveFilterChips from './Filters/ActiveFilterChips';
import { FilterSpacer } from './Filters/FilterSpacer';
import { NoddiTableFilterList } from './Filters/NoddiTableFilterList';

function NoddiBaseTable<Item>({
  items = [],
  isLoading,
  headers,
  getUrlPath,
  searchProps,
  onRowClick,
  emptyStateText,
  centerHeaderTexts
}: BaseTableProps<Item>) {
  return (
    <Box sx={{ overflowX: 'auto', width: '100%' }}>
      <Table sx={{ marginTop: searchProps ? '20px' : null }}>
        <TableHead
          sx={{
            '& .MuiTableRow-root': {
              backgroundColor: noddiColors.systemColors.outlineStroke,
              color: noddiColors.systemColors.grey
            },
            '& .MuiTableRow-root th:first-of-type': {
              borderTopLeftRadius: '16px',
              borderBottomLeftRadius: '16px'
            },
            '& .MuiTableRow-root th:last-of-type': {
              borderTopRightRadius: '16px',
              borderBottomRightRadius: '16px'
            }
          }}
        >
          <TableRow>
            {headers.length > 0 &&
              headers.map((item, index) => (
                <TableCell sx={{ textAlign: centerHeaderTexts ? 'center' : 'start' }} key={index}>
                  {item}
                </TableCell>
              ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {items.length === 0 && emptyStateText && !isLoading ? (
            <TableRow>
              <TableCell style={{ textAlign: 'center', padding: '20px' }} colSpan={headers.length}>
                {emptyStateText}
              </TableCell>
            </TableRow>
          ) : (
            items.map((item, index) => (
              <Fragment key={index}>
                {getUrlPath?.(item.item) ? (
                  <TableRow hover key={index}>
                    {item.renderItems.map((renderItem, index) => (
                      <TableCell style={{ padding: '4px 0' }} key={index}>
                        <Link className='block px-4 py-3' to={getUrlPath(item.item)!}>
                          {renderItem}
                        </Link>
                      </TableCell>
                    ))}
                  </TableRow>
                ) : (
                  <TableRow
                    className={cn(onRowClick && 'cursor-pointer')}
                    onClick={() => onRowClick?.(item.item)}
                    hover={!!onRowClick}
                    key={index}
                  >
                    {item.renderItems.map((renderItem, index) => (
                      <TableCell key={index}>{renderItem}</TableCell>
                    ))}
                  </TableRow>
                )}
              </Fragment>
            ))
          )}
        </TableBody>
      </Table>
    </Box>
  );
}

export function NoddiTable<Item>({
  count = 0,
  items = [],
  setPage,
  setPageSize,
  page = 0,
  pageSize = 10,
  isLoading,
  headers,
  bgColor = 'white',
  getUrlPath,
  onRowClick,
  searchProps,
  emptyStateText,
  filters,
  clearFilters,
  wrapWithCard = true,
  translations,
  centerHeaderTexts,
  debounceSearchValueMilliseconds = 200,
  ...cardProps
}: TableProps<Item> & Omit<NoddiCardProps, 'children'>) {
  const texts = translations ?? commonTexts.table;

  const debouncedSearchValue = debounce(searchProps?.onSearch || (() => {}), debounceSearchValueMilliseconds);

  const isPaginated = count && setPage && setPageSize && page && pageSize;

  const TableContent = (
    <div>
      <Stack direction='column'>
        {searchProps && (
          <NoddiSearchBar
            handleChange={debouncedSearchValue}
            placeholder={searchProps.placeholder}
            defaultValue={searchProps.defaultValue}
            sx={{
              width: '100%',
              maxWidth: '600px',
              marginRight: '12px',
              marginBottom: filters ? '12px' : '0',
              boxSizing: 'border-box',
              '& .MuiInputBase-root.MuiOutlinedInput-root': {
                height: '100%'
              }
            }}
          />
        )}
        {filters && (
          <FilterSpacer>
            <Stack direction='row' gap={0.5} width='100%' flexWrap='wrap' justifyContent='flex-start'>
              <NoddiTableFilterList filters={filters} clearFilters={clearFilters} translations={texts} />
            </Stack>
          </FilterSpacer>
        )}
        {filters && <NoddiActiveFilterChips filters={filters ?? []} />}
      </Stack>
      <Box sx={{ position: 'relative' }} bgcolor={bgColor}>
        {isLoading ? (
          <Stack minHeight={debounceSearchValueMilliseconds} alignItems='center' justifyContent='center'>
            <NoddiCircularLoader />
          </Stack>
        ) : isPaginated ? (
          <>
            <NoddiBaseTable
              centerHeaderTexts={centerHeaderTexts}
              items={items}
              isLoading={isLoading}
              headers={headers}
              getUrlPath={getUrlPath}
              onRowClick={onRowClick}
              searchProps={searchProps}
              emptyStateText={emptyStateText}
            />
            <NoddiTablePagination
              count={count}
              page={page}
              setPage={setPage}
              rowsPerPage={pageSize}
              setRowsPerPage={setPageSize}
              translations={texts}
            />
          </>
        ) : (
          <NoddiBaseTable
            centerHeaderTexts={centerHeaderTexts}
            items={items}
            isLoading={isLoading}
            headers={headers}
            getUrlPath={getUrlPath}
            onRowClick={onRowClick}
            searchProps={searchProps}
            emptyStateText={emptyStateText}
          />
        )}
      </Box>
    </div>
  );

  if (!wrapWithCard) {
    return TableContent;
  }
  return (
    <NoddiCard disableSpacing {...cardProps}>
      {TableContent}
    </NoddiCard>
  );
}
