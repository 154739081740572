import { t } from '@lingui/macro';
import { URLKeys, noddiAsync } from 'noddi-async';
import { useAuthContext } from 'noddi-provider';

import { ErrorPageWithTranslations } from '../../components/ErrorPageWithTrans';
import ContentWrapperWithQuery from '../../components/Layouts/ContentWrapperWithQuery';
import { NewBookingButtonScalable } from '../../components/NewBookingButton/NewBookingButtonScalable';
import MembershipDiscount from './MembershipDiscount';
import NoMemberShip from './NoMembership';

const Membership = () => {
  const { currentUserGroupId: userGroupId } = useAuthContext();
  if (!userGroupId) {
    return <ErrorPageWithTranslations />;
  }
  const query = noddiAsync.useGet({
    type: URLKeys.getMembershipPrograms,
    input: { userGroupId }
  });

  return (
    <ContentWrapperWithQuery
      title={t`Membership benefits`}
      subtitle={t`An overview of all your active memberships and recurring benefits`}
      HeaderElement={<NewBookingButtonScalable />}
      className='text-primary-darkPurple'
      query={query}
    >
      {({ data }) =>
        data.length ? (
          <div className='flex flex-col gap-6'>
            {data.map((membershipProgram) => (
              <div className='flex flex-col gap-2' key={membershipProgram.id}>
                <h2 className='font-bold text-5 md:text-7'>{membershipProgram.name}</h2>
                <div className='flex w-full flex-col gap-2'>
                  {membershipProgram.discounts.map((discount) => (
                    <div key={discount.id}>
                      <MembershipDiscount discount={discount} />
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        ) : (
          <NoMemberShip />
        )
      }
    </ContentWrapperWithQuery>
  );
};

export default Membership;
