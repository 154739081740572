import { Trans } from '@lingui/macro';
import { URLKeys, noddiAsync } from 'noddi-async';
import { invalidateQueryExactMatch } from 'noddi-async/src/utils';
import { tracking, useAuthContext } from 'noddi-provider';
import { NoddiButton } from 'noddi-ui-common';
import { customerRoutes } from 'noddi-util';
import { useNavigate } from 'react-router-dom';
import { ApiErrorMessageWithTrans } from '../../../components/ApiErrorMessageWithTrans';
import { useAutoRecallComment, useAutoRecallMetaData } from '../AutoRecallStore';

export const ConfirmAutoRecall = () => {
  const { bookingId } = useAutoRecallMetaData();
  const { isLoggedIn } = useAuthContext();
  const comment = useAutoRecallComment();

  const navigate = useNavigate();

  const {
    mutateAsync: patchConfirmAutoRecallBooking,
    isPending,
    error
  } = noddiAsync.usePatch({
    type: URLKeys.patchConfirmAutoRecallBooking,
    queryConfig: {
      onSuccess: () => {
        invalidateQueryExactMatch({
          urlKey: URLKeys.getUserBooking,
          input: { bookingId: bookingId as unknown as string }
        });

        tracking.track('autoRecallConfirmBooking');
        navigate(customerRoutes.confirmation.getPath({ bookingId: bookingId! }));
      }
    }
  });

  const {
    mutateAsync: updateRecallBooking,
    isPending: isUpdateRecallBookingPending,
    error: updateRecallBookingError
  } = noddiAsync.usePatch({
    type: URLKeys.patchRecallBooking
  });

  const onSubmit = async () => {
    updateRecallBooking({
      id: bookingId!,
      commentsUser: comment
    });
    patchConfirmAutoRecallBooking({ bookingId: bookingId! });
  };

  return (
    <div className='flex w-full items-center justify-end gap-2'>
      {(error || updateRecallBookingError) && (
        <div className='absolute bottom-20 mb-2 mdx:relative mdx:bottom-0'>
          <ApiErrorMessageWithTrans error={[updateRecallBookingError, error]} />
        </div>
      )}

      <NoddiButton
        disabled={!bookingId || !isLoggedIn}
        variant='success'
        loading={isPending || isUpdateRecallBookingPending}
        onPress={onSubmit}
      >
        <Trans>Confirm booking</Trans>
      </NoddiButton>
    </div>
  );
};
