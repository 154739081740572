import { Trans } from '@lingui/macro';
import { noddiAsync, URLKeys } from 'noddi-async';

import { useIsMobile } from 'noddi-ui';
import { NoddiButton } from 'noddi-ui-common';
import { customerRoutes, TIRE_SET_OFFER_ADMIN_VIEW_PARAM } from 'noddi-util';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import ContentWrapperWithQuery from '../../components/Layouts/ContentWrapperWithQuery';
import { useScrollToTop } from '../BookingFlow/helpers/useScrollToTop';
import TireOfferExplanation from './TireOfferExplanation';
import { TireOfferMeasurements } from './TireOfferMeasurements';
import TiresSvg from './TiresSvg';

const TireOffer = () => {
  const { slug } = useParams() as { slug: string };
  const [searchParams] = useSearchParams();
  const isMobile = useIsMobile();
  const navigate = useNavigate();

  useScrollToTop();

  const query = noddiAsync.useGet({
    type: URLKeys.getCarTireQuoteBySlug,
    input: { slug },
    queryConfig: { staleTime: Infinity }
  });

  noddiAsync.useGet({
    type: URLKeys.getTireSetOfferPageView,
    input: { slug },
    queryConfig: { enabled: searchParams.get(TIRE_SET_OFFER_ADMIN_VIEW_PARAM) !== 'true', staleTime: Infinity }
  });

  return (
    <ContentWrapperWithQuery widerScreen hideBackButton query={query}>
      {({ data }) => (
        <div>
          <div className='mt-6 flex flex-col gap-6 md:flex-row-reverse md:items-center mdx:gap-25 xl:gap-40'>
            <div>
              <TiresSvg height={isMobile ? 131 : undefined} width={isMobile ? 192 : undefined} />
            </div>
            <div className='mb-2 flex flex-col gap-6 sm:mt-0'>
              <h1 className='font-bold text-8 text-primary-darkPurple md:text-11'>
                <Trans>Time for new tires?</Trans>
              </h1>
              <p className='text-primary-darkPurple'>
                <Trans>
                  Our measurements show that your tires may soon be, or are already, below the legal tread depth.
                </Trans>
              </p>
              <NoddiButton
                onPress={() => navigate(customerRoutes.tireOfferSelection.getPath({ slug }))}
                endIcon='ArrowRight'
              >
                <Trans>See offer for new tires</Trans>
              </NoddiButton>

              <TireOfferMeasurements tireOffer={data} />
            </div>
          </div>
          <div className='mt-10 flex flex-col gap-2 md:mt-20'>
            <h2 className='font-bold text-primary-darkPurple md:text-6'>
              <Trans>How it works</Trans>
            </h2>
            <TireOfferExplanation slug={slug} />
          </div>
        </div>
      )}
    </ContentWrapperWithQuery>
  );
};

export default TireOffer;
