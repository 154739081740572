import { Trans } from '@lingui/macro';
import { NoddiLogo, useIsWithinTailWindBreakpoint } from 'noddi-ui';
import { NoddiButton, noddiColors } from 'noddi-ui-common';
import { customerRoutes } from 'noddi-util';
import { useNavigate } from 'react-router-dom';
import NavWrapper from './NavWrapper';

interface HomeHeaderNavProps {
  onNavOpen: () => void;
}

export const HomeHeaderNav = ({ onNavOpen }: HomeHeaderNavProps) => {
  const navigate = useNavigate();
  const isMd = useIsWithinTailWindBreakpoint('md');

  return (
    <NavWrapper>
      <div className='flex w-full items-center gap-6'>
        <button className='p-2' onClick={() => navigate(customerRoutes.homepage.getPath())}>
          <NoddiLogo color={noddiColors.primary.orange} width={67} height={19} />
        </button>

        <div className='ml-auto flex gap-3'>
          <NoddiButton
            variant='secondary'
            size={isMd ? 'small' : 'default'}
            className='rounded-full border-none'
            endIcon='HamburgerMenu'
            onPress={onNavOpen}
          >
            <Trans>Menu</Trans>
          </NoddiButton>
        </div>
      </div>
    </NavWrapper>
  );
};
