import { t, Trans } from '@lingui/macro';

import { AutoRecallBooking } from 'noddi-async/src/types';
import { getLongAddressName, NoddiBasicCard } from 'noddi-ui';
import { cn, NoddiButton, NoddiIcon } from 'noddi-ui-common';
import { customerRoutes } from 'noddi-util';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { BrandBanner } from '../BookingFlow/components/BrandAnnouncement';
import { useAutoRecallActions, useAutoRecallMetaData } from './AutoRecallStore';
import { CancelModalButton } from './CancelModalButton';
import { usePopulateBookingStore } from './hooks/usePopulateBookingStore';
import { PriceSummaryCardEdition } from './PriceSummary';

type Props = {
  booking: AutoRecallBooking;
  slug: string;
  className?: string;
};

export const AutoRecallCard = ({ booking, slug, className }: Props) => {
  const navigate = useNavigate();
  const { clearStore: clearAutoRecallStore, setBookingMetaData } = useAutoRecallActions();
  const metaData = useAutoRecallMetaData();
  const populateBookingStore = usePopulateBookingStore();

  const { address, bookingId } = booking;

  useEffect(() => {
    const clearAndPopulateAutoRecallStore = () => {
      clearAutoRecallStore();

      setBookingMetaData({
        slug,
        bookingId
      });
    };

    if (metaData.bookingId !== bookingId || metaData.slug !== slug) {
      clearAndPopulateAutoRecallStore();
    }
  }, [bookingId, clearAutoRecallStore, metaData.bookingId, metaData.slug, setBookingMetaData, slug]);

  const handleEditCarsAndServices = (booking: AutoRecallBooking) => {
    populateBookingStore(booking);
    navigate(customerRoutes.newBookingCars.getPath());
  };

  return (
    <NoddiBasicCard className={cn(className, 'flex flex-col overflow-hidden p-0')}>
      <img src='/assets/images/wc.jpg' alt={t`Noddi wheel change`} />

      <div className='flex flex-col gap-4 px-5 pb-3 pt-5'>
        {address && (
          <div className='flex w-full justify-between gap-4'>
            <div className='flex gap-2'>
              <NoddiIcon name='LocationPin' />
              <span>{getLongAddressName(address)}</span>
            </div>
            <NoddiButton
              onPress={() => navigate(customerRoutes.autoRecallAddress.getPath())}
              variant='secondary'
              size='small'
              startIcon='Edit'
            >
              <Trans>Edit</Trans>
            </NoddiButton>
          </div>
        )}

        <div className='flex w-full items-center justify-between gap-4'>
          <div className='flex gap-2'>
            <NoddiIcon name='WheelAngle' />
            <Trans>Cars and services</Trans>
          </div>

          <NoddiButton
            onPress={() => handleEditCarsAndServices(booking)}
            startIcon='Edit'
            variant='secondary'
            size='small'
          >
            <Trans>Edit</Trans>
          </NoddiButton>
        </div>

        <PriceSummaryCardEdition shoppingCart={booking} />
        <BrandBanner brand={booking.brand} />

        <div className='flex flex-col gap-2'>
          <NoddiButton
            onPress={() => navigate(customerRoutes.autoRecallTimeWindowPicker.getPath())}
            startIcon='CheckRounded'
            className='w-full'
            variant='success'
          >
            <Trans>Pick time</Trans>
          </NoddiButton>
          <CancelModalButton />
        </div>
      </div>
    </NoddiBasicCard>
  );
};
