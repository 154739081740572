import { Path, Svg, SvgProps } from 'react-native-svg';

const CheckedRectangle = ({ color, ...props }: SvgProps) => (
  <Svg viewBox='0 0 24 24' width={24} height={24} fill='none' {...props}>
    <Path
      fill={color}
      fillRule='evenodd'
      d='M12 22c-4.714 0-7.071 0-8.536-1.465C2 19.072 2 16.714 2 12s0-7.071 1.464-8.536C4.93 2 7.286 2 12 2c4.714 0 7.071 0 8.535 1.464C22 4.93 22 7.286 22 12c0 4.714 0 7.071-1.465 8.535C19.072 22 16.714 22 12 22Zm4.03-13.03a.75.75 0 0 1 0 1.06l-5 5a.75.75 0 0 1-1.06 0l-2-2a.75.75 0 1 1 1.06-1.06l1.47 1.47 4.47-4.47a.75.75 0 0 1 1.06 0Z'
      clipRule='evenodd'
    />
  </Svg>
);
export { CheckedRectangle };
