import { Path, Svg, SvgProps } from 'react-native-svg';

const WheelChange = ({ color, ...props }: SvgProps) => (
  <Svg width='30' height='30' viewBox='0 0 30 30' fill='none' {...props}>
    <Path d='M19.5 25C21.9853 25 24 20.5228 24 15C24 9.47716 21.9853 5 19.5 5' stroke={color} strokeWidth='1.59375' />
    <Path
      d='M15 15C15 20.5228 12.9853 25 10.5 25C8.01472 25 6 20.5228 6 15C6 9.47716 8.01472 5 10.5 5C12.9853 5 15 9.47716 15 15Z'
      stroke={color}
      strokeWidth='1.59375'
    />
    <Path d='M10.5 25C12.9853 25 15 20.5228 15 15C15 9.47716 12.9853 5 10.5 5' stroke={color} strokeWidth='1.59375' />
    <Path
      d='M12 15C12 18.3137 11.3284 21 10.5 21C9.67157 21 9 18.3137 9 15C9 11.6863 9.67157 8.99999 10.5 8.99999C11.3284 8.99999 12 11.6863 12 15Z'
      stroke={color}
      strokeWidth='1.59375'
    />
    <Path d='M10.5 5L19.5 5' stroke={color} strokeWidth='1.59375' />
    <Path d='M10.5 25L19.5 25' stroke={color} strokeWidth='1.59375' />
    <Path d='M12 15H11' stroke={color} strokeWidth='1.59375' strokeLinecap='round' />
  </Svg>
);

export { WheelChange };
