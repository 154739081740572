import { NoddiAsyncError } from '../components/Elements/Errors/ErrorMessage';
import { getRawNoddiApiErrors } from '../components/Elements/Errors/utils';
import { useToast } from './ToastProvider';

export const useNoddiToast = () => {
  const { addToast } = useToast();

  const success = (description: string) => addToast({ type: 'success', description });
  const error = (description: string) => addToast({ type: 'error', description });
  const warning = (description: string) => addToast({ type: 'warning', description });
  // Use this in internal apps only
  const asyncError = (e: NoddiAsyncError) => {
    error(getRawNoddiApiErrors(e));
  };

  const noddiToast = { success, error, warning, asyncError };

  return { noddiToast };
};
