import { Trans } from '@lingui/macro';
import { NoddiButton } from 'noddi-ui-common';
import { useState } from 'react';
import { CancelModal } from './CancelModal';
export const CancelModalButton = () => {
  const [showExtraInfoModal, setShowExtraInfoModal] = useState(false);

  return (
    <>
      <CancelModal open={showExtraInfoModal} onClose={() => setShowExtraInfoModal(false)} />

      <NoddiButton
        onPress={() => {
          setShowExtraInfoModal(true);
        }}
        size='small'
        className='w-full'
        variant='link'
      >
        <Trans>Not interested?</Trans>
      </NoddiButton>
    </>
  );
};
