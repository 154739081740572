import Avatar from '@mui/material/Avatar';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { useAuthContext } from 'noddi-provider';
import { ReactNode, useState } from 'react';

import { cn, noddiColors, NoddiIcon } from 'noddi-ui-common';
import { SideNavDivider } from './SideNavDivider';

const stringAvatar = ({ str, isLoading }: { str: string; isLoading: boolean }) => {
  let displayStr;
  if (isLoading) {
    displayStr = '?';
  } else {
    const words = str.split(' ');
    const firstLetters = words.map((word) => word.charAt(0));
    const avatarStr = firstLetters.join('').toUpperCase();
    displayStr = avatarStr.length > 3 ? avatarStr.slice(0, 3) + '..' : avatarStr;
  }

  const getFontSize = () => {
    if (displayStr.length > 3) {
      return '7px';
    } else if (displayStr.length > 2) {
      return '10px';
    } else {
      return '14px';
    }
  };

  return {
    children: displayStr,
    sx: {
      borderRadius: '4px',
      backgroundColor: noddiColors.primary.white,
      height: '25px',
      width: '25px',
      fontSize: getFontSize()
    }
  };
};

interface SelectComponentProps {
  handleClearDataWhenImpersonating?: () => void;
  disableUserGroupSelection?: boolean;
}
const SelectComponent = ({ handleClearDataWhenImpersonating, disableUserGroupSelection }: SelectComponentProps) => {
  const { userData, updateUserData, updateImpersonatedData, impersonatedUser } = useAuthContext();

  const [isOpen, setIsOpen] = useState(false);

  const handleChange = (e: SelectChangeEvent<string | number>) => {
    if (!userData) {
      throw new Error('User data is not defined');
    }

    const newUserData = userData.user;
    newUserData.userGroups = userData.user.userGroups.map((ug) => {
      ug.isSelected = ug.id.toString() === e.target.value.toString();
      return ug;
    });

    if (impersonatedUser) {
      updateImpersonatedData({ ...userData, user: newUserData });
    } else {
      updateUserData({ ...userData, user: newUserData });
    }
    handleClearDataWhenImpersonating?.();
  };

  const userGroups = userData?.user.userGroups || [];

  const selectedUserGroup = userGroups.find((ug) => ug.isSelected);
  const selectedUserGroupName = selectedUserGroup?.name ?? '';

  const enable = userGroups.length > 1 && !disableUserGroupSelection;

  return (
    <div>
      <button
        disabled={!enable}
        className='flex w-full items-center justify-between gap-2 pr-3'
        onClick={() => setIsOpen(!isOpen)}
      >
        <div className='flex items-center gap-2'>
          <Avatar
            {...stringAvatar({
              str: selectedUserGroupName,
              isLoading: !selectedUserGroup
            })}
          />
          <span className='text-start'>{selectedUserGroupName}</span>
        </div>
        {enable && <NoddiIcon name='AltArrowDown' color={noddiColors.primary.white} />}
      </button>

      <Select
        sx={{ visibility: 'hidden', height: 0, width: '100%' }}
        open={isOpen}
        value={selectedUserGroup?.id ?? ''}
        onOpen={() => setIsOpen(true)}
        onClose={() => setIsOpen(false)}
        onChange={(e) => handleChange(e)}
      >
        {userGroups.map((userGroup) => (
          <MenuItem
            key={userGroup.id}
            value={userGroup.id}
            selected={userGroup.isSelected}
            sx={{
              '&:hover': {
                backgroundColor: noddiColors.systemColors.outlineStroke,
                '&.Mui-selected': {
                  backgroundColor: noddiColors.systemColors.outlineStroke
                }
              },
              '&.Mui-selected': {
                backgroundColor: noddiColors.primary.darkPurple30
              }
            }}
          >
            <span className='text-3'>{userGroup.name}</span>
          </MenuItem>
        ))}
      </Select>
    </div>
  );
};

export const SideNavHeader = ({
  handleClearDataWhenImpersonating,
  CustomerHeaderElement,
  disableUserGroupSelection
}: {
  handleClearDataWhenImpersonating?: () => void;
  CustomerHeaderElement?: ReactNode;
  disableUserGroupSelection?: boolean;
}) => {
  return (
    <div className='flex flex-col px-5 py-4 text-3 text-primary-white'>
      <SelectComponent
        disableUserGroupSelection={disableUserGroupSelection}
        handleClearDataWhenImpersonating={handleClearDataWhenImpersonating}
      />
      {CustomerHeaderElement}
      <SideNavDivider className={cn(!!CustomerHeaderElement && '!mt-4')} />
    </div>
  );
};
