import { useState } from 'react';

import { TZDate } from '@date-fns/tz';
import { addDays } from 'date-fns';
import { noddiAsync, URLKeys } from 'noddi-async';
import { AddressOutput, AvailableBookingTimeWindow, SelectedSalesItemsCarGrouped } from 'noddi-async/src/types';
import { DateFormats, noddiFormatDate } from 'noddi-util';
import { useIsWithinTailWindBreakpoint } from '../../../hooks';
import { ErrorPage, LoadingScreen } from '../../Elements';
import { DateCard } from './DateCard';
import { GoToDatePicker } from './GoToDatePicker';
import { SliderArrows } from './SliderArrows';
import { TimeWindowCard } from './TimeWindowCard';
import { getFromAndToDate } from './utils';

type BookingTimeWindowPickerProps = {
  address: AddressOutput;
  cars: SelectedSalesItemsCarGrouped[];
  selectedDeliveryWindow?: { startsAt: string; endsAt: string };
  customFromDate?: string;
  translations: {
    today: string;
    tomorrow: string;
    soldOut: string;
    nextButton: string;
    prevButton: string;
    jumpToDateTriggerText: string;
    loading: string;
  };
  onAvailableSelect: (bookingTimeWindow: AvailableBookingTimeWindow) => void;
  onUnavailableSelect?: (bookingTimeWindow: AvailableBookingTimeWindow) => void;
};

export const BookingTimeWindowPicker = ({
  address,
  cars,
  selectedDeliveryWindow,
  onAvailableSelect,
  onUnavailableSelect,
  translations,
  customFromDate
}: BookingTimeWindowPickerProps) => {
  const {
    data: latestDeliveryWindow,
    isPending: isLatestDeliveryWindowPending,
    error: latestDeliveryWindowError
  } = noddiAsync.useGet({
    type: URLKeys.getLatestDeliveryWindow
  });

  const {
    data: firstAvailableDeliveryWindow,
    isPending: isFirstAvailableDeliveryWindowPending,
    error: firstAvailableDeliveryWindowError
  } = noddiAsync.usePostAsGetQuery({
    type: URLKeys.getFirstAvailableDeliveryWindow,
    input: {
      addressId: address.id,
      cars
    }
  });

  if (isLatestDeliveryWindowPending || isFirstAvailableDeliveryWindowPending) {
    return <LoadingScreen loadingText={translations.loading} />;
  }

  if (latestDeliveryWindowError || firstAvailableDeliveryWindowError) {
    return <ErrorPage apiError={[latestDeliveryWindowError, firstAvailableDeliveryWindowError]} />;
  }

  if (!latestDeliveryWindow.date || !firstAvailableDeliveryWindow.data.date) {
    return (
      <ErrorPage errorMessage='Sorry, we are unable to deliver to this address at time point in time, as no workers are available. Please try again later.' />
    );
  }

  const maxDate = new TZDate(latestDeliveryWindow.date, address.timeZone);
  const firstAvailableDate = new TZDate(firstAvailableDeliveryWindow.data.date, address.timeZone);
  const customFromDateTZ = customFromDate ? new TZDate(new Date(customFromDate), address.timeZone) : undefined;

  return (
    <BookingTimeWindowPickerContent
      address={address}
      cars={cars}
      selectedDeliveryWindow={selectedDeliveryWindow}
      onAvailableSelect={onAvailableSelect}
      onUnavailableSelect={onUnavailableSelect}
      translations={translations}
      maxDate={maxDate}
      firstAvailableDate={firstAvailableDate}
      customFromDateTZ={customFromDateTZ}
    />
  );
};

export const BookingTimeWindowPickerContent = ({
  address,
  cars,
  selectedDeliveryWindow,
  onAvailableSelect,
  onUnavailableSelect,
  translations,
  maxDate,
  firstAvailableDate,
  customFromDateTZ
}: BookingTimeWindowPickerProps & {
  maxDate: TZDate;
  firstAvailableDate: TZDate;
  customFromDateTZ?: TZDate;
}) => {
  const { timeZone, id: addressId } = address;
  const isMd = useIsWithinTailWindBreakpoint('md');

  const tomorrow = new TZDate(addDays(new Date(), 1), timeZone);

  const initialFromDate =
    customFromDateTZ ??
    (selectedDeliveryWindow?.startsAt
      ? new TZDate(new Date(selectedDeliveryWindow?.startsAt), timeZone)
      : firstAvailableDate);

  const [date, setDate] = useState(initialFromDate);

  const [page, setPage] = useState(0);

  const pageSize = isMd ? 4 : 6;

  const { fromDate, toDate } = getFromAndToDate({
    date,
    page,
    pageSize,
    tomorrow,
    maxDate
  });

  const {
    error: timeWindowsByDateError,
    isPending: isTimeWindowsByDatePending,
    data: timeWindowsByDate
  } = noddiAsync.useGet({
    type: URLKeys.getAvailableBookingTimeWindowsByDate,
    input: {
      addressId,
      selectedSalesItemIds: cars.map((car) => car.selectedSalesItemIds).flat(),
      fromDate: noddiFormatDate(fromDate, DateFormats.DASHED_DATE_ISO_8601),
      toDate: noddiFormatDate(toDate, DateFormats.DASHED_DATE_ISO_8601)
    }
  });

  const onSkip = (skipToDate: TZDate) => {
    setDate(skipToDate);
    setPage(0);
  };

  if (timeWindowsByDateError) {
    return <ErrorPage />;
  }

  return (
    <div className='relative'>
      <GoToDatePicker
        skipToDate={date}
        setSkipToDate={onSkip}
        minDate={tomorrow}
        maxDate={maxDate}
        translations={{
          jumpToDateTriggerText: translations.jumpToDateTriggerText
        }}
      />
      <div className='flex flex-col gap-2 md:gap-4'>
        <SliderArrows
          translations={translations}
          prevBtnDisabled={fromDate === tomorrow}
          nextBtnDisabled={toDate === maxDate}
          onPrevButtonClick={() => setPage(page - 1)}
          onNextButtonClick={() => setPage(page + 1)}
        />
        {isTimeWindowsByDatePending ? (
          <LoadingScreen loadingText={translations.loading} />
        ) : (
          <div className='flex min-h-[60vh] justify-between gap-1 sm:justify-evenly'>
            {Object.entries(timeWindowsByDate).map(([date, timeWindowsForOneDate]) => (
              <div key={date}>
                <DateCard timeWindowsForOneDate={timeWindowsForOneDate} translations={translations} />
                <div className='flex flex-col items-center justify-center gap-8 md:gap-10'>
                  {Object.entries(timeWindowsForOneDate).map(
                    ([timeSlot, timeWindow]) =>
                      timeWindow && (
                        <div key={timeSlot} className='flex h-13 min-h-13 items-center'>
                          <TimeWindowCard
                            onAvailableSelect={onAvailableSelect}
                            onUnavailableSelect={onUnavailableSelect}
                            translations={translations}
                            timeWindow={timeWindow}
                            selectedDeliveryWindow={selectedDeliveryWindow}
                          />
                        </div>
                      )
                  )}
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};
