import { Path, Svg, SvgProps } from 'react-native-svg';

const Filter = ({ color, ...props }: SvgProps) => (
  <Svg width={32} height={32} viewBox='0 0 32 32' fill='none' {...props}>
    <Path
      stroke={color}
      strokeWidth={1.5}
      d='M13.333 18.133a3.2 3.2 0 1 1 0 6.4 3.2 3.2 0 0 1 0-6.4ZM18.667 7.467a3.2 3.2 0 1 0 0 6.4 3.2 3.2 0 0 0 0-6.4Z'
    />
    <Path
      stroke={color}
      strokeLinecap='round'
      strokeWidth={1.5}
      d='M19.2 21.29h7.467M12.8 10.622H5.333M5.333 21.29h2.134M26.667 10.622h-2.134'
    />
  </Svg>
);
export { Filter };
