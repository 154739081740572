import { noddiAsync, URLKeys } from 'noddi-async';

import { t, Trans } from '@lingui/macro';
import { useAuthContext } from 'noddi-provider';
import { NoddiBasicCard, NoddiTextInput } from 'noddi-ui';
import { NoddiButton, NoddiIcon } from 'noddi-ui-common';
import { customerRoutes, formatCurrencyAmount } from 'noddi-util';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ApiErrorMessageWithTrans } from '../../components/ApiErrorMessageWithTrans';
import { CustomerSMSLogin } from '../../components/CustomerSMSLogin';
import ContentWrapperWithQuery from '../../components/Layouts/ContentWrapperWithQuery';
import { getTirePrice } from '../../utils/tire';
import { useScrollToTop } from '../BookingFlow/helpers/useScrollToTop';
import { useTireOfferStore } from './TireOfferStore';
import { tireOfferAction } from './types';

const TireOfferSummary = () => {
  const { slug } = useParams() as { slug: string };
  const { selectedQuoteItem } = useTireOfferStore();
  const { isLoggedIn } = useAuthContext();
  const [userComment, setUserComment] = useState('');

  const navigate = useNavigate();
  useScrollToTop();

  const query = noddiAsync.useGet({
    type: URLKeys.getCarTireQuoteBySlug,
    input: { slug },
    queryConfig: { staleTime: Infinity }
  });

  const {
    mutateAsync: confirmTireQuoteOffer,
    isPending: isConfirmTireQuoteOfferPending,
    error: confirmTireQuoteOfferError
  } = noddiAsync.usePost({
    type: URLKeys.postConfirmTireQuoteOffer,
    queryConfig: {
      onSuccess: async () => {
        navigate(customerRoutes.tireOfferConfirmation.getPath({ action: tireOfferAction.ACCEPTED }));
      }
    }
  });

  if (!selectedQuoteItem) {
    return null;
  }

  const totalTireSum = selectedQuoteItem.quoteItemTires.reduce((sum, tire) => {
    const price = getTirePrice(tire);
    return sum + price.amount * (tire.quantitySelected ?? 0);
  }, 0);

  return (
    <ContentWrapperWithQuery
      title={t`Ready to confirm your order?`}
      subtitle={t`Upon confirmation, we will order the tires from our central warehouse and schedule a time for installation at your home.`}
      query={query}
    >
      {({ data }) => (
        <div className='flex flex-col gap-4'>
          <NoddiBasicCard className='mt-5 flex flex-col gap-2 text-primary-darkPurple'>
            {selectedQuoteItem.quoteItemTires.map((tire) => {
              const price = getTirePrice(tire);
              return (
                <div key={tire.id}>
                  <div className='flex items-center justify-between'>
                    <div className='flex items-center gap-1'>
                      <NoddiIcon name='WheelAngle' />
                      <p className='font-bold'>
                        {tire.tireInventory.tire.brand.name} - {tire.tireInventory.tire.model}
                      </p>
                    </div>
                    <p>{formatCurrencyAmount(price.amount * (tire.quantitySelected ?? 0), 0, price.currency)}</p>
                  </div>
                  <div className='mb-2 flex justify-end'>
                    <p>
                      {tire.quantitySelected ?? 0} <Trans>pcs</Trans>
                    </p>
                  </div>
                </div>
              );
            })}

            {data.genericItems.map(({ name, price, id }) => (
              <div className='flex justify-between' key={id}>
                <p>{name}</p>
                <p>{formatCurrencyAmount(price.amount, 0, price.currency)}</p>
              </div>
            ))}

            <div className='flex justify-between font-bold'>
              <p>
                <Trans>Total sum including VAT</Trans>
              </p>
              <p>
                {formatCurrencyAmount(
                  data.genericItems.reduce((acc, item) => acc + item.price.amount, 0) + totalTireSum,
                  0
                )}
              </p>
            </div>
          </NoddiBasicCard>

          {isLoggedIn ? (
            <div>
              <NoddiBasicCard className='flex flex-col gap-3'>
                <p className='text-5'>
                  <Trans>Anything more we should know?</Trans>
                </p>

                <NoddiTextInput
                  rows={2}
                  onChange={(e) => setUserComment(e.target.value)}
                  value={userComment ?? ''}
                  placeholder={t`Optional note if any extra information is needed...`}
                  multiline
                />
              </NoddiBasicCard>
              {confirmTireQuoteOfferError && <ApiErrorMessageWithTrans error={confirmTireQuoteOfferError} />}
              <div className='md:flex md:justify-end'>
                <NoddiButton
                  variant='success'
                  className='mt-5 w-full md:w-fit'
                  loading={isConfirmTireQuoteOfferPending}
                  onPress={async () =>
                    await confirmTireQuoteOffer({
                      quoteItemId: selectedQuoteItem.id,
                      quoteItemTires: selectedQuoteItem.quoteItemTires.map((tire) => ({
                        quoteItemTireId: tire.id,
                        quantity: tire.quantitySelected ?? 0
                      })),
                      comments: userComment
                    })
                  }
                >
                  <Trans>Confirm order</Trans>
                </NoddiButton>
              </div>
            </div>
          ) : (
            <div className='text-primary-darkPurple'>
              <p className='mb-2 font-bold'>
                <Trans>You need to log in in order to confirm the order</Trans>
              </p>
              <CustomerSMSLogin />
            </div>
          )}
        </div>
      )}
    </ContentWrapperWithQuery>
  );
};

export default TireOfferSummary;
