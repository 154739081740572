import { t } from '@lingui/macro';
import { noddiAsync, URLKeys } from 'noddi-async';
import { useAuthContext } from 'noddi-provider';
import { Account as AccountNoddiUi, getLanguagePropertiesFromCountryCode, NoddiBasicCard } from 'noddi-ui';
import { useNavigate } from 'react-router-dom';

import { NoddiButton, NoddiIcon } from 'noddi-ui-common';
import { customerRoutes } from 'noddi-util';
import getCommonTranslations from '../../commonTranslations';
import { ApiErrorMessageWithTrans } from '../../components/ApiErrorMessageWithTrans';
import ContentWrapper from '../../components/Layouts/ContentWrapper';
import { NoddiLoadingScreenWithTrans } from '../../components/NoddiLoadingScreenWithTrans';
import Addresses from '../Addresses';

export const EditAccount = () => (
  <ContentWrapper title={t`Edit account`}>
    <AccountNoddiUi
      translations={{
        profileUpdatedSuccessfully: getCommonTranslations().profileUpdatedSuccessfully,
        firstName: getCommonTranslations().firstName,
        lastName: getCommonTranslations().lastName,
        email: getCommonTranslations().email,
        phoneNumber: getCommonTranslations().phoneNumber,
        save: getCommonTranslations().save,
        language: getCommonTranslations().language,
        validation: {
          required: getCommonTranslations().required
        }
      }}
    />
  </ContentWrapper>
);

const Account = () => {
  const navigate = useNavigate();
  const { userData } = useAuthContext();

  const {
    data: languages,
    error: languagesError,
    isPending: isLanguagesPending
  } = noddiAsync.useGet({
    type: URLKeys.getLanguages
  });

  const {
    data: user,
    error: userError,
    isPending: isUserPending
  } = noddiAsync.useGet({
    type: URLKeys.getUser,
    input: { id: userData?.user.id },
    queryConfig: {
      enabled: !!userData?.user.id
    }
  });

  if (!userData || isUserPending || isLanguagesPending) {
    return <NoddiLoadingScreenWithTrans />;
  }

  if (userError || languagesError) {
    return <ApiErrorMessageWithTrans error={[languagesError, userError]} />;
  }

  const selectedLanguage = languages.find((lang) => lang.code === user.language.code);

  return (
    <ContentWrapper className='flex flex-col gap-6 text-primary-darkPurple' title={t`My account`}>
      <NoddiBasicCard className='flex flex-col gap-4'>
        <p className='text-5 font-semibold'>
          {user.firstName} {user.lastName}
        </p>
        <div className='flex items-center gap-2'>
          <NoddiIcon name='Phone' size='medium' />
          <p>{user.phoneNumber}</p>
        </div>
        <div className='flex items-center gap-2'>
          <NoddiIcon name='Home' size='medium' />
          <p>{user.email}</p>
        </div>

        <div>
          <p className='flex items-center gap-2'>
            <span className='text-5'>{getLanguagePropertiesFromCountryCode(selectedLanguage?.code ?? '').flag}</span>
            <span>{getLanguagePropertiesFromCountryCode(selectedLanguage?.code ?? '').name}</span>
          </p>
        </div>

        <div className='flex flex-col gap-2'>
          <NoddiButton
            startIcon='Edit'
            className='h-auto gap-2 p-0'
            onPress={() => navigate(customerRoutes.editProfile.getPath())}
            variant='link'
          >
            {t`Edit account information`}
          </NoddiButton>
        </div>
      </NoddiBasicCard>

      <Addresses />
    </ContentWrapper>
  );
};

export default Account;
