import { URLKeys, noddiAsync } from 'noddi-async';
import { useAuthContext } from 'noddi-provider';
import { Error404Page } from 'noddi-ui';
import { useParams } from 'react-router-dom';

import getCommonTranslations from '../../../commonTranslations';
import { ErrorPageWithTranslations } from '../../../components/ErrorPageWithTrans';
import ContentWrapper from '../../../components/Layouts/ContentWrapper';
import { NoddiLoadingScreenWithTrans } from '../../../components/NoddiLoadingScreenWithTrans';
import { bookingCategoryLabelToEnum } from '../../BookingFlow/helpers/utils';
import { UserAlreadyAMember } from '../AlreadyMember';
import MembershipNAF from '../NAF';
import { ActivateMembershipCard } from './ActivateMembershipCard';
import { HighlightedSalesItem } from './HighLightedSalesItem';

export const Membership = () => {
  const { currentUserGroupId } = useAuthContext();
  const { slug } = useParams();

  if (!slug) {
    return <ErrorPageWithTranslations />;
  }

  // Custom membership page for NAF
  if (slug === 'naf') {
    return <MembershipNAF />;
  }

  return <MembershipContent slug={slug} userGroupId={currentUserGroupId} />;
};

const MembershipContent = ({ slug, userGroupId }: { slug: string; userGroupId?: number }) => {
  const { isLoggedIn } = useAuthContext();

  const {
    data: membership,
    error: membershipError,
    isPending: isMembershipPending,
    isFetching: isMembershipFetching
  } = noddiAsync.useGet({
    type: URLKeys.getMembershipFromSlug,
    input: { slug, userGroupId }
  });

  if (isMembershipPending || isMembershipFetching) {
    return <NoddiLoadingScreenWithTrans />;
  }

  if (membershipError) {
    if (membershipError.status === 404) {
      return (
        <Error404Page
          translations={{
            pageNotFoundHeading: getCommonTranslations().pageNotFoundHeading,
            pageNotFoundMessage: getCommonTranslations().pageNotFoundMessage,
            goBack: getCommonTranslations().goBack
          }}
        />
      );
    }

    return <ErrorPageWithTranslations apiError={membershipError} />;
  }

  return (
    <ContentWrapper hideBackButton title={membership.name}>
      <div className='flex flex-col gap-8 text-primary-darkPurple'>
        {isLoggedIn && membership.isAlreadyMember ? (
          <UserAlreadyAMember />
        ) : (
          <p className='opacity-80'>{membership.description}</p>
        )}

        {!membership.isAlreadyMember && <ActivateMembershipCard slug={slug} />}

        <div className='flex flex-col gap-4'>
          {membership.discounts.map((discount) => (
            <div key={discount.description} className='flex flex-col gap-4'>
              <p className='font-bold text-5'>{discount.name}</p>
              {discount.highlightedSalesItems.map((highlightedSalesItem) => (
                <HighlightedSalesItem
                  key={highlightedSalesItem.name}
                  serviceName={highlightedSalesItem.name}
                  price={highlightedSalesItem.price}
                  type={bookingCategoryLabelToEnum(highlightedSalesItem.bookingCategorySlug)}
                  discountedPrice={highlightedSalesItem.discountedPrice}
                />
              ))}
            </div>
          ))}
        </div>
      </div>
    </ContentWrapper>
  );
};
