import { t, Trans } from '@lingui/macro';
import { noddiAsync, URLKeys } from 'noddi-async';
import { tracking } from 'noddi-provider';
import { NoddiDialog } from 'noddi-ui';
import { NoddiButton } from 'noddi-ui-common';
import { customerRoutes } from 'noddi-util';
import { useNavigate } from 'react-router-dom';
import { ApiErrorMessageWithTrans } from '../../components/ApiErrorMessageWithTrans';
import { useAutoRecallMetaData } from './AutoRecallStore';

interface CancelModalProps {
  open: boolean;
  onClose: () => void;
}

export const CancelModal = ({ open, onClose }: CancelModalProps) => {
  const { bookingId } = useAutoRecallMetaData();

  const navigate = useNavigate();

  const {
    mutateAsync: cancelBooking,
    isPending: isCancelling,
    error
  } = noddiAsync.usePatch({
    type: URLKeys.patchCancelRecallProposal,
    queryConfig: {
      onSuccess: () => {
        tracking.track('autoRecallCancelBooking');
        navigate(customerRoutes.cancelledBooking.getPath());
      }
    }
  });

  return (
    <NoddiDialog title={t`Cancel your booking`} onClose={onClose} open={open}>
      <div className='flex flex-col gap-4'>
        <span>
          <Trans>
            If you don't want to use your prepared booking this season, please cancel it. This way, we won't send you
            any further reminders.
          </Trans>
        </span>
        {error && <ApiErrorMessageWithTrans error={error} />}

        <NoddiButton
          onPress={() => {
            cancelBooking({ id: bookingId! });
          }}
          loading={isCancelling}
          startIcon='Cross'
          className='w-full'
          variant='destructive'
        >
          <Trans>Cancel</Trans>
        </NoddiButton>
      </div>
    </NoddiDialog>
  );
};
