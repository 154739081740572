import { t, Trans } from '@lingui/macro';
import { NoddiBasicCard, NoddiLogo, useNavigateOnLoginSuccess } from 'noddi-ui';

import { customerRoutes } from 'noddi-util';
import { ContactUs } from '../../components/ContactUs';
import { CustomerSMSLogin } from '../../components/CustomerSMSLogin';
import ContentWrapper from '../../components/Layouts/ContentWrapper';

const Login = () => {
  const { navigateOnLoginSuccess } = useNavigateOnLoginSuccess();

  const onLoginSuccess = () => navigateOnLoginSuccess({ loginSuccessPath: customerRoutes.homepage.getPath() });

  return (
    <ContentWrapper className='mx-auto min-h-[80vh] max-w-xl justify-center'>
      <NoddiBasicCard className='flex flex-col gap-6'>
        <div className='flex items-center justify-between gap-4'>
          <h3 className='font-bold text-5'>
            <Trans>Log in</Trans>
          </h3>
          <NoddiLogo width={67} height={19} />
        </div>

        <CustomerSMSLogin onLoginSuccess={onLoginSuccess} />
        <ContactUs customText={t`if you're having trouble logging in`} />
      </NoddiBasicCard>
    </ContentWrapper>
  );
};

export default Login;
