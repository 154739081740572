import { t } from '@lingui/macro';
import { NoddiCollapseCard } from 'noddi-ui';
import { formatCurrencyAmount } from 'noddi-util';
import { useState } from 'react';

import { SALES_ITEM_TYPE, SalesItemsCar } from 'noddi-async/src/types';
import { SalesItem } from './SalesItem';
import { SalesItemContainer, SalesItemWrapper } from './SalesItemWrapper';
import { ServiceCategoryHeader } from './ServiceCategoryHeader';

type ServiceCategoryCollapseCardProps = {
  numberOfSelectedSalesItems: number;
  salesItems: SalesItemsCar['salesItems'];
  licensePlate: SalesItemsCar['licensePlate'];
  category: string;
  salesItemType: SALES_ITEM_TYPE;
  mountAsExpanded?: boolean;
};

export const ServiceCategoryCollapseCard = ({
  numberOfSelectedSalesItems,
  salesItemType,
  category,
  salesItems,
  licensePlate,
  mountAsExpanded
}: ServiceCategoryCollapseCardProps) => {
  const [isExpanded, setIsExpanded] = useState(false);

  // I guess we dont want the groups to say from 0 kr?
  const priceFrom = salesItems
    .filter((item) => item.price.amount > 0)
    .reduce((acc, item) => Math.min(acc, item.price.amount), Infinity);

  const currency = salesItems[0]?.price.currency;

  return (
    <NoddiCollapseCard
      sx={{ padding: 0, borderRadius: '16px' }}
      preventHandleExpand
      expandedChanged={(expanded) => setIsExpanded(expanded)}
      mountAsExpanded={mountAsExpanded}
      hideChevron
      header={
        <ServiceCategoryHeader
          numberOfSelectedSalesItems={numberOfSelectedSalesItems}
          priceFrom={`${t`from`} ${formatCurrencyAmount(priceFrom, 0, currency)}`}
          category={category}
          isExpanded={isExpanded}
        />
      }
      collapseBody={
        <SalesItemContainer>
          {salesItems.map((item) => (
            <SalesItemWrapper key={item.salesItemId}>
              <SalesItem
                className='px-4 py-3 !shadow-none sm:px-6 sm:py-5'
                type={salesItemType}
                licensePlate={licensePlate}
                salesItem={item}
              />
            </SalesItemWrapper>
          ))}
        </SalesItemContainer>
      }
    />
  );
};
