import { Route, Routes } from 'react-router-dom';

import { customerRoutes } from 'noddi-util';
import { MaintenanceContent } from '../../components/SystemDownTimeBlocker';
import { getNoddiRemoteConfig } from '../../utils/remoteConfigSetup';
import { Addons } from './Steps/Addons';
import { Address } from './Steps/Address';
import { Cars } from './Steps/Car';
import { ServiceItemsForCars } from './Steps/Services';
import { Summary } from './Steps/Summary';
import { BuildingBooking } from './Steps/Summary/BuildingBooking';
import { TierHotelPickup } from './Steps/TierHotelPickup';
import { TimeWindow } from './Steps/TimeWindows';
import { CancelTireHotel } from './Steps/TireHotelCancel';

export function BookingRouter() {
  const maintenanceScreenBooking = getNoddiRemoteConfig('maintenanceScreenBooking');

  if (maintenanceScreenBooking?.shouldShow) {
    return <MaintenanceContent maintenanceScreen={maintenanceScreenBooking} />;
  }

  return (
    <Routes>
      <Route path={customerRoutes.newBookingAddress.getBasePath()} element={<Address />} />
      <Route path={customerRoutes.newBookingCars.getBasePath()} element={<Cars />} />
      <Route path={customerRoutes.newBookingPrimaryItems.getBasePath()} element={<ServiceItemsForCars />} />
      <Route path={customerRoutes.newBookingCancelTireHotel.getBasePath()} element={<CancelTireHotel />} />
      <Route path={customerRoutes.newBookingAddons.getBasePath()} element={<Addons />} />
      <Route path={customerRoutes.newBookingTierHotel.getBasePath()} element={<TierHotelPickup />} />
      <Route path={customerRoutes.newBookingTimeWindow.getBasePath()} element={<TimeWindow />} />
      <Route path={customerRoutes.newBookingSummary.getBasePath()} element={<Summary />} />
      <Route path={customerRoutes.newBookingBuilding.getBasePath()} element={<BuildingBooking />} />
    </Routes>
  );
}
