import { TZDate } from '@date-fns/tz';
import { t, Trans } from '@lingui/macro';
import { noddiAsync, URLKeys } from 'noddi-async';
import { useAuthContext } from 'noddi-provider';
import { getLongAddressName, NoddiFeedbackBox, OrderSummary } from 'noddi-ui';
import { NoddiButton } from 'noddi-ui-common';
import { customerRoutes, DateFormats, noddiFormatDate } from 'noddi-util';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import getCommonTranslations from '../../../commonTranslations';
import { ApiErrorMessageWithTrans } from '../../../components/ApiErrorMessageWithTrans';
import { ErrorPageWithTranslations } from '../../../components/ErrorPageWithTrans';
import ContentWrapper from '../../../components/Layouts/ContentWrapper';
import { NoddiLoadingScreenWithTrans } from '../../../components/NoddiLoadingScreenWithTrans';
import { CancelDialog } from './CancelDialog';
import { MetaSection } from './MetaSection';

const BookingDetails = () => {
  const { currentUserGroupId: userGroupId } = useAuthContext();
  const navigate = useNavigate();
  const { id: bookingId } = useParams();
  const [cancelDialogIsOpen, setCancelDialogIsOpen] = useState(false);

  const bookingIdAsString = bookingId as string;
  const {
    isPending: isBookingPending,
    data: bookingData,
    error,
    refetch: refetchBookingData
  } = noddiAsync.useGet({
    type: URLKeys.getUserBooking,
    input: { bookingId: bookingIdAsString },
    queryConfig: {
      enabled: !!userGroupId && !!bookingId
    }
  });

  const { mutateAsync: cancelBooking, isPending: isCancelling } = noddiAsync.usePost({
    type: URLKeys.getCancelBooking,
    queryConfig: {
      onSuccess: () => {
        navigate(customerRoutes.cancelledBooking.getPath());
      }
    }
  });

  const bookingIdNumber = Number(bookingId);

  const {
    isLoading: isDownloadingReceiptLoading,
    refetch: downloadReceipt,
    error: downloadReceiptError
  } = noddiAsync.useGet({
    type: URLKeys.downloadBookingReceipt,
    input: {
      bookingId: bookingIdNumber
    },
    queryConfig: {
      enabled: false
    }
  });

  const {
    isPending: isBookingPermissionsPending,
    data: bookingPermissions,
    error: bookingPermissionsError
  } = noddiAsync.useGet({
    type: URLKeys.getEditBookingPermissions,
    input: { id: bookingIdAsString },
    queryConfig: {
      enabled: !!bookingId
    }
  });

  if (isBookingPending || isCancelling || isBookingPermissionsPending) {
    return <NoddiLoadingScreenWithTrans />;
  }
  if (error || bookingPermissionsError) {
    return <ErrorPageWithTranslations apiError={[bookingPermissionsError, error]} />;
  }

  const {
    completedAt,
    startedAt,
    deliveryWindowStartsAt,
    deliveryWindowEndsAt,
    isCancelled,
    isCancelClosed,
    isInvoiced,
    amountDue,
    hasReceipt,
    slug,
    isPaid,
    address,
    commentsUser,
    cars,
    otherOrderLines,
    discountOrderLines,
    address: { timeZone }
  } = bookingData;

  const getDateText = () => {
    if (completedAt) {
      return `${noddiFormatDate(new TZDate(completedAt, timeZone), DateFormats.FULL_MONTH_YEAR_DATE_TIME)}`;
    }
    if (startedAt) {
      return `${noddiFormatDate(new TZDate(startedAt, timeZone), DateFormats.FULL_MONTH_YEAR_DATE_TIME)}`;
    }
    return `${noddiFormatDate(
      new TZDate(deliveryWindowStartsAt, timeZone),
      DateFormats.FULL_MONTH_YEAR_DATE_TIME
    )} - ${noddiFormatDate(new TZDate(deliveryWindowEndsAt, timeZone), DateFormats.TIME)}`;
  };
  function navigateToEditBookingTimeWindow(id: number) {
    navigate(customerRoutes.editMyBookingTimeWindow.getPath({ id }));
  }

  const showActionButtons = !isCancelled && !isCancelClosed;
  const showPaymentButton = completedAt && !isInvoiced && amountDue && amountDue > 0;

  return (
    <ContentWrapper backButtonPath='/' title={t`Your booking`}>
      <div className='flex flex-col gap-4'>
        <div className='flex justify-end'>
          {showActionButtons ? (
            <div className='flex gap-2'>
              {bookingPermissions.timeWindows.canEdit && (
                <NoddiButton
                  variant='primary'
                  onPress={() => navigateToEditBookingTimeWindow(bookingData.id)}
                  startIcon='Edit'
                >
                  <Trans>Change time</Trans>
                </NoddiButton>
              )}
              <NoddiButton variant='secondary' onPress={() => navigate(customerRoutes.bookingInfo.getPath({ slug }))}>
                <Trans>View status</Trans>
              </NoddiButton>
            </div>
          ) : (
            !isCancelled &&
            !completedAt && (
              <NoddiFeedbackBox
                title={t`This booking cannot be changed or canceled due to the proximity to the start time. For questions, contact us at hei@noddi.no`}
                variant='info'
              />
            )
          )}
          {!!showPaymentButton && (
            <NoddiButton onPress={() => navigate(customerRoutes.bookingInfo.getPath({ slug }))}>
              <Trans>Pay</Trans>
            </NoddiButton>
          )}
          {hasReceipt && (
            <div className='flex flex-col items-end'>
              {downloadReceiptError && (
                <div className='mb-3'>
                  <ApiErrorMessageWithTrans error={downloadReceiptError} />
                </div>
              )}

              <NoddiButton
                loading={isDownloadingReceiptLoading}
                startIcon='Download'
                variant='secondary'
                onPress={async () => {
                  await downloadReceipt();

                  refetchBookingData();
                }}
              >
                <Trans>Receipt</Trans>
              </NoddiButton>
            </div>
          )}
        </div>
        <div>
          {bookingData && (
            <OrderSummary
              isPaid={isPaid}
              isInvoiced={isInvoiced}
              translations={getCommonTranslations().orderSummary}
              MetaSection={
                <MetaSection
                  isPaid={hasReceipt}
                  isCancelled={!!isCancelled}
                  slug={slug}
                  address={getLongAddressName(address)}
                  dataText={getDateText()}
                  canEditComment={bookingPermissions.canEditComments}
                  commentsUser={commentsUser ?? ''}
                  bookingId={bookingIdAsString}
                />
              }
              showHeader={false}
              carItemLines={cars}
              otherOrderLines={otherOrderLines}
              discountLines={discountOrderLines}
            />
          )}
        </div>
        {showActionButtons && (
          <NoddiButton
            variant='secondary'
            className='self-end'
            onPress={() => setCancelDialogIsOpen(true)}
            disabled={isCancelling}
          >
            <Trans>Cancel booking</Trans>
          </NoddiButton>
        )}
      </div>
      <CancelDialog
        open={cancelDialogIsOpen}
        onClose={() => setCancelDialogIsOpen(false)}
        bookingIdNumber={bookingIdNumber}
        cancelBooking={cancelBooking}
      />
    </ContentWrapper>
  );
};

export default BookingDetails;
