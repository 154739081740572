import { AvailableBookingTimeWindow } from 'noddi-async/src/types';
import { DateFormats, isToday, isTomorrow, noddiFormatDate } from 'noddi-util';

import { TZDate } from '@date-fns/tz';
import { cn } from '../../../helpers/utils';
interface DateCardProps {
  timeWindowsForOneDate: { [timeSlot: string]: AvailableBookingTimeWindow | null };
  translations: {
    today: string;
    tomorrow: string;
  };
}

export const DateCard = ({ timeWindowsForOneDate: timeWindowsByDate, translations }: DateCardProps) => {
  // Get the first non null time window for the day and extract date information
  const firstTimeWindow = Object.values(timeWindowsByDate).find((window) => window !== null);

  if (!firstTimeWindow) {
    return null;
  }

  const timeZone = firstTimeWindow.timeZone;
  const parsedDay = new TZDate(firstTimeWindow.startsAt, timeZone);

  const getDayFormat = () => {
    if (isToday(parsedDay)) {
      return translations.today;
    }
    if (isTomorrow(parsedDay)) {
      return translations.tomorrow;
    }
    return noddiFormatDate(parsedDay, DateFormats.SHORT_WEEKDAY_DAY_MONTH);
  };

  return (
    <div className='py-2 text-center'>
      <span className={cn('text-nowrap')}>{getDayFormat()}</span>
    </div>
  );
};
