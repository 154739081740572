import { SALES_ITEM_TYPE, SelectedSalesItemsCar } from 'noddi-async/src/types';
import { NoddiIcon } from 'noddi-ui-common';
import { useBookingActions, useBookingCars, useBookingSelectedSalesItemsCars } from '../../BookingStore';
import { compareLicensePlates } from '../../helpers/utils';

interface MultipleCarSelectionBlockProps {
  carsWithTierPickupOptions: SelectedSalesItemsCar[];
  salesItemId: number;
  isWheelPickupRequired: boolean;
  isAddonsAvailable: boolean;
  priceFormatted: string;
  mutuallyExclusiveSalesItemIds: number[];
}

export const MultipleCarSelectionBlock = ({
  carsWithTierPickupOptions,
  priceFormatted,
  salesItemId,
  isWheelPickupRequired,
  isAddonsAvailable,
  mutuallyExclusiveSalesItemIds
}: MultipleCarSelectionBlockProps) => {
  const cars = useBookingCars();
  const selectedSalesItemsCars = useBookingSelectedSalesItemsCars();

  const { removeSalesItems, addSalesItems } = useBookingActions();

  function onSelect({
    e,
    car,
    isAlreadySelected
  }: {
    e: React.MouseEvent<HTMLDivElement>;
    car: SelectedSalesItemsCar;
    isAlreadySelected: boolean;
  }) {
    const params = [
      {
        licensePlate: car.licensePlate,
        salesItemId,
        type: SALES_ITEM_TYPE.TIRE_HOTEL_PICKUP,
        mutuallyExclusiveSalesItemIds,
        isAddonsAvailable,
        isWheelPickupRequired
      }
    ];
    e.stopPropagation();

    isAlreadySelected ? removeSalesItems(params) : addSalesItems(params);
  }

  return (
    <div className='mb-3 flex flex-col gap-1'>
      {carsWithTierPickupOptions.map((car) => {
        const isSelected = !!selectedSalesItemsCars.find(
          (item) => compareLicensePlates(item.licensePlate, car.licensePlate) && item.salesItemId === salesItemId
        );

        const carInfo = cars.find((item) => compareLicensePlates(item.licensePlate, car.licensePlate));
        return (
          <div
            onClick={(e) => onSelect({ e, car, isAlreadySelected: isSelected })}
            className='flex items-center justify-between gap-2 hover:cursor-pointer'
            key={car.licensePlate.number}
          >
            <div className='flex items-center gap-2'>
              <div className='flex size-6 items-center justify-center rounded-md border bg-primary-white'>
                {isSelected && <NoddiIcon name='Check' size='large' />}
              </div>
              <div className='flex flex-col gap-1 p-2'>
                <p>
                  {carInfo?.make} {carInfo?.model}
                </p>
                <p>{car.licensePlate.number}</p>
              </div>
            </div>
            <p>{priceFormatted}</p>
          </div>
        );
      })}
    </div>
  );
};
