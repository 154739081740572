import { t } from '@lingui/macro';
import { useNavigate } from 'react-router-dom';
import { useBookingTimeWindow } from '../../BookingStore';
import { BookingScreen } from '../../components/BookingScreen';
import { summaryStepPath } from '../../helpers/bookingFlowPaths';
import { TimeWindowPicker } from './TimeWindowPicker';

export const TimeWindow = () => {
  const navigate = useNavigate();
  const selectedTimeWindow = useBookingTimeWindow();

  return (
    <BookingScreen
      onNextClicked={() => navigate(summaryStepPath)}
      title={t`When is it convenient for us to come to you?`}
      widerScreen
      // Hide bottom bar if no time window is selected
      customBottomBar={selectedTimeWindow?.deliveryWindowId ? undefined : <></>}
      showSelectedTimeWindow
      hideShoppingCart
    >
      <TimeWindowPicker />
    </BookingScreen>
  );
};
