import {
  AvailableBookingTimeWindow,
  BOOKING_TYPES,
  BookingAddress,
  SelectedSalesItemsCar
} from 'noddi-async/src/types';
import { CarSpec } from 'noddi-async/src/types/customerapp/booking/shared';
import { create } from 'zustand';
import { createJSONStorage, devtools, persist } from 'zustand/middleware';
import {
  getIsSalesItemAlreadyInState,
  getNewSalesItemsInState,
  removeMultipleSalesItemsConstructor,
  removeSalesItemConstructor
} from '../../utils/salesItem';
import { compareLicensePlates } from './helpers/utils';

interface AddSalesItemParams extends SelectedSalesItemsCar {
  // SalesItems that are mutually exclusive is auto deselected when new item is added, ex; selecting car wash basic removes car wash premium
  mutuallyExclusiveSalesItemIds: number[];
}

interface RemoveSalesItemParams {
  licensePlate: SelectedSalesItemsCar['licensePlate'];
  salesItemId: SelectedSalesItemsCar['salesItemId'];
  type: SelectedSalesItemsCar['type'];
}

interface BookingState {
  clearStore: () => void;

  bookingType: BOOKING_TYPES;
  setBookingType: (bookingType: BOOKING_TYPES) => void;

  selectedAddress?: BookingAddress;
  setSelectedAddress: (address?: BookingAddress) => void;

  selectedCars: CarSpec[];
  addCar: (carToAdd: CarSpec) => void;
  removeCar: (carToRemove: CarSpec) => void;

  // Here car + PrimaryItems or Addons or TierHotelPickUpItems are stored
  selectedSalesItemsCars: SelectedSalesItemsCar[];
  // Only used when pre populating the store, no validation is done here as backend is trusted
  setSelectedSalesItemsCars: (selectedSalesItemsCars: SelectedSalesItemsCar[]) => void;

  addSalesItem: (
    params: SelectedSalesItemsCar & {
      // SalesItems that are mutually exclusive is auto deselected when new item is added, ex; selecting car wash basic removes car wash premium
      mutuallyExclusiveSalesItemIds: number[];
    }
  ) => void;
  addSalesItems: (items: AddSalesItemParams[]) => void;
  removeSalesItems: (items: RemoveSalesItemParams[]) => void;
  removeSalesItem: (params: RemoveSalesItemParams) => void;

  selectedTimeWindow?: AvailableBookingTimeWindow;
  setSelectedTimeWindow: (timeWindow: AvailableBookingTimeWindow) => void;

  comment?: string;
  setComment: (comment: string) => void;
}

// Initial state with empty values
const initialStateValues = {
  bookingType: BOOKING_TYPES.NORMAL,
  selectedAddress: undefined,
  selectedCars: [],
  selectedSalesItemsCars: [],
  selectedTimeWindow: undefined,
  comment: ''
};

const useBookingStore = create<BookingState>()(
  persist(
    devtools((set) => ({
      ...initialStateValues,
      clearStore: () => set(initialStateValues),

      setBookingType: (bookingType) => set({ bookingType }),

      setSelectedAddress: (selectedAddress) =>
        set({
          selectedAddress,
          selectedSalesItemsCars: [],
          selectedTimeWindow: undefined
        }),

      setSelectedSalesItemsCars: (selectedSalesItemsCars) => set({ selectedSalesItemsCars }),

      addCar: (carToAdd) => {
        set((state) => {
          const selectedCars = state.selectedCars;
          const isAlreadySelected = selectedCars.some((selectedCar) =>
            compareLicensePlates(selectedCar.licensePlate, carToAdd.licensePlate)
          );

          if (isAlreadySelected) {
            return state;
          }

          return {
            selectedCars: [...selectedCars, carToAdd]
          };
        });
      },
      removeCar: (carToRemove) => {
        set((state) => ({
          selectedCars: state.selectedCars.filter(
            (selectedCar) => !compareLicensePlates(selectedCar.licensePlate, carToRemove.licensePlate)
          ),
          selectedSalesItemsCars: state.selectedSalesItemsCars.filter(
            (selectedSalesItemsCar) =>
              !compareLicensePlates(selectedSalesItemsCar.licensePlate, carToRemove.licensePlate)
          )
        }));
      },

      addSalesItem: (params) => {
        set((state) => {
          const selectedSalesItemsCars = state.selectedSalesItemsCars;
          const { mutuallyExclusiveSalesItemIds, ...salesItemToAdd } = params;

          const isAlreadySelected = getIsSalesItemAlreadyInState({
            selectedSalesItemsCars,
            salesItemId: salesItemToAdd.salesItemId,
            licensePlate: salesItemToAdd.licensePlate
          });

          if (isAlreadySelected) {
            return state;
          }

          const newItems = getNewSalesItemsInState({
            selectedSalesItemsCars,
            salesItemToAdd,
            mutuallyExclusiveSalesItemIds
          });
          return {
            selectedSalesItemsCars: newItems,
            selectedTimeWindow: undefined
          };
        });
      },

      addSalesItems: (items) => {
        set((state) => {
          const updatedSalesItemsCars = [...state.selectedSalesItemsCars];

          items.forEach((currentItem) => {
            const { mutuallyExclusiveSalesItemIds, ...currentSalesItemToAdd } = currentItem;
            const isAlreadySelected = getIsSalesItemAlreadyInState({
              selectedSalesItemsCars: updatedSalesItemsCars,
              salesItemId: currentSalesItemToAdd.salesItemId,
              licensePlate: currentSalesItemToAdd.licensePlate
            });

            if (!isAlreadySelected) {
              // make sure to remove all trailing addons if a primary item is added

              const newItems = getNewSalesItemsInState({
                selectedSalesItemsCars: updatedSalesItemsCars,
                salesItemToAdd: currentSalesItemToAdd,
                mutuallyExclusiveSalesItemIds
              });

              updatedSalesItemsCars.length = 0;
              updatedSalesItemsCars.push(...newItems);
            }
          });

          return {
            selectedSalesItemsCars: updatedSalesItemsCars,
            selectedTimeWindow: undefined
          };
        });
      },

      removeSalesItem: (itemToRemove) => {
        set((state) =>
          removeSalesItemConstructor({
            selectedSalesItemsCars: state.selectedSalesItemsCars,
            itemToRemove
          })
        );
      },
      removeSalesItems: (itemsToRemove) => {
        set((state) =>
          removeMultipleSalesItemsConstructor({
            selectedSalesItemsCars: state.selectedSalesItemsCars,
            itemsToRemove
          })
        );
      },

      setSelectedTimeWindow: (selectedTimeWindow) => set({ selectedTimeWindow }),
      setComment: (comment) => set({ comment })
    })),
    {
      name: 'booking',
      storage: createJSONStorage(() => sessionStorage)
    }
  )
);

// Getters
export const useBookingType = () => useBookingStore((state) => state.bookingType);
export const useBookingCars = () => useBookingStore((state) => state.selectedCars);
export const useBookingAddress = () => useBookingStore((state) => state.selectedAddress);
export const useBookingSelectedSalesItemsCars = () => useBookingStore((state) => state.selectedSalesItemsCars);
export const useBookingTimeWindow = () => useBookingStore((state) => state.selectedTimeWindow);
export const useBookingComment = () => useBookingStore((state) => state.comment);

// Actions
export const useBookingActions = () => {
  const setBookingType = useBookingStore((state) => state.setBookingType);
  const setSelectedAddress = useBookingStore((state) => state.setSelectedAddress);
  const addCar = useBookingStore((state) => state.addCar);
  const removeCar = useBookingStore((state) => state.removeCar);
  const clearStore = useBookingStore((state) => state.clearStore);
  const addSalesItem = useBookingStore((state) => state.addSalesItem);
  const removeSalesItem = useBookingStore((state) => state.removeSalesItem);
  const setSelectedTimeWindow = useBookingStore((state) => state.setSelectedTimeWindow);
  const setComment = useBookingStore((state) => state.setComment);
  const addSalesItems = useBookingStore((state) => state.addSalesItems);
  const removeSalesItems = useBookingStore((state) => state.removeSalesItems);
  const setSelectedSalesItemsCars = useBookingStore((state) => state.setSelectedSalesItemsCars);

  return {
    setBookingType,
    setSelectedAddress,
    addCar,
    removeCar,
    clearStore,
    addSalesItem,
    removeSalesItem,
    setSelectedTimeWindow,
    setComment,
    addSalesItems,
    removeSalesItems,
    setSelectedSalesItemsCars
  };
};

//Helpers
export const useIsAddonsAvailable = () => {
  const selectionResultsCars = useBookingSelectedSalesItemsCars();
  return selectionResultsCars.map((car) => car.isAddonsAvailable).includes(true);
};

export const useIsTierHotelPickupRequired = () => {
  const selectionResultsCars = useBookingSelectedSalesItemsCars();
  return selectionResultsCars.map((car) => car.isWheelPickupRequired).includes(true);
};

export const useIsMultipleCarsBooking = () => {
  const selectedCars = useBookingCars();
  return selectedCars.length > 1;
};
