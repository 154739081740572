import * as Sentry from '@sentry/react';

const dsn = import.meta.env.VITE_APP_SENTRY_DSN;
const environment = import.meta.env.VITE_ENVIRONMENT;

const sentryConfig = {
  dsn,
  integrations: [
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false
    }),
    //The Browser Tracing integration automatically instruments browser pageload/navigation actions as transactions, and captures requests, metrics and errors as spans.
    Sentry.browserTracingIntegration(),
    Sentry.breadcrumbsIntegration({
      console: true, // Capture console logs
      dom: true, // Capture DOM interactions
      fetch: true, // Capture fetch requests
      xhr: true // Capture XHR requests
    })
  ],

  environment,
  // Performance Monitoring
  //  Capture 10% of the transactions in production and 100% in development
  tracesSampleRate: environment === 'development' ? 1.0 : 0.1,
  //Session Replays turned off by default
  replaysSessionSampleRate: 0,
  replaysOnErrorSampleRate: 0,
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ['localhost', /^https:\/\/app.noddi\.no\/api/],
  enabled: environment !== 'local',
  // Ignore specific errors

  ignoreErrors: [
    'window.google.maps.Geocoder is not a constructor',
    "undefined is not a constructor (evaluating 'new window.google.maps.Geocoder')",
    "Cannot read properties of undefined (reading 'AutocompleteService')",
    'Error verifying pin code'
  ]
};

export default sentryConfig;
