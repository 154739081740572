import { SALES_ITEM_TYPE } from 'noddi-async/src/types';

export function getSalesItemType(
  isPrimary: boolean,
  isAddon: boolean,
  isTireHotelPickup: boolean,
  isTireHotelCancel: boolean
): SALES_ITEM_TYPE {
  if (isPrimary) {
    return SALES_ITEM_TYPE.PRIMARY;
  }

  if (isAddon) {
    return SALES_ITEM_TYPE.ADDON;
  }

  if (isTireHotelPickup) {
    return SALES_ITEM_TYPE.TIRE_HOTEL_PICKUP;
  }

  if (isTireHotelCancel) {
    return SALES_ITEM_TYPE.TIRE_HOTEL_CANCEL;
  }

  return SALES_ITEM_TYPE.PRIMARY;
}
