import { Trans, t } from '@lingui/macro';
import { SALES_ITEM_TYPE, SelectedSalesItemsCar, TierHotelPickupSalesItemOption } from 'noddi-async/src/types';
import {
  AddressSearch,
  NoddiBasicCard,
  NoddiFeedbackBox,
  NoddiIconButton,
  NoddiLinearProgressLoader,
  cn,
  getLongAddressName
} from 'noddi-ui';
import { formatCurrencyAmount } from 'noddi-util';

import { URLKeys, noddiAsync } from 'noddi-async';
import { NoddiButton, NoddiIcon, noddiColors } from 'noddi-ui-common';
import { useState } from 'react';
import getCommonTranslations from '../../../../commonTranslations';
import { useBookingActions, useBookingAddress, useBookingSelectedSalesItemsCars } from '../../BookingStore';
import { compareLicensePlates } from '../../helpers/utils';
import { AddressError } from '../Address/AddressError';
import { MultipleCarSelectionBlock } from './MultipleCarSelectionBlock';

type WheelStorageOptionProps = {
  tireHotelSalesItem: TierHotelPickupSalesItemOption;
  carsWithTierPickupOptions: SelectedSalesItemsCar[];
};

export const TireHotelMultipleCars = ({ tireHotelSalesItem, carsWithTierPickupOptions }: WheelStorageOptionProps) => {
  const {
    name,
    description,
    price,
    salesItemId,
    requiresPickupAddressSelection,
    mutuallyExclusiveSalesItemIds,
    isAddonsAvailable,
    isWheelPickupRequired
  } = tireHotelSalesItem;

  const selectedAddress = useBookingAddress();
  const selectedSalesItemsCars = useBookingSelectedSalesItemsCars();

  const [isTirePickUpAtHomeExpanded, setIsTirePickUpAtHomeExpanded] = useState(false);

  const { removeSalesItems, addSalesItems } = useBookingActions();

  const params = carsWithTierPickupOptions.map((carSalesItem) => ({
    licensePlate: carSalesItem.licensePlate,
    salesItemId,
    type: SALES_ITEM_TYPE.TIRE_HOTEL_PICKUP,
    mutuallyExclusiveSalesItemIds,
    isAddonsAvailable,
    isWheelPickupRequired
  }));

  const hasAnySelectedSalesItems = selectedSalesItemsCars.some((item) =>
    carsWithTierPickupOptions.some(
      (car) => compareLicensePlates(item.licensePlate, car.licensePlate) && item.salesItemId === salesItemId
    )
  );

  //check if any car has a custom pick up address
  const customPickUpAddress = selectedSalesItemsCars.find((item) => item.metaData?.selectedTierHotelPickUpAddress);

  const pickUpAtHomeToggle = () => {
    hasAnySelectedSalesItems ? removeSalesItems(params) : addSalesItems(params);
    setIsTirePickUpAtHomeExpanded(!hasAnySelectedSalesItems);
  };

  const {
    mutateAsync: createAddressFromPlaceId,
    reset: resetCreateAddressFromPlaceId,
    isPending,
    error: addressError,
    data: createdAddress
  } = noddiAsync.usePost({
    type: URLKeys.postAddressCreatedFromPlaceId,
    queryConfig: {
      onSuccess: ({ data: { isInDeliveryArea, ...rest } }) => {
        if (isInDeliveryArea) {
          const params = carsWithTierPickupOptions.map((carSalesItem) => ({
            licensePlate: carSalesItem.licensePlate,
            salesItemId,
            type: SALES_ITEM_TYPE.TIRE_HOTEL_PICKUP,
            mutuallyExclusiveSalesItemIds,
            metaData: { selectedTierHotelPickUpAddress: rest },
            isAddonsAvailable,
            isWheelPickupRequired
          }));

          addSalesItems(params);
        }
      }
    }
  });

  const isFree = price.amount === 0;
  const priceFormatted = isFree ? t`Free` : formatCurrencyAmount(price.amount, 0, price.currency);

  if (!requiresPickupAddressSelection) {
    return (
      <div>
        <NoddiBasicCard
          onClick={() => setIsTirePickUpAtHomeExpanded(!isTirePickUpAtHomeExpanded)}
          className={cn('flex flex-col gap-2', hasAnySelectedSalesItems && 'bg-primary-purple text-primary-white')}
        >
          <div className='flex justify-between gap-2'>
            <span className='text-6'>{name}</span>
            <NoddiIcon
              color={hasAnySelectedSalesItems ? noddiColors.primary.white : undefined}
              name={isTirePickUpAtHomeExpanded ? 'AltArrowUp' : 'AltArrowDown'}
            />
          </div>
          <span>{description}</span>
          <div className='-ml-1 flex gap-1'>
            <NoddiIcon
              name='LocationPin'
              color={hasAnySelectedSalesItems ? noddiColors.primary.white : noddiColors.primary.black}
            />
            {selectedAddress && <span>{getLongAddressName(selectedAddress)}</span>}
          </div>
          <div className='flex items-center justify-between'>
            <span className={cn('font-semibold', isFree && !hasAnySelectedSalesItems && 'text-signal-success')}>
              {priceFormatted}
            </span>
            <NoddiButton
              variant='secondary'
              onPress={pickUpAtHomeToggle}
              startIcon={hasAnySelectedSalesItems ? 'Minus' : 'Plus'}
            >
              {hasAnySelectedSalesItems ? <Trans>Remove</Trans> : <Trans>Choose</Trans>}
            </NoddiButton>
          </div>
          {isTirePickUpAtHomeExpanded && (
            <MultipleCarSelectionBlock
              carsWithTierPickupOptions={carsWithTierPickupOptions}
              salesItemId={salesItemId}
              isAddonsAvailable={isAddonsAvailable}
              isWheelPickupRequired={isWheelPickupRequired}
              mutuallyExclusiveSalesItemIds={mutuallyExclusiveSalesItemIds}
              priceFormatted={priceFormatted}
            />
          )}
        </NoddiBasicCard>
      </div>
    );
  }

  return (
    <NoddiBasicCard
      className={cn('flex flex-col gap-2', !!customPickUpAddress && 'bg-primary-purple text-primary-white')}
    >
      <span className='text-6'>{name}</span>
      <span>{description}</span>

      <span className={cn('font-semibold', isFree && !hasAnySelectedSalesItems && 'text-signal-success')}>
        {t`From`} {priceFormatted}
      </span>
      {customPickUpAddress?.metaData?.selectedTierHotelPickUpAddress?.id ? (
        <div className='flex flex-col gap-6'>
          <NoddiBasicCard className='flex w-full items-center justify-between gap-3 rounded-md pl-2 text-primary-black'>
            <div className='flex items-center gap-2'>
              <NoddiIcon name='LocationPin' size='large' />
              <span>{getLongAddressName(customPickUpAddress?.metaData?.selectedTierHotelPickUpAddress)}</span>
            </div>
            <NoddiIconButton
              iconName='Cross'
              iconSize='medium'
              variant='destructive'
              onClick={() => {
                resetCreateAddressFromPlaceId();
                removeSalesItems(params);
              }}
            />
          </NoddiBasicCard>
          <MultipleCarSelectionBlock
            carsWithTierPickupOptions={carsWithTierPickupOptions}
            salesItemId={salesItemId}
            isAddonsAvailable={isAddonsAvailable}
            isWheelPickupRequired={isWheelPickupRequired}
            mutuallyExclusiveSalesItemIds={mutuallyExclusiveSalesItemIds}
            priceFormatted={priceFormatted}
          />
        </div>
      ) : (
        <>
          <AddressSearch
            translations={getCommonTranslations().addressSearch}
            onSelect={async (addressSuggestion) => {
              await createAddressFromPlaceId({ placeId: addressSuggestion.placeId });
            }}
          />
          {isPending && <NoddiLinearProgressLoader />}
          <AddressError addressError={addressError} />
        </>
      )}
      {createdAddress && !createdAddress.data.isInDeliveryArea && (
        <NoddiFeedbackBox
          variant='error'
          className='mt-4 text-primary-black'
          title={getCommonTranslations().weDoNotOfferTheseServicesAtThisAddress}
        />
      )}
    </NoddiBasicCard>
  );
};
