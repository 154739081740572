import { URLKeys, noddiAsync } from 'noddi-async';
import { useState } from 'react';

import { ErrorPageWithTranslations } from '../ErrorPageWithTrans';
import { NoddiCircularLoaderWithTrans } from '../NoddiCircularLoaderWithTrans';
import BookingsSection from './BookingSection';
import { DEFAULT_NUMBER_OF_BOOKINGS_SHOWN } from './constants';

const UpcomingBookings = ({ userGroupId }: { userGroupId: number }) => {
  const [showAllBookings, setShowAllBookings] = useState(false);

  const { isPending, data, error } = noddiAsync.useGet({
    type: URLKeys.getUpcomingBookingsForUserGroup,
    input: { userGroupId, pageSize: showAllBookings ? 1000 : DEFAULT_NUMBER_OF_BOOKINGS_SHOWN, page: 1 }
  });

  if (error) {
    return <ErrorPageWithTranslations apiError={error} />;
  }

  if (isPending) {
    return <NoddiCircularLoaderWithTrans />;
  }

  return (
    <BookingsSection
      bookingsPaginated={data}
      isUpcomingBooking
      setShowAllBookings={setShowAllBookings}
      showAllBookings={showAllBookings}
    />
  );
};

export default UpcomingBookings;
