import Stack from '@mui/material/Stack';

import { noddiColors } from 'noddi-ui-common';
import NavFooter from './SideNavFooter';
import { SideNavHeader } from './SideNavHeader';
import { SideNavItems } from './SideNavItems';
import { VerticalLayoutProps } from './VerticalLayoutProps';

export const SIDE_NAV_WIDTH = 278;

export const PaperProps = () => {
  return {
    sx: {
      '--nav-item-color': noddiColors.primary.white,
      '--nav-item-hover-bg': noddiColors.primary.purple,
      '--nav-item-active-bg': noddiColors.primary.purple,
      '--nav-item-active-color': noddiColors.secondary.white,
      '--nav-item-icon-color': noddiColors.primary.white,
      '--nav-item-icon-active-color': noddiColors.primary.orange,
      backgroundColor: noddiColors.primary.darkPurple,
      width: SIDE_NAV_WIDTH
    }
  };
};

export const DrawerContent = ({
  navItems,
  CustomNavElement,
  CustomerHeaderElement,
  LanguagePicker,
  customPathToProfile,
  hideProfile,
  translations,
  handleClearDataWhenImpersonating,
  disableUserGroupSelection
}: VerticalLayoutProps) => {
  return (
    <Stack sx={{ height: '100%', direction: 'column', justifyContent: 'space-between' }}>
      <div>
        <SideNavHeader
          CustomerHeaderElement={CustomerHeaderElement}
          handleClearDataWhenImpersonating={handleClearDataWhenImpersonating}
          disableUserGroupSelection={disableUserGroupSelection}
        />
        <SideNavItems navItems={navItems} />
      </div>
      <NavFooter
        CustomNavElement={CustomNavElement}
        LanguagePicker={LanguagePicker}
        customPathToProfile={customPathToProfile}
        hideProfile={hideProfile}
        translations={translations}
      />
    </Stack>
  );
};
