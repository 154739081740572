import { Trans } from '@lingui/macro';
import { AnimatePresence, motion } from 'framer-motion';
import { cn, useOnClickOutside } from 'noddi-ui';
import { NoddiButton } from 'noddi-ui-common';
import { useRef, useState } from 'react';
import { LicensePlateCountryOption, getLicensePlateCountryOptions } from '../../helpers/licensePlateCountryOptions';

interface LicensePlateCountrySelectorProps {
  setLicensePlateCountry: (country: LicensePlateCountryOption) => void;
  licensePlateCountry?: LicensePlateCountryOption;
}

export const LicensePlateCountrySelector = ({
  setLicensePlateCountry,
  licensePlateCountry
}: LicensePlateCountrySelectorProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const options = getLicensePlateCountryOptions();

  useOnClickOutside(dropdownRef, () => setIsOpen(false));

  return (
    <div ref={dropdownRef} className='w-fit'>
      <NoddiButton variant='link' startIcon='Globe' className='pl-0' onPress={() => setIsOpen(!isOpen)}>
        <Trans>Car registered in another country? </Trans>
      </NoddiButton>

      <AnimatePresence>
        {isOpen && (
          <motion.div
            initial={{ opacity: 0, y: -10 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -10 }}
            transition={{ duration: 0.3 }}
            className='absolute z-[10000] mt-2 flex w-fit flex-col rounded-2xl bg-primary-white shadow-sm'
          >
            {options.map((option) => (
              <button
                key={option.value}
                className={cn(
                  'flex w-full cursor-pointer gap-4 p-2 [&:not(:last-child)]:border-b-[1.5px] [&:not(:last-child)]:border-systemColors-outlineStroke',
                  option.value === licensePlateCountry?.value && 'font-bold'
                )}
                onClick={() => {
                  setLicensePlateCountry(option);
                  setIsOpen(false);
                }}
              >
                <span className='w-10'>{option.flag}</span>
                <span className='w-full text-start'>{option.label}</span>
              </button>
            ))}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};
