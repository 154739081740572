import { t } from '@lingui/macro';
import { URLKeys, noddiAsync } from 'noddi-async';

import { BookingAddress, SALES_ITEM_TYPE } from 'noddi-async/src/types';
import { CarSpec } from 'noddi-async/src/types/customerapp/booking/shared';
import { useNavigate } from 'react-router-dom';
import { ErrorPageWithTranslations } from '../../../../components/ErrorPageWithTrans';
import {
  NoddiLoadingScreenUpdateBooking,
  NoddiLoadingScreenWithTrans
} from '../../../../components/NoddiLoadingScreenWithTrans';
import {
  useBookingAddress,
  useBookingCars,
  useIsAddonsAvailable,
  useIsMultipleCarsBooking,
  useIsTierHotelPickupRequired
} from '../../BookingStore';
import { BookingScreen } from '../../components/BookingScreen';
import { NewBookingErrorPage } from '../../components/NewBookingErrorPage';
import { addonsStepPath, tierHotelPickupStepPath } from '../../helpers/bookingFlowPaths';
import { useNavigateToTimePickerOrAutoRecallPage } from '../../helpers/navigate';
import { useIsStepValid } from '../../helpers/useStepCompletion';
import { SalesItemsMultipleCarsSelector } from './SalesItemsFleetSelector';
import { ServiceItemsForSingleCar } from './ServiceItemsForCar';
import { useGetUnavailableSalesItems } from './useGetUnavailableSalesItems';

export const ServiceItemsForCars = () => {
  const navigate = useNavigate();

  const { isSalesItemStepValid } = useIsStepValid();

  const { unavailableSalesItemsError } = useGetUnavailableSalesItems();

  const isAddonsAvailable = useIsAddonsAvailable();
  const isTierHotelPickupRequired = useIsTierHotelPickupRequired();
  const { navigateAfterPickingSalesItems, isPatchBookingPending, patchBookingError, isPotentialConflictsLoading } =
    useNavigateToTimePickerOrAutoRecallPage();

  const navigateToNextStep = () => {
    if (isAddonsAvailable) {
      return navigate(addonsStepPath);
    }
    if (isTierHotelPickupRequired) {
      return navigate(tierHotelPickupStepPath);
    }
    navigateAfterPickingSalesItems();
  };

  const selectedAddress = useBookingAddress();
  const selectedCars = useBookingCars();

  if (!selectedAddress || !selectedCars.length) {
    return <NewBookingErrorPage />;
  }

  if (unavailableSalesItemsError || patchBookingError) {
    return <ErrorPageWithTranslations apiError={[unavailableSalesItemsError, patchBookingError]} />;
  }

  if (isPatchBookingPending) {
    return <NoddiLoadingScreenUpdateBooking />;
  }

  return (
    <BookingScreen
      nextButtonLoading={isPotentialConflictsLoading}
      onNextClicked={navigateToNextStep}
      disableNextButton={!isSalesItemStepValid}
      title={t`What can we help you with?`}
    >
      <SalesItemsSelector selectedAddress={selectedAddress} selectedCars={selectedCars} />
    </BookingScreen>
  );
};

const SalesItemsSelector = ({
  selectedAddress,
  selectedCars
}: {
  selectedAddress: BookingAddress;
  selectedCars: CarSpec[];
}) => {
  const isMultipleCarsBooking = useIsMultipleCarsBooking();

  const {
    data: carsWithSalesItems,
    isPending: isSalesItemsPending,
    error: salesItemsError
  } = noddiAsync.usePostAsGetQuery({
    type: URLKeys.getInitialAvailableSalesItems,
    input: {
      addressId: selectedAddress.id,
      licensePlates: selectedCars.map((car) => ({
        number: car.licensePlate.number,
        countryCode: car.licensePlate.countryCode
      }))
    }
  });

  if (isSalesItemsPending) {
    return <NoddiLoadingScreenWithTrans />;
  }

  if (salesItemsError) {
    return <ErrorPageWithTranslations apiError={salesItemsError} />;
  }

  return (
    <div className='flex flex-col gap-8'>
      {isMultipleCarsBooking ? (
        <SalesItemsMultipleCarsSelector
          availableSalesItems={carsWithSalesItems.data}
          salesItemType={SALES_ITEM_TYPE.PRIMARY}
        />
      ) : (
        carsWithSalesItems.data.cars.map((carWithSalesItems) => (
          <ServiceItemsForSingleCar key={carWithSalesItems.licensePlate.number} salesItemsCar={carWithSalesItems} />
        ))
      )}
    </div>
  );
};
