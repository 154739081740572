import { Circle, Path, Svg, SvgProps } from 'react-native-svg';

const Exclamation = ({ color, ...props }: SvgProps) => (
  <Svg width={30} height={30} viewBox='0 0 30 30' fill='none' {...props}>
    <Path stroke={color} strokeLinecap='round' strokeWidth={1.5} d='M15 10v6' />
    <Circle cx={15} cy={19} r={1} fill={color} />
    <Path
      stroke={color}
      strokeWidth={1.5}
      d='M5 15c0-4.714 0-7.071 1.464-8.536C7.93 5 10.286 5 15 5c4.714 0 7.071 0 8.535 1.464C25 7.93 25 10.286 25 15c0 4.714 0 7.071-1.465 8.535C22.072 25 19.714 25 15 25s-7.071 0-8.536-1.465C5 22.072 5 19.714 5 15Z'
    />
  </Svg>
);
export { Exclamation };
