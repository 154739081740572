import { t } from '@lingui/macro';
import { NoddiAsyncError } from 'noddi-async/src/types';
import { ErrorMessage } from 'noddi-ui';
import { ApiErrorMessageWithTrans } from '../../../../components/ApiErrorMessageWithTrans';

const isMissingFieldError = (addressError: NoddiAsyncError, field: string) => {
  return addressError.response?.data.errors.some(({ attr }) => attr === field);
};

export const AddressError = ({ addressError }: { addressError: NoddiAsyncError | null }) => {
  if (!addressError) {
    return null;
  }

  if (isMissingFieldError(addressError, 'street_number')) {
    return <ErrorMessage message={t`Please select an address with a valid street number`} />;
  }

  if (isMissingFieldError(addressError, 'street_name')) {
    return <ErrorMessage message={t`Please select an address with a valid street name`} />;
  }

  if (isMissingFieldError(addressError, 'zip_code')) {
    return <ErrorMessage message={t`Please select an address with a valid zip code`} />;
  }

  if (isMissingFieldError(addressError, 'city')) {
    return <ErrorMessage message={t`Please select an address with a valid city`} />;
  }

  return <ApiErrorMessageWithTrans error={addressError} />;
};
