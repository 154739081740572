import { t } from '@lingui/macro';
import { noddiAsync, URLKeys } from 'noddi-async';
import { BookingInfoStatus } from 'noddi-async/src/types';
import { useParams } from 'react-router-dom';

import { ErrorPageWithTranslations } from '../../components/ErrorPageWithTrans';
import ContentWrapper from '../../components/Layouts/ContentWrapper';
import { NoddiLoadingScreenWithTrans } from '../../components/NoddiLoadingScreenWithTrans';
import { BookingContentBasedOnStatus } from './BookingContentBasedOnStatus';
import FeedbackAfterCompletedBooking from './FeedbackAfterCompletedBooking';
import InvoicedInfo from './InvoicedInfo';
import { JobCompletedAndPaymentLeft } from './JobCompletedAndPaymentLeft';
import PaymentInfo from './PaymentInfo';

const BookingInfo = () => {
  const { slug } = useParams<{ slug: string }>();

  const {
    data: bookingInfo,
    isPending: isBookingInfoPending,
    isSuccess: isBookingInfoSuccess,
    error: bookingInfoError
  } = noddiAsync.useGet({
    type: URLKeys.getBookingInfo,
    input: { slug: slug ?? '' }
  });

  const shouldRefetch = isBookingInfoSuccess && !!bookingInfo?.delayInSeconds;

  const { data: currentEstimatedArrivalTime } = noddiAsync.useGet({
    type: URLKeys.getCurrentEstimatedArrivalTime,
    input: { slug: slug ?? '' },
    queryConfig: {
      refetchInterval: shouldRefetch ? 60000 * 2 : false,
      refetchOnWindowFocus: shouldRefetch
    }
  });

  if (isBookingInfoPending) {
    return <NoddiLoadingScreenWithTrans />;
  }

  if (bookingInfoError) {
    return <ErrorPageWithTranslations apiError={bookingInfoError} />;
  }

  if (!bookingInfo) {
    return null;
  }

  const isInvoiced = bookingInfo.order.status === 'Invoiced';
  const amountDue = bookingInfo.order.amountDue;
  const hasPayed = isInvoiced && amountDue <= 0;
  const jobCompletedAndPaymentLeft = isInvoiced && amountDue > 0;
  const showFeedbackContent =
    !bookingInfo.feedback && [BookingInfoStatus.COMPLETED].includes(bookingInfo.status) && hasPayed;

  const getTitle = () => {
    if (showFeedbackContent) {
      return t`Tell us about your experience`;
    }
    if (jobCompletedAndPaymentLeft) {
      return t`Job completed`;
    }
    return t`Your booking`;
  };

  const Content = () => {
    if (showFeedbackContent) {
      return <FeedbackAfterCompletedBooking bookingId={bookingInfo.id} />;
    }

    if (bookingInfo.isInvoiced) {
      return <InvoicedInfo />;
    }

    if (hasPayed) {
      return <PaymentInfo orderId={bookingInfo.order.id} paymentMethod={bookingInfo.order.paymentMethod} />;
    }

    if (jobCompletedAndPaymentLeft) {
      return <JobCompletedAndPaymentLeft bookingInfo={bookingInfo} orderId={bookingInfo.order.id} />;
    }

    return (
      <BookingContentBasedOnStatus
        status={bookingInfo.status}
        bookingInfo={bookingInfo}
        currentEstimatedArrivalTime={currentEstimatedArrivalTime}
      />
    );
  };

  return (
    <ContentWrapper backButtonPath='/' title={getTitle()}>
      <Content />
    </ContentWrapper>
  );
};

export default BookingInfo;
