import { useState } from 'react';
import { NoddiIconButton } from '../../../molecules';

const BASE_URL = import.meta.env.VITE_APP_BE_URL;
const isLocalEvn = import.meta.env.VITE_ENVIRONMENT === 'local';

const APIBanner = () => {
  const isTestBackend = BASE_URL.includes('https://dev.api.noddi.no');
  const isProdBackend = BASE_URL.includes('https://api.noddi.no');

  const isLocalEnvAndProdBackend = isLocalEvn && isProdBackend;

  const [showBanner, setShowBanner] = useState(isTestBackend || isLocalEnvAndProdBackend);

  if (!showBanner) {
    return null;
  }
  return (
    <div
      className={`fixed left-0 top-0 z-[9999] flex size-fit rounded-lg ${isLocalEnvAndProdBackend ? 'bg-signal-danger' : 'bg-signal-success20'} p-[0.5px]`}
    >
      <div className='flex items-center gap-2'>
        <div className='font-bold'>{isLocalEnvAndProdBackend ? 'PROD BE' : 'TEST BE'}</div>
        <NoddiIconButton variant='ghost' iconSize='small' iconName='Cross' onClick={() => setShowBanner(false)} />
      </div>
    </div>
  );
};

export { APIBanner };
