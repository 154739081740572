import { Route, Routes } from 'react-router-dom';

import { customerRoutes } from 'noddi-util';
import { AutoRecall } from '.';
import { MaintenanceContent } from '../../components/SystemDownTimeBlocker';
import { getNoddiRemoteConfig } from '../../utils/remoteConfigSetup';
import { AutoRecallAddress } from './Address';
import { AutoRecallSummary } from './Summary';
import { AutoRecallTimeWindowPicker } from './TimeWindowPicker/TimeWindowPicker';

export function AutoRecallRouter() {
  const maintenanceScreenBooking = getNoddiRemoteConfig('maintenanceScreenBooking');

  if (maintenanceScreenBooking?.shouldShow) {
    return <MaintenanceContent maintenanceScreen={maintenanceScreenBooking} />;
  }

  return (
    <Routes>
      <Route path={customerRoutes.autoRecallLandingPage.getBasePath()} element={<AutoRecall />} />

      <Route path={customerRoutes.autoRecallSummary.getBasePath()} element={<AutoRecallSummary />} />

      <Route path={customerRoutes.autoRecallTimeWindowPicker.getBasePath()} element={<AutoRecallTimeWindowPicker />} />

      <Route path={customerRoutes.autoRecallAddress.getBasePath()} element={<AutoRecallAddress />} />
    </Routes>
  );
}
