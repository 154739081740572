import { t } from '@lingui/macro';
import { URLKeys, noddiAsync } from 'noddi-async';

import { ErrorPageWithTranslations } from '../../../../components/ErrorPageWithTrans';
import {
  NoddiLoadingScreenUpdateBooking,
  NoddiLoadingScreenWithTrans
} from '../../../../components/NoddiLoadingScreenWithTrans';
import { useBookingSelectedSalesItemsCars } from '../../BookingStore';
import { BookingScreen } from '../../components/BookingScreen';
import { NewBookingErrorPage } from '../../components/NewBookingErrorPage';
import { useNavigateToTimePickerOrAutoRecallPage } from '../../helpers/navigate';
import { useIsStepValid } from '../../helpers/useStepCompletion';
import { CarWithTierPickUpOptions } from './CarWithTierPickUpOptions';
import { MultipleCarsWithPickUpOptions } from './MultipleCarsWithPickUpOptions';

export const TierHotelPickup = () => {
  const { navigateAfterPickingSalesItems, isPatchBookingPending, patchBookingError, isPotentialConflictsLoading } =
    useNavigateToTimePickerOrAutoRecallPage();

  const selectedSalesItemsCars = useBookingSelectedSalesItemsCars();

  const carsWithTierPickupOptions = selectedSalesItemsCars.filter((car) => car.isWheelPickupRequired);
  const hasMultipleCarsWithTierPickup = carsWithTierPickupOptions.length > 1;

  const { isTierHotelPickUpStepValid } = useIsStepValid();

  const {
    data: pickUpOptions,
    isPending,
    error
  } = noddiAsync.useGet({
    type: URLKeys.getTierHotelPickupSalesItemsOptions
  });

  if (error || patchBookingError) {
    return <ErrorPageWithTranslations apiError={[error, patchBookingError]} />;
  }

  if (isPending) {
    return <NoddiLoadingScreenWithTrans />;
  }

  if (isPatchBookingPending) {
    return <NoddiLoadingScreenUpdateBooking />;
  }

  if (!carsWithTierPickupOptions.length) {
    return <NewBookingErrorPage />;
  }

  return (
    <BookingScreen
      nextButtonLoading={isPotentialConflictsLoading}
      onNextClicked={navigateAfterPickingSalesItems}
      disableNextButton={!isTierHotelPickUpStepValid}
      title={t`Where should we pick up your tires?`}
    >
      {hasMultipleCarsWithTierPickup ? (
        <MultipleCarsWithPickUpOptions
          carsWithTierPickupOptions={carsWithTierPickupOptions}
          pickUpOptions={pickUpOptions}
        />
      ) : (
        <div className='flex flex-col gap-8'>
          {carsWithTierPickupOptions.map((item) => (
            <CarWithTierPickUpOptions
              key={item.licensePlate.number}
              licensePlate={item.licensePlate}
              pickUpOptions={pickUpOptions}
            />
          ))}
        </div>
      )}
    </BookingScreen>
  );
};
