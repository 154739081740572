import { Trans, t } from '@lingui/macro';
import { Form, Formik } from 'formik';
import { URLKeys, noddiAsync } from 'noddi-async';
import { invalidateQueryExactMatch } from 'noddi-async/src/utils';
import { useAuthContext } from 'noddi-provider';
import { FieldWrapper, NoddiFeedbackBox, NoddiFormTextInput } from 'noddi-ui';
import { NoddiButton } from 'noddi-ui-common';
import { useState } from 'react';
import * as Yup from 'yup';
import { ApiErrorMessageWithTrans } from '../../../components/ApiErrorMessageWithTrans';
import { BenefitsLogin } from '../../../components/BenefitsLogin';

type Props = {
  slug: string;
};

const getValidationSchema = () => {
  return Yup.object().shape({
    authCode: Yup.string().required(t`Required`)
  });
};

export const ActivateMembershipCard = ({ slug }: Props) => {
  const [discountCode, setDiscountCode] = useState<string>();
  const { currentUserGroupId: userGroupId, isLoggedIn } = useAuthContext();

  const {
    mutateAsync: activateMembership,
    isPending: isActivateMembershipPending,
    error: activateMembershipError
  } = noddiAsync.usePost({
    type: URLKeys.postActivateMembership,
    queryConfig: {
      onSuccess: async () => {
        await invalidateQueryExactMatch({ urlKey: URLKeys.getMembershipFromSlug, input: { slug, userGroupId } });
      }
    }
  });

  const {
    data: verifyMembershipCodeData,
    error: verifyMembershipCodeError,
    isLoading: isGetVerifyMembershipCodeLoading
  } = noddiAsync.useGet({
    type: URLKeys.getVerifyMembershipCode,
    input: { slug, authCode: discountCode! },
    queryConfig: {
      enabled: !!discountCode
    }
  });

  const activateMemberShipAfterLogin = async (userGroupId: number | null) => {
    if (userGroupId) {
      await activateMembership({ userGroupId, slug, authCode: discountCode! });
    }
  };

  return (
    <>
      {!isLoggedIn && verifyMembershipCodeData?.isValid ? (
        <BenefitsLogin activateMemberShipAfterLogin={activateMemberShipAfterLogin} />
      ) : (
        <div className='flex flex-col gap-2'>
          <p className='font-bold text-primary-darkPurple'>
            <Trans>Enter discount code to connect the membership benefits to your account</Trans> 🥳
          </p>
          <Formik
            initialValues={{
              authCode: ''
            }}
            validationSchema={getValidationSchema()}
            validateOnMount
            onSubmit={async (values) => {
              if (userGroupId) {
                await activateMembership({ userGroupId, slug, ...values });
              } else {
                setDiscountCode(values.authCode);
              }
            }}
          >
            {({ isValid, submitForm }) => (
              <Form>
                <FieldWrapper>
                  <NoddiFormTextInput name='authCode' />
                  {verifyMembershipCodeData && !verifyMembershipCodeData.isValid && (
                    <NoddiFeedbackBox variant='error' description={t`Incorrect discount code, please try again`} />
                  )}
                  <div className='flex justify-end'>
                    <NoddiButton
                      onPress={submitForm}
                      disabled={!isValid}
                      loading={isActivateMembershipPending || isGetVerifyMembershipCodeLoading}
                    >
                      {t`Activate membership`}
                    </NoddiButton>
                  </div>
                </FieldWrapper>
              </Form>
            )}
          </Formik>
        </div>
      )}

      {verifyMembershipCodeError && <ApiErrorMessageWithTrans error={verifyMembershipCodeError} />}
      {activateMembershipError && <ApiErrorMessageWithTrans error={activateMembershipError} />}
    </>
  );
};
