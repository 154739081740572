import { AxiosResponse } from 'axios';

import {
  BookingCalendarData,
  BookingForRoutePlanner,
  BookingGeneralDetails,
  BookingItemsOverviewInput,
  BookingItemsOverviewItem,
  BookingOverviewDetailsListType,
  BookingTimeWindowTemplate,
  BookingTimeWindowTemplatePostPatch,
  Campaign,
  CampaignList,
  CapacityContributionListItem,
  CapacityContributionOverviewInput,
  CapacityContributionOverviewItem,
  CapacityContributionStatus,
  CapacityContributionType,
  CapacityInterval,
  CarDetailType,
  CarPatchInputType,
  CarSize,
  CarWheel,
  CarWheelSet,
  CarWheelSetAtFacility,
  CarWheelSetListItemType,
  CarWheelSetPatchInput,
  CarWheelSetStorageInformation,
  CarWheelSetV2,
  CarWheelStorageObject,
  CarWheelStorageObjectCreateInput,
  CommonRouteSettlementsInput,
  CouponGroup,
  CouponGroupList,
  CouponList,
  CouponTemplate,
  CouponTemplateList,
  Currency,
  DashboardData,
  DeliveryWindowGroup,
  DeliveryWindowWithCapacity,
  FeedbackAdmin,
  FeedbackAdminInput,
  GroupInfo,
  InternalCoupon,
  ListCapacityIntervalsInput,
  MarkWheelStoragePickupAsComplete,
  MembershipProgramListItem,
  MinimalSalesItem,
  Money,
  OptionsTypeInteger,
  Order,
  OrderTransaction,
  OrderTransactionDintero,
  Organization,
  OrganizationCreateInput,
  OrganizationUpdateInput,
  PaginatedBookingOverviewDetailsListInput,
  PaginatedCapacityContributionsInput,
  PaginatedInput,
  PaginatedResponse,
  PublicCoupon,
  RewardLevel,
  RewardLevelList,
  RouteInSolution,
  RouteOverview,
  RoutePlannerBookingsInputType,
  RoutePlannerCapacityContribution,
  RoutePlannerCapacityContributionsInputType,
  RouteProblemListType,
  RouteProblemType,
  RouteSettlement,
  RouteSettlementInfo,
  RouteSolution,
  RouteStatusMap,
  SalesItemBookingCategory,
  SalesItemCreateInputType,
  SalesItemListItem,
  SalesItemProps,
  SalesItemUpdateInputType,
  ServiceAreaCreateInput,
  ServiceAreaDetail,
  ServiceAreaForMap,
  ServiceAreaListItemV2,
  ServiceAreasForServiceDepartmentForMap,
  ServiceAreaUpdateInput,
  ServiceCategory,
  ServiceCategoryBaseInput,
  ServiceCategoryPatchInput,
  ServiceDepartment,
  ServiceDepartmentArea,
  ServiceDepartmentAreaCategory,
  ServiceDepartmentList,
  ServiceWorker,
  ServiceWorkersBase,
  StartRoutePlannerInputType,
  StorageArea,
  StorageAreaCreateInput,
  StorageAreaListItem,
  StorageAreaOverview,
  StorageAreaUpdateInput,
  StorageFacility,
  StorageFacilityCreateInput,
  StorageFacilityUpdateInput,
  StorageObjectGroupWithLatestEvent,
  StorageUnit,
  StorageUnitCreateInput,
  StorageUnitListItem,
  StorageUnitType,
  StorageUnitUpdateInput,
  TireHotelContractListItem,
  UnpaidBooking,
  UserGroup,
  UserGroupListItem,
  UserGroupPatchInput,
  ValidateRouteProblemResponse,
  WheelStorageAgreementFilters,
  WheelStoragePickupBookingItem,
  WheelStoragePickupsParams,
  WorkerAddressPair,
  WorkerAddressPairList,
  WorkerAddressPairUpdateInput,
  WorkerContract,
  WorkerContractBase,
  WorkerContractPatch
} from '../types';
import {
  ServiceDepartmentCapacityInput,
  ServiceDepartmentOverview,
  ServiceDepartmentOverviewInput
} from '../types/adminapp/route/capacityOverview';
import {
  InventoryOrder,
  InventoryOrderCreationPayload,
  QuoteItemStatusType,
  TireInQuoteItem,
  TireOfferV2,
  TireQuotePayload,
  TireQuoteSalesItem
} from '../types/shared/quotes';

import {
  LoadIndexFilterType,
  SpeedIndexFilterType,
  TireFiltersInput,
  TireFilterStringValueOption,
  TireSpecificationFilters
} from '../types/adminapp/tireOffer';
import { Brand } from '../types/shared/brand';
import { Cohort } from '../types/shared/recall';
import {
  AddTireToInventoryPayload,
  Tire,
  TireCreationPayload,
  TireFromInventorySupplier
} from '../types/shared/tireFromInventory';
import { downloadToExcel } from '../utils';
import { apiVersion, FetchMethods, ResponseType } from './commonResourceTypes';
import { CommonAxiosResType, getNoddiUrl } from './resources';

export const AdminURLKeys = {
  getServiceWorkerAddressPair: 'getServiceWorkerAddressPair',
  patchServiceWorkerAddressPair: 'patchServiceWorkerAddressPair',
  getSuggestedServiceDepartmentAreas: 'getSuggestedServiceDepartmentAreas',
  getRoutesOverview: 'getRoutesOverview',
  getRouteSettlement: 'getRouteSettlement',
  getRouteSettlements: 'getRouteSettlements',
  patchRouteSettlement: 'patchRouteSettlement',
  postRouteSettlementsToExcel: 'postRouteSettlementsToExcel',
  postSetPaidAtRouteSettlements: 'postSetPaidAtRouteSettlements',
  getUnpaidBookings: 'getUnpaidBookings',
  getUnpaidBookingsToExcel: 'getUnpaidBookingsToExcel',
  postCreateInvoiceForUnpaidBookings: 'postCreateInvoiceForUnpaidBookings',
  getTotalUnpaidBookingsAmount: 'getTotalUnpaidBookingsAmount',
  getRouteSolution: 'getRouteSolution',
  getCalendarBookings: 'getCalendarBookings',
  getServiceArea: 'getServiceArea',
  getBrand: 'getBrand',
  patchBrand: 'patchBrand',
  postServiceArea: 'postServiceArea',
  patchServiceArea: 'patchServiceArea',
  getPaginatedServiceAreas: 'getPaginatedServiceAreas',
  getServiceAreasForMap: 'getServiceAreasForMap',
  getServiceAreasForMapForServiceDepartment: 'getServiceAreasForMapForServiceDepartment',
  getServiceDepartments: 'getServiceDepartments',
  getPaginatedServiceDepartments: 'getPaginatedServiceDepartments',
  getPaginatedBrands: 'getPaginatedBrands',
  postBrand: 'postBrand',
  getServiceDepartment: 'getServiceDepartment',
  postServiceDepartment: 'postServiceDepartment',
  patchServiceDepartment: 'patchServiceDepartment',
  getBookingRouteDetails: 'getBookingRouteDetails',
  getBookingGeneralDetails: 'getBookingGeneralDetails',
  getBookingOrder: 'getBookingOrder',
  getGroupMembersById: 'getGroupMembersById',
  postAddDiscountToOrder: 'postAddDiscountToOrder',
  getCampaigns: 'getCampaigns',
  getCampaign: 'getCampaign',
  postCampaign: 'postCampaign',
  patchCampaign: 'patchCampaign',
  getCouponGroups: 'getCouponGroups',
  getPaginatedCouponGroups: 'getPaginatedCouponGroups',
  getCouponGroup: 'getCouponGroup',
  postCouponGroup: 'postCouponGroup',
  patchCouponGroup: 'patchCouponGroup',
  getCouponTemplates: 'getCouponTemplates',
  getPaginatedCouponTemplates: 'getPaginatedCouponTemplates',
  getCouponTemplate: 'getCouponTemplate',
  postCouponTemplate: 'postCouponTemplate',
  patchCouponTemplate: 'patchCouponTemplate',
  getPaginatedCoupons: 'getPaginatedCoupons',
  getPaginatedUserGroupCoupons: 'getPaginatedUserGroupCoupons',
  getCoupon: 'getCoupon',
  postCoupon: 'postCoupon',
  patchCoupon: 'patchCoupon',
  getCampaignRewardLevels: 'getCampaignRewardLevels',
  getPaginatedCampaignRewardLevels: 'getPaginatedCampaignRewardLevels',
  getCampaignRewardLevel: 'getCampaignRewardLevel',
  postCampaignRewardLevel: 'postCampaignRewardLevel',
  patchCampaignRewardLevel: 'patchCampaignRewardLevel',
  getPaginatedUserGroups: 'getPaginatedUserGroups',
  getUserGroup: 'getUserGroup',
  postUserGroup: 'postUserGroup',
  patchUserGroup: 'patchUserGroup',
  postOrganization: 'postOrganization',
  patchOrganization: 'patchOrganization',
  getPaginatedSalesItems: 'getPaginatedSalesItems',
  getSalesItem: 'getSalesItem',
  postSalesItem: 'postSalesItem',
  patchSalesItem: 'patchSalesItem',
  getServiceCategoryList: 'getServiceCategoryList',
  getBookingCohorts: 'getBookingCohorts',
  getCarSizesList: 'getCarSizesList',
  getSalesItemBookingCategories: 'getSalesItemBookingCategories',
  getSalesItemsTypes: 'getSalesItemsTypes',
  patchCarDetails: 'patchCarDetails',
  getOrderTransaction: 'getOrderTransaction',
  postCaptureOrderTransactionDintero: 'postCaptureOrderTransactionDintero',
  postRefundOrderTransactionDintero: 'postRefundOrderTransactionDintero',
  postPartialRefundOrderTransactionDintero: 'postPartialRefundOrderTransactionDintero',
  postVoidOrderTransactionDintero: 'postVoidOrderTransactionDintero',
  getServiceCategory: 'getServiceCategory',
  postServiceCategory: 'postServiceCategory',
  patchServiceCategory: 'patchServiceCategory',
  postBookingTimeWindowTemplate: 'postBookingTimeWindowTemplate',
  deleteBookingTimeWindowTemplate: 'deleteBookingTimeWindowTemplate',
  patchBookingTimeWindowTemplate: 'patchBookingTimeWindowTemplate',
  getBookingTimeWindowTemplate: 'getBookingTimeWindowTemplate',
  getCarWheel: 'getCarWheel',
  getCarWheelsByIds: 'getCarWheelsByIds',
  getCarWheelSet: 'getCarWheelSet',
  getCarWheelSetStorageInformation: 'getCarWheelSetStorageInformation',
  patchCarWheelSet: 'patchCarWheelSet',
  postCarWheelStorageObjects: 'postCarWheelStorageObjects',
  getPaginatedStorageObjectsForCarWheel: 'getPaginatedStorageObjectsForCarWheel',
  getStorageFacilities: 'getStorageFacilities',
  getPaginatedStorageFacilities: 'getPaginatedStorageFacilities',
  getStorageFacility: 'getStorageFacility',
  postStorageFacility: 'postStorageFacility',
  patchStorageFacility: 'patchStorageFacility',
  getStorageAreasOverview: 'getStorageAreasOverview',
  getStorageAreasForFacility: 'getStorageAreasForFacility',
  getPaginatedStorageAreasForFacility: 'getPaginatedStorageAreasForFacility',
  getStorageArea: 'getStorageArea',
  getPaginatedStorageObjectGroupsForFacility: 'getPaginatedStorageObjectGroupsForFacility',
  getPaginatedCarWheelSetsForFacility: 'getPaginatedCarWheelSetsForFacility',
  getCapacityContributionTypes: 'getCapacityContributionTypes',
  postStorageArea: 'postStorageArea',
  patchStorageArea: 'patchStorageArea',
  deleteStorageArea: 'deleteStorageArea',
  getPaginatedStorageUnits: 'getPaginatedStorageUnits',
  getStorageUnit: 'getStorageUnit',
  postStorageUnit: 'postStorageUnit',
  patchStorageUnit: 'patchStorageUnit',
  deleteStorageUnit: 'deleteStorageUnit',
  getPaginatedStorageUnitsForArea: 'getPaginatedStorageUnitsForArea',
  getStorageUnitTypes: 'getStorageUnitTypes',
  postPickUpCarWheelSet: 'postPickUpCarWheelSet',
  getPaginatedCarWheelSetForCar: 'getPaginatedCarWheelSetForCar',
  getCarWheelSetForCar: 'getCarWheelSetForCar',
  getCoreCountries: 'getCoreCountries',
  getPaginatedUserGroupMembershipProgram: 'getPaginatedUserGroupMembershipProgram',
  getDeliveryWindowsWithCapacity: 'getDeliveryWindowsWithCapacity',
  getPaginatedCapacityContributions: 'getPaginatedCapacityContributions',
  getCapacityContributionStatuses: 'getCapacityContributionStatuses',
  getCapacityContributionOverview: 'getCapacityContributionOverview',
  getBookingItemsOverview: 'getBookingItemsOverview',
  getCapacityContributionsForRoute: 'getCapacityContributionsForRoute',
  getPaginatedServiceCategories: 'getPaginatedServiceCategories',
  deleteOrderLine: 'deleteOrderLine',
  getPaginatedRouteProblems: 'getPaginatedRouteProblems',
  getRouteProblem: 'getRouteProblem',
  getPaginatedCapacityContributionsForRoutePlanner: 'getPaginatedCapacityContributionsForRoutePlanner',
  getRoutePlannerBookings: 'getRoutePlannerBookings',
  patchServiceDepartmentCapacity: 'patchServiceDepartmentCapacity',
  getRouteProblemValidation: 'getRouteProblemValidation',
  postStartRoutePlanner: 'postStartRoutePlanner',
  postSelectRouteSolution: 'postSelectRouteSolution',
  getPaginatedBookingOverviewDetailsList: 'getPaginatedBookingOverviewDetailsList',
  getAdminDashboardBookings: 'getAdminDashboardBookings',
  postCancelBooking: 'postCancelBooking',
  postChangeCapacityContribution: 'postChangeCapacityContribution',
  getWheelStoragePickupBookingItems: 'getWheelStoragePickupBookingItems',
  getWheelStorageAgreements: 'getWheelStorageAgreements',
  postMarkWheelsAsPickedUp: 'postMarkWheelsAsPickedUp',
  getCapacityIntervals: 'getCapacityIntervals',
  postCreateCarTireQuotes: 'postCreateCarTireQuotes',
  getServiceDepartmentsOverview: 'getServiceDepartmentsOverview',
  getFeedbacks: 'getFeedbacks',
  patchFeedback: 'patchFeedback',
  getPaginatedDeliveryWindowGroups: 'getPaginatedDeliveryWindowGroups',
  getPaginatedWorkers: 'getPaginatedWorkers',
  getWorkers: 'getWorkers',
  getPaginatedWorkerContracts: 'getPaginatedWorkerContracts',
  getWorkerContract: 'getWorkerContract',
  postWorkerContract: 'postWorkerContract',
  patchWorkerContract: 'patchWorkerContract',
  getWorker: 'getWorker',
  patchWorker: 'patchWorker',
  postWorker: 'postWorker',
  getWorkerContractAddressPairs: 'getWorkerContractAddressPairs',
  getServiceDepartmentArea: 'getServiceDepartmentArea',
  patchServiceDepartmentArea: 'patchServiceDepartmentArea',
  getServiceDepartmentAreaCategory: 'getServiceDepartmentAreaCategory',
  patchServiceDepartmentAreaCategory: 'patchServiceDepartmentAreaCategory',
  postDeliveryWindowGroup: 'postDeliveryWindowGroup',
  getDeliveryWindowGroup: 'getDeliveryWindowGroup',
  getRoutesForMapStatus: 'getRoutesForMapStatus',
  getTireSpeedIndexFilters: 'getTireSpeedIndexFilters',
  getTireLoadIndexFilters: 'getTireLoadIndexFilters',
  getTireSoundClassFilters: 'getTireSoundClassFilters',
  getTireClassificationFilters: 'getTireClassificationFilters',
  getTireEuClassFilters: 'getTireEuClassFilters',
  getTireConstructionFilters: 'getTireConstructionFilters',
  getTireRollingResistanceFilters: 'getTireRollingResistanceFilters',
  getTireQuoteSalesItems: 'getTireQuoteSalesItems',
  getSalesItemCategories: 'getSalesItemCategories',
  getMinimalSalesItems: 'getMinimalSalesItems',
  getTireInventoryList: 'getTireInventoryList',
  getCarSpecificationTireFilters: 'getCarSpecificationTireFilters',
  patchTireQuoteOffer: 'patchTireQuoteOffer',
  patchCarTireQuoteItemTire: 'patchCarTireQuoteItemTire',
  postAddCarToUserGroup: 'postAddCarToUserGroup',
  postRemoveCarsFromUserGroup: 'postRemoveCarsFromUserGroup',
  getTireBrands: 'getTireBrands',
  postCreateTire: 'postCreateTire',
  getTireShopSuppliers: 'getTireShopSuppliers',
  postTireToInventory: 'postTireToInventory',
  getStorageUnitsForAreaFromIdentifier: 'getStorageUnitsForAreaFromIdentifier',
  postOrderInventory: 'postOrderInventory',
  patchInventoryOrderToQuoteItemTire: 'patchInventoryOrderToQuoteItemTire',
  getIsShopClosed: 'getIsShopClosed',
  patchCarTireQuote: 'patchCarTireQuote',
  getBookingAddressCities: 'getBookingAddressCities',
  getBookingAddressZipCodes: 'getBookingAddressZipCodes'
} as const;

export type AdminURLKeysType = keyof typeof AdminURLKeys;

type InputDataOrVoid<Type extends AdminURLKeysType> = InputData<Type> extends void ? void : InputData<Type>;

type InputData<urlKey extends AdminURLKeysType> = AdminServerRequests[urlKey]['input'];

export const getAdminUrls: {
  [key in AdminURLKeysType]: {
    getUrl: (input: InputDataOrVoid<key>) => string;
    getBody?: (input: InputDataOrVoid<key>) => Partial<InputData<key>>;
    method: FetchMethods;
    responseType?: ResponseType;
    handleRes?: (res: CommonAxiosResType<key>) => Promise<unknown>;
    getHeaders?: (input: InputDataOrVoid<key>) => Record<string, string>;
    useSuperUserToken?: boolean;
    skipConvertingToCamelCase?: boolean;
    skipBodyTransformation?: boolean;
  };
} = {
  getIsShopClosed: {
    getUrl: ({ serviceDepartmentId, ...queryParams }) =>
      getNoddiUrl({
        path: `${apiVersion}service-departments/${serviceDepartmentId}/is-shop-closed/`,
        queryParams
      }),
    method: FetchMethods.get
  },
  getServiceWorkerAddressPair: {
    getUrl: ({ id }) =>
      getNoddiUrl({
        path: `${apiVersion}service-worker-address-pairs/${id}/`
      }),
    method: FetchMethods.get
  },
  patchServiceWorkerAddressPair: {
    getUrl: ({ id }) =>
      getNoddiUrl({
        path: `${apiVersion}service-worker-address-pairs/${id}/`
      }),
    getBody: (data) => data,
    method: FetchMethods.patch
  },
  getSuggestedServiceDepartmentAreas: {
    getUrl: ({ id, ...queryParams }) =>
      getNoddiUrl({
        path: `${apiVersion}service-worker-address-pairs/${id}/suggested-service-department-areas/`,
        queryParams
      }),
    method: FetchMethods.get
  },
  getRoutesOverview: {
    getUrl: ({ serviceDepartmentId, ...queryParams }) =>
      getNoddiUrl({
        path: `${apiVersion}service-departments/${serviceDepartmentId}/routes/`,
        queryParams
      }),
    method: FetchMethods.get
  },
  getPaginatedWorkerContracts: {
    getUrl: ({ serviceDepartmentId, ...rest }) =>
      getNoddiUrl({
        path: `${apiVersion}service-departments/${serviceDepartmentId}/service-worker-contracts/`,
        queryParams: rest
      }),
    method: FetchMethods.get
  },

  postWorkerContract: {
    getUrl: () =>
      getNoddiUrl({
        path: `${apiVersion}service-worker-contracts/`
      }),
    getBody: (data) => data,
    method: FetchMethods.post
  },
  getWorkerContract: {
    getUrl: ({ id }) =>
      getNoddiUrl({
        path: `${apiVersion}service-worker-contracts/${id}/`
      }),
    method: FetchMethods.get
  },
  patchWorkerContract: {
    getUrl: ({ id }) =>
      getNoddiUrl({
        path: `${apiVersion}service-worker-contracts/${id}/`
      }),
    getBody: (data) => data,
    method: FetchMethods.patch
  },
  getPaginatedWorkers: {
    getUrl: (queryParams) =>
      getNoddiUrl({
        path: `${apiVersion}service-workers/`,
        queryParams
      }),
    method: FetchMethods.get
  },
  getWorkers: {
    getUrl: (queryParams) =>
      getNoddiUrl({
        path: `${apiVersion}service-workers/`,
        queryParams
      }),
    method: FetchMethods.get
  },
  getWorker: {
    getUrl: ({ id }) =>
      getNoddiUrl({
        path: `${apiVersion}service-workers/${id}/`
      }),
    method: FetchMethods.get
  },
  patchWorker: {
    getUrl: ({ id }) =>
      getNoddiUrl({
        path: `${apiVersion}service-workers/${id}/`
      }),
    getBody: (data) => data,
    method: FetchMethods.patch
  },
  postWorker: {
    getUrl: () =>
      getNoddiUrl({
        path: `${apiVersion}service-workers/`
      }),
    getBody: (data) => data,
    method: FetchMethods.post
  },
  getWorkerContractAddressPairs: {
    getUrl: ({ serviceWorkerContractId }) =>
      getNoddiUrl({
        path: `${apiVersion}service-worker-contracts/${serviceWorkerContractId}/address-pairs/`
      }),
    method: FetchMethods.get
  },
  getPaginatedDeliveryWindowGroups: {
    getUrl: ({ serviceDepartmentId, ...rest }) =>
      getNoddiUrl({
        path: `${apiVersion}service-departments/${serviceDepartmentId}/delivery-window-groups/`,
        queryParams: rest
      }),
    method: FetchMethods.get
  },
  getDeliveryWindowGroup: {
    getUrl: ({ id }) =>
      getNoddiUrl({
        path: `${apiVersion}delivery-window-groups/${id}/`
      }),
    method: FetchMethods.get
  },
  postDeliveryWindowGroup: {
    getUrl: () =>
      getNoddiUrl({
        path: `${apiVersion}delivery-window-groups/`
      }),
    getBody: (data) => data,
    method: FetchMethods.post
  },

  getServiceDepartmentArea: {
    getUrl: ({ serviceDepartmentId, serviceAreaId }) =>
      getNoddiUrl({
        path: `${apiVersion}service-departments/${serviceDepartmentId}/service-areas/${serviceAreaId}/`
      }),
    method: FetchMethods.get
  },
  patchServiceDepartmentArea: {
    getUrl: ({ serviceDepartmentId, serviceAreaId }) =>
      getNoddiUrl({
        path: `${apiVersion}service-departments/${serviceDepartmentId}/service-areas/${serviceAreaId}/`
      }),
    getBody: (data) => data,
    method: FetchMethods.patch
  },
  getServiceDepartmentAreaCategory: {
    getUrl: ({ serviceDepartmentId, serviceAreaId, id }) =>
      getNoddiUrl({
        path: `${apiVersion}service-departments/${serviceDepartmentId}/service-areas/${serviceAreaId}/service-categories/${id}/`
      }),
    method: FetchMethods.get
  },
  patchServiceDepartmentAreaCategory: {
    getUrl: ({ serviceDepartmentId, serviceAreaId, id }) =>
      getNoddiUrl({
        path: `${apiVersion}service-departments/${serviceDepartmentId}/service-areas/${serviceAreaId}/service-categories/${id}/`
      }),
    getBody: (data) => data,
    method: FetchMethods.patch
  },
  getServiceDepartmentsOverview: {
    getUrl: (queryParams) =>
      getNoddiUrl({
        path: `${apiVersion}service-departments/capacity-overview/`,
        queryParams
      }),
    method: FetchMethods.get
  },
  getRouteSettlements: {
    getUrl: (queryParams) => {
      return getNoddiUrl({
        path: `${apiVersion}route-settlements/`,
        queryParams
      });
    },
    method: FetchMethods.get
  },
  getRouteSettlement: {
    getUrl: ({ id }) =>
      getNoddiUrl({
        path: `${apiVersion}route-settlements/${id}/`
      }),
    method: FetchMethods.get
  },
  patchRouteSettlement: {
    getUrl: ({ id }) =>
      getNoddiUrl({
        path: `${apiVersion}route-settlements/${id}/`
      }),
    getBody: (data) => data,
    method: FetchMethods.patch
  },
  postSetPaidAtRouteSettlements: {
    getUrl: () =>
      getNoddiUrl({
        path: `${apiVersion}route-settlements/update-paid-at/`
      }),
    getBody: (data) => data,
    method: FetchMethods.post
  },
  postRouteSettlementsToExcel: {
    getUrl: () =>
      getNoddiUrl({
        path: `${apiVersion}route-settlements/to-excel/`
      }),
    getBody: (data) => data,
    handleRes: async (res) => {
      return downloadToExcel(res);
    },
    method: FetchMethods.post,
    responseType: 'arraybuffer'
  },
  getUnpaidBookings: {
    getUrl: (queryParams) =>
      getNoddiUrl({
        path: `${apiVersion}bookings/unpaid/`,
        queryParams
      }),
    method: FetchMethods.get
  },
  getUnpaidBookingsToExcel: {
    getUrl: (queryParams) =>
      getNoddiUrl({
        path: `${apiVersion}bookings/unpaid-to-excel/`,
        queryParams
      }),
    handleRes: async (res) => {
      return downloadToExcel(res);
    },
    method: FetchMethods.get,
    responseType: 'arraybuffer'
  },
  postCreateInvoiceForUnpaidBookings: {
    getUrl: (queryParams) =>
      getNoddiUrl({
        path: `${apiVersion}bookings/unpaid-create-invoices/`,
        queryParams
      }),
    getBody: (data) => data,
    method: FetchMethods.post
  },
  getTotalUnpaidBookingsAmount: {
    getUrl: (queryParams) =>
      getNoddiUrl({
        path: `${apiVersion}bookings/unpaid-amount/`,
        queryParams
      }),
    method: FetchMethods.get
  },
  getRouteSolution: {
    getUrl: ({ routeSolutionId }) =>
      getNoddiUrl({
        path: `${apiVersion}route-solutions/${routeSolutionId}/`
      }),
    method: FetchMethods.get
  },
  getCalendarBookings: {
    getUrl: (queryParams) =>
      getNoddiUrl({
        path: `${apiVersion}bookings/status-counts/`,
        queryParams
      }),
    skipConvertingToCamelCase: true,
    method: FetchMethods.get
  },
  getServiceArea: {
    getUrl: ({ id }) =>
      getNoddiUrl({
        path: `${apiVersion}service-areas-v2/${id}/`
      }),
    method: FetchMethods.get
  },
  getBrand: {
    getUrl: ({ id }) =>
      getNoddiUrl({
        path: `${apiVersion}brands/${id}/`
      }),
    method: FetchMethods.get
  },
  patchBrand: {
    getUrl: ({ id }) =>
      getNoddiUrl({
        path: `${apiVersion}brands/${id}/`
      }),
    getHeaders: () => ({
      'Content-Type': 'multipart/form-data'
    }),
    getBody: (data) => data,
    skipBodyTransformation: true,
    method: FetchMethods.patch
  },
  postServiceArea: {
    getUrl: () =>
      getNoddiUrl({
        path: `${apiVersion}service-areas-v2/create/`
      }),
    getBody: (data) => data,
    method: FetchMethods.post
  },
  patchServiceArea: {
    getUrl: ({ id }) =>
      getNoddiUrl({
        path: `${apiVersion}service-areas-v2/${id}/update/`
      }),
    getBody: (data) => data,
    method: FetchMethods.patch
  },
  getServiceDepartments: {
    getUrl: () =>
      getNoddiUrl({
        path: `${apiVersion}service-departments/`
      }),
    method: FetchMethods.get
  },
  getPaginatedServiceDepartments: {
    getUrl: (queryParams) =>
      getNoddiUrl({
        path: `${apiVersion}service-departments/`,
        queryParams
      }),
    method: FetchMethods.get
  },
  getPaginatedBrands: {
    getUrl: (queryParams) =>
      getNoddiUrl({
        path: `${apiVersion}brands/`,
        queryParams
      }),
    method: FetchMethods.get
  },
  getServiceDepartment: {
    getUrl: ({ id }) =>
      getNoddiUrl({
        path: `${apiVersion}service-departments/${id}/`
      }),
    method: FetchMethods.get
  },
  getServiceAreasForMap: {
    getUrl: (queryParams) =>
      getNoddiUrl({
        path: `${apiVersion}service-areas/for-map/`,
        queryParams
      }),
    method: FetchMethods.get
  },
  getServiceAreasForMapForServiceDepartment: {
    getUrl: ({ serviceDepartmentId, ...queryParams }) =>
      getNoddiUrl({
        path: `${apiVersion}service-departments/${serviceDepartmentId}/service-department-areas/`,
        queryParams
      }),
    method: FetchMethods.get
  },
  getPaginatedServiceAreas: {
    getUrl: (queryParams) =>
      getNoddiUrl({
        path: `${apiVersion}service-areas-v2/`,
        queryParams
      }),
    method: FetchMethods.get
  },
  postServiceDepartment: {
    getUrl: () =>
      getNoddiUrl({
        path: `${apiVersion}service-departments/`
      }),
    getBody: (data) => data,
    method: FetchMethods.post
  },
  postBrand: {
    getUrl: () =>
      getNoddiUrl({
        path: `${apiVersion}brands/`
      }),
    getHeaders: () => ({
      'Content-Type': 'multipart/form-data'
    }),
    getBody: (data) => data,
    skipBodyTransformation: true,
    method: FetchMethods.post
  },
  patchServiceDepartment: {
    getUrl: ({ id }) =>
      getNoddiUrl({
        path: `${apiVersion}service-departments/${id}/`
      }),
    getBody: (data) => data,
    method: FetchMethods.patch
  },
  postMarkWheelsAsPickedUp: {
    getUrl: ({ bookingItemId }) =>
      getNoddiUrl({
        path: `${apiVersion}booking-items/${bookingItemId}/mark-wheels-as-picked-up/`
      }),
    getBody: (data) => data,
    method: FetchMethods.post
  },
  getBookingGeneralDetails: {
    getUrl: ({ bookingId }) =>
      getNoddiUrl({
        path: `${apiVersion}bookings/${bookingId}/general-details/`
      }),
    method: FetchMethods.get
  },
  getBookingRouteDetails: {
    getUrl: ({ bookingId }) =>
      getNoddiUrl({
        path: `${apiVersion}bookings/${bookingId}/route-details/`
      }),
    method: FetchMethods.get
  },
  getBookingOrder: {
    getUrl: ({ bookingId }) =>
      getNoddiUrl({
        path: `${apiVersion}bookings/${bookingId}/order/`
      }),
    method: FetchMethods.get
  },
  getGroupMembersById: {
    getUrl: ({ groupId }) =>
      getNoddiUrl({
        path: `${apiVersion}user-groups/list-members/${groupId}/`
      }),
    method: FetchMethods.get
  },
  postAddDiscountToOrder: {
    getUrl: ({ orderId }) =>
      getNoddiUrl({
        path: `${apiVersion}orders/${orderId}/add-discount/`
      }),
    getBody: (data) => data,
    method: FetchMethods.post
  },
  getCampaigns: {
    getUrl: (queryParams) =>
      getNoddiUrl({
        path: `${apiVersion}campaigns/`,
        queryParams
      }),
    method: FetchMethods.get
  },
  getCampaign: {
    getUrl: ({ id }) =>
      getNoddiUrl({
        path: `${apiVersion}campaigns/${id}/`
      }),
    method: FetchMethods.get
  },
  postCampaign: {
    getUrl: () =>
      getNoddiUrl({
        path: `${apiVersion}campaigns/`
      }),
    getBody: (data) => data,
    method: FetchMethods.post
  },
  patchCampaign: {
    getUrl: ({ id }) =>
      getNoddiUrl({
        path: `${apiVersion}campaigns/${id}/`
      }),
    getBody: (data) => data,
    method: FetchMethods.patch
  },
  getCouponGroups: {
    getUrl: () =>
      getNoddiUrl({
        path: `${apiVersion}coupon-groups/`
      }),
    method: FetchMethods.get
  },
  getPaginatedCouponGroups: {
    getUrl: (queryParams) =>
      getNoddiUrl({
        path: `${apiVersion}coupon-groups/`,
        queryParams
      }),
    method: FetchMethods.get
  },
  getCouponGroup: {
    getUrl: ({ id }) =>
      getNoddiUrl({
        path: `${apiVersion}coupon-groups/${id}/`
      }),
    method: FetchMethods.get
  },
  postCouponGroup: {
    getUrl: () =>
      getNoddiUrl({
        path: `${apiVersion}coupon-groups/`
      }),
    getBody: (data) => data,
    method: FetchMethods.post
  },
  patchCouponGroup: {
    getUrl: ({ id }) =>
      getNoddiUrl({
        path: `${apiVersion}coupon-groups/${id}/`
      }),
    getBody: (data) => data,
    method: FetchMethods.patch
  },
  getCouponTemplates: {
    getUrl: () =>
      getNoddiUrl({
        path: `${apiVersion}coupon-templates/`
      }),
    method: FetchMethods.get
  },
  getPaginatedCouponTemplates: {
    getUrl: (queryParams) =>
      getNoddiUrl({
        path: `${apiVersion}coupon-templates/`,
        queryParams
      }),
    method: FetchMethods.get
  },
  getCouponTemplate: {
    getUrl: ({ id }) =>
      getNoddiUrl({
        path: `${apiVersion}coupon-templates/${id}/`
      }),
    method: FetchMethods.get
  },
  postCouponTemplate: {
    getUrl: () =>
      getNoddiUrl({
        path: `${apiVersion}coupon-templates/`
      }),
    getBody: (data) => data,
    method: FetchMethods.post
  },
  patchCouponTemplate: {
    getUrl: ({ id }) =>
      getNoddiUrl({
        path: `${apiVersion}coupon-templates/${id}/`
      }),
    getBody: (data) => data,
    method: FetchMethods.patch
  },
  getPaginatedCoupons: {
    getUrl: (queryParams) =>
      getNoddiUrl({
        path: `${apiVersion}coupons/`,
        queryParams
      }),
    method: FetchMethods.get
  },
  getPaginatedUserGroupCoupons: {
    getUrl: ({ id, ...queryParams }) =>
      getNoddiUrl({
        path: `${apiVersion}user-groups/${id}/coupons/`,
        queryParams
      }),
    method: FetchMethods.get
  },
  getCoupon: {
    getUrl: ({ id }) =>
      getNoddiUrl({
        path: `${apiVersion}coupons/${id}/`
      }),
    method: FetchMethods.get
  },
  postCoupon: {
    getUrl: () =>
      getNoddiUrl({
        path: `${apiVersion}coupons/`
      }),
    getBody: (data) => data,
    method: FetchMethods.post
  },
  patchCoupon: {
    getUrl: ({ id }) =>
      getNoddiUrl({
        path: `${apiVersion}coupons/${id}/`
      }),
    getBody: (data) => data,
    method: FetchMethods.patch
  },
  getCampaignRewardLevels: {
    getUrl: () =>
      getNoddiUrl({
        path: `${apiVersion}campaign-reward-levels/`
      }),
    method: FetchMethods.get
  },
  getPaginatedCampaignRewardLevels: {
    getUrl: (queryParams) =>
      getNoddiUrl({
        path: `${apiVersion}campaign-reward-levels/`,
        queryParams
      }),
    method: FetchMethods.get
  },
  getCampaignRewardLevel: {
    getUrl: ({ id }) =>
      getNoddiUrl({
        path: `${apiVersion}campaign-reward-levels/${id}/`
      }),
    method: FetchMethods.get
  },
  postCampaignRewardLevel: {
    getUrl: () =>
      getNoddiUrl({
        path: `${apiVersion}campaign-reward-levels/`
      }),
    getBody: (data) => data,
    method: FetchMethods.post
  },
  patchCampaignRewardLevel: {
    getUrl: ({ id }) =>
      getNoddiUrl({
        path: `${apiVersion}campaign-reward-levels/${id}/`
      }),
    getBody: (data) => data,
    method: FetchMethods.patch
  },
  getPaginatedUserGroups: {
    getUrl: (queryParams) =>
      getNoddiUrl({
        path: `${apiVersion}user-groups/`,
        queryParams
      }),
    method: FetchMethods.get
  },
  getUserGroup: {
    getUrl: ({ id }) =>
      getNoddiUrl({
        path: `${apiVersion}user-groups/${id}/`
      }),
    method: FetchMethods.get
  },
  postUserGroup: {
    getUrl: () =>
      getNoddiUrl({
        path: `${apiVersion}user-groups/`
      }),
    getBody: (data) => data,
    method: FetchMethods.post
  },
  patchUserGroup: {
    getUrl: ({ id }) =>
      getNoddiUrl({
        path: `${apiVersion}user-groups/${id}/`
      }),
    getBody: (data) => data,
    method: FetchMethods.patch
  },
  postOrganization: {
    getUrl: () =>
      getNoddiUrl({
        path: `${apiVersion}organizations/`
      }),
    getBody: (data) => data,
    method: FetchMethods.post
  },
  patchOrganization: {
    getUrl: ({ id }) =>
      getNoddiUrl({
        path: `${apiVersion}organizations/${id}/`
      }),
    getBody: (data) => data,
    method: FetchMethods.patch
  },
  getPaginatedSalesItems: {
    getUrl: (queryParams) =>
      getNoddiUrl({
        path: `${apiVersion}sales-items/`,
        queryParams
      }),
    method: FetchMethods.get
  },
  getSalesItem: {
    getUrl: ({ id }) =>
      getNoddiUrl({
        path: `${apiVersion}sales-items/${id}/`
      }),
    method: FetchMethods.get
  },
  getWheelStoragePickupBookingItems: {
    getUrl: (queryParams) =>
      getNoddiUrl({
        path: `${apiVersion}booking-items/wheel-storage-pickups/`,
        queryParams
      }),
    method: FetchMethods.get
  },
  getWheelStorageAgreements: {
    getUrl: (queryParams) =>
      getNoddiUrl({
        path: `${apiVersion}tire-hotel-contracts/`,
        queryParams
      }),
    method: FetchMethods.get
  },
  postSalesItem: {
    getUrl: () =>
      getNoddiUrl({
        path: `${apiVersion}sales-items/`
      }),
    getBody: (data) => data,
    method: FetchMethods.post
  },
  patchSalesItem: {
    getUrl: ({ id }) =>
      getNoddiUrl({
        path: `${apiVersion}sales-items/${id}/`
      }),
    getBody: (data) => data,
    method: FetchMethods.patch
  },
  getServiceCategoryList: {
    getUrl: () =>
      getNoddiUrl({
        path: `${apiVersion}service-categories/`
      }),
    method: FetchMethods.get
  },
  getBookingCohorts: {
    getUrl: () =>
      getNoddiUrl({
        path: `${apiVersion}bookings/cohorts/`
      }),
    method: FetchMethods.get
  },
  getCarSizesList: {
    getUrl: () =>
      getNoddiUrl({
        path: `${apiVersion}car-sizes/`
      }),
    method: FetchMethods.get
  },
  getSalesItemBookingCategories: {
    getUrl: () =>
      getNoddiUrl({
        path: `${apiVersion}sales-item-booking-categories/`
      }),
    method: FetchMethods.get
  },
  getSalesItemsTypes: {
    getUrl: () =>
      getNoddiUrl({
        path: `${apiVersion}sales-items/types/`
      }),
    method: FetchMethods.get
  },
  patchCarDetails: {
    getUrl: ({ id }) =>
      getNoddiUrl({
        path: `${apiVersion}cars/${id}/`
      }),
    getBody: (data) => data,
    method: FetchMethods.patch
  },
  getOrderTransaction: {
    getUrl: ({ id }) =>
      getNoddiUrl({
        path: `${apiVersion}order-transactions/${id}/`
      }),
    method: FetchMethods.get
  },
  postCaptureOrderTransactionDintero: {
    getUrl: ({ id }) =>
      getNoddiUrl({
        path: `${apiVersion}order-transactions-dintero/${id}/capture/`
      }),
    method: FetchMethods.post
  },
  postRefundOrderTransactionDintero: {
    getUrl: ({ id }) =>
      getNoddiUrl({
        path: `${apiVersion}order-transactions-dintero/${id}/refund/`
      }),
    method: FetchMethods.post
  },
  postPartialRefundOrderTransactionDintero: {
    getUrl: ({ id }) =>
      getNoddiUrl({
        path: `${apiVersion}order-transactions-dintero/${id}/partial-refund/`
      }),
    getBody: (data) => data,
    method: FetchMethods.post
  },
  postVoidOrderTransactionDintero: {
    getUrl: ({ id }) =>
      getNoddiUrl({
        path: `${apiVersion}order-transactions-dintero/${id}/void/`
      }),
    method: FetchMethods.post
  },
  getServiceCategory: {
    getUrl: ({ id }) =>
      getNoddiUrl({
        path: `${apiVersion}service-categories/${id}/`
      }),
    method: FetchMethods.get
  },
  postServiceCategory: {
    getUrl: () =>
      getNoddiUrl({
        path: `${apiVersion}service-categories/`
      }),
    getBody: (data) => data,
    method: FetchMethods.post
  },
  postBookingTimeWindowTemplate: {
    getUrl: () =>
      getNoddiUrl({
        path: `${apiVersion}booking-time-window-templates/`
      }),
    getBody: (data) => data,
    method: FetchMethods.post
  },
  deleteBookingTimeWindowTemplate: {
    getUrl: ({ id }) =>
      getNoddiUrl({
        path: `${apiVersion}booking-time-window-templates/${id}/`
      }),
    getBody: (data) => data,
    method: FetchMethods.delete
  },
  getBookingTimeWindowTemplate: {
    getUrl: ({ id }) =>
      getNoddiUrl({
        path: `${apiVersion}booking-time-window-templates/${id}/`
      }),
    method: FetchMethods.get
  },
  patchBookingTimeWindowTemplate: {
    getUrl: ({ id }) =>
      getNoddiUrl({
        path: `${apiVersion}booking-time-window-templates/${id}/`
      }),
    getBody: (data) => data,
    method: FetchMethods.patch
  },
  patchServiceCategory: {
    getUrl: ({ id }) =>
      getNoddiUrl({
        path: `${apiVersion}service-categories/${id}/`
      }),
    getBody: (data) => data,
    method: FetchMethods.patch
  },
  getCarWheel: {
    getUrl: ({ id }) =>
      getNoddiUrl({
        path: `${apiVersion}car-wheels/${id}/`
      }),
    method: FetchMethods.get
  },
  getCarWheelsByIds: {
    getUrl: (queryParams) =>
      getNoddiUrl({
        path: `${apiVersion}car-wheels/`,
        queryParams
      }),
    method: FetchMethods.get
  },
  getCarWheelSet: {
    getUrl: ({ id }) =>
      getNoddiUrl({
        path: `${apiVersion}car-wheel-sets/${id}/`
      }),
    method: FetchMethods.get
  },
  getCarWheelSetStorageInformation: {
    getUrl: ({ id }) =>
      getNoddiUrl({
        path: `${apiVersion}car-wheel-sets/${id}/storage-information/`
      }),
    method: FetchMethods.get
  },
  patchCarWheelSet: {
    getUrl: ({ id }) =>
      getNoddiUrl({
        path: `${apiVersion}car-wheel-sets/${id}/`
      }),
    getBody: (data) => data,
    method: FetchMethods.patch
  },
  postCarWheelStorageObjects: {
    getUrl: () =>
      getNoddiUrl({
        path: `${apiVersion}car-wheel-storage-objects/`
      }),
    getBody: (data) => data,
    method: FetchMethods.post
  },
  getPaginatedStorageObjectsForCarWheel: {
    getUrl: ({ id, ...queryParams }) =>
      getNoddiUrl({
        path: `${apiVersion}car-wheels/${id}/storage-objects/`,
        queryParams
      }),
    method: FetchMethods.get
  },
  getStorageFacilities: {
    getUrl: () =>
      getNoddiUrl({
        path: `${apiVersion}storage-facilities/`
      }),
    method: FetchMethods.get
  },
  getPaginatedStorageFacilities: {
    getUrl: (queryParams) =>
      getNoddiUrl({
        path: `${apiVersion}storage-facilities/`,
        queryParams
      }),
    method: FetchMethods.get
  },
  getStorageFacility: {
    getUrl: ({ id }) =>
      getNoddiUrl({
        path: `${apiVersion}storage-facilities/${id}/`
      }),
    method: FetchMethods.get
  },
  postStorageFacility: {
    getUrl: () =>
      getNoddiUrl({
        path: `${apiVersion}storage-facilities/`
      }),
    getBody: (data) => data,
    method: FetchMethods.post
  },
  patchStorageFacility: {
    getUrl: ({ id }) =>
      getNoddiUrl({
        path: `${apiVersion}storage-facilities/${id}/`
      }),
    getBody: (data) => data,
    method: FetchMethods.patch
  },
  getStorageAreasOverview: {
    getUrl: (queryParams) =>
      getNoddiUrl({
        path: `${apiVersion}storage-areas/overview/`,
        queryParams
      }),
    method: FetchMethods.get
  },
  getStorageAreasForFacility: {
    getUrl: ({ storageFacilityId, ...queryParams }) =>
      getNoddiUrl({
        path: `${apiVersion}storage-facilities/${storageFacilityId}/storage-areas/`,
        queryParams
      }),
    method: FetchMethods.get
  },
  getPaginatedStorageAreasForFacility: {
    getUrl: ({ id, ...queryParams }) =>
      getNoddiUrl({
        path: `${apiVersion}storage-facilities/${id}/storage-areas/`,
        queryParams
      }),
    method: FetchMethods.get
  },
  getStorageArea: {
    getUrl: ({ id }) =>
      getNoddiUrl({
        path: `${apiVersion}storage-areas/${id}/`
      }),
    method: FetchMethods.get
  },
  getPaginatedStorageObjectGroupsForFacility: {
    getUrl: ({ storageFacilityId, ...queryParams }) =>
      getNoddiUrl({
        path: `${apiVersion}storage-facilities/${storageFacilityId}/storage-object-groups/`,
        queryParams
      }),
    method: FetchMethods.get
  },
  getPaginatedCarWheelSetsForFacility: {
    getUrl: ({ storageFacilityId, ...queryParams }) =>
      getNoddiUrl({
        path: `${apiVersion}storage-facilities/${storageFacilityId}/car-wheel-sets/`,
        queryParams
      }),
    method: FetchMethods.get
  },
  getCapacityContributionTypes: {
    getUrl: () =>
      getNoddiUrl({
        path: `${apiVersion}capacity-contribution-types/`
      }),
    method: FetchMethods.get
  },
  postStorageArea: {
    getUrl: () =>
      getNoddiUrl({
        path: `${apiVersion}storage-areas/`
      }),
    getBody: (data) => data,
    method: FetchMethods.post
  },
  patchStorageArea: {
    getUrl: ({ id }) =>
      getNoddiUrl({
        path: `${apiVersion}storage-areas/${id}/`
      }),
    getBody: (data) => data,
    method: FetchMethods.patch
  },
  deleteStorageArea: {
    getUrl: ({ id }) =>
      getNoddiUrl({
        path: `${apiVersion}storage-areas/${id}/`
      }),
    method: FetchMethods.delete
  },
  getPaginatedStorageUnits: {
    getUrl: (queryParams) =>
      getNoddiUrl({
        path: `${apiVersion}storage-units/`,
        queryParams
      }),
    method: FetchMethods.get
  },
  getStorageUnit: {
    getUrl: ({ id }) =>
      getNoddiUrl({
        path: `${apiVersion}storage-units/${id}/`
      }),
    method: FetchMethods.get
  },
  postStorageUnit: {
    getUrl: () =>
      getNoddiUrl({
        path: `${apiVersion}storage-units/`
      }),
    getBody: (data) => data,
    method: FetchMethods.post
  },
  patchStorageUnit: {
    getUrl: ({ id }) =>
      getNoddiUrl({
        path: `${apiVersion}storage-units/${id}/`
      }),
    getBody: (data) => data,
    method: FetchMethods.patch
  },
  deleteStorageUnit: {
    getUrl: ({ id }) =>
      getNoddiUrl({
        path: `${apiVersion}storage-units/${id}/`
      }),
    method: FetchMethods.delete
  },
  getPaginatedStorageUnitsForArea: {
    getUrl: ({ id, ...queryParams }) =>
      getNoddiUrl({
        path: `${apiVersion}storage-areas/${id}/storage-units/`,
        queryParams
      }),
    method: FetchMethods.get
  },
  getStorageUnitsForAreaFromIdentifier: {
    getUrl: ({ identifier, ...queryParams }) =>
      getNoddiUrl({
        path: `${apiVersion}storage-areas/identifier/${identifier}/storage-units/`,
        queryParams
      }),
    method: FetchMethods.get
  },
  getStorageUnitTypes: {
    getUrl: () =>
      getNoddiUrl({
        path: `${apiVersion}storage-unit-types/`
      }),
    method: FetchMethods.get
  },
  postPickUpCarWheelSet: {
    getUrl: ({ id }) =>
      getNoddiUrl({
        path: `${apiVersion}car-wheel-sets/${id}/pick-up/`
      }),
    getBody: (data) => data,
    method: FetchMethods.post
  },
  getPaginatedCarWheelSetForCar: {
    getUrl: ({ id, ...queryParams }) =>
      getNoddiUrl({
        path: `${apiVersion}cars/${id}/wheel-sets/`,
        queryParams
      }),
    method: FetchMethods.get
  },
  getCarWheelSetForCar: {
    getUrl: ({ id }) =>
      getNoddiUrl({
        path: `${apiVersion}cars/${id}/wheel-sets/`
      }),
    method: FetchMethods.get
  },
  getCoreCountries: {
    getUrl: () =>
      getNoddiUrl({
        path: `${apiVersion}countries/`
      }),
    method: FetchMethods.get
  },
  getPaginatedUserGroupMembershipProgram: {
    getUrl: ({ id, ...queryParams }) =>
      getNoddiUrl({
        path: `${apiVersion}user-groups/${id}/membership-programs/`,
        queryParams
      }),
    method: FetchMethods.get
  },
  getPaginatedCapacityContributions: {
    getUrl: (queryParams) =>
      getNoddiUrl({
        path: `${apiVersion}capacity-contributions/`,
        queryParams
      }),
    method: FetchMethods.get
  },
  getDeliveryWindowsWithCapacity: {
    getUrl: (queryParams) =>
      getNoddiUrl({
        path: `${apiVersion}delivery-windows/capacity/`,
        queryParams
      }),
    method: FetchMethods.get
  },
  getCapacityContributionOverview: {
    getUrl: (queryParams) =>
      getNoddiUrl({
        path: `${apiVersion}capacity-contributions/overview/`,
        queryParams
      }),
    method: FetchMethods.get
  },

  getBookingItemsOverview: {
    getUrl: (queryParams) =>
      getNoddiUrl({
        path: `${apiVersion}booking-items/overview/`,
        queryParams
      }),
    method: FetchMethods.get
  },
  getCapacityContributionStatuses: {
    getUrl: () =>
      getNoddiUrl({
        path: `${apiVersion}capacity-contributions/statuses/`
      }),
    method: FetchMethods.get
  },
  getCapacityContributionsForRoute: {
    getUrl: (queryParams) =>
      getNoddiUrl({
        path: `${apiVersion}capacity-contributions/for-route/`,
        queryParams
      }),
    method: FetchMethods.get
  },
  getPaginatedServiceCategories: {
    getUrl: (queryParams) =>
      getNoddiUrl({
        path: `${apiVersion}service-categories/`,
        queryParams
      }),
    method: FetchMethods.get
  },
  deleteOrderLine: {
    getUrl: ({ orderLineId }) =>
      getNoddiUrl({
        path: `${apiVersion}order-lines/${orderLineId}/`
      }),
    getBody: (data) => data,
    method: FetchMethods.delete
  },
  getPaginatedRouteProblems: {
    getUrl: (queryParams) =>
      getNoddiUrl({
        path: `${apiVersion}route-problems/`,
        queryParams
      }),
    method: FetchMethods.get
  },
  getRouteProblem: {
    getUrl: ({ routeProblemId }) =>
      getNoddiUrl({
        path: `${apiVersion}route-problems/${routeProblemId}/`
      }),
    method: FetchMethods.get
  },
  getPaginatedCapacityContributionsForRoutePlanner: {
    getUrl: (queryParams) =>
      getNoddiUrl({
        path: `${apiVersion}capacity-contributions/for-route-planner/`,
        queryParams
      }),
    method: FetchMethods.get
  },
  getRoutePlannerBookings: {
    getUrl: (queryParams) =>
      getNoddiUrl({
        path: `${apiVersion}bookings/for-route-planner/`,
        queryParams
      }),
    method: FetchMethods.get
  },
  patchServiceDepartmentCapacity: {
    getUrl: ({ serviceDepartmentId }) =>
      getNoddiUrl({
        path: `${apiVersion}service-departments/${serviceDepartmentId}/update-capacity/`
      }),
    getBody: (data) => data,
    method: FetchMethods.patch
  },
  getRouteProblemValidation: {
    getUrl: (queryParams) =>
      getNoddiUrl({
        path: `${apiVersion}route-problems/validate/`,
        queryParams
      }),
    method: FetchMethods.get
  },
  postStartRoutePlanner: {
    getUrl: () =>
      getNoddiUrl({
        path: `${apiVersion}route-problems/start-route-planner/`
      }),
    getBody: (data) => data,
    method: FetchMethods.post
  },
  postSelectRouteSolution: {
    getUrl: ({ id }) =>
      getNoddiUrl({
        path: `${apiVersion}route-solutions/${id}/select/`
      }),
    method: FetchMethods.post
  },
  getPaginatedBookingOverviewDetailsList: {
    getUrl: (queryParams) =>
      getNoddiUrl({
        path: `${apiVersion}bookings/for-calendar/`,
        queryParams
      }),
    method: FetchMethods.get
  },
  getAdminDashboardBookings: {
    getUrl: (queryParams) =>
      getNoddiUrl({
        path: `${apiVersion}bookings/admin-dashboard-data/`,
        queryParams
      }),
    method: FetchMethods.get
  },
  postCancelBooking: {
    getUrl: ({ id }) =>
      getNoddiUrl({
        path: `${apiVersion}bookings/${id}/cancel/`
      }),
    getBody: (data) => data,
    method: FetchMethods.post
  },
  postChangeCapacityContribution: {
    getUrl: ({ routeId }) =>
      getNoddiUrl({
        path: `${apiVersion}routes/${routeId}/change-capacity-contribution/`
      }),
    getBody: (data) => data,
    method: FetchMethods.post
  },
  getCapacityIntervals: {
    getUrl: (queryParams) =>
      getNoddiUrl({
        path: `${apiVersion}capacity-intervals/`,
        queryParams
      }),
    method: FetchMethods.get
  },
  postCreateCarTireQuotes: {
    getUrl: () =>
      getNoddiUrl({
        path: `${apiVersion}car-tire-quotes/`
      }),
    getBody: (data) => data,
    method: FetchMethods.post
  },
  getFeedbacks: {
    getUrl: (queryParams) =>
      getNoddiUrl({
        path: `${apiVersion}service-feedbacks/`,
        queryParams
      }),

    method: FetchMethods.get
  },
  patchFeedback: {
    getUrl: ({ id }) =>
      getNoddiUrl({
        path: `${apiVersion}service-feedbacks/${id}/`
      }),
    getBody: (data) => data,
    method: FetchMethods.patch
  },
  getRoutesForMapStatus: {
    getUrl: (queryParams) =>
      getNoddiUrl({
        path: `${apiVersion}routes/for-map/`,
        queryParams
      }),

    method: FetchMethods.get
  },
  getTireClassificationFilters: {
    getUrl: () =>
      getNoddiUrl({
        path: `${apiVersion}cars/filter/tires/classifications/`
      }),

    method: FetchMethods.get
  },
  getTireConstructionFilters: {
    getUrl: () =>
      getNoddiUrl({
        path: `${apiVersion}cars/filter/tires/construction/`
      }),

    method: FetchMethods.get
  },
  getTireEuClassFilters: {
    getUrl: () =>
      getNoddiUrl({
        path: `${apiVersion}cars/filter/tires/eu-classes/`
      }),

    method: FetchMethods.get
  },
  getTireLoadIndexFilters: {
    getUrl: () =>
      getNoddiUrl({
        path: `${apiVersion}cars/filter/tires/load-index/`
      }),

    method: FetchMethods.get
  },
  getTireRollingResistanceFilters: {
    getUrl: () =>
      getNoddiUrl({
        path: `${apiVersion}cars/filter/tires/rolling-resistance/`
      }),

    method: FetchMethods.get
  },
  getTireSpeedIndexFilters: {
    getUrl: () =>
      getNoddiUrl({
        path: `${apiVersion}cars/filter/tires/speed-index/`
      }),

    method: FetchMethods.get
  },
  getTireSoundClassFilters: {
    getUrl: () =>
      getNoddiUrl({
        path: `${apiVersion}cars/filter/tires/sound-classes/`
      }),

    method: FetchMethods.get
  },
  getTireQuoteSalesItems: {
    getUrl: (queryParams) =>
      getNoddiUrl({
        path: `${apiVersion}car-tire-quotes/sales-items/`,
        queryParams
      }),
    method: FetchMethods.get
  },
  getSalesItemCategories: {
    getUrl: () =>
      getNoddiUrl({
        path: `${apiVersion}sales-items/categories/`
      }),

    method: FetchMethods.get
  },
  getMinimalSalesItems: {
    getUrl: (queryParams) =>
      getNoddiUrl({
        path: `${apiVersion}sales-items/filter-list/`,
        queryParams
      }),

    method: FetchMethods.get
  },
  getTireInventoryList: {
    getUrl: (queryParams) =>
      getNoddiUrl({
        path: `${apiVersion}shop/tire-inventory/`,
        queryParams
      }),

    method: FetchMethods.get
  },
  postTireToInventory: {
    getUrl: (queryParams) =>
      getNoddiUrl({
        path: `${apiVersion}shop/tire-inventory/`,
        queryParams
      }),
    getBody: (data) => data,
    method: FetchMethods.post
  },
  getTireShopSuppliers: {
    getUrl: () =>
      getNoddiUrl({
        path: `${apiVersion}shop/tire-suppliers/`
      }),

    method: FetchMethods.get
  },
  getCarSpecificationTireFilters: {
    getUrl: () =>
      getNoddiUrl({
        path: `${apiVersion}cars/filter/tires/specifications/`
      }),

    method: FetchMethods.get
  },
  patchTireQuoteOffer: {
    getUrl: ({ carTireQuoteId }) =>
      getNoddiUrl({
        path: `${apiVersion}car-tire-quotes/${carTireQuoteId}/`
      }),
    method: FetchMethods.patch,
    getBody: (data) => data
  },
  patchCarTireQuoteItemTire: {
    getUrl: ({ id }) =>
      getNoddiUrl({
        path: `${apiVersion}car-tire-quote-item-tire/${id}/`
      }),
    method: FetchMethods.patch,
    getBody: (data) => data
  },
  postAddCarToUserGroup: {
    getUrl: ({ userGroupId }) =>
      getNoddiUrl({
        path: `${apiVersion}user-groups/${userGroupId}/add-cars/`
      }),
    method: FetchMethods.post,
    getBody: (data) => data
  },
  postRemoveCarsFromUserGroup: {
    getUrl: ({ userGroupId, ...rest }) =>
      getNoddiUrl({
        path: `${apiVersion}user-groups/${userGroupId}/remove-cars/`,
        queryParams: rest
      }),
    method: FetchMethods.delete
  },
  getTireBrands: {
    getUrl: () =>
      getNoddiUrl({
        path: `${apiVersion}shop/tire-brands/`
      }),

    method: FetchMethods.get
  },
  postCreateTire: {
    getUrl: () =>
      getNoddiUrl({
        path: `${apiVersion}shop/tires/`
      }),
    method: FetchMethods.post,
    getBody: (data) => data
  },
  postOrderInventory: {
    getUrl: () =>
      getNoddiUrl({
        path: `${apiVersion}shop/inventory-order/`
      }),
    method: FetchMethods.post,
    getBody: (data) => data
  },
  patchInventoryOrderToQuoteItemTire: {
    getUrl: ({ quoteItemTireId }) =>
      getNoddiUrl({
        path: `${apiVersion}car-tire-quote-item-tire/${quoteItemTireId}/`
      }),
    method: FetchMethods.patch,
    getBody: (data) => data
  },
  patchCarTireQuote: {
    getUrl: ({ carTireQuoteId }) =>
      getNoddiUrl({
        path: `${apiVersion}car-tire-quotes/${carTireQuoteId}/`
      }),
    method: FetchMethods.patch,
    getBody: (data) => data
  },
  getBookingAddressCities: {
    getUrl: () =>
      getNoddiUrl({
        path: `${apiVersion}addresses/cities/`
      }),
    method: FetchMethods.get
  },
  getBookingAddressZipCodes: {
    getUrl: () =>
      getNoddiUrl({
        path: `${apiVersion}addresses/zip-codes/`
      }),
    method: FetchMethods.get
  }
} as const;

export interface AdminServerRequests {
  getServiceDepartmentsOverview: {
    input: ServiceDepartmentOverviewInput;
    output: ServiceDepartmentOverview[];
  };
  getIsShopClosed: {
    input: { date: string | Date; serviceDepartmentId: number };
    output: boolean;
  };
  getServiceWorkerAddressPair: {
    input: { id: string | number };
    output: WorkerAddressPair;
  };
  patchServiceWorkerAddressPair: {
    input: WorkerAddressPairUpdateInput;
    output: undefined;
  };
  getSuggestedServiceDepartmentAreas: {
    input: { id: string | number; maxDrivingTimeInSeconds?: number };
    output: { id: number }[];
  };
  getRoutesOverview: {
    input: {
      date?: string | null;
      serviceDepartmentId: number | string;
    };
    output: RouteInSolution[];
  };
  postWorker: {
    input: { userId: number; languageCodes: string[] };
    output: AxiosResponse<{ id: string }>;
  };
  getWorkerContractAddressPairs: {
    input: { serviceWorkerContractId: string | number };
    output: WorkerAddressPairList[];
  };
  patchWorker: {
    input: { id: string; languageCodes: string[]; isActive: boolean; nickname: string; skillIds: number[] };
    output: undefined;
  };
  getWorker: {
    input: { id: string | number | undefined };
    output: ServiceWorker;
  };
  getPaginatedWorkerContracts: {
    input: PaginatedInput & { serviceDepartmentId: number; isActive?: boolean };
    output: PaginatedResponse<WorkerContractBase[]>;
  };
  getWorkerContract: {
    input: { id: string };
    output: WorkerContract;
  };
  postWorkerContract: {
    input: {
      serviceDepartmentId: number;
      contractStartDate: string;
      contractEndDate: string | null;
      serviceWorkerId: number;
    };
    output: AxiosResponse<{ id: string }>;
  };
  patchWorkerContract: {
    input: WorkerContractPatch;
    output: undefined;
  };
  getPaginatedWorkers: {
    input: PaginatedInput & { serviceDepartmentIds?: number[] };
    output: PaginatedResponse<ServiceWorkersBase[]>;
  };
  getWorkers: {
    input: {
      serviceDepartmentIds?: number[];
    };
    output: ServiceWorkersBase[];
  };
  getPaginatedDeliveryWindowGroups: {
    input: PaginatedInput & { serviceDepartmentId: number };
    output: PaginatedResponse<{ id: string; name: string; createdAt: string }[]>;
  };
  getDeliveryWindowGroup: {
    input: { id: string };
    output: DeliveryWindowGroup;
  };
  postDeliveryWindowGroup: {
    input: { name: string; serviceDepartmentId: number };
    output: AxiosResponse<{ id: string }>;
  };
  getServiceDepartmentArea: {
    input: { serviceDepartmentId: number; serviceAreaId: string };
    output: ServiceDepartmentArea;
  };
  patchServiceDepartmentArea: {
    input: { serviceDepartmentId: number; serviceAreaId: string; serviceCategoryIds?: number[] };
    output: unknown;
  };
  getServiceDepartmentAreaCategory: {
    input: { serviceDepartmentId: number; serviceAreaId: string; id: string };
    output: ServiceDepartmentAreaCategory;
  };
  patchServiceDepartmentAreaCategory: {
    input: { serviceDepartmentId: number; serviceAreaId: string; id: string; deliveryWindowGroupIds?: number[] };
    output: unknown;
  };
  getRouteSettlements: {
    input: CommonRouteSettlementsInput;
    output: PaginatedResponse<RouteSettlementInfo[]>;
  };
  getRouteSettlement: {
    input: { id: string };
    output: RouteSettlement;
  };
  patchRouteSettlement: {
    input: {
      id: string;
      extraPayment: string;
      extraPaymentDescription: string;
    };
    output: RouteSettlement;
  };
  postRouteSettlementsToExcel: {
    input: { routeSettlementIds: number[] };
    output: ArrayBufferLike;
  };
  postSetPaidAtRouteSettlements: {
    input: { routeSettlementIds: number[]; paidAt: string };
    output: undefined;
  };
  postMarkWheelsAsPickedUp: {
    input: MarkWheelStoragePickupAsComplete;
    output: unknown;
  };
  getUnpaidBookings: {
    input: {
      fromDate?: string | null;
      toDate?: string | null;
      pageSize: number;
      page: number;
    };
    output: PaginatedResponse<UnpaidBooking[]>;
  };
  getUnpaidBookingsToExcel: {
    input: {
      fromDate?: string | null;
      toDate?: string | null;
    };
    output: ArrayBufferLike;
  };
  postCreateInvoiceForUnpaidBookings: {
    input: {
      fromDate?: string | null;
      toDate?: string | null;
    };
    output: undefined;
  };
  getTotalUnpaidBookingsAmount: {
    input: {
      fromDate?: string | null;
      toDate?: string | null;
    };
    output: number;
  };
  getRouteSolution: {
    input: {
      routeSolutionId?: string;
    };
    output: RouteSolution;
  };
  getCalendarBookings: {
    input: {
      fromDate: string | null;
      toDate: string | null;
      serviceDepartmentIds: number | null;
    };
    output: BookingCalendarData;
  };
  getServiceAreasForMap: {
    input: { excludeIds?: number[] };
    output: ServiceAreaForMap[];
  };
  getServiceAreasForMapForServiceDepartment: {
    input: { serviceCategoryIds?: number[]; deliveryWindowGroupIds?: number[]; serviceDepartmentId: number };
    output: ServiceAreasForServiceDepartmentForMap[];
  };
  getPaginatedServiceAreas: {
    input: PaginatedInput;
    output: PaginatedResponse<ServiceAreaListItemV2[]>;
  };
  getServiceArea: {
    input: { id: string | number };
    output: ServiceAreaDetail;
  };
  getBrand: {
    input: { id: string | number };
    output: Brand;
  };
  postServiceArea: {
    input: ServiceAreaCreateInput;
    output: AxiosResponse<ServiceAreaDetail>;
  };
  patchServiceArea: {
    input: ServiceAreaUpdateInput;
    output: ServiceAreaDetail;
  };
  patchBrand: {
    input: {
      id: string;
      name?: string;
      logo?: File | null;
    };
    output: Brand;
  };
  getServiceDepartments: {
    input: undefined;
    output: ServiceDepartmentList[];
  };
  getPaginatedServiceDepartments: {
    input: PaginatedInput;
    output: PaginatedResponse<ServiceDepartmentList[]>;
  };
  getPaginatedBrands: {
    input: PaginatedInput;
    output: PaginatedResponse<Brand[]>;
  };
  getServiceDepartment: {
    input: { id: number };
    output: ServiceDepartment;
  };
  postServiceDepartment: {
    input: {
      userGroupId: number;
      currency: string;
      timeZone: string;
      country: string;
      brandId: number;
      serviceOrganizationId: number;
    };
    output: AxiosResponse<ServiceDepartment>;
  };
  postBrand: {
    input: { name: string; logo: File };
    output: AxiosResponse<Brand>;
  };
  patchServiceDepartment: {
    input: {
      id: number;
      serviceCategoryIds?: number[];
      serviceAreaIds?: number[];
      currency?: string;
      timeZone?: string;
      countryCode?: string;
      brandId?: number;
    };
    output: ServiceDepartment;
  };
  getBookingGeneralDetails: {
    input: { bookingId: number };
    output: BookingGeneralDetails;
  };
  getBookingRouteDetails: {
    input: { bookingId: number };
    output: RouteOverview;
  };
  getBookingOrder: {
    input: { bookingId: number };
    output: Order;
  };
  getGroupMembersById: {
    input: { groupId: number };
    output: GroupInfo;
  };
  postAddDiscountToOrder: {
    input: {
      orderId: number;
      bookingItemId: number | null;
      salesItemId: number | null;
      description: string;
      price: number;
    };
    output: unknown;
  };
  getCampaigns: {
    input: PaginatedInput;
    output: PaginatedResponse<CampaignList[]>;
  };
  getCampaign: {
    input: { id: number };
    output: Campaign;
  };
  postCampaign: {
    input: {
      name: string;
      slug: string;
      userGroupId: number;
      couponGroupId: number;
      rewardLevelIds: number[];
      isForNewUserOnly: boolean;
    };
    output: Campaign;
  };
  patchCampaign: {
    input: {
      id: number;
      name: string;
      slug: string;
      userGroupId: number;
      couponGroupId: number;
      rewardLevelIds: number[];
      isForNewUserOnly: boolean;
    };
    output: Campaign;
  };
  getCouponGroups: {
    input: undefined;
    output: CouponGroupList[];
  };
  getPaginatedCouponGroups: {
    input: PaginatedInput;
    output: PaginatedResponse<CouponGroupList[]>;
  };
  getCouponGroup: {
    input: { id: number };
    output: CouponGroup;
  };
  postCouponGroup: {
    input: {
      nameInternal: string;
      descriptionPublic: string;
      expiresAt?: string | null;
      couponTemplateIds: number[];
    };
    output: CouponGroup;
  };
  patchCouponGroup: {
    input: {
      id: number;
      nameInternal: string;
      descriptionPublic: string;
      expiresAt?: string | null;
      couponTemplateIds: number[];
    };
    output: CouponGroup;
  };
  getCouponTemplates: {
    input: undefined;
    output: CouponTemplateList[];
  };
  getPaginatedCouponTemplates: {
    input: PaginatedInput;
    output: PaginatedResponse<CouponTemplateList[]>;
  };
  getCouponTemplate: {
    input: { id: number };
    output: CouponTemplate;
  };
  postCouponTemplate: {
    input: {
      nameInternal: string;
      namePublic: string;
      descriptionPublic: string;
      discount?: Money | null;
      discountPercentage?: number | null;
      expiryDaysAfterReceiving?: number | null;
      expiresAt?: string | null;
      validForSalesItemIds?: number[] | null;
    };
    output: CouponTemplate;
  };
  patchCouponTemplate: {
    input: {
      id: number;
      nameInternal: string;
      namePublic: string;
      descriptionPublic: string;
      discount?: Money | null;
      discountPercentage?: number | null;
      expiryDaysAfterReceiving?: number | null;
      expiresAt?: string | null;
      validForSalesItemIds?: number[] | null;
    };
    output: CouponTemplate;
  };
  getPaginatedCoupons: {
    input: PaginatedInput;
    output: PaginatedResponse<CouponList[]>;
  };
  getPaginatedUserGroupCoupons: {
    input: PaginatedInput & { id: number; includeUsed?: boolean; includeUsedOnBookingIds: number[] };
    output: PaginatedResponse<PublicCoupon[]>;
  };
  getCoupon: {
    input: { id: number };
    output: InternalCoupon;
  };
  postCoupon: {
    input: {
      nameInternal: string;
      namePublic: string;
      descriptionPublic: string;
      discount?: Money | null;
      discountPercentage?: number | null;
      expiresAt?: string | null;
      validForSalesItemIds?: number[] | null;
      userGroupId: number;
    };
    output: InternalCoupon;
  };
  patchCoupon: {
    input: {
      id: number;
      nameInternal: string;
      namePublic: string;
      descriptionPublic: string;
      discount?: Money | null;
      discountPercentage?: number | null;
      expiresAt?: string | null;
      validForSalesItemIds?: number[] | null;
    };
    output: InternalCoupon;
  };
  getCampaignRewardLevels: {
    input: undefined;
    output: RewardLevelList[];
  };
  getPaginatedCampaignRewardLevels: {
    input: PaginatedInput;
    output: PaginatedResponse<RewardLevelList[]>;
  };
  getCampaignRewardLevel: {
    input: { id: number };
    output: RewardLevel;
  };
  postCampaignRewardLevel: {
    input: {
      nameInternal: string;
      descriptionInternal: string;
      minNumResponses: number;
      couponGroupId: number;
    };
    output: RewardLevel;
  };
  patchCampaignRewardLevel: {
    input: {
      id: number;
      nameInternal: string;
      descriptionInternal: string;
      minNumResponses: number;
      couponGroupId: number;
    };
    output: RewardLevel;
  };
  getPaginatedUserGroups: {
    input: PaginatedInput;
    output: PaginatedResponse<UserGroupListItem[]>;
  };
  getUserGroup: {
    input: { id: number };
    output: UserGroup;
  };
  postUserGroup: {
    input: { name: string; userId: number };
    output: AxiosResponse<UserGroupListItem>;
  };
  patchUserGroup: {
    input: UserGroupPatchInput;
    output: UserGroup;
  };
  postOrganization: {
    input: OrganizationCreateInput;
    output: Organization;
  };
  patchOrganization: {
    input: OrganizationUpdateInput;
    output: Organization;
  };
  getPaginatedSalesItems: {
    input: PaginatedInput & { type?: number[] };
    output: PaginatedResponse<SalesItemListItem[]>;
  };
  getSalesItem: {
    input: { id: number };
    output: SalesItemProps;
  };
  postSalesItem: {
    input: SalesItemCreateInputType;
    output: SalesItemProps;
  };
  patchSalesItem: {
    input: SalesItemUpdateInputType;
    output: SalesItemProps;
  };
  getServiceCategoryList: {
    input: undefined;
    output: ServiceCategory[];
  };
  getBookingCohorts: {
    input: undefined;
    output: Cohort[];
  };
  getCarSizesList: {
    input: undefined;
    output: CarSize[];
  };
  getSalesItemBookingCategories: {
    input: undefined;
    output: SalesItemBookingCategory[];
  };
  getSalesItemsTypes: {
    input: undefined;
    output: OptionsTypeInteger[];
  };
  patchCarDetails: {
    input: CarPatchInputType;
    output: CarDetailType;
  };
  getOrderTransaction: {
    input: { id: string | undefined };
    output: OrderTransaction;
  };
  postCaptureOrderTransactionDintero: {
    input: { id: string | number | undefined };
    output: OrderTransactionDintero;
  };
  postRefundOrderTransactionDintero: {
    input: { id: string | number | undefined };
    output: OrderTransactionDintero;
  };
  postPartialRefundOrderTransactionDintero: {
    input: { id: string | number | undefined; amount: number };
    output: OrderTransactionDintero;
  };
  postVoidOrderTransactionDintero: {
    input: { id: string | number | undefined };
    output: OrderTransactionDintero;
  };
  getServiceCategory: {
    input: { id: string };
    output: ServiceCategory;
  };
  postServiceCategory: {
    input: ServiceCategoryBaseInput;
    output: ServiceCategory;
  };
  patchServiceCategory: {
    input: ServiceCategoryPatchInput;
    output: ServiceCategory;
  };
  postBookingTimeWindowTemplate: {
    input: BookingTimeWindowTemplatePostPatch & { deliveryWindowGroupId: string };
    output: { id: number };
  };
  deleteBookingTimeWindowTemplate: {
    input: { id: number };
    output: unknown;
  };
  getBookingTimeWindowTemplate: {
    input: { id: number };
    output: BookingTimeWindowTemplate;
  };
  patchBookingTimeWindowTemplate: {
    input: BookingTimeWindowTemplatePostPatch & { id: number };
    output: unknown;
  };
  getCarWheel: {
    input: { id: number };
    output: CarWheel;
  };
  getCarWheelsByIds: {
    input: { ids: number[] };
    output: CarWheel[];
  };
  getCarWheelSet: {
    input: { id: number };
    output: CarWheelSetV2;
  };
  getCarWheelSetStorageInformation: {
    input: { id: number };
    output: CarWheelSetStorageInformation;
  };
  patchCarWheelSet: {
    input: CarWheelSetPatchInput;
    output: CarWheelSet;
  };
  postCarWheelStorageObjects: {
    input: CarWheelStorageObjectCreateInput;
    output: CarWheelStorageObject;
  };
  getPaginatedStorageObjectsForCarWheel: {
    input: PaginatedInput & { id: number };
    output: PaginatedResponse<CarWheelStorageObject[]>;
  };
  getStorageFacilities: {
    input: undefined;
    output: StorageFacility[];
  };
  getPaginatedStorageFacilities: {
    input: PaginatedInput;
    output: PaginatedResponse<StorageFacility[]>;
  };
  getStorageFacility: {
    input: { id: number | string };
    output: StorageFacility;
  };
  postStorageFacility: {
    input: StorageFacilityCreateInput;
    output: StorageFacility;
  };
  patchStorageFacility: {
    input: StorageFacilityUpdateInput;
    output: StorageFacility;
  };
  getStorageAreasOverview: {
    input: { storageFacilityIds?: number[]; storageAreaIds?: number[] };
    output: StorageAreaOverview[];
  };
  getStorageAreasForFacility: {
    input: { storageFacilityId: number };
    output: StorageAreaListItem[];
  };
  getPaginatedStorageAreasForFacility: {
    input: PaginatedInput & { id: number };
    output: PaginatedResponse<StorageAreaListItem[]>;
  };
  getStorageArea: {
    input: { id: number | string };
    output: StorageArea;
  };
  getPaginatedStorageObjectGroupsForFacility: {
    input: PaginatedInput & { storageFacilityId: number };
    output: PaginatedResponse<StorageObjectGroupWithLatestEvent[]>;
  };
  getPaginatedCarWheelSetsForFacility: {
    input: PaginatedInput & { storageFacilityId: number };
    output: PaginatedResponse<CarWheelSetAtFacility[]>;
  };
  getCapacityContributionTypes: {
    input: undefined;
    output: {
      value: number;
      label: string;
    }[];
  };
  postStorageArea: {
    input: StorageAreaCreateInput;
    output: AxiosResponse<StorageArea>;
  };
  patchStorageArea: {
    input: StorageAreaUpdateInput & { id: number };
    output: AxiosResponse<StorageArea>;
  };
  deleteStorageArea: {
    input: { id: number };
    output: undefined;
  };
  getPaginatedStorageUnits: {
    input: PaginatedInput;
    output: PaginatedResponse<StorageUnit[]>;
  };
  getStorageUnit: {
    input: { id: number | string };
    output: StorageUnit;
  };
  postStorageUnit: {
    input: StorageUnitCreateInput;
    output: AxiosResponse<StorageUnit>;
  };
  patchStorageUnit: {
    input: StorageUnitUpdateInput;
    output: AxiosResponse<StorageUnit>;
  };
  deleteStorageUnit: {
    input: { id: number };
    output: undefined;
  };
  getPaginatedStorageUnitsForArea: {
    input: PaginatedInput & { id: number };
    output: PaginatedResponse<StorageUnitListItem[]>;
  };
  getStorageUnitsForAreaFromIdentifier: {
    input: { identifier: string };
    output: StorageUnitListItem[];
  };
  getStorageUnitTypes: {
    input: undefined;
    output: StorageUnitType[];
  };
  postPickUpCarWheelSet: {
    input: { id: number };
    output: CarWheelSet;
  };
  getPaginatedCarWheelSetForCar: {
    input: PaginatedInput & { id: number };
    output: PaginatedResponse<CarWheelSetListItemType[]>;
  };
  getCarWheelSetForCar: {
    input: { id: number };
    output: CarWheelSetListItemType[];
  };
  getCoreCountries: {
    input: undefined;
    output: {
      label: string;
      value: string;
    }[];
  };
  getPaginatedUserGroupMembershipProgram: {
    input: PaginatedInput & { id: number };
    output: PaginatedResponse<MembershipProgramListItem[]>;
  };
  getDeliveryWindowsWithCapacity: {
    input: {
      date: string;
      serviceDepartmentAreaId: number;
      serviceCategoryIds: number[];
      capabilityIds?: number[];
    };
    output: DeliveryWindowWithCapacity[];
  };
  getPaginatedCapacityContributions: {
    input: PaginatedCapacityContributionsInput;
    output: PaginatedResponse<CapacityContributionListItem[]>;
  };
  getCapacityContributionStatuses: {
    input: undefined;
    output: CapacityContributionStatus[];
  };
  getCapacityContributionOverview: {
    input: CapacityContributionOverviewInput;
    output: CapacityContributionOverviewItem[];
  };
  getBookingItemsOverview: {
    input: BookingItemsOverviewInput;
    output: BookingItemsOverviewItem[];
  };
  getCapacityContributionsForRoute: {
    input: { routeId: string; serviceDepartmentId?: number };
    output: CapacityContributionType[];
  };
  getPaginatedServiceCategories: {
    input: PaginatedInput;
    output: PaginatedResponse<ServiceCategory[]>;
  };
  deleteOrderLine: {
    input: { orderLineId: string };
    output: undefined;
  };
  getPaginatedRouteProblems: {
    input: PaginatedInput & { date: string | Date; serviceDepartmentId: string };
    output: PaginatedResponse<RouteProblemListType[]>;
  };
  getRouteProblem: {
    input: { routeProblemId: string };
    output: RouteProblemType;
  };
  getPaginatedCapacityContributionsForRoutePlanner: {
    input: RoutePlannerCapacityContributionsInputType & Partial<PaginatedInput>;
    output: RoutePlannerCapacityContribution[] & Partial<PaginatedResponse<RoutePlannerCapacityContribution[]>>;
  };
  getRoutePlannerBookings: {
    input: RoutePlannerBookingsInputType;
    output: BookingForRoutePlanner[];
  };
  patchServiceDepartmentCapacity: {
    input: ServiceDepartmentCapacityInput;
    output: undefined;
  };
  getRouteProblemValidation: {
    input: { date: string; serviceDepartmentId: number };
    output: ValidateRouteProblemResponse;
  };
  postStartRoutePlanner: {
    input: StartRoutePlannerInputType;
    output: undefined;
  };
  getWheelStoragePickupBookingItems: {
    input: WheelStoragePickupsParams & PaginatedInput;
    output: PaginatedResponse<WheelStoragePickupBookingItem[]>;
  };
  getWheelStorageAgreements: {
    input: WheelStorageAgreementFilters & PaginatedInput;
    output: PaginatedResponse<TireHotelContractListItem[]>;
  };
  postSelectRouteSolution: {
    input: { id: string };
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    output: any;
  };
  getPaginatedBookingOverviewDetailsList: {
    input: PaginatedBookingOverviewDetailsListInput;
    output: PaginatedResponse<BookingOverviewDetailsListType[]>;
  };
  getAdminDashboardBookings: {
    input: { fromDate: string; toDate: string; bookingStatusIds: number[] };
    output: DashboardData;
  };
  postCancelBooking: {
    input: { id: number; notifyCustomer: boolean };
    output: undefined;
  };
  postChangeCapacityContribution: {
    input: { routeId: number; capacityContributionId: number | null };
    output: undefined;
  };
  getCapacityIntervals: {
    input: ListCapacityIntervalsInput;
    output: CapacityInterval[];
  };
  postCreateCarTireQuotes: {
    input: TireQuotePayload;
    output: AxiosResponse<{ id: number }>;
  };

  getFeedbacks: {
    input: PaginatedInput & FeedbackAdminInput;
    output: PaginatedResponse<FeedbackAdmin[]>;
  };
  patchFeedback: {
    input: Partial<FeedbackAdmin> & { id: number };
    output: FeedbackAdmin;
  };

  getRoutesForMapStatus: {
    input: { date: string };
    output: RouteStatusMap[];
  };
  getTireSpeedIndexFilters: {
    input: undefined;
    output: SpeedIndexFilterType[];
  };
  getTireClassificationFilters: {
    input: undefined;
    output: TireFilterStringValueOption[];
  };
  getTireConstructionFilters: {
    input: undefined;
    output: TireFilterStringValueOption[];
  };
  getTireEuClassFilters: {
    input: undefined;
    output: TireFilterStringValueOption[];
  };
  getTireLoadIndexFilters: {
    input: undefined;
    output: LoadIndexFilterType[];
  };
  getTireRollingResistanceFilters: {
    input: undefined;
    output: TireFilterStringValueOption[];
  };
  getTireSoundClassFilters: {
    input: undefined;
    output: TireFilterStringValueOption[];
  };
  getTireQuoteSalesItems: {
    input: PaginatedInput;
    output: PaginatedResponse<TireQuoteSalesItem[]>;
  };
  getSalesItemCategories: {
    input: undefined;
    output: { value: string; label: string }[];
  };
  getMinimalSalesItems: {
    input: { type?: number[]; currencies?: Currency[] };
    output: MinimalSalesItem[];
  };
  getTireInventoryList: {
    input: PaginatedInput & TireFiltersInput & { season?: string };
    output: PaginatedResponse<TireFromInventorySupplier[]>;
  };
  getCarSpecificationTireFilters: {
    input: undefined;
    output: TireSpecificationFilters;
  };
  patchTireQuoteOffer: {
    input: TireOfferV2 & { carTireQuoteId: number };
    output: undefined;
  };
  patchCarTireQuoteItemTire: {
    input: TireInQuoteItem;
    output: undefined;
  };
  postAddCarToUserGroup: {
    input: { userGroupId: number; carIds: number[] };
    output: undefined;
  };
  getTireBrands: {
    input: undefined;
    output: { id: number; name: string }[];
  };
  postCreateTire: {
    input: TireCreationPayload;
    output: AxiosResponse<Tire>;
  };
  postTireToInventory: {
    input: AddTireToInventoryPayload;
    output: undefined;
  };
  getTireShopSuppliers: {
    input: undefined;
    output: { id: number; name: string }[];
  };
  postRemoveCarsFromUserGroup: {
    input: { carIds: number[]; userGroupId: number };
    output: undefined;
  };
  postOrderInventory: {
    input: InventoryOrderCreationPayload;
    output: AxiosResponse<InventoryOrder>;
  };
  patchInventoryOrderToQuoteItemTire: {
    input: { quoteItemTireId: number; inventoryOrderId: number };
    output: undefined;
  };
  patchCarTireQuote: {
    input: { carTireQuoteId: number; status: QuoteItemStatusType };
    output: AxiosResponse<TireOfferV2>;
  };
  getBookingAddressCities: {
    input: { search?: string };
    output: { cities: string[] };
  };
  getBookingAddressZipCodes: {
    input: { search?: string };
    output: { zipCodes: string[] };
  };
}
