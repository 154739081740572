import { t, Trans } from '@lingui/macro';
import { Skeleton } from '@mui/material';
import { tracking } from 'noddi-provider';
import { cn, NoddiBasicCard, NoddiDialog } from 'noddi-ui';
import { formatCurrencyAmount } from 'noddi-util';
import { useState } from 'react';

import { SALES_ITEM_TYPE } from 'noddi-async/src/types';
import { NoddiButton, noddiColors, NoddiIcon } from 'noddi-ui-common';
import { GestureResponderEvent } from 'react-native';
import { useBookingActions, useBookingSelectedSalesItemsCars } from '../../BookingStore';
import { UnavailableModal } from '../../components/salesItems/UnavailableModal';
import { useGetUnavailableSalesItems } from '../../Steps/Services/useGetUnavailableSalesItems';
import { FleetCarWithSalesItemProperties, ServiceItemsMappedForFleetWithCars } from './SalesItemsFleetSelector';

type SalesItemProps = {
  salesItem: ServiceItemsMappedForFleetWithCars;
  type: SALES_ITEM_TYPE;
};

export const SalesItemMultipleCars = ({ salesItem, type }: SalesItemProps) => {
  const selectedSalesItemsCars = useBookingSelectedSalesItemsCars();
  const hasAnySelectedSalesItems = selectedSalesItemsCars.some((item) =>
    salesItem.cars.some((car) => car.salesItemId === item.salesItemId)
  );
  const [isExpanded, setIsExpanded] = useState(hasAnySelectedSalesItems);
  const [showUnavailableModal, setShowUnavailableModal] = useState(false);

  const [showFullDescription, setShowFullDescription] = useState(false);

  const { addSalesItems, removeSalesItems, removeSalesItem, addSalesItem } = useBookingActions();

  const { isUnavailableSalesItemsLoading, unavailableSalesItems } = useGetUnavailableSalesItems();

  const { name, shortDescription, description, price } = salesItem;

  const isAnySalesItemsUnavailable = salesItem.cars.some((car) =>
    unavailableSalesItems?.data.some((unavailableSalesItem) => unavailableSalesItem.salesItemId === car.salesItemId)
  );

  const isFree = price.amount === 0;
  const priceFormatted = isFree ? t`Free` : `${t`from`} ${formatCurrencyAmount(price.amount, 0, price.currency)}`;

  const onShowFullDescriptionClick = (e: GestureResponderEvent) => {
    e.stopPropagation();
    tracking.track('salesItemDescriptionDetailsClicked', { salesItemName: name });
    setShowFullDescription(true);
  };

  function onExpandAndSelectAll(e: GestureResponderEvent) {
    const params = salesItem.cars.map((car) => ({
      licensePlate: car.licensePlate,
      salesItemId: car.salesItemId,
      type,
      isAddonsAvailable: car.isAddonsAvailable,
      isWheelPickupRequired: car.isWheelPickupRequired,
      mutuallyExclusiveSalesItemIds: car.mutuallyExclusiveSalesItemIds
    }));
    hasAnySelectedSalesItems ? removeSalesItems(params) : addSalesItems(params);

    setIsExpanded(!hasAnySelectedSalesItems);

    e.stopPropagation();
  }

  function onSelect({
    e,
    car,
    isAlreadySelected
  }: {
    e: React.MouseEvent<HTMLDivElement>;
    car: FleetCarWithSalesItemProperties;
    isAlreadySelected: boolean;
  }) {
    isAlreadySelected
      ? removeSalesItem({
          licensePlate: car.licensePlate,
          salesItemId: car.salesItemId,
          type
        })
      : addSalesItem({
          licensePlate: car.licensePlate,
          salesItemId: car.salesItemId,
          type,
          mutuallyExclusiveSalesItemIds: car.mutuallyExclusiveSalesItemIds,
          isAddonsAvailable: car.isAddonsAvailable,
          isWheelPickupRequired: car.isWheelPickupRequired
        });
    e.stopPropagation();
  }

  function onUnavailableModalOpen(e: GestureResponderEvent) {
    setShowUnavailableModal(true);
    e.stopPropagation();
  }

  const showSkeletonLoader = isUnavailableSalesItemsLoading && !hasAnySelectedSalesItems;

  return (
    <NoddiBasicCard
      className={cn(
        'shadow-none',
        hasAnySelectedSalesItems && 'bg-primary-purple text-primary-white',
        'px-4 py-3 sm:px-6 sm:py-5'
      )}
      onClick={() => setIsExpanded((prev) => !prev)}
    >
      <UnavailableModal
        // Todo, now it shows first only
        salesItem={{ name: salesItem.name, salesItemId: salesItem.cars[0]?.salesItemId as number }}
        open={showUnavailableModal}
        onClose={() => setShowUnavailableModal(false)}
        unavailableSalesItems={unavailableSalesItems?.data}
      />
      <NoddiDialog title={name} onClose={() => setShowFullDescription(false)} open={showFullDescription}>
        {description}
      </NoddiDialog>
      <div className='flex flex-col gap-2'>
        <div className='flex items-center justify-between gap-2'>
          <span className='text-5'>{name}</span>

          <NoddiIcon
            color={hasAnySelectedSalesItems ? noddiColors.primary.white : undefined}
            name={isExpanded ? 'AltArrowUp' : 'AltArrowDown'}
          />
        </div>
        <p className={cn('font-semibold', isFree && !hasAnySelectedSalesItems && 'text-signal-success')}>
          {priceFormatted}
        </p>
        <p>{shortDescription}</p>
        <div className='flex h-12 items-end justify-end'>
          {showSkeletonLoader ? (
            <Skeleton variant='rounded' height={30} width={100} />
          ) : (
            <div className='flex items-center gap-2'>
              {isAnySalesItemsUnavailable ? (
                <NoddiButton
                  startIcon='ArrowSquareRightCorner'
                  size='small'
                  variant={hasAnySelectedSalesItems ? 'lightLink' : 'link'}
                  onPress={onUnavailableModalOpen}
                >
                  <Trans>Why am I not able to select this?</Trans>
                </NoddiButton>
              ) : (
                <>
                  <NoddiButton
                    startIcon='ArrowSquareRightCorner'
                    size='small'
                    variant={hasAnySelectedSalesItems ? 'lightLink' : 'link'}
                    onPress={onShowFullDescriptionClick}
                  >
                    <Trans>Details</Trans>
                  </NoddiButton>
                  <NoddiButton
                    variant='secondary'
                    size='small'
                    startIcon={hasAnySelectedSalesItems ? 'Minus' : 'Plus'}
                    onPress={onExpandAndSelectAll}
                  >
                    {hasAnySelectedSalesItems ? t`Remove` : t`Add`}
                  </NoddiButton>
                </>
              )}
            </div>
          )}
        </div>
      </div>
      {isExpanded && (
        <div className='mb-3 flex flex-col gap-1'>
          {salesItem.cars.map((car) => {
            const isSelected = selectedSalesItemsCars.some(
              (item) => item.salesItemId === car.salesItemId && item.licensePlate.number === car.licensePlate.number
            );
            return (
              <div
                onClick={(event) => onSelect({ e: event, car, isAlreadySelected: isSelected })}
                className='flex items-center justify-between gap-2 hover:cursor-pointer'
                key={car.licensePlate.number}
              >
                <div className='flex items-center gap-2'>
                  <div className='flex size-6 items-center justify-center rounded-md border bg-primary-white'>
                    {isSelected && <NoddiIcon name='Check' size='large' />}
                  </div>
                  <div className='flex flex-col gap-1 p-2'>
                    <p>
                      {car.make} {car.model}
                    </p>
                    <p>{car.licensePlate.number}</p>
                  </div>
                </div>
                <p>{formatCurrencyAmount(salesItem.price.amount, salesItem.price.amount)}</p>
              </div>
            );
          })}
        </div>
      )}
    </NoddiBasicCard>
  );
};
