import { embed } from '@dintero/checkout-web-sdk';
import { CountryCodeType } from 'noddi-util';
import { useEffect, useRef, useState } from 'react';
import { NoddiCircularLoaderWithTrans } from '../../components/NoddiCircularLoaderWithTrans';

const getLanguageForDintero = (countryCode: CountryCodeType) => {
  switch (countryCode) {
    case 'NO':
      return 'no';
    case 'SE':
      return 'sv';
    default:
      return 'en';
  }
};

export default function PaymentForm({ sessionId, countryCode }: { sessionId: string; countryCode: CountryCodeType }) {
  const ref = useRef<HTMLDivElement>(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!ref.current) {
      return;
    }

    setIsLoading(true);
    // eslint-disable-next-line promise/catch-or-return
    embed({
      container: ref.current,
      sid: sessionId,
      language: getLanguageForDintero(countryCode)
      // eslint-disable-next-line promise/always-return
    }).then(() => {
      setIsLoading(false);
      // Scroll to the embedded container once loading is complete
      setTimeout(() => {
        ref.current?.scrollIntoView({ behavior: 'smooth' });
      }, 500);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div ref={ref} style={{ marginTop: 24 }}>
      {isLoading && <NoddiCircularLoaderWithTrans />}
    </div>
  );
}
