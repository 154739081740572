import { t } from '@lingui/macro';
import { useAuthContext } from 'noddi-provider';
import { NavItem } from 'noddi-ui/src/types/navItem';

import { noddiColors, NoddiIcon } from 'noddi-ui-common';
import { customerRoutes } from 'noddi-util';

export const useNavItems = () => {
  const { hasTireHotel, hasMemberships } = useAuthContext();

  const navItems: NavItem[] = [
    {
      items: [
        {
          path: customerRoutes.homepage.getPath(),
          title: t`My profile`,
          icon: <NoddiIcon name='UserCircle' color={noddiColors.primary.white} size='medium' />
        }
      ]
    },
    {
      items: [
        {
          path: customerRoutes.bookings.getPath(),
          title: t`Bookings`,
          icon: <NoddiIcon name='Calendar' color={noddiColors.primary.white} size='medium' />
        }
      ]
    },
    {
      items: [
        {
          path: customerRoutes.bonus.getPath(),
          title: t`Bonus`,
          icon: <NoddiIcon size='medium' color={noddiColors.primary.white} name='Star' />
        }
      ]
    },
    {
      items: [
        {
          path: customerRoutes.myMembershipPrograms.getPath(),
          title: t`Membership benefits`,
          icon: <NoddiIcon name='Plus' size='medium' color={noddiColors.primary.white} />,
          shouldShow: () => hasMemberships ?? false
        }
      ]
    },
    {
      items: [
        {
          path: customerRoutes.tireHotel.getPath(),
          title: t`Tire hotel`,
          icon: <NoddiIcon name='Home' size='medium' color={noddiColors.primary.white} />,
          shouldShow: () => hasTireHotel ?? false
        }
      ]
    },
    {
      items: [
        {
          path: customerRoutes.tireOfferHomePage.getPath(),
          title: t`Tire offer`,
          icon: <NoddiIcon name='WheelAngle' size='medium' color={noddiColors.primary.white} />
        }
      ]
    }
  ];

  const getNavItems = () => navItems;

  return { getNavItems };
};
