import { t } from '@lingui/macro';
import { noddiAsync, URLKeys } from 'noddi-async';

import { getCarDisplayName, NoddiDialog } from 'noddi-ui';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import ContentWrapperWithQuery from '../../components/Layouts/ContentWrapperWithQuery';
import { useScrollToTop } from '../BookingFlow/helpers/useScrollToTop';
import { TireDimensionUpdate } from './TireDimensionUpdate';
import TireSelectionCard from './TireSelectionCard';

const TireSelection = () => {
  const { slug } = useParams() as { slug: string };
  const [tireDimensionsModalOpen, setTireDimensionsModalOpen] = useState(false);

  const query = noddiAsync.useGet({
    type: URLKeys.getCarTireQuoteBySlug,
    input: { slug },
    queryConfig: { staleTime: Infinity }
  });

  const carName = getCarDisplayName(query.data?.car ?? {}) ?? t`your car`;

  useScrollToTop();

  return (
    <ContentWrapperWithQuery
      title={t`Your tire choices`}
      subtitle={t`We have prepared an offer with options for new tires for ${carName}. The offer is based on the tire dimensions the car is registered with at the Norwegian Public Roads Administration, and we will verify that these match before ordering.`}
      widerScreen
      query={query}
    >
      {({ data }) => (
        <div className='flex flex-col gap-2'>
          <div className='flex flex-col gap-4'>
            {data.quoteItems.map((quoteItem, index) => (
              <TireSelectionCard
                slug={slug}
                quoteItem={quoteItem}
                key={index}
                setTireDimensionsModalOpen={setTireDimensionsModalOpen}
              />
            ))}
          </div>
          <NoddiDialog
            open={tireDimensionsModalOpen}
            onClose={() => setTireDimensionsModalOpen(false)}
            title={t`Update tire dimensions`}
          >
            <TireDimensionUpdate id={data.id} />
          </NoddiDialog>
        </div>
      )}
    </ContentWrapperWithQuery>
  );
};

export default TireSelection;
