import { Trans } from '@lingui/macro';
import { BOOKING_TYPES, TireHotelContract, TireHotelOptionsForCar } from 'noddi-async/src/types';
import { NoddiBasicCard } from 'noddi-ui';
import { NoddiButton, NoddiDummyButton } from 'noddi-ui-common';
import { customerRoutes } from 'noddi-util';
import { useNavigate } from 'react-router-dom';
import { useBookingActions } from '../BookingFlow/BookingStore';
import TireHotelSvg from './TireHotelSvg';

type ContractCardProps = {
  tireHotelContract: TireHotelContract;
  tireHotelOption?: TireHotelOptionsForCar;
};

const ContractCard = ({ tireHotelContract, tireHotelOption }: ContractCardProps) => {
  const navigate = useNavigate();

  const { clearStore, addCar, setBookingType } = useBookingActions();

  const { bookingItemSalesItem } = tireHotelContract;

  const noneCanRenew = tireHotelOption?.canRenewTireHotel;
  const canTerminate = tireHotelOption?.canTerminateTireHotel;

  function prePopulateAndNavigate(to: 'renew' | 'terminate') {
    const licensePlate = {
      number: tireHotelContract.car.licensePlateNumber,
      countryCode: tireHotelContract.car.countryCode
    };
    clearStore();
    // TODO | New booking flow , this mapping should happen backend? :D
    addCar({
      make: tireHotelContract.car.carGeneration.make.name,
      model: tireHotelContract.car.carGeneration.model.name,
      hasActiveTireHotelContract: true,
      licensePlate
    });

    if (to === 'terminate') {
      setBookingType(BOOKING_TYPES.CANCEL_TIER_HOTEL);
    }
    navigate(customerRoutes.newBookingAddress.getPath());
  }

  return (
    <NoddiBasicCard
      onClick={
        bookingItemSalesItem
          ? () => navigate(customerRoutes.myBookingDetails.getPath({ id: bookingItemSalesItem.bookingId }))
          : undefined
      }
      className='flex flex-col gap-3'
    >
      <div className='flex justify-between gap-6'>
        <div>
          <p className='font-bold text-5'>{`${tireHotelContract.car.carGeneration.make.name} ${tireHotelContract.car.carGeneration.model.name}`}</p>
          <p className='text-5'>{tireHotelContract.car.licensePlateNumber}</p>
        </div>
        <div className='flex flex-col gap-2'>
          {noneCanRenew && (
            <NoddiButton
              size='small'
              onPress={(e) => {
                e.stopPropagation();
                prePopulateAndNavigate('renew');
              }}
            >
              <Trans>Order wheel change</Trans>
            </NoddiButton>
          )}
          {canTerminate && (
            <NoddiButton
              className='w-full'
              variant='secondary'
              size='small'
              onPress={(e) => {
                e.stopPropagation();
                prePopulateAndNavigate('terminate');
              }}
            >
              <Trans>Cancel agreement</Trans>
            </NoddiButton>
          )}
        </div>
      </div>
      <div className='flex items-end justify-between gap-4'>
        <div>
          <TireHotelSvg />
        </div>

        {!!bookingItemSalesItem && (
          <NoddiDummyButton className='-mb-2 -mr-4' variant='link' endIcon='ArrowRight'>
            <Trans>See booking</Trans>
          </NoddiDummyButton>
        )}
      </div>
    </NoddiBasicCard>
  );
};

export default ContractCard;
