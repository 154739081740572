import { t, Trans } from '@lingui/macro';
import { captureException } from '@sentry/react';
import { useMutationState } from '@tanstack/react-query';
import { NoddiAsyncReturnType, URLKeys } from 'noddi-async';
import { NoddiAsyncError } from 'noddi-async/src/types/shared';
import { commonTrackingEvents, tracking } from 'noddi-provider';
import { NoddiButton } from 'noddi-ui-common';
import { customerRoutes } from 'noddi-util';
import { ReactElement, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ApiErrorMessageWithTrans } from '../../../../components/ApiErrorMessageWithTrans';
import { GoogleTrackingService } from '../../../../services/GoogleTrackingService';
import { GtmEvents } from '../../../../types/gtmTracking';
import { useBookingActions, useBookingCars } from '../../BookingStore';
import { useCreateBooking } from '../../helpers/useCreateBooking';
import { useGetConfirmBookingMutationKey } from '../../helpers/useGetConfirmBookingKey';
import { AnimatedProgressBar } from './AnimatedProgressBar';

export function BuildingBooking(): ReactElement {
  const navigate = useNavigate();
  const confirmBookingMutationKey = useGetConfirmBookingMutationKey();
  const { clearStore } = useBookingActions();
  const cars = useBookingCars();

  const bookingMutation = useMutationState({
    filters: { mutationKey: confirmBookingMutationKey }
  });

  const { createNewBooking, isPending } = useCreateBooking({});

  const isCreateBookingSuccessFull = bookingMutation.some((mutation) => mutation.status === 'success');
  const isCreateBookingError = bookingMutation.some((mutation) => mutation.status === 'error');

  const data = bookingMutation.find((mutation) => mutation.status === 'success')?.data as NoddiAsyncReturnType<
    typeof URLKeys.postCreateBooking,
    typeof URLKeys.postCreateBooking
  >;

  function handleProgressChange(value: number) {
    const parsedValue = Math.floor(value);
    if (parsedValue < 30) {
      return t`We are finding the right time and place...`;
    } else if (parsedValue < 50) {
      return t`We are matching you with a skilled professional...`;
    } else if (parsedValue < 80) {
      return t`We are locking in the booking...`;
    }
    return t`Almost there...🚀`;
  }

  useEffect(() => {
    if (isCreateBookingSuccessFull && data) {
      try {
        tracking.track(commonTrackingEvents.bookingCreated, {
          numberOfCars: cars.length
        });
        GoogleTrackingService.trackEvent(GtmEvents.purchase);
      } catch (error) {
        captureException(error);
      }
      clearStore();
      navigate(customerRoutes.confirmation.getPath({ bookingId: data.data.id }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCreateBookingSuccessFull]);

  return (
    <div className='mx-auto mt-8 size-full max-w-2xl px-5 pb-26 md:mt-14 lg:px-0'>
      <div className='flex h-[calc(90vh-14rem)] flex-col items-center justify-center md:h-[calc(90vh-20rem)]'>
        <AnimatedProgressBar
          actionButton={({ handleRestart }) => {
            return (
              isCreateBookingError &&
              !isPending && (
                <div className='mt-4 flex flex-col items-center gap-2'>
                  <ApiErrorMessageWithTrans
                    error={bookingMutation.find((mutation) => mutation.status === 'error')?.error as NoddiAsyncError}
                  />
                  <NoddiButton
                    onPress={() => {
                      handleRestart();
                      createNewBooking();
                    }}
                    loading={isPending}
                  >
                    <Trans>Try again</Trans>
                  </NoddiButton>
                </div>
              )
            );
          }}
          finishedNumber={99}
          intervalDelayInSeconds={20}
          isPaused={isCreateBookingError && !isPending}
        >
          {({ progress }) => {
            return (
              <div className='mb-10 flex flex-col items-center'>
                <p className='font-bold text-7 text-primary-darkPurple'>Lager din booking ({Math.floor(progress)}%)</p>
                <p className='text-wrap text-center text-5'>{handleProgressChange(progress)}</p>
              </div>
            );
          }}
        </AnimatedProgressBar>
      </div>
    </div>
  );
}
