export enum SMSSubjects {
  Confirmed = 'ad_hoc'
}

export enum BookingStatus {
  Confirmed = 1,
  Assigned = 2,
  Cancelled = 3,
  Completed = 4,
  UnableToComplete = 5,
  RecallProposal = 6
}

export const BOOKING_STATUS_OPTIONS = [
  { value: BookingStatus.Confirmed, label: 'Confirmed' },
  { value: BookingStatus.Assigned, label: 'Assigned' },
  { value: BookingStatus.Cancelled, label: 'Cancelled' },
  { value: BookingStatus.Completed, label: 'Completed' },
  { value: BookingStatus.UnableToComplete, label: 'Unable to complete' },
  { value: BookingStatus.RecallProposal, label: 'Recall proposal' }
];
