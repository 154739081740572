import { LinearProgress } from '@mui/material';
import Box from '@mui/material/Box';
import { noddiColors } from 'noddi-ui-common';

export function NoddiLinearProgressLoader() {
  return (
    <Box sx={{ width: '100%', marginTop: 2 }}>
      <LinearProgress
        style={{ backgroundColor: noddiColors.primary.purple }}
        sx={{
          '& .MuiLinearProgress-bar': {
            backgroundColor: noddiColors.primary.purple60
          }
        }}
      />
    </Box>
  );
}
