import { Dimensions, PixelRatio, Platform } from 'react-native';

const window = Dimensions.get('window');

const deviceHeight = window.height;
const deviceWidth = window.width;

// iPhone X scale screenWidth = 375 screenHeight = 812
const scaledWidth: number = deviceWidth / 375;
const scaledHeight: number = deviceHeight / 812;

enum BasedType {
  Width = 'width',
  Height = 'height'
}

function normalize(size: number, based: BasedType = BasedType.Width): number {
  if (Platform.OS === 'web') {
    return size;
  } else {
    const newSize = size * (based === 'height' ? scaledHeight : scaledWidth);
    return PixelRatio.roundToNearestPixel(newSize);
  }
}

const widthPixel = (size: number): number => {
  return normalize(size, BasedType.Width);
};

const heightPixel = (size: number): number => {
  return normalize(size, BasedType.Height);
};

export const iconPixel = (size: number): number => {
  return normalize(size, BasedType.Width);
};

export const fontPixel = (size: number): number => {
  return heightPixel(size);
};

// for Margin and Padding horizontal pixel
export const pixelSizeHorizontal = (size: number): number => {
  return widthPixel(size);
};

// for Margin and Padding vertical pixel
export const pixelSizeVertical = (size: number): number => {
  return heightPixel(size);
};
