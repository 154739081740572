import { t } from '@lingui/macro';
import groupBy from 'lodash/groupBy';
import { SALES_ITEM_TYPE, SalesItemsCar } from 'noddi-async/src/types';
import { ErrorPageBase } from 'noddi-ui';
import { ErrorPageWithTranslations } from '../../../../components/ErrorPageWithTrans';
import { useBookingCars, useBookingSelectedSalesItemsCars } from '../../BookingStore';
import { CarIdentifierWithIcon } from '../../components/CarIdentifierWithIcon';
import { ServiceCategoryCollapseCard } from '../../components/salesItems/ServiceCategoryCollapseCard';
import { compareLicensePlates } from '../../helpers/utils';

type CarWithSalesItemsProps = {
  salesItemsCar: SalesItemsCar;
};

export const ServiceItemsForSingleCar = ({ salesItemsCar }: CarWithSalesItemsProps) => {
  const { licensePlate, salesItems } = salesItemsCar;

  const selectedSalesItemsCars = useBookingSelectedSalesItemsCars();
  const selectCars = useBookingCars();

  const selectedSalesItemsCar = selectedSalesItemsCars.filter((item) =>
    compareLicensePlates(item.licensePlate, licensePlate)
  );

  const sortedSalesItems = salesItems.toSorted((a, b) => a.uiSortOrder - b.uiSortOrder);

  const bookingCategories = groupBy(sortedSalesItems, 'bookingCategorySlug');

  const car = selectCars.find((car) => compareLicensePlates(car.licensePlate, licensePlate));

  const numberOfCategories = Object.keys(bookingCategories).length;
  const carName = `${car?.make} ${car?.model} - ${car?.licensePlate.number}`;

  if (!numberOfCategories && car?.hasActiveTireHotelContract) {
    return (
      <ErrorPageBase
        title={t`We do not provide tire hotel services in this area`}
        description={t`${carName} has an active tire hotel contract, but we do not provide tire hotel services in this area - please contact us at hei@noddi.no`}
      />
    );
  }

  if (!car || !numberOfCategories) {
    return <ErrorPageWithTranslations />;
  }

  const onlyOneCategory = numberOfCategories === 1;

  return (
    <div className='flex flex-col gap-6'>
      <CarIdentifierWithIcon car={car} />

      <div className='flex flex-col gap-4'>
        {Object.entries(bookingCategories).map(([category, salesItems]) => {
          const numberOfSelectedSalesItems = selectedSalesItemsCar.filter((item) =>
            salesItems.map((item) => item.salesItemId).includes(item.salesItemId)
          ).length;

          return (
            <ServiceCategoryCollapseCard
              key={category}
              category={category}
              salesItems={salesItems}
              licensePlate={licensePlate}
              mountAsExpanded={onlyOneCategory}
              numberOfSelectedSalesItems={numberOfSelectedSalesItems}
              salesItemType={SALES_ITEM_TYPE.PRIMARY}
            />
          );
        })}
      </div>
    </div>
  );
};
