/* eslint-disable @typescript-eslint/no-explicit-any */
import { Money } from '../../adminapp';
import { AddressOutput, Brand } from '../../shared';
import { Car, CommunicatedArrivalWindow, Order } from './shared';

export enum BookingInfoStatus {
  //The customer has started the booking process, but is not booked yet.
  DRAFT = 'Draft',
  //The customer has successfully made a booking.
  CONFIRMED = 'Confirmed',
  //The booking has been assigned to a route.
  ASSIGNED_TO_ROUTE = 'Assigned',
  //The booking has been cancelled for some reason.
  CANCELLED = 'Cancelled',
  //We have completed the booking, meaning that the service has been performed at the customers address.
  COMPLETED = 'Completed',
  //We were unable to complete the booking for some reason.
  UNABLE_TO_COMPLETE = 'Unable to complete'
}

export type BookingAddress = {
  publicAnnouncement: string;
  brands: Brand[];
} & AddressOutput;

export interface BookingInfo {
  id: number;
  slug: string;
  status: BookingInfoStatus;
  deliveryWindowStartsAt: string;
  deliveryWindowEndsAt: string;
  deliveryWindowFee: Money;
  referralCode: string;
  communicatedArrivalWindow: CommunicatedArrivalWindow;
  startedAt: any;
  delayInSeconds: number;
  serviceWorkerNickname?: string;
  serviceWorkerPhoneNumber?: string;
  feedback?: Feedback;
  order: Order;
  isPaid: boolean;
  isInvoiced: boolean;
  cars: Car[];
}

export interface Feedback {
  customerRatingCarResult: number;
  customerRatingCommunication: number;
  customerRatingEaseOfUse: number;
  customerRatingPoliteness: number;
  customerRatingOverall: number;
  customerComment: string;
}

export interface FeedbackAdmin {
  id: number;
  addToServiceWorkerStats: boolean;
  customerRatingCarResult: number;
  customerRatingCommunication: number;
  customerRatingEaseOfUse: number;
  customerRatingPoliteness: number;
  customerRatingOverall: number;
  customerComment: string;
  createdAt: string;
  serviceWorker: {
    firstName: string;
    lastName: string;
  };
  booking: {
    id: number;
    userGroup: {
      id: number;
      name: string;
    };
  };
}

export interface FeedbackAdminInput {
  serviceWorkerId?: number | null;
  createdFromDate?: string | null;
  createdToDate?: string | null;
  bookingFromDate?: string | null;
  bookingToDate?: string | null;
}
