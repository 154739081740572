import { Trans, t } from '@lingui/macro';
import { tracking, useAuthContext, useLanguageContext } from 'noddi-provider';
import { NoddiTextInput, SMSLogin, UserRegistrationForm } from 'noddi-ui';
import { NoddiButton } from 'noddi-ui-common';
import { customerRoutes } from 'noddi-util';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import getCommonTranslations from '../../commonTranslations';
import { BasicHeaderNav } from '../../components/Layouts/BasicHeaderNav';
import ContentWrapper from '../../components/Layouts/ContentWrapper';
import { getErrorCodes } from '../../utils/translation';

const CompanySignup = () => {
  const { isLoggedIn } = useAuthContext();
  const { currentLanguage } = useLanguageContext();
  const navigate = useNavigate();
  const [orgName, setOrgName] = useState('');
  const [orgNumber, setOrgNumber] = useState('');

  function onExistingUserCompanySignupSuccess() {
    tracking.track('companySignupWithExistingUser', { orgName, orgNumber });
    navigate(customerRoutes.companySignupSuccess.getPath());
  }

  return (
    <div className='min-h-screen bg-peach-to-purple'>
      <BasicHeaderNav />
      <ContentWrapper title={t`Noddi for your company`}>
        <p>
          <Trans>
            Do you need help with car washing, tire changes, or tire hotel for the vehicles in your company?
          </Trans>
        </p>
        <p className='mt-7 font-bold text-7 text-primary-purple'>
          <Trans>Fill out the form, and we will get in touch</Trans>
        </p>

        <div className='mt-6 flex flex-col gap-6'>
          <NoddiTextInput
            value={orgName}
            onChange={(newValue) => setOrgName(newValue.target.value)}
            label={t`Company name`}
          />
          <NoddiTextInput
            value={orgNumber}
            onChange={(newValue) => setOrgNumber(newValue.target.value)}
            label={t`Company organization number`}
            type='number'
          />
        </div>
        {isLoggedIn ? (
          <div className='mt-4 flex justify-end'>
            <NoddiButton onPress={onExistingUserCompanySignupSuccess}>
              <Trans>Send in</Trans>
            </NoddiButton>
          </div>
        ) : (
          <SMSLogin
            errorCodes={getErrorCodes()}
            translations={getCommonTranslations().smsLoginTranslations}
            language={currentLanguage}
            onLoginSuccess={onExistingUserCompanySignupSuccess}
            className='mt-4'
            trackUserIdentity={(user) => {
              tracking.trackUserLogin(user);
            }}
            userRegistrationForm={(phoneNumber) => (
              <UserRegistrationForm
                errorCodes={getErrorCodes()}
                translations={getCommonTranslations().registrationTranslations}
                orgProperties={{ orgName, orgNumber }}
                customCTAText={t`Send in`}
                phoneNumber={phoneNumber}
                skipPasswordCreation
                trackUserIdentity={(user) => {
                  tracking.trackUserIdentity(user);
                  tracking.track('companySignup', { orgName, orgNumber });
                }}
                onLoginSuccess={() => navigate(customerRoutes.companySignupSuccess.getPath())}
              />
            )}
          />
        )}
      </ContentWrapper>
    </div>
  );
};

export default CompanySignup;
