import { t } from '@lingui/macro';
import { noddiAsync, URLKeys } from 'noddi-async';
import { useAuthContext } from 'noddi-provider';
import { getCarDisplayName, NoddiBasicCard, NoddiDialog } from 'noddi-ui';
import { NoddiDummyButton, NoddiIcon } from 'noddi-ui-common';
import { useState } from 'react';
import { ApiErrorMessageWithTrans } from '../../components/ApiErrorMessageWithTrans';
import ContentWrapper from '../../components/Layouts/ContentWrapper';
import { ConfirmTireOffer } from './ConfirmTireOffer';

export default function TireOfferHomePage() {
  const { currentUserGroupId: userGroupId } = useAuthContext();
  const [activeTireSetOfferId, setActiveTireSetOfferId] = useState<number | null>(null);

  const {
    isPending: isTireSetOffersPending,
    data: tireSetOffers,
    isFetching: isTireSetOffersFetching,
    error: tireSetOffersError
  } = noddiAsync.useGet({
    type: URLKeys.getCarTireQuotes,
    input: { userGroupIds: [userGroupId!] },
    queryConfig: {
      select(data) {
        return data.sort((a, b) => {
          if (a.isSelected) {
            return -1;
          }
          if (b.isSelected) {
            return 1;
          }
          return 0;
        });
      }
    }
  });

  if (isTireSetOffersPending || isTireSetOffersFetching) {
    return (
      <ContentWrapper title={t`Tire offer`}>
        <div className='flex flex-col gap-4'>
          {[1, 2, 3, 4, 5].map((index) => (
            <div key={index} className='h-32 animate-pulse rounded-lg bg-primary-darkPurple/30' />
          ))}
        </div>
      </ContentWrapper>
    );
  }

  if (tireSetOffersError) {
    return <ApiErrorMessageWithTrans error={tireSetOffersError} />;
  }

  if (tireSetOffers.length === 0) {
    return (
      <ContentWrapper title={t`Tire offer`}>
        <h1>{t`You currently don't have any tire offers - if you would like to get a tire offer, please contact us at hei@noddi.no.`}</h1>
      </ContentWrapper>
    );
  }

  return (
    <ContentWrapper title={t`Tire offer`}>
      <div className='flex flex-col gap-4'>
        {tireSetOffers.map((tireSetOffer) => (
          <NoddiBasicCard
            key={tireSetOffer.slug}
            onClick={tireSetOffer.isSelected ? undefined : () => setActiveTireSetOfferId(tireSetOffer.id)}
          >
            <div className='flex items-center gap-1'>
              <NoddiIcon name='WheelAngle' />
              <p className='md:text-5'>{getCarDisplayName(tireSetOffer.car)}</p>
            </div>
            {tireSetOffer.isSelected ? (
              <div className='mt-2 flex flex-col gap-1'>
                <p>{t`Order confirmed`} 🎉</p>

                <p className='text-3'>{t`We will be in touch with you shortly when we have ordered the tires!`}</p>
              </div>
            ) : (
              <div className='mt-2 flex justify-end'>
                <NoddiDummyButton size='small' endIcon='ArrowRight'>{t`View offer`}</NoddiDummyButton>
              </div>
            )}
          </NoddiBasicCard>
        ))}
      </div>
      <NoddiDialog
        title={t`Select and order tire set`}
        open={!!activeTireSetOfferId}
        onClose={() => setActiveTireSetOfferId(null)}
      >
        {activeTireSetOfferId && (
          <ConfirmTireOffer tireSetOfferId={activeTireSetOfferId} setActiveTireSetOfferId={setActiveTireSetOfferId} />
        )}
      </NoddiDialog>
    </ContentWrapper>
  );
}
