import { t } from '@lingui/macro';
import { noddiAsync, URLKeys } from 'noddi-async';
import { Brand } from 'noddi-async/src/types';
import { ApiErrorMessage, cn, NoddiBasicCard, NoddiCircularLoader } from 'noddi-ui';
import { useBookingAddress } from '../BookingStore';
import { translateBookingCategories } from '../helpers/utils';

const getFormattedList = (items: string[]) => {
  if (items.length === 1) {
    return items[0];
  }

  if (items.length === 2) {
    return items.join(` ${t`and`} `);
  }

  return items.slice(0, -1).join(', ') + ` ${t`and`} ` + items.slice(-1);
};

interface Props {
  brands: Brand[];
}

export const BrandsAnnouncement = ({ brands }: Props) => {
  const bookingAddress = useBookingAddress();

  const {
    data: serviceCategories,
    error: serviceCategoriesError,
    isPending: isServiceCategoriesPending
  } = noddiAsync.useGet({
    type: URLKeys.getBookingCategoriesForNewBooking,
    input: { addressId: bookingAddress?.id },
    queryConfig: { enabled: !!bookingAddress?.id }
  });

  if (serviceCategoriesError) {
    return <ApiErrorMessage error={serviceCategoriesError} />;
  }

  if (isServiceCategoriesPending) {
    return (
      <NoddiBasicCard className='flex w-full items-center justify-center'>
        <NoddiCircularLoader />
      </NoddiBasicCard>
    );
  }

  if (!brands.length) {
    return null;
  }

  const brandList = getFormattedList(brands.map((brand) => brand.name));

  return (
    <NoddiBasicCard className='flex flex-col gap-6'>
      <span>{`${brandList} ${t`deliver these services to your address`} 🥳`}</span>

      {brands.map(({ logo, name, slug }) => {
        const brandCategories = serviceCategories?.filter((category) => category.brand.slug === slug);
        if (!brandCategories) {
          return null;
        }
        const serviceCategoryNames = getFormattedList(
          brandCategories.map((category) => translateBookingCategories(category.slug))
        );

        return (
          <div key={slug} className='flex items-center gap-4'>
            <img src={logo} alt={name} className='w-22 lg:w-32' />
            <p>{serviceCategoryNames}</p>
          </div>
        );
      })}
    </NoddiBasicCard>
  );
};

export const BrandBanner = ({ className, brand }: { className?: string; brand: Brand }) => {
  return (
    <div className={cn('flex items-center gap-4', className)}>
      <img src={brand.logo} alt={brand.name} className='w-16' />
      <p>{`${t`Services provided by`} ${brand.name}`}</p>
    </div>
  );
};
