import { Trans } from '@lingui/macro';
import { tracking } from 'noddi-provider';
import { NoddiBasicCard } from 'noddi-ui';
import { NoddiDummyButton } from 'noddi-ui-common';
import { customerRoutes } from 'noddi-util';
import { useNavigate } from 'react-router-dom';

export const ReferralCard = () => {
  const navigate = useNavigate();

  return (
    <NoddiBasicCard
      onClick={() => {
        tracking.track('referralPressedAfterBooking');
        navigate(customerRoutes.bonus.getPath());
      }}
      className='flex flex-col gap-4 bg-secondary-lightPurple text-primary-darkPurple'
    >
      <p className='font-bold text-5'>
        <Trans>Enjoying Noddi so far?</Trans>
      </p>

      <p>
        <Trans>Refer a friend and you both get up to 300 kr in discounts on future bookings</Trans>💜
      </p>
      <div className='flex justify-end'>
        <NoddiDummyButton variant='secondary' startIcon='ArrowSquareRightCorner' size='small'>
          <Trans>Invite a friend</Trans>
        </NoddiDummyButton>
      </div>
    </NoddiBasicCard>
  );
};
