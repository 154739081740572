import { NoddiButton } from 'noddi-ui-common';

type Props = {
  onNextButtonClick: () => void;
  onPrevButtonClick: () => void;
  prevBtnDisabled: boolean;
  nextBtnDisabled: boolean;
  translations: { nextButton: string; prevButton: string };
};

export const SliderArrows = ({
  nextBtnDisabled,
  onNextButtonClick,
  onPrevButtonClick,
  prevBtnDisabled,
  translations
}: Props) => {
  return (
    <div className='flex justify-between'>
      <NoddiButton
        variant='secondary'
        size='small'
        onPress={onPrevButtonClick}
        disabled={prevBtnDisabled}
        startIcon='AltArrowLeft'
      >
        {translations.prevButton}
      </NoddiButton>
      <NoddiButton
        size='small'
        variant='secondary'
        onPress={onNextButtonClick}
        endIcon='AltArrowRight'
        disabled={nextBtnDisabled}
      >
        {translations.nextButton}
      </NoddiButton>
    </div>
  );
};
