import { Trans, t } from '@lingui/macro';
import { useAuthContext } from 'noddi-provider';
import { FixedBottomBarContainer, NoddiBasicCard, getLongAddressName } from 'noddi-ui';

import { URLKeys, noddiAsync } from 'noddi-async';
import { NoddiIcon } from 'noddi-ui-common';
import { getBookingTimeWindowDisplayText } from 'noddi-util';
import HelpScoutChat from '../../../components/BookingFlow/HelpScoutChat';
import { CustomerSMSLogin } from '../../../components/CustomerSMSLogin';
import { ErrorPageWithTranslations } from '../../../components/ErrorPageWithTrans';
import { NoddiLoadingScreenWithTrans } from '../../../components/NoddiLoadingScreenWithTrans';
import { BrandBanner } from '../../BookingFlow/components/BrandAnnouncement';
import { PriceSummary } from '../../BookingFlow/components/PriceSummary';
import CommentBox from '../../BookingFlow/Steps/Summary/CommentBox';
import { useAutoRecallActions, useAutoRecallComment, useAutoRecallMetaData } from '../AutoRecallStore';
import { AutoRecallContentWrapper } from '../ContentWrapper';
import { ConfirmAutoRecall } from './ConfirmAutoRecall';

export const AutoRecallSummary = () => {
  const { isLoggedIn } = useAuthContext();

  const { setComment } = useAutoRecallActions();
  const comment = useAutoRecallComment();
  const { slug } = useAutoRecallMetaData();

  const {
    data: booking,
    isPending: isBookingPending,
    error: bookingError
  } = noddiAsync.useGet({
    type: URLKeys.getAutoRecallBooking,
    input: { slug: slug! }
  });

  if (isBookingPending) {
    return <NoddiLoadingScreenWithTrans />;
  }

  if (bookingError) {
    return <ErrorPageWithTranslations apiError={bookingError} />;
  }

  const { deliveryWindowStartsAt, deliveryWindowEndsAt, address } = booking;

  const selectedBookingTimeWindow = {
    startsAt: deliveryWindowStartsAt,
    endsAt: deliveryWindowEndsAt,
    timeZone: address.timeZone
  };

  const timeWindowDisplay = selectedBookingTimeWindow
    ? getBookingTimeWindowDisplayText(selectedBookingTimeWindow)
    : t`Problems with the last time slot`;

  return (
    <AutoRecallContentWrapper title={t`Does this look correct?`}>
      <div className='flex flex-col gap-4'>
        {isLoggedIn ? <CommentBox comment={comment} handleChange={setComment} /> : <CustomerSMSLogin />}

        <NoddiBasicCard className='flex flex-col gap-3'>
          {address && (
            <div className='flex gap-2'>
              <NoddiIcon name='LocationPin' />
              <p>{getLongAddressName(address)}</p>
            </div>
          )}
          <div className='flex gap-2'>
            <NoddiIcon name='Calendar' />
            <p>{timeWindowDisplay}</p>
          </div>
          <div className='flex gap-2'>
            <NoddiIcon name='Phone' />
            <p>
              <Trans>We'll text you the day before with estimated arrival time and when you're next on the list</Trans>
            </p>
          </div>
        </NoddiBasicCard>

        <NoddiBasicCard>
          <BrandBanner brand={booking.brand} className='mb-3' />
          <PriceSummary shoppingCart={booking} />
        </NoddiBasicCard>
      </div>
      <FixedBottomBarContainer>
        <HelpScoutChat />
        <ConfirmAutoRecall />
      </FixedBottomBarContainer>
    </AutoRecallContentWrapper>
  );
};
