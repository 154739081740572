import { noddiAsync, URLKeys } from 'noddi-async';
import { BOOKING_TYPES } from 'noddi-async/src/types';
import { tracking } from 'noddi-provider';
import { customerRoutes } from 'noddi-util';
import { useNavigate } from 'react-router-dom';
import { useAutoRecallActions, useAutoRecallMetaData } from '../../AutoRecall/AutoRecallStore';
import { useBookingActions, useBookingSelectedSalesItemsCars, useBookingType } from '../BookingStore';
import { timeWindowStepPath } from './bookingFlowPaths';
import { getSalesItemCarsPayload } from './utils';

export const useNavigateToTimePickerOrAutoRecallPage = () => {
  const navigate = useNavigate();

  const { bookingId, slug } = useAutoRecallMetaData();
  const type = useBookingType();
  const { clearStore } = useBookingActions();
  const selectedSalesItemsCars = useBookingSelectedSalesItemsCars();

  const { setHasInvalidTimeWindow } = useAutoRecallActions();

  const {
    data: potentialConflicts,
    isLoading: isPotentialConflictsLoading,
    error
  } = noddiAsync.useGet({
    type: URLKeys.getPotentialConflictsOnServicesChange,
    input: {
      bookingId: bookingId!,
      selectedSalesItemIds: selectedSalesItemsCars.map(({ salesItemId }) => salesItemId)
    },
    // Only relevant for recall bookings for now. When normal patch bookings are implemented, this should be included in the queryConfig
    queryConfig: {
      enabled: type === BOOKING_TYPES.AUTO_RECALL && !!selectedSalesItemsCars.length
    }
  });

  // Only relevant for recall bookings for now. When normal patch bookings are implemented it will use normal patchBooking endpoint here
  const {
    mutateAsync: patchBooking,
    isPending: isPatchBookingPending,
    error: patchBookingError
  } = noddiAsync.usePatch({
    type: URLKeys.patchRecallBooking,
    queryConfig: {
      onSuccess: async () => {
        tracking.track('autoRecallEditCarsAndServices');
        clearStore();
        navigate(customerRoutes.autoRecallLandingPage.getPath({ slug: slug! }));
      }
    }
  });

  const navigateAfterPickingSalesItems = async () => {
    if (type !== BOOKING_TYPES.AUTO_RECALL) {
      navigate(timeWindowStepPath);
      return;
    }

    if (potentialConflicts) {
      setHasInvalidTimeWindow(potentialConflicts.isDeliveryWindowConflicting);
      // For normal patch bookings ( will be implemented later) we want to return here,
      // but for auto recall we want to patch the booking bc they are going to picker anyways

      await patchBooking({
        id: bookingId!,
        cars: getSalesItemCarsPayload(selectedSalesItemsCars)
      });
    }
  };

  return {
    navigateAfterPickingSalesItems,
    isPatchBookingPending,
    patchBookingError: patchBookingError || error,
    isPotentialConflictsLoading
  };
};
