import { t } from '@lingui/macro';
import groupBy from 'lodash/groupBy';
import {
  AvailableSalesItemsForBooking,
  Money,
  SALES_ITEM_TYPE,
  SalesItemsCar,
  SalesItemV2
} from 'noddi-async/src/types';
import { NoddiFeedbackBox } from 'noddi-ui';
import { NoddiButton } from 'noddi-ui-common';
import { ErrorPageWithTranslations } from '../../../../components/ErrorPageWithTrans';
import { getUniqueSalesItems, numberOfSelectedItemsFleets } from '../../../../utils/salesItem';
import { useBookingActions, useBookingCars, useBookingSelectedSalesItemsCars } from '../../BookingStore';
import { CarIdentifierWithIcon } from '../../components/CarIdentifierWithIcon';
import { compareLicensePlates } from '../../helpers/utils';
import { ServiceCategoryFleetCollapseCard } from './ServiceCategoryFleetCollapseCard';

type CarWithoutSalesItems = Omit<SalesItemsCar, 'salesItems'>;

export type FleetCarWithSalesItemProperties = CarWithoutSalesItems & {
  isAddonsAvailable: SalesItemV2['isAddonsAvailable'];
  isWheelPickupRequired: SalesItemV2['isWheelPickupRequired'];
  mutuallyExclusiveSalesItemIds: SalesItemV2['mutuallyExclusiveSalesItemIds'];
  salesItemId: SalesItemV2['salesItemId'];
};

// CU-86c26e3jq
// Todo, not clear to me which description, which shortDescription, and which price?
// Now its only first one that is found when mapping? maybe change name to groupedDescription, groupedShortDescription, priceFrom?

export interface ServiceItemsMappedForFleetWithCars {
  name: string;
  description: string;
  uiSortOrder: number;
  shortDescription: string;
  bookingCategorySlug: string | null;
  price: Money;
  cars: FleetCarWithSalesItemProperties[];
}

export const SalesItemsMultipleCarsSelector = ({
  availableSalesItems,
  salesItemType
}: {
  availableSalesItems: AvailableSalesItemsForBooking;
  salesItemType: SALES_ITEM_TYPE;
}) => {
  const selectedSalesItemsCars = useBookingSelectedSalesItemsCars();

  const uniqueSalesItems = getUniqueSalesItems(availableSalesItems);
  const { removeCar } = useBookingActions();
  const bookingCars = useBookingCars();

  const sortedSalesItems = uniqueSalesItems.toSorted((a, b) => a.uiSortOrder - b.uiSortOrder);

  const bookingCategories = groupBy(sortedSalesItems, 'bookingCategorySlug');
  const numberOfCategories = Object.keys(bookingCategories).length;

  const onlyOneCategory = numberOfCategories === 1;

  const mountedAsExpanded = salesItemType === SALES_ITEM_TYPE.ADDON || onlyOneCategory;

  if (!numberOfCategories) {
    return <ErrorPageWithTranslations />;
  }
  // find out if salesItems is empty array for cars - and if so return that
  const carsWithNoAvailableSalesItems = availableSalesItems.cars.filter((car) => car.salesItems.length === 0);

  return (
    <>
      {carsWithNoAvailableSalesItems.map((salesItemCar) => {
        const carName = `${salesItemCar.make} ${salesItemCar.model} - ${salesItemCar.licensePlate.number}`;
        const car = bookingCars.find((car) => compareLicensePlates(car.licensePlate, salesItemCar.licensePlate));

        if (!car) {
          return null;
        }

        if (salesItemType === SALES_ITEM_TYPE.PRIMARY) {
          return (
            <div key={salesItemCar.licensePlate.number} className='flex flex-col gap-6'>
              <NoddiFeedbackBox
                variant='warning'
                title={t`We do not provide tire hotel services in this area`}
                description={t`${carName} has an active tire hotel contract, but we do not provide tire hotel services in this area - please contact us at hei@noddi.no if you need help with this`}
              />
              <div className='flex justify-end'>
                <NoddiButton variant='secondary' size='small' onPress={() => removeCar(car)}>
                  {t`Remove car from booking`}
                </NoddiButton>
              </div>
            </div>
          );
        }

        return null;
      })}

      {Object.entries(bookingCategories).map(([category, salesItems]) => {
        const numberOfSelectedSalesItems = numberOfSelectedItemsFleets({
          salesItemsInCategory: salesItems,
          selectedSalesItems: selectedSalesItemsCars
        });

        return (
          <ServiceCategoryFleetCollapseCard
            key={category}
            category={category}
            salesItems={salesItems}
            numberOfSelectedSalesItems={numberOfSelectedSalesItems}
            salesItemType={salesItemType}
            mountAsExpanded={mountedAsExpanded}
          />
        );
      })}

      {carsWithNoAvailableSalesItems.map((salesItemCar) => {
        const car = bookingCars.find((car) => compareLicensePlates(car.licensePlate, salesItemCar.licensePlate));

        if (!car) {
          return null;
        }

        if (salesItemType === SALES_ITEM_TYPE.ADDON) {
          return (
            <div key={salesItemCar.licensePlate.number} className='flex flex-col gap-6'>
              <CarIdentifierWithIcon hideTireHotelInfo car={car} />
              <NoddiFeedbackBox
                description={t`Unfortunately, we don't have offer any addons for the services you've selected on this car`}
              />
            </div>
          );
        }

        return null;
      })}
    </>
  );
};
