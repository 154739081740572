import { Money } from 'noddi-async/src/types';
import { TireInQuoteItem } from 'noddi-async/src/types/shared/quotes';
import { Tire } from 'noddi-async/src/types/shared/tireFromInventory';

export function getDimensionsString(tire: Tire): string {
  return `${tire.dimensionWidthMm}/${tire.dimensionProfile}R${tire.dimensionRimInch}`;
}
export function getTirePrice(tireQuoteItem: TireInQuoteItem): Money {
  return tireQuoteItem?.priceAdHoc ?? tireQuoteItem.tireInventory.salesItem.price;
}

export function getQuantityOptions(quantityMax: number): { label: string; value: number }[] {
  return Array.from({ length: quantityMax }, (_, i) => ({
    label: `${i + 1} stk`,
    value: i + 1
  }));
}
