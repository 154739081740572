import { Trans } from '@lingui/macro';
import { useAuthContext } from 'noddi-provider';
import { NoddiButton } from 'noddi-ui-common';

import { customerRoutes } from 'noddi-util';
import { useNavigate } from 'react-router-dom';
import { useBookingAddress, useBookingTimeWindow } from '../../BookingStore';
import { useCreateBooking } from '../../helpers/useCreateBooking';

export const ConfirmBooking = () => {
  const selectedAddress = useBookingAddress();
  const bookingTimeWindow = useBookingTimeWindow();
  const navigate = useNavigate();

  const { currentUserGroupId: userGroupId, userData } = useAuthContext();

  const { createNewBooking } = useCreateBooking({
    onMutate: () => {
      navigate(customerRoutes.newBookingBuilding.getPath());
    }
  });

  return (
    <div className='flex w-full items-center justify-end gap-2'>
      <NoddiButton
        variant='success'
        disabled={!userGroupId || !userData || !selectedAddress || !bookingTimeWindow}
        onPress={createNewBooking}
      >
        <Trans>Confirm booking</Trans>
      </NoddiButton>
    </div>
  );
};
