import { Trans, t } from '@lingui/macro';
import { NoddiBasicCard } from 'noddi-ui';
import { useNavigate } from 'react-router-dom';

import { NoddiDummyButton } from 'noddi-ui-common';
import { customerRoutes } from 'noddi-util';
import ContentWrapper from '../../components/Layouts/ContentWrapper';
import { ReferralCard } from './ReferralCard';

interface BookingConfirmationProps {
  id: string;
}

const BookingConfirmation = ({ id }: BookingConfirmationProps) => {
  const navigate = useNavigate();

  return (
    <ContentWrapper hideBackButton title={t`Booking confirmed`}>
      <div className='flex flex-col gap-8'>
        <div className='flex flex-col gap-4'>
          <NoddiBasicCard
            onClick={() => navigate(customerRoutes.myBookingDetails.getPath({ id }))}
            className='flex flex-col gap-4 text-primary-darkPurple'
          >
            <p>
              <Trans>A confirmation message will be sent to you via SMS shortly.</Trans>
            </p>
            <div className='flex flex-col gap-1'>
              <p className='font-bold'>
                <Trans>Payment information</Trans> 💰
              </p>
              <p>
                <Trans>Once the job is complete, you'll receive a payment link via SMS.</Trans>
              </p>
            </div>
            <div className='flex justify-end'>
              <NoddiDummyButton endIcon='ArrowRight' size='small'>
                <Trans>See booking</Trans>
              </NoddiDummyButton>
            </div>
          </NoddiBasicCard>
        </div>
        <ReferralCard />
      </div>
    </ContentWrapper>
  );
};

export default BookingConfirmation;
