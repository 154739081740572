import { t } from '@lingui/macro';
import { ErrorPageWithTranslations } from '../../../components/ErrorPageWithTrans';
import { useCleanUpAndNavigateToNewBooking } from '../../../components/NewBookingButton/NewBookingButton';

export const NewBookingErrorPage = () => {
  const { cleanUpAndNavigateToNewBooking } = useCleanUpAndNavigateToNewBooking();
  return (
    <ErrorPageWithTranslations
      errorMessage={t`Something didn't go as planned while filling in your booking details. Please try again`}
      customCallback={{ callback: () => cleanUpAndNavigateToNewBooking(), ctaText: t`New booking` }}
    />
  );
};
