import { screens } from 'noddi-ui-common/tailwind-design-preset';
import { useCallback, useEffect, useState } from 'react';

export const useIsWithinTailWindBreakpoint = (breakpoint: keyof typeof screens) => {
  const createMediaQuery = useCallback(() => {
    const width = parseInt(screens[breakpoint].replace('px', ''));
    return window.matchMedia(`(max-width: ${width}px)`);
  }, [breakpoint]);

  const [matches, setMatches] = useState(() => createMediaQuery().matches);

  useEffect(() => {
    const mediaQuery = createMediaQuery();
    const updateMatch = () => setMatches(mediaQuery.matches);

    mediaQuery.addEventListener('change', updateMatch);
    return () => mediaQuery.removeEventListener('change', updateMatch);
  }, [breakpoint, createMediaQuery]);

  return matches;
};
