import { Stack, Typography } from '@mui/material';

import { BookingInfo, BookingInfoStatus } from 'noddi-async';
import { DateFormats, noddiFormatDate } from 'noddi-util';
import styled, { keyframes } from 'styled-components';

import { Trans } from '@lingui/macro';
import { noddiColors } from 'noddi-ui-common';
import RelevantQuestionsForBooking from './RelevantQuestionsForBooking';

const Circle = styled.div`
  width: 15px;
  height: 15px;
  min-width: 15px;
  min-height: 15px;
  background-color: ${noddiColors.primary.darkPurple};
  border-radius: 50%;
  position: relative;
`;

const rotate = keyframes`
    0% {-webkit-transform: scale(0.1, 0.1); opacity: 0.0;}
    50% {opacity: 1.0;}
    100% {-webkit-transform: scale(1.2, 1.2); opacity: 0.0;}
`;

const Ringring = styled.div`
  border: 3px solid ${noddiColors.primary.darkPurple};
  -webkit-border-radius: 30px;
  height: 25px;
  width: 25px;
  position: absolute;
  left: -5px;
  top: -5px;
  -webkit-animation: ${rotate} 1s ease-out;
  -webkit-animation-iteration-count: infinite;
  opacity: 0;
`;

export function BookingContentBasedOnStatus({
  status,
  bookingInfo,
  currentEstimatedArrivalTime
}: {
  status: BookingInfoStatus;
  bookingInfo: BookingInfo;
  currentEstimatedArrivalTime?: { start?: string; end?: string };
}) {
  const delayInMinutes = Math.floor(bookingInfo.delayInSeconds / 60);
  const showDelayedMessage = delayInMinutes > 30;
  const showAheadOfScheduleMessage = delayInMinutes < -30;
  const showCommunicatedTimes = !showAheadOfScheduleMessage && !showDelayedMessage;
  const hasLiveEstimate = currentEstimatedArrivalTime?.end && currentEstimatedArrivalTime?.start;

  const CommunicatedArrivalTimes = () => (
    <>
      {bookingInfo.communicatedArrivalWindow.start && bookingInfo.communicatedArrivalWindow.end ? (
        <Stack direction='row'>
          {hasLiveEstimate && (
            <Circle style={{ marginRight: 12, marginTop: 4 }}>
              <Ringring />
            </Circle>
          )}
          <Typography>
            {bookingInfo.serviceWorkerNickname
              ? `Vår tekniker ${bookingInfo.serviceWorkerNickname} forventes`
              : 'Vi for venter'}{' '}
            å komme til deg mellom{' '}
            <b>{noddiFormatDate(bookingInfo.communicatedArrivalWindow.start, DateFormats.TIME)}</b> og{' '}
            <b>{noddiFormatDate(bookingInfo.communicatedArrivalWindow.end, DateFormats.TIME)}</b>
          </Typography>
        </Stack>
      ) : null}

      {!hasLiveEstimate && (
        <p className='mt-3'>
          <Trans>You will be notified when we are on our way to you!</Trans>
        </p>
      )}
    </>
  );
  const CurrentEstimatedTimes = () =>
    hasLiveEstimate ? (
      <Stack direction='row'>
        <Circle style={{ marginRight: 12, marginTop: 4 }}>
          <Ringring />
        </Circle>
        <Typography width='80%'>
          {showAheadOfScheduleMessage
            ? 'Vi er litt foran skjemaet og ankommer muligens litt før den opprinnelige tiden.'
            : 'Vi er litt bak skjemaet og ankommer muligens etter den opprinnelige tiden.'}{' '}
          {bookingInfo.serviceWorkerNickname
            ? `Vår tekniker ${bookingInfo.serviceWorkerNickname} forventes`
            : 'Vi for venter'}{' '}
          å komme til deg mellom{' '}
          <b>
            kl{' '}
            {currentEstimatedArrivalTime?.start
              ? noddiFormatDate(currentEstimatedArrivalTime.start, DateFormats.TIME)
              : '-'}
          </b>{' '}
          og{' '}
          <b>
            kl{' '}
            {currentEstimatedArrivalTime?.end
              ? noddiFormatDate(currentEstimatedArrivalTime.end, DateFormats.TIME)
              : '-'}
          </b>
        </Typography>
      </Stack>
    ) : (
      // fallback to communicated times if we don't have currentEstimatedArrivalTime
      <CommunicatedArrivalTimes />
    );

  switch (status) {
    case BookingInfoStatus.DRAFT:
      return <></>;
    case BookingInfoStatus.CANCELLED:
    case BookingInfoStatus.UNABLE_TO_COMPLETE:
      return (
        <Stack>
          <Typography>
            {' '}
            {status === BookingInfoStatus.UNABLE_TO_COMPLETE ? (
              <Trans>We were unable to complete this booking. Please contact us for more information.</Trans>
            ) : (
              <Trans>This booking is listed as cancelled in our systems.</Trans>
            )}
          </Typography>
        </Stack>
      );
    case BookingInfoStatus.ASSIGNED_TO_ROUTE:
    case BookingInfoStatus.CONFIRMED:
      return (
        <>
          {showCommunicatedTimes ? <CommunicatedArrivalTimes /> : <CurrentEstimatedTimes />}

          <div style={{ marginTop: 22 }}>
            <RelevantQuestionsForBooking />
          </div>
        </>
      );

    default:
      return <div />;
  }
}
