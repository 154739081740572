import { cva, type VariantProps } from 'class-variance-authority';

import { IconName, IconSize, noddiColors, NoddiIcon, webButtonStyling } from 'noddi-ui-common';
import { MouseEventHandler } from 'react';
import { cn } from '../helpers/utils';

// Don't add hover as effect persists on mobile after pressed, which makes the button look disabled.
const iconButtonVariants = cva(cn('relative rounded-full p-1 active:scale-90 disabled:opacity-50', webButtonStyling), {
  variants: {
    variant: {
      primary: 'bg-primary-purple',
      secondary: 'border border-primary-darkPurple bg-primary-white',
      destructive: 'bg-signal-danger50',
      ghost: ''
    }
  },
  defaultVariants: {
    variant: 'primary'
  }
});

const iconColorVariant = cva('', {
  variants: {
    variant: {
      primary: noddiColors.primary.white,
      secondary: noddiColors.primary.darkPurple,
      destructive: noddiColors.primary.orange,
      ghost: noddiColors.primary.black
    }
  },
  defaultVariants: {
    variant: 'primary'
  }
});

const loadingSpinnerVariants = cva(
  'absolute inset-0 box-border size-full animate-spin-slow rounded-full border-2 border-systemColors-transparent',
  {
    variants: {
      variant: {
        primary: 'border-t-primary-white',
        secondary: 'border-t-primary-darkPurple',
        destructive: 'border-t-primary-orange',
        ghost: 'border-t-primary-black'
      }
    },
    defaultVariants: {
      variant: 'primary'
    }
  }
);

interface NoddiIconButtonProps extends VariantProps<typeof iconButtonVariants> {
  iconName: IconName;
  iconSize?: IconSize;
  onClick?: MouseEventHandler<HTMLButtonElement> | (() => void);
  isLoading?: boolean;
  className?: string;
  color?: string;
  disabled?: boolean;
}

export const NoddiIconButton = ({
  iconName,
  iconSize = 'medium',
  variant = 'secondary',
  onClick,
  isLoading,
  className,
  disabled,
  // This is a workaround to avoid passing color prop to NoddiIcon ignoring variants but is handy escape hatch
  color
}: NoddiIconButtonProps) => {
  return (
    <button
      type='button'
      disabled={!!disabled}
      className={cn(iconButtonVariants({ variant }), className)}
      onClick={onClick}
    >
      <NoddiIcon name={iconName} size={iconSize} color={color ?? iconColorVariant({ variant })} />
      {isLoading && <div className={loadingSpinnerVariants({ variant })} />}
    </button>
  );
};
