import { useLanguageContext } from 'noddi-provider';
import { NoddiFeedbackBox } from 'noddi-ui';
import { MaintenanceScreen as MaintenanceScreenType } from '../utils/remoteConfigSetup';
import ContentWrapper from './Layouts/ContentWrapper';
import { CustomerHeaderNav } from './Layouts/CustomerHeaderNav';

export const MaintenanceScreen = ({ maintenanceScreen }: { maintenanceScreen: MaintenanceScreenType }) => {
  return (
    <div className='min-h-screen bg-pint-to-lighterPint-gradient'>
      <CustomerHeaderNav />
      <MaintenanceContent maintenanceScreen={maintenanceScreen} />
    </div>
  );
};

export const MaintenanceContent = ({ maintenanceScreen }: { maintenanceScreen: MaintenanceScreenType }) => {
  const { currentLanguage } = useLanguageContext();

  const getCurrentLanguageText = (text: MaintenanceScreenType['title']) => {
    switch (currentLanguage) {
      case 'se':
        return text.swedish;
      case 'nb':
        return text.norwegian;
      case 'en':
        return text.english;
    }
  };

  const title = getCurrentLanguageText(maintenanceScreen.title);
  const subtitle = getCurrentLanguageText(maintenanceScreen.subtitle);
  return (
    <ContentWrapper hideBackButton widerScreen>
      <div className='mx-auto flex min-h-[60vh] max-w-2xl flex-col items-center justify-center p-4 text-center md:p-10'>
        <NoddiFeedbackBox title={title} description={subtitle} />
      </div>
    </ContentWrapper>
  );
};
