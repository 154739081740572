import { t } from '@lingui/macro';
import { Skeleton } from '@mui/material';
import { tracking } from 'noddi-provider';
import { cn, NoddiBasicCard, NoddiDialog } from 'noddi-ui';
import { formatCurrencyAmount } from 'noddi-util';
import { useState } from 'react';

import { LicensePlateInfo, SALES_ITEM_TYPE, SalesItemV2 } from 'noddi-async/src/types';
import { GestureResponderEvent } from 'react-native';
import { useBookingActions, useBookingSelectedSalesItemsCars } from '../../BookingStore';
import { compareLicensePlates } from '../../helpers/utils';
import { useGetUnavailableSalesItems } from '../../Steps/Services/useGetUnavailableSalesItems';
import { SalesItemButtons } from './SalesItemButtons';
import { UnavailableModal } from './UnavailableModal';

type SalesItemProps = {
  salesItem: SalesItemV2;
  licensePlate: LicensePlateInfo;
  className?: string;
  type: SALES_ITEM_TYPE;
};

export const SalesItem = ({ salesItem, licensePlate, className, type }: SalesItemProps) => {
  const [showUnavailableModal, setShowUnavailableModal] = useState(false);
  const [showFullDescription, setShowFullDescription] = useState(false);

  const selectedSalesItemsCars = useBookingSelectedSalesItemsCars();
  const { addSalesItem, removeSalesItem } = useBookingActions();
  const { isUnavailableSalesItemsLoading, unavailableSalesItems } = useGetUnavailableSalesItems();

  const {
    name,
    shortDescription,
    description,
    price,
    salesItemId,
    isAddonsAvailable,
    isWheelPickupRequired,
    mutuallyExclusiveSalesItemIds
  } = salesItem;

  const isAlreadySelected = selectedSalesItemsCars.some(
    (item) => compareLicensePlates(item.licensePlate, licensePlate) && item.salesItemId === salesItemId
  );

  const isUnavailable = unavailableSalesItems?.data.some(
    (unavailableSalesItem) => unavailableSalesItem.salesItemId === salesItemId
  );

  const isFree = price.amount === 0;
  const priceFormatted = isFree ? t`Free` : formatCurrencyAmount(price.amount, 0, price.currency);

  const handleSalesItemToggle = () => {
    isAlreadySelected
      ? removeSalesItem({
          licensePlate,
          salesItemId,
          type
        })
      : addSalesItem({
          licensePlate,
          salesItemId,
          type,
          mutuallyExclusiveSalesItemIds,
          isAddonsAvailable,
          isWheelPickupRequired
        });
  };
  // If sales item is unavailable show modal with explanation
  // Otherwise, add or remove sales item
  const handleSalesItemCardClick = () => {
    isUnavailable ? setShowUnavailableModal(true) : handleSalesItemToggle();
  };

  const onShowFullDescriptionClick = (e: GestureResponderEvent) => {
    tracking.track('salesItemDescriptionDetailsClicked', { salesItemName: name });
    setShowFullDescription(true);
    e.stopPropagation();
  };

  const showSkeletonLoader = isUnavailableSalesItemsLoading && !isAlreadySelected;

  // Remove trailing period from shortDescription if it exists
  const displayShortDescription = shortDescription.endsWith('.') ? shortDescription.slice(0, -1) : shortDescription;

  return (
    <>
      <UnavailableModal
        salesItem={salesItem}
        open={showUnavailableModal}
        onClose={() => setShowUnavailableModal(false)}
        unavailableSalesItems={unavailableSalesItems?.data}
      />
      <NoddiDialog title={name} onClose={() => setShowFullDescription(false)} open={showFullDescription}>
        {description}
      </NoddiDialog>
      <NoddiBasicCard
        onClick={isUnavailableSalesItemsLoading ? undefined : handleSalesItemCardClick}
        className={cn('flex flex-col gap-2', isAlreadySelected && 'bg-primary-purple text-primary-white', className)}
      >
        <p className='text-5'>{name}</p>
        <p className={cn('font-semibold', isFree && !isAlreadySelected && 'text-signal-success')}>{priceFormatted}</p>
        {!!displayShortDescription && <p>{displayShortDescription}</p>}
        <div className='flex h-12 items-end justify-end'>
          {showSkeletonLoader ? (
            <Skeleton variant='rounded' height={30} width={100} />
          ) : (
            <SalesItemButtons
              isUnavailable={isUnavailable}
              description={description}
              isSelected={isAlreadySelected}
              onShowFullDescriptionClick={onShowFullDescriptionClick}
            />
          )}
        </div>
      </NoddiBasicCard>
    </>
  );
};
