import { cva, type VariantProps } from 'class-variance-authority';
import { noddiColors, NoddiIcon, webButtonStyling } from 'noddi-ui-common';
import { PropsWithChildren } from 'react';
import { Pressable, PressableProps, View } from 'react-native';
import { cn, isMobile, isWeb } from '../utils';

type NoddiSelectableBoxProps = {
  isSelected: boolean;
  setIsSelected: (isSelected: boolean) => void;
} & PressableProps &
  VariantProps<typeof selectableVariants>;

const selectableVariants = cva(
  cn(
    'flex w-full flex-row items-center gap-4 rounded-lg border px-3 py-2 text-start',
    isMobile && ' active:opacity-50',
    isWeb && webButtonStyling
  ),
  {
    variants: {
      variant: {
        primary: cn('bg-systemColors-purpleBg'),
        secondary: cn('border-primary-darkPurple bg-primary-white')
      }
    },
    defaultVariants: {
      variant: 'primary'
    }
  }
);

const iconColorVariants = cva('', {
  variants: {
    variant: {
      primary: noddiColors.primary.black,
      secondary: noddiColors.primary.darkPurple
    }
  },
  defaultVariants: {
    variant: 'primary'
  }
});

const NoddiSelectableBox = ({
  children,
  isSelected,
  setIsSelected,
  variant,
  ...props
}: PropsWithChildren<NoddiSelectableBoxProps>) => {
  const style = selectableVariants({ variant });
  const iconColor = iconColorVariants({ variant });

  return (
    <Pressable accessibilityRole='button' onPress={() => setIsSelected(!isSelected)} className={style} {...props}>
      <View>
        {isSelected ? (
          <NoddiIcon name='CheckedRectangle' color={iconColor} />
        ) : (
          <NoddiIcon name='Rectangle' color={iconColor} />
        )}
      </View>
      {children}
    </Pressable>
  );
};

export { NoddiSelectableBox };
