import { t, Trans } from '@lingui/macro';
import { NoddiButton } from 'noddi-ui-common';
import { customerRoutes } from 'noddi-util';
import { useNavigate } from 'react-router-dom';
import ContentWrapper from '../../../components/Layouts/ContentWrapper';
import NewBookingButton from '../../../components/NewBookingButton/NewBookingButton';

export const CancelledBookingFeedBack = () => {
  const navigate = useNavigate();

  return (
    <ContentWrapper>
      <div>
        <p className='mt-20 font-bold text-10 text-primary-darkPurple'>{t`Thank you for your feedback!`}</p>
        <p className='mt-7 font-bold text-7 text-primary-purple'>{t`We hope to see you back again soon`} 🚀</p>

        <div className='mt-10 flex w-full items-center gap-4 lg:mt-40'>
          <NoddiButton
            variant='secondary'
            className='flex-1 self-end lg:flex-none'
            onPress={() => navigate(customerRoutes.homepage.getPath())}
          >
            <Trans>Close</Trans>
          </NoddiButton>
          <NewBookingButton className='flex-1 lg:flex-none' defaultSize />
        </div>
      </div>
    </ContentWrapper>
  );
};
