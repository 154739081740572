import { Trans, t } from '@lingui/macro';
import { useAuthContext } from 'noddi-provider';
import { FixedBottomBarContainer, NoddiBasicCard, getLongAddressName } from 'noddi-ui';

import { URLKeys, noddiAsync } from 'noddi-async';
import { AvailableBookingTimeWindow, BookingAddress, SelectedSalesItemsCar } from 'noddi-async/src/types';
import { NoddiIcon } from 'noddi-ui-common';
import { getBookingTimeWindowDisplayText } from 'noddi-util';
import HelpScoutChat from '../../../../components/BookingFlow/HelpScoutChat';
import { CustomerSMSLogin } from '../../../../components/CustomerSMSLogin';
import { ErrorPageWithTranslations } from '../../../../components/ErrorPageWithTrans';
import { NoddiLoadingScreenWithTrans } from '../../../../components/NoddiLoadingScreenWithTrans';
import {
  useBookingActions,
  useBookingAddress,
  useBookingComment,
  useBookingSelectedSalesItemsCars,
  useBookingTimeWindow
} from '../../BookingStore';
import { BookingScreen } from '../../components/BookingScreen';
import { BrandBanner } from '../../components/BrandAnnouncement';
import { NewBookingErrorPage } from '../../components/NewBookingErrorPage';
import { PriceSummary } from '../../components/PriceSummary';
import { groupCars } from '../../helpers/utils';
import CommentBox from './CommentBox';
import { ConfirmBooking } from './ConfirmBooking';

export const Summary = () => {
  const selectedSalesItemsCars = useBookingSelectedSalesItemsCars();
  const bookingTimeWindow = useBookingTimeWindow();
  const selectedAddress = useBookingAddress();

  if (!selectedAddress || !bookingTimeWindow || !selectedSalesItemsCars.length) {
    return <NewBookingErrorPage />;
  }
  return (
    <SummaryContent
      selectedSalesItemsCars={selectedSalesItemsCars}
      bookingTimeWindow={bookingTimeWindow}
      selectedAddress={selectedAddress}
    />
  );
};

const SummaryContent = ({
  selectedSalesItemsCars,
  bookingTimeWindow,
  selectedAddress
}: {
  selectedSalesItemsCars: SelectedSalesItemsCar[];
  bookingTimeWindow: AvailableBookingTimeWindow;
  selectedAddress: BookingAddress;
}) => {
  const { isLoggedIn } = useAuthContext();

  // TODO | New booking I guess we want to debounce this value, as now confirmBooking.tsx which subscribes is rerendered on every key stroke
  const { setComment } = useBookingActions();

  const { currentUserGroupId: userGroupId } = useAuthContext();
  const comment = useBookingComment();

  const {
    data: shoppingCart,
    isPending: isShoppingCartPending,
    error: shoppingCartError
  } = noddiAsync.usePostAsGetQuery({
    type: URLKeys.postGetShoppingCartForNewBooking,
    input: {
      addressId: selectedAddress?.id,
      cars: groupCars(selectedSalesItemsCars),
      deliveryWindow: bookingTimeWindow
        ? {
            id: bookingTimeWindow.deliveryWindowId,
            endsAt: bookingTimeWindow.endsAt,
            startsAt: bookingTimeWindow.startsAt
          }
        : undefined,
      userGroupId
    },
    queryConfig: {
      staleTime: Infinity
    }
  });

  if (shoppingCartError) {
    return <ErrorPageWithTranslations apiError={shoppingCartError} />;
  }

  if (isShoppingCartPending) {
    return <NoddiLoadingScreenWithTrans />;
  }

  const timeWindowDisplay = bookingTimeWindow
    ? getBookingTimeWindowDisplayText(bookingTimeWindow)
    : t`Problems with the last time slot`;

  return (
    <BookingScreen
      title={t`Does this look correct?`}
      customBottomBar={
        <FixedBottomBarContainer>
          <HelpScoutChat />
          <ConfirmBooking />
        </FixedBottomBarContainer>
      }
    >
      <div className='flex flex-col gap-4'>
        {isLoggedIn ? (
          <CommentBox comment={comment} handleChange={setComment} />
        ) : (
          <NoddiBasicCard>
            <CustomerSMSLogin />
          </NoddiBasicCard>
        )}

        <NoddiBasicCard className='flex flex-col gap-3'>
          {selectedAddress && (
            <div className='flex gap-2'>
              <NoddiIcon name='LocationPin' />
              <p>{getLongAddressName(selectedAddress)}</p>
            </div>
          )}
          <div className='flex gap-2'>
            <NoddiIcon name='Calendar' />
            <p>{timeWindowDisplay}</p>
          </div>
          <div className='flex gap-2'>
            <NoddiIcon name='Phone' />
            <p>
              <Trans>We'll text you the day before with estimated arrival time and when you're next on the list</Trans>
            </p>
          </div>
        </NoddiBasicCard>

        <NoddiBasicCard>
          {selectedAddress.brands[0]?.logo && <BrandBanner brand={selectedAddress.brands[0]} className='mb-3' />}
          <PriceSummary shoppingCart={shoppingCart.data} />
        </NoddiBasicCard>
      </div>
    </BookingScreen>
  );
};
