import { NoddiButton } from 'noddi-ui-common';
import { NoddiFeedbackBox } from '../../../molecules';

type ErrorPageBaseProps = {
  title: string;
  description: string;
  buttonText?: string;
  onButtonClick?: () => void;
};

export const ErrorPageBase = ({ title, description, buttonText, onButtonClick }: ErrorPageBaseProps) => {
  return (
    <div className='mx-auto flex min-h-[60vh] max-w-2xl flex-col items-center justify-center p-4 text-center md:p-10'>
      <NoddiFeedbackBox variant='error' title={title} description={description} />
      {buttonText && onButtonClick && (
        <NoddiButton onPress={onButtonClick} className='mt-6'>
          {buttonText}
        </NoddiButton>
      )}
    </div>
  );
};
