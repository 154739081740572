import { t } from '@lingui/macro';
import { ButtonProps, NoddiButton } from 'noddi-ui-common';
import { useNavigate } from 'react-router-dom';

import { customerRoutes } from 'noddi-util';
import { useBookingActions } from '../../pages/BookingFlow/BookingStore';

export function useCleanUpAndNavigateToNewBooking() {
  const { clearStore } = useBookingActions();
  const navigate = useNavigate();

  function cleanUpAndNavigateToNewBooking() {
    clearStore();
    navigate(customerRoutes.newBookingAddress.getPath());
  }

  return { cleanUpAndNavigateToNewBooking };
}

type NewBookingButtonProps = {
  className?: string;
  defaultSize?: boolean;
  loading?: boolean;
  variant?: ButtonProps['variant'];
};
const NewBookingButton = ({ className, loading, defaultSize, variant }: NewBookingButtonProps) => {
  const { cleanUpAndNavigateToNewBooking } = useCleanUpAndNavigateToNewBooking();
  return (
    <NoddiButton
      className={className}
      variant={variant}
      size={defaultSize ? 'default' : 'small'}
      endIcon='ArrowRight'
      onPress={cleanUpAndNavigateToNewBooking}
      loading={loading}
    >{t`New booking`}</NoddiButton>
  );
};

export default NewBookingButton;
