import { noddiAsync, URLKeys } from 'noddi-async';
import { UserDataProps } from 'noddi-async/src/types';
import { useAuthContext } from 'noddi-provider';
import { ErrorPageWithTranslations } from '../../components/ErrorPageWithTrans';

import { AutoRecallHomepage } from '../../components/Home/AutoRecallHomepage';
import Cars from '../../components/Home/Cars';
import Discounts from '../../components/Home/Discounts';
import { HomePageHeader } from '../../components/Home/HomePageHeader';
import NoBookingsCard from '../../components/Home/NoUpcomingBookingsCard';
import PreviousBookings from '../../components/Home/PreviousBooking';
import { TireSetOffers } from '../../components/Home/TireSetOffers';
import UpcomingBookings from '../../components/Home/UpcomingBookings';
import ContentWrapper from '../../components/Layouts/ContentWrapper';
import { NoddiLoadingScreenWithTrans } from '../../components/NoddiLoadingScreenWithTrans';
import { TireHotelContent } from '../TireHotel/TireHotel';

type HomeContentProps = {
  userGroupId: number;
  userData: UserDataProps;
  hasTireHotel: boolean;
};

const HomeContent = ({ userGroupId, userData, hasTireHotel }: HomeContentProps) => {
  const {
    isPending: isNumberOfBookingsPending,
    data: numberOfBookings,
    error: numberOfBookingsError
  } = noddiAsync.useGet({
    type: URLKeys.getUserGroupNumberOfBookings,
    input: { userGroupId }
  });

  if (numberOfBookingsError) {
    return <ErrorPageWithTranslations apiError={numberOfBookingsError} />;
  }

  if (isNumberOfBookingsPending) {
    return <NoddiLoadingScreenWithTrans />;
  }

  const isFirstTimeCustomer = !numberOfBookings;

  return (
    <ContentWrapper hideBackButton widerScreen>
      <div className='flex flex-col gap-6 text-primary-darkPurple md:gap-17'>
        <HomePageHeader userData={userData} isFirstTimeCustomer={isFirstTimeCustomer} />
        <div className='flex flex-col gap-8'>
          <TireSetOffers userGroupId={userGroupId} />
          <div className='grid grid-cols-1 gap-8 md:grid-cols-2'>
            <AutoRecallHomepage />

            {isFirstTimeCustomer && <NoBookingsCard />}
            <UpcomingBookings userGroupId={userGroupId} />
            {hasTireHotel && <TireHotelContent showHeading />}
            <Cars userGroupId={userGroupId} />
            <PreviousBookings userGroupId={userGroupId} />
            <Discounts userGroupId={userGroupId} />
          </div>
        </div>
      </div>
    </ContentWrapper>
  );
};

export const Home = () => {
  const { currentUserGroupId: userGroupId, userData, hasTireHotel } = useAuthContext();

  if (!userGroupId || !userData) {
    return null;
  }

  return <HomeContent userGroupId={userGroupId} userData={userData} hasTireHotel={!!hasTireHotel} />;
};
