import { t } from '@lingui/macro';

import { noddiAsync, URLKeys } from 'noddi-async';
import { tracking } from 'noddi-provider';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { ErrorPageWithTranslations } from '../../components/ErrorPageWithTrans';
import { NoddiLoadingScreenWithTrans } from '../../components/NoddiLoadingScreenWithTrans';
import { AutoRecallCard } from './AutoRecallCard';
import { AutoRecallContentWrapper } from './ContentWrapper';

export const AutoRecall = () => {
  const { slug } = useParams<{ slug: string }>();

  if (!slug) {
    return <ErrorPageWithTranslations />;
  }

  return <AutoRecallContent slug={slug} />;
};

export const AutoRecallContent = ({ slug }: { slug: string }) => {
  const {
    data: booking,
    isPending: isBookingPending,
    error: bookingError
  } = noddiAsync.useGet({
    type: URLKeys.getAutoRecallBooking,
    input: { slug }
  });

  useEffect(() => {
    if (booking) {
      tracking.track('autoRecallViewed');
    }
  }, [booking]);

  if (isBookingPending) {
    return <NoddiLoadingScreenWithTrans />;
  }

  if (bookingError) {
    return <ErrorPageWithTranslations apiError={bookingError} />;
  }

  return (
    <AutoRecallContentWrapper
      subtitle={`${t`It's time for your tire change again! We've reserved a spot for you`}`}
      hideBackButton
      title={`${t`Hi`} ${booking.userFirstName}!`}
    >
      <AutoRecallCard booking={booking} slug={slug} />
    </AutoRecallContentWrapper>
  );
};
