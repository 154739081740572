import { Switch } from '@mui/material';
import { noddiColors } from 'noddi-ui-common';
import styled from 'styled-components';

export const ColorSwitch = styled(Switch)(() => ({
  '& .MuiSwitch-switchBase': {
    color: noddiColors.signal.danger,
    '&.Mui-checked': {
      color: noddiColors.signal.success,
      '& + .MuiSwitch-track': {
        backgroundColor: noddiColors.signal.success50,
        opacity: 0.7
      }
    },
    '&.Mui-disabled': {
      opacity: 0.5
    },
    '& + .MuiSwitch-track': {
      backgroundColor: noddiColors.signal.danger50,
      opacity: 0.7
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: 0.3
    }
  }
}));
