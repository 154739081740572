import { Stack } from '@mui/system';
import { useAuthContext } from 'noddi-provider';

import { noddiColors, NoddiIcon } from 'noddi-ui-common';
import { SideNavDivider } from './SideNavDivider';
import { SideNavSection } from './SideNavSection';
import { VerticalLayoutProps } from './VerticalLayoutProps';

const paths = {
  profile: '/profile',
  logout: '/logout'
};

const SideNavFooter = ({
  CustomNavElement,
  LanguagePicker,
  customPathToProfile,
  hideProfile,
  translations
}: Pick<
  VerticalLayoutProps,
  'CustomNavElement' | 'LanguagePicker' | 'customPathToProfile' | 'hideProfile' | 'translations'
>) => {
  const { logout } = useAuthContext();
  const items = [];

  if (!hideProfile) {
    items.push({
      path: customPathToProfile ?? paths.profile,
      title: translations.profile,
      icon: <NoddiIcon color={noddiColors.primary.white} name='UserCircle' />
    });
  }

  items.push({
    path: paths.logout,
    onClick: logout,
    title: translations.logout,
    icon: <NoddiIcon name='Logout' color={noddiColors.primary.white} />
  });

  const navItems = [
    {
      items
    }
  ];

  return (
    <Stack sx={{ marginBottom: '30px' }}>
      {!!CustomNavElement && CustomNavElement}

      <Stack sx={{ p: 2, color: noddiColors.primary.white, marginTop: '30px' }}>
        <SideNavDivider />
      </Stack>
      <Stack
        component='nav'
        spacing={1}
        sx={{
          flexGrow: 1,
          px: 2
        }}
      >
        {LanguagePicker}
        {navItems.map((section, index) => (
          <SideNavSection items={section?.items} key={index} />
        ))}
      </Stack>
    </Stack>
  );
};

export default SideNavFooter;
