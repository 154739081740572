export const CountryCode = {
  NORWAY: 'NO',
  SWEDEN: 'SE'
} as const;

export type CountryCodeType = (typeof CountryCode)[keyof typeof CountryCode];

export const TIRE_SET_OFFER_ADMIN_VIEW_PARAM = 'adminView';

export const noddiBetaAppIdentifierPath = 'com.noddi.noddiworkerapp.preview://';
export const noddiAppIdentifierPath = 'com.noddi.noddiworkerapp://';

export const getMobileStoragePath = ({ identifier, environment }: { identifier: string; environment: string }) => {
  if (environment === 'development') {
    return `${noddiBetaAppIdentifierPath}storageObject/${identifier}`;
  }

  return `${noddiAppIdentifierPath}storageObject/${identifier}`;
};
