import { t, Trans } from '@lingui/macro';
import { captureException } from '@sentry/react';
import { TireQuoteItem } from 'noddi-async/src/types/shared/quotes';
import { NoddiBasicCard, NoddiDropdown } from 'noddi-ui';
import { CarWheelPosition, NoddiButton } from 'noddi-ui-common';
import { customerRoutes, formatCurrencyAmount } from 'noddi-util';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { TireMetaData } from '../../components/TireOffer/TireMetaData';
import { getDimensionsString, getQuantityOptions, getTirePrice } from '../../utils/tire';
import { useTireOfferStore } from './TireOfferStore';

interface StaggeredTireSelectionCardProps {
  quoteItem: TireQuoteItem;
  slug: string;
}

const StaggeredTireSelectionCard = ({ quoteItem, slug }: StaggeredTireSelectionCardProps) => {
  const { setSelectedQuoteItem, selectedQuoteItem } = useTireOfferStore();
  const navigate = useNavigate();

  const currentQuoteItem = quoteItem.id === selectedQuoteItem?.id ? selectedQuoteItem : quoteItem;

  const frontTire = currentQuoteItem.quoteItemTires.find((tire) => tire.staggeredPosition?.value === 'FRONT');
  const rearTire = currentQuoteItem.quoteItemTires.find((tire) => tire.staggeredPosition?.value === 'REAR');

  const [frontQuantity, setFrontQuantity] = useState(frontTire?.quantitySelected || frontTire?.quantityDefault || 0);
  const [rearQuantity, setRearQuantity] = useState(rearTire?.quantitySelected || rearTire?.quantityDefault || 0);

  if (!frontTire || !rearTire) {
    captureException(new Error(`Got a tire set without staggered position - quote with slug ${slug}`));
    return null;
  }

  const frontQuantityOptions = getQuantityOptions(frontTire.quantityMax);
  const rearQuantityOptions = getQuantityOptions(rearTire.quantityMax);

  function updateQuantity(position: 'FRONT' | 'REAR', value: number) {
    if (position === 'FRONT') {
      setFrontQuantity(value);
    } else {
      setRearQuantity(value);
    }
  }

  function onSelectTireOption() {
    const updatedQuoteItem = {
      ...currentQuoteItem,
      quoteItemTires: currentQuoteItem.quoteItemTires.map((tire) => ({
        ...tire,
        quantitySelected: tire.staggeredPosition?.value === 'FRONT' ? frontQuantity : rearQuantity
      }))
    };
    setSelectedQuoteItem(updatedQuoteItem);
    navigate(customerRoutes.tireOfferSummary.getPath({ slug }));
  }

  const frontPrice = getTirePrice(frontTire);
  const rearPrice = getTirePrice(rearTire);
  const totalPrice = frontPrice.amount * frontQuantity + rearPrice.amount * rearQuantity;

  return (
    <NoddiBasicCard className='flex flex-col gap-4'>
      <NoddiBasicCard className='flex flex-col gap-3 bg-systemColors-purpleBg'>
        <div className='flex gap-2'>
          <div>
            <CarWheelPosition carSides={['FRONT_LEFT', 'FRONT_RIGHT']} />
          </div>
          <div>
            <p className='font-bold text-3'>{frontTire.tireInventory.tire.brand.name}</p>
            <p className='font-bold text-5'>{frontTire.tireInventory.tire.model}</p>
            <p>{getDimensionsString(frontTire.tireInventory.tire)}</p>
          </div>
        </div>
        <TireMetaData tire={frontTire.tireInventory.tire} />
        <div>
          <NoddiDropdown
            options={frontQuantityOptions}
            onSelect={(item) => updateQuantity('FRONT', item.value)}
            placeholder={`${frontQuantity} ${t`pcs`}`}
            selectedOption={frontQuantityOptions.find((option) => option.value === frontQuantity)}
          />
        </div>
      </NoddiBasicCard>
      <NoddiBasicCard className='flex flex-col gap-3 bg-systemColors-purpleBg'>
        <div className='flex flex-col gap-4'>
          {/* Rear Tires */}
          <div className='flex gap-2'>
            <div>
              <CarWheelPosition carSides={['REAR_LEFT', 'REAR_RIGHT']} />
            </div>
            <div>
              <p className='font-bold text-3'>{rearTire.tireInventory.tire.brand.name}</p>
              <p className='font-bold text-5'>{rearTire.tireInventory.tire.model}</p>
              <p>{getDimensionsString(rearTire.tireInventory.tire)}</p>
            </div>
          </div>
          <TireMetaData tire={rearTire.tireInventory.tire} />
          <div>
            <NoddiDropdown
              options={rearQuantityOptions}
              onSelect={(item) => updateQuantity('REAR', item.value)}
              placeholder={`${rearQuantity} ${t`pcs`}`}
              selectedOption={rearQuantityOptions.find((option) => option.value === rearQuantity)}
            />
          </div>
        </div>
      </NoddiBasicCard>

      <div>
        <p className='font-bold'>
          {t`Total`} {formatCurrencyAmount(totalPrice, 0, frontPrice.currency)}
        </p>
        <div className='flex flex-col gap-1'>
          <p className='text-3'>
            {formatCurrencyAmount(frontPrice.amount, 0, frontPrice.currency)} <Trans>per front tire</Trans>
          </p>
          <p className='text-3'>
            {formatCurrencyAmount(rearPrice.amount, 0, rearPrice.currency)} <Trans>per rear tire</Trans>
          </p>
        </div>
        <div className='flex justify-end'>
          <NoddiButton onPress={onSelectTireOption}>{t`Select`}</NoddiButton>
        </div>
      </div>
    </NoddiBasicCard>
  );
};

export default StaggeredTireSelectionCard;
