import { Route, Routes } from 'react-router-dom';

import { customerRoutes } from 'noddi-util';
import TireOffer from './TireOffer';
import TireOfferConfirmation from './TireOffer/TireOfferConfirmation';
import TireOfferSummary from './TireOffer/TireOfferSummary';
import TireSelection from './TireOffer/TireSelection';

export function TireOfferRouter() {
  return (
    <Routes>
      <Route path={customerRoutes.tireOfferInfo.getBasePath()} element={<TireOffer />} />
      <Route path={customerRoutes.tireOfferSelection.getBasePath()} element={<TireSelection />} />
      <Route path={customerRoutes.tireOfferSummary.getBasePath()} element={<TireOfferSummary />} />
      <Route path={customerRoutes.tireOfferConfirmation.getBasePath()} element={<TireOfferConfirmation />} />
    </Routes>
  );
}
