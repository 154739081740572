import { TZDate } from '@date-fns/tz';
import { NoddiButton } from 'noddi-ui-common';
import { useRef, useState } from 'react';
import { useIsWithinTailWindBreakpoint } from '../../../hooks';
import { NoddiDatePicker } from '../../Elements';

type Props = {
  translations: { jumpToDateTriggerText: string };
  skipToDate: TZDate;
  setSkipToDate: (skipToDate: TZDate) => void;
  maxDate: TZDate;
  minDate: TZDate;
};

export const GoToDatePicker = ({ translations, setSkipToDate, skipToDate, maxDate, minDate }: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const isLg = useIsWithinTailWindBreakpoint('lg');

  const buttonRef = useRef<HTMLDivElement>(null);

  const handleDateChange = (newDate: TZDate | null) => {
    if (newDate) {
      setSkipToDate(newDate);
      setIsOpen(false);
    }
  };

  return (
    <div className='absolute -top-14 right-0'>
      <div ref={buttonRef}>
        <NoddiButton className='-mr-4' startIcon='Calendar' onPress={() => setIsOpen(true)} variant='link'>
          {translations.jumpToDateTriggerText}
        </NoddiButton>
      </div>
      <NoddiDatePicker
        disableHighlightToday
        open={isOpen}
        onClose={() => setIsOpen(false)}
        value={skipToDate}
        onChange={handleDateChange}
        minDate={minDate}
        maxDate={maxDate}
        slots={{
          textField: () => null
        }}
        slotProps={{
          popper: {
            anchorEl: buttonRef.current,
            // Kind of strange that bottom-start doesn't work on big screens, this does the trick doe..
            placement: isLg ? 'bottom-start' : 'bottom-end'
          }
        }}
      />
    </div>
  );
};
