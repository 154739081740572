import { Trans } from '@lingui/macro';
import { NoddiBasicCard, cn } from 'noddi-ui';
import { NoddiButton } from 'noddi-ui-common';
import { useIsMobile } from 'noddi-ui/src/hooks';
import { PropsWithChildren, useState } from 'react';
import { TimeWindowModal } from './TimeWindowModal';

export const WrapperWithModalButton = ({ children }: PropsWithChildren<unknown>) => {
  const isMobile = useIsMobile();
  const [showExtraInfoModal, setShowExtraInfoModal] = useState(false);

  return (
    <>
      <TimeWindowModal open={showExtraInfoModal} onClose={() => setShowExtraInfoModal(false)} />
      <NoddiBasicCard
        className={cn(
          'flex h-fit flex-col gap-4 p-8 max-sm:p-0',
          isMobile && 'bg-systemColors-transparent shadow-none'
        )}
      >
        <NoddiButton
          className='-ml-4'
          startIcon='ArrowSquareRightCorner'
          onPress={() => setShowExtraInfoModal(true)}
          variant='link'
        >
          <Trans>Read more about delivery</Trans>
        </NoddiButton>
        {children}
      </NoddiBasicCard>
    </>
  );
};
