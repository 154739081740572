import { cn, useIsMobile } from 'noddi-ui';
import { Outlet, useLocation } from 'react-router-dom';

import { customerRoutes } from 'noddi-util';
import HelpScoutChat from '../BookingFlow/HelpScoutChat';
import { CustomerHeaderNav } from './CustomerHeaderNav';

export const RouterLayout = ({
  className,
  showChat,
  peachBg
}: {
  className?: string;
  showChat?: boolean;
  peachBg?: boolean;
}) => {
  const { pathname } = useLocation();
  const isTimePickerStep =
    pathname.includes(customerRoutes.newBookingTimeWindow.getBasePath()) ||
    pathname.includes(customerRoutes.autoRecallTimeWindowPicker.getBasePath());
  const isMobile = useIsMobile();

  const isBuildingBooking = pathname.includes(customerRoutes.newBookingBuilding.getBasePath());

  const applyTimePickerMobileGradient = isTimePickerStep && isMobile;
  const showChatInScreen = isBuildingBooking || showChat;

  return (
    <div
      className={cn(
        `min-h-screen ${applyTimePickerMobileGradient ? 'bg-pinto-to-white-gradient' : peachBg ? 'bg-peach-to-purple' : 'bg-pint-to-lighterPint-gradient'}`,
        className
      )}
    >
      <CustomerHeaderNav /> <Outlet />
      {showChatInScreen && (
        <div className='fixed bottom-10 right-10 z-50'>
          <HelpScoutChat position='right' size='small' />
        </div>
      )}
    </div>
  );
};
