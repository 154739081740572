import { t } from '@lingui/macro';
import { URLKeys, noddiAsync } from 'noddi-async';
// eslint-disable-next-line
import { AvailableSalesItemsForBooking, BookingAddress, SALES_ITEM_TYPE } from 'noddi-async/src/types';
import { useNavigate } from 'react-router-dom';
import { ErrorPageWithTranslations } from '../../../../components/ErrorPageWithTrans';
import {
  NoddiLoadingScreenUpdateBooking,
  NoddiLoadingScreenWithTrans
} from '../../../../components/NoddiLoadingScreenWithTrans';
import {
  useBookingAddress,
  useBookingSelectedSalesItemsCars,
  useIsMultipleCarsBooking,
  useIsTierHotelPickupRequired
} from '../../BookingStore';
import { BookingScreen } from '../../components/BookingScreen';
import { NewBookingErrorPage } from '../../components/NewBookingErrorPage';
import { tierHotelPickupStepPath } from '../../helpers/bookingFlowPaths';
import { useNavigateToTimePickerOrAutoRecallPage } from '../../helpers/navigate';
import { groupCars } from '../../helpers/utils';
import { SalesItemsMultipleCarsSelector } from '../Services/SalesItemsFleetSelector';
import { CarWithAddons } from './CarWithAddons';

export const Addons = () => {
  const navigate = useNavigate();
  const isTierHotelPickupRequired = useIsTierHotelPickupRequired();
  const { navigateAfterPickingSalesItems, isPatchBookingPending, patchBookingError, isPotentialConflictsLoading } =
    useNavigateToTimePickerOrAutoRecallPage();

  const selectedAddress = useBookingAddress();

  const navigateToNextStep = () => {
    if (isTierHotelPickupRequired) {
      return navigate(tierHotelPickupStepPath);
    }
    navigateAfterPickingSalesItems();
  };

  if (!selectedAddress) {
    return <NewBookingErrorPage />;
  }

  if (patchBookingError) {
    return <ErrorPageWithTranslations apiError={patchBookingError} />;
  }

  if (isPatchBookingPending) {
    return <NoddiLoadingScreenUpdateBooking />;
  }

  return (
    <BookingScreen
      nextButtonLoading={isPotentialConflictsLoading}
      onNextClicked={navigateToNextStep}
      title={t`Do you need help with anything else?`}
    >
      <AddonsSelector selectedAddress={selectedAddress} />
    </BookingScreen>
  );
};

const AddonsSelector = ({ selectedAddress }: { selectedAddress: BookingAddress }) => {
  const selectedSalesItemsCars = useBookingSelectedSalesItemsCars();
  const isMultipleCarsBooking = useIsMultipleCarsBooking();

  const {
    data: carsWithAddons,
    isPending: isCarsWithAddonsPending,
    error: carsWithAddonsError
  } = noddiAsync.usePostAsGetQuery({
    type: URLKeys.postFetchAddons,
    input: {
      addressId: selectedAddress.id,
      // Which addons are available are only dependant on primary sales items
      cars: groupCars(selectedSalesItemsCars.filter((car) => car.type === SALES_ITEM_TYPE.PRIMARY))
    },
    queryConfig: {
      staleTime: Infinity
    }
  });

  if (isCarsWithAddonsPending) {
    return <NoddiLoadingScreenWithTrans />;
  }
  if (carsWithAddonsError) {
    return <ErrorPageWithTranslations apiError={carsWithAddonsError} />;
  }

  return (
    <div className='flex flex-col gap-8'>
      {isMultipleCarsBooking ? (
        <SalesItemsMultipleCarsSelector
          availableSalesItems={carsWithAddons.data}
          salesItemType={SALES_ITEM_TYPE.ADDON}
        />
      ) : (
        <SingleCarAddonsSelector availableSalesItems={carsWithAddons.data} />
      )}
    </div>
  );
};

const SingleCarAddonsSelector = ({ availableSalesItems }: { availableSalesItems: AvailableSalesItemsForBooking }) => {
  return (
    <>
      {availableSalesItems.cars.map((carWithAddons) => {
        return <CarWithAddons key={carWithAddons.licensePlate.number} carWithAddons={carWithAddons} />;
      })}
    </>
  );
};
