import { NoddiFeedbackBox, NoddiFeedbackWrapper } from 'noddi-ui';
import { createContext, PropsWithChildren, useCallback, useContext, useState } from 'react';
import { Toast, ToastContextType, ToastType } from './types';

const ToastContext = createContext<ToastContextType | null>(null);

export const ToastProvider = ({ children }: PropsWithChildren<unknown>) => {
  const [toast, setToast] = useState<Toast | null>(null);

  const addToast = useCallback(
    ({ type, title, description }: { type: ToastType; title?: string; description: string }) => {
      setToast({ type, title, description });
      setTimeout(() => {
        setToast(null);
      }, 5000);
    },
    []
  );

  const { type, title, description } = toast || {};

  return (
    <ToastContext.Provider value={{ addToast }}>
      {children}
      <NoddiFeedbackWrapper>
        {toast && (
          <NoddiFeedbackBox
            variant={type}
            title={title}
            description={description}
            className='border-y-0 border-r-0 bg-primary-white shadow-cardShadow'
          />
        )}
      </NoddiFeedbackWrapper>
    </ToastContext.Provider>
  );
};

export const useToast = () => {
  const context = useContext(ToastContext);
  if (!context) {
    throw new Error('useToast must be used within a ToastProvider');
  }
  return context;
};
