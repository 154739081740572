import { Trans } from '@lingui/macro';
import { URLKeys, noddiAsync } from 'noddi-async';
import { useAuthContext } from 'noddi-provider';

import { NoddiLoadingScreenWithTrans } from '../../components/NoddiLoadingScreenWithTrans';
import Address from './Address';

const Addresses = () => {
  const { getCurrentUserGroupId } = useAuthContext();
  const userGroupId = getCurrentUserGroupId();

  const { isPending: userGroupAddressesIsPending, data: userGroupAddresses } = noddiAsync.useGet({
    type: URLKeys.getUserGroupAddresses,
    input: { userGroupId: userGroupId as number },
    queryConfig: { enabled: !!userGroupId, staleTime: Infinity }
  });

  if (userGroupAddressesIsPending) {
    return <NoddiLoadingScreenWithTrans />;
  }
  if (!userGroupAddresses?.length) {
    return null;
  }

  return (
    <div className='flex flex-col gap-4'>
      <h2 className='font-bold text-5 text-primary-darkPurple md:text-7'>
        <Trans>My addresses</Trans>
      </h2>
      <div className='flex flex-col gap-2'>
        {userGroupAddresses?.map((userGroupAddress, index) => (
          <Address key={index} userGroupAddress={userGroupAddress} />
        ))}
      </div>
    </div>
  );
};

export default Addresses;
