import { t } from '@lingui/macro';
import { noddiAsync, URLKeys } from 'noddi-async';
import { AvailableBookingTimeWindow } from 'noddi-async/src/types';
import { useAuthContext } from 'noddi-provider';
import { BookingTimeWindowPicker } from 'noddi-ui';
import { useState } from 'react';
import { useParams } from 'react-router-dom';

import getCommonTranslations from '../../../commonTranslations';
import { ErrorPageWithTranslations } from '../../../components/ErrorPageWithTrans';
import ContentWrapper from '../../../components/Layouts/ContentWrapper';
import { NoddiLoadingScreenWithTrans } from '../../../components/NoddiLoadingScreenWithTrans';
import { WrapperWithModalButton } from '../../BookingFlow/Steps/TimeWindows/WrapperWithModalButton';
import EditBookingTimeWindowDialog from './EditBookingDialog';

export default function EditBookingTimeWindow() {
  const { currentUserGroupId: userGroupId, isSuperUser } = useAuthContext();
  const { id: bookingId } = useParams();

  const [editDialogIsOpen, setEditDialogIsOpen] = useState(false);
  const [selectedTimeWindow, setSelectedTimeWindow] = useState<AvailableBookingTimeWindow>();

  const bookingIdAsString = bookingId as string;

  const {
    isPending: isBookingPending,
    data: bookingData,
    isError
  } = noddiAsync.useGet({
    type: URLKeys.getUserBooking,
    input: { bookingId: bookingIdAsString },
    queryConfig: {
      enabled: !!userGroupId && !!bookingId
    }
  });

  const {
    isPending: isBookingPermissionsPending,
    data: bookingPermissions,
    isError: isBookingPermissionsError
  } = noddiAsync.useGet({
    type: URLKeys.getEditBookingPermissions,
    input: { id: bookingIdAsString },
    queryConfig: {
      enabled: !!bookingId
    }
  });

  if (isBookingPending || isBookingPermissionsPending) {
    return <NoddiLoadingScreenWithTrans />;
  }
  if (isError || isBookingPermissionsError) {
    return <ErrorPageWithTranslations />;
  }

  const { address, cars, deliveryWindowStartsAt, deliveryWindowEndsAt } = bookingData;

  return (
    <ContentWrapper title={t`When is it convenient for us to come to you?`}>
      <EditBookingTimeWindowDialog
        isOpen={editDialogIsOpen}
        setIsOpen={setEditDialogIsOpen}
        bookingId={bookingIdAsString}
        selectedTimeWindow={selectedTimeWindow}
      />
      <WrapperWithModalButton>
        <BookingTimeWindowPicker
          address={address}
          cars={cars.map((car) => {
            return {
              licensePlate: car.licensePlate,
              selectedSalesItemIds: car.orderLines.map((orderLine) => orderLine.salesItemId!)
            };
          })}
          selectedDeliveryWindow={
            deliveryWindowStartsAt && deliveryWindowEndsAt
              ? { startsAt: deliveryWindowStartsAt, endsAt: deliveryWindowEndsAt }
              : undefined
          }
          onAvailableSelect={(timeWindow) => {
            if (bookingPermissions.timeWindows.canEdit || isSuperUser) {
              setSelectedTimeWindow(timeWindow);
              setEditDialogIsOpen(true);
            }
          }}
          onUnavailableSelect={(timeWindow) => {
            if (!isSuperUser) {
              return;
            }
            setSelectedTimeWindow(timeWindow);
            setEditDialogIsOpen(true);
          }}
          translations={getCommonTranslations().timePicker}
        />
      </WrapperWithModalButton>
    </ContentWrapper>
  );
}
