export function stringToUpperCase(value: string) {
  return value.charAt(0).toUpperCase() + value.slice(1);
}

export function startsWithIgnoreCase(value: string, search: string) {
  return value.toLowerCase().startsWith(search.toLowerCase());
}

export function thousandSeparator(value: string, separator = ' ') {
  return value.replace(/\B(?=(\d{3})+(?!\d))/g, separator);
}

export const trimAllWhiteCharacters = (mobileNumber: string) => mobileNumber.replace(/\s+/g, '');

export const secondsToTimeString = (seconds: number, detailed = true) => {
  if (seconds < 0) {
    return '0 seconds';
  }

  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = seconds % 60;

  const hoursString = hours > 0 ? `${hours} hour${hours === 1 ? '' : 's'} ` : '';
  const minutesString = minutes > 0 ? `${minutes} minute${minutes === 1 ? '' : 's'} ` : '';
  const secondsString =
    remainingSeconds > 0 || (hours === 0 && minutes === 0)
      ? `${remainingSeconds} second${remainingSeconds === 1 ? '' : 's'}`
      : '';

  if (detailed) {
    return (hoursString + minutesString + secondsString).trim();
  } else {
    // If not detailed, return hours and minutes, or just minutes if hours are 0
    if (hours > 0) {
      return (hoursString + minutesString).trim();
    } else {
      return minutesString.trim();
    }
  }
};
