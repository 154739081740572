import { Circle, Ellipse, Path, Svg, SvgProps } from 'react-native-svg';

const Sad = ({ color, ...props }: SvgProps) => (
  <Svg width='30' height='30' viewBox='0 0 30 30' fill='none' {...props}>
    <Circle cx='15' cy='15' r='10' stroke={color} strokeWidth='1.5' />
    <Path
      d='M12 20C12.8504 19.3697 13.8846 19 15 19C16.1154 19 17.1496 19.3697 18 20'
      stroke={color}
      strokeWidth='1.5'
      strokeLinecap='round'
    />
    <Ellipse cx='18' cy='13.5' rx='1' ry='1.5' fill={color} />
    <Ellipse cx='12' cy='13.5' rx='1' ry='1.5' fill={color} />
  </Svg>
);

export { Sad };
