import { Path, Svg, SvgProps } from 'react-native-svg';

const Copy = ({ color, ...props }: SvgProps) => (
  <Svg width='30' height='30' viewBox='0 0 30 30' fill='none' {...props}>
    <Path
      d='M9 14C9 11.1716 9 9.75736 9.87868 8.87868C10.7574 8 12.1716 8 15 8H18C20.8284 8 22.2426 8 23.1213 8.87868C24 9.75736 24 11.1716 24 14V19C24 21.8284 24 23.2426 23.1213 24.1213C22.2426 25 20.8284 25 18 25H15C12.1716 25 10.7574 25 9.87868 24.1213C9 23.2426 9 21.8284 9 19V14Z'
      stroke={color}
      strokeWidth='1.5'
    />
    <Path
      d='M9 22C7.34315 22 6 20.6569 6 19V13C6 9.22876 6 7.34315 7.17157 6.17157C8.34315 5 10.2288 5 14 5H18C19.6569 5 21 6.34315 21 8'
      stroke={color}
      strokeWidth='1.5'
    />
  </Svg>
);

export { Copy };
