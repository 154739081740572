import { t, Trans } from '@lingui/macro';
import { NoddiButton } from 'noddi-ui-common';
import { customerRoutes } from 'noddi-util';
import { useNavigate, useParams } from 'react-router-dom';
import ContentWrapper from '../../components/Layouts/ContentWrapper';
import { TireOfferAction } from './types';

const TireOfferConfirmation = () => {
  const { action } = useParams() as { action: TireOfferAction };
  const navigate = useNavigate();

  const headerText = action === 'accepted' ? t`Your order is confirmed!` : t`We have registered your new dimensions`;
  const descriptionText =
    action === 'accepted'
      ? t`We will be in touch with you shortly!`
      : t`We will be in touch with you shortly with an updated offer based on your tire dimensions!`;

  return (
    <ContentWrapper>
      <div>
        <p className='mt-20 font-bold text-10 text-primary-darkPurple'>{headerText}</p>
        <p className='mt-7 font-bold text-7 text-primary-purple'>{descriptionText}</p>
        <p className='mt-4 text-primary-darkPurple'>
          <Trans>In the meantime, if you have any questions, contact us at hei@noddi.no</Trans>
        </p>
        <NoddiButton className='mt-4 self-end' onPress={() => navigate(customerRoutes.homepage.getPath())}>
          <Trans>Close</Trans>
        </NoddiButton>
      </div>
    </ContentWrapper>
  );
};

export default TireOfferConfirmation;
