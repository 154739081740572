import { tracking, useAuthContext } from 'noddi-provider';

import { t, Trans } from '@lingui/macro';
import { noddiAsync, URLKeys } from 'noddi-async';
import { AvailableBookingTimeWindow } from 'noddi-async/src/types';
import { invalidateQueryExactMatch } from 'noddi-async/src/utils';
import { BookingTimeWindowPicker, FixedBottomBarContainer, NoddiBasicCard } from 'noddi-ui';
import { NoddiButton, NoddiIcon } from 'noddi-ui-common';
import { customerRoutes, differenceBetweenDates, getBookingTimeWindowDisplayText } from 'noddi-util';
import { useNavigate } from 'react-router-dom';
import getCommonTranslations from '../../../commonTranslations';
import HelpScoutChat from '../../../components/BookingFlow/HelpScoutChat';
import { ErrorPageWithTranslations } from '../../../components/ErrorPageWithTrans';
import { NoddiLoadingScreenWithTrans } from '../../../components/NoddiLoadingScreenWithTrans';
import { WrapperWithModalButton } from '../../BookingFlow/Steps/TimeWindows/WrapperWithModalButton';
import { useAutoRecallActions, useAutoRecallHasInvalidTimeWindow, useAutoRecallMetaData } from '../AutoRecallStore';
import { AutoRecallContentWrapper } from '../ContentWrapper';

export const mapToDeliveryWindow = (
  bookingTimeWindow: Pick<AvailableBookingTimeWindow, 'startsAt' | 'endsAt'> | null | undefined
) =>
  bookingTimeWindow
    ? {
        startsAt: bookingTimeWindow.startsAt,
        endsAt: bookingTimeWindow.endsAt
      }
    : undefined;

export const AutoRecallTimeWindowPicker = () => {
  const { isSuperUser } = useAuthContext();
  const { slug } = useAutoRecallMetaData();
  const { setHasInvalidTimeWindow } = useAutoRecallActions();
  const hasInvalidTimeWindow = useAutoRecallHasInvalidTimeWindow();

  const navigate = useNavigate();

  const {
    data: booking,
    isPending: isBookingPending,
    error: bookingError
  } = noddiAsync.useGet({
    type: URLKeys.getAutoRecallBooking,
    input: { slug: slug! }
  });

  const {
    mutateAsync: updateRecallBooking,
    isPending: isUpdateRecallBookingPending,
    error: updateRecallBookingError,
    variables: updateBookingVariables
  } = noddiAsync.usePatch({
    type: URLKeys.patchRecallBooking,
    queryConfig: {
      onSuccess: async () => {
        await invalidateQueryExactMatch({
          urlKey: URLKeys.getAutoRecallBooking,
          input: { slug: slug! }
        });
        tracking.track('autoRecallUserChangedTimeWindow', {
          differenceInDaysBetweenStartedAt:
            updateBookingVariables?.deliveryWindow?.startsAt && booking?.deliveryWindowStartsAt
              ? differenceBetweenDates(
                  updateBookingVariables.deliveryWindow.startsAt,
                  booking.deliveryWindowStartsAt,
                  'days'
                )
              : 0
        });
      }
    }
  });

  if (isBookingPending) {
    return <NoddiLoadingScreenWithTrans />;
  }

  if (bookingError || updateRecallBookingError) {
    return <ErrorPageWithTranslations apiError={[bookingError, updateRecallBookingError]} />;
  }

  const { deliveryWindowStartsAt, deliveryWindowEndsAt, address, cars, bookingId } = booking;

  const selectedBookingTimeWindow = isUpdateRecallBookingPending
    ? {
        startsAt: updateBookingVariables.deliveryWindow!.startsAt,
        endsAt: updateBookingVariables.deliveryWindow!.endsAt,
        timeZone: address.timeZone
      }
    : {
        startsAt: deliveryWindowStartsAt,
        endsAt: deliveryWindowEndsAt,
        timeZone: address.timeZone
      };

  const selectedDeliveryWindow = mapToDeliveryWindow({
    startsAt: selectedBookingTimeWindow.startsAt,
    endsAt: selectedBookingTimeWindow.endsAt
  });

  const onSelect = (timeWindow: AvailableBookingTimeWindow) => {
    updateRecallBooking({
      id: bookingId,
      deliveryWindow: {
        id: timeWindow.deliveryWindowId,
        endsAt: timeWindow.endsAt,
        startsAt: timeWindow.startsAt
      }
    });
    setHasInvalidTimeWindow(false);
  };

  const timeWindowDisplay = selectedBookingTimeWindow
    ? getBookingTimeWindowDisplayText(selectedBookingTimeWindow)
    : t`Problems with the last time slot`;

  return (
    <AutoRecallContentWrapper widerScreen title={t`When is it convenient for us to come to you?`}>
      <div className='flex justify-center md:justify-start'>
        <NoddiBasicCard className='mb-2 flex w-75 flex-col gap-2 shadow-md'>
          <span className='text-center font-bold'>
            <Trans>Time window</Trans>
          </span>
          <div className='flex gap-2'>
            <NoddiIcon name='ClockCircle' />
            {!hasInvalidTimeWindow && <span>{timeWindowDisplay}</span>}
          </div>
        </NoddiBasicCard>
      </div>
      <WrapperWithModalButton>
        <BookingTimeWindowPicker
          address={address}
          cars={cars.map((car) => {
            return {
              licensePlate: car.licensePlate,
              selectedSalesItemIds: car.salesItems.map((salesItems) => salesItems.salesItemId)
            };
          })}
          onUnavailableSelect={(timeWindow) => {
            if (!isSuperUser) {
              return;
            }
            onSelect(timeWindow);
          }}
          // Bc is autoRecall booking we allow having invalid time window on server until they book, thats why this is a bit different
          selectedDeliveryWindow={hasInvalidTimeWindow ? undefined : selectedDeliveryWindow}
          customFromDate={selectedDeliveryWindow?.startsAt}
          onAvailableSelect={(timeWindow) => {
            onSelect(timeWindow);
          }}
          translations={getCommonTranslations().timePicker}
        />
      </WrapperWithModalButton>
      <FixedBottomBarContainer>
        <HelpScoutChat />
        <NoddiButton
          loading={isUpdateRecallBookingPending}
          endIcon='ArrowRight'
          disabled={hasInvalidTimeWindow}
          onPress={() => navigate(customerRoutes.autoRecallSummary.getPath())}
        >
          <Trans>Next</Trans>
        </NoddiButton>
      </FixedBottomBarContainer>
    </AutoRecallContentWrapper>
  );
};
