import { AutoRecallBooking, BOOKING_TYPES } from 'noddi-async/src/types';
import { useBookingActions } from '../../BookingFlow/BookingStore';
import { getSalesItemType } from '../helpers';

export const usePopulateBookingStore = () => {
  const { clearStore, addCar, setSelectedAddress, setSelectedSalesItemsCars, setBookingType } = useBookingActions();

  const populateBookingStore = (booking: AutoRecallBooking) => {
    clearStore();
    setBookingType(BOOKING_TYPES.AUTO_RECALL);
    setSelectedAddress({ ...booking.address, publicAnnouncement: '', brands: [booking.brand] });

    booking.cars.forEach(({ make, model, hasActiveTireHotelContract, licensePlate }) => {
      addCar({
        make,
        model,
        hasActiveTireHotelContract,
        licensePlate
      });
    });

    setSelectedSalesItemsCars(
      booking.cars.flatMap(({ salesItems, licensePlate }) =>
        salesItems.map(
          ({
            isAddonsAvailable,
            isWheelPickupRequired,
            salesItemId,
            isAddon,
            isPrimary,
            isTireHotelCancel,
            isTireHotelPickup
          }) => ({
            licensePlate,
            salesItemId,
            type: getSalesItemType(isPrimary, isAddon, isTireHotelPickup, isTireHotelCancel),
            isWheelPickupRequired,
            isAddonsAvailable
          })
        )
      )
    );
  };

  return populateBookingStore;
};
