import { Trans } from '@lingui/macro';
import { tracking, useAuthContext, useLanguageContext } from 'noddi-provider';
import { NoddiLanguageSelector, NoddiLogo, useIsWithinTailWindBreakpoint } from 'noddi-ui';
import { NoddiButton } from 'noddi-ui-common';
import { customerRoutes } from 'noddi-util';
import { useLocation, useNavigate } from 'react-router-dom';
import { getSupportedLocales } from '../../utils/translation';
import { useCleanUpAndNavigateToNewBooking } from '../NewBookingButton/NewBookingButton';
import NavWrapper from './NavWrapper';

export const CustomerHeaderNav = () => {
  const { isLoggedIn } = useAuthContext();
  const isMd = useIsWithinTailWindBreakpoint('md');

  const navigate = useNavigate();
  const { pathname } = useLocation();

  const isLoginPage = pathname === customerRoutes.login.getPath();
  const { currentLanguage, setCurrentLanguage } = useLanguageContext();
  const { cleanUpAndNavigateToNewBooking } = useCleanUpAndNavigateToNewBooking();

  const isSummaryStep = pathname.includes(customerRoutes.newBookingSummary.getBasePath());
  // use hardcoded string as it has unique slugs
  const isMemberShipsPage = pathname.includes('memberships');

  const goToLogin = () => {
    const redirectUrl =
      window.location.pathname !== customerRoutes.login.getPath() ? window.location.pathname : undefined;
    const extraParams = redirectUrl ? `?redirectUrl=${redirectUrl}` : '';
    navigate(`${customerRoutes.login.getPath()}${extraParams}`);
  };

  const NewBookingButton = () => (
    <NoddiButton
      size={isMd ? 'small' : 'default'}
      variant='ghost'
      onPress={cleanUpAndNavigateToNewBooking}
      endIcon='ArrowRight'
    >
      <Trans>New booking</Trans>
    </NoddiButton>
  );

  const MyProfileButton = () => (
    <NoddiButton
      size={isMd ? 'small' : 'default'}
      variant='ghost'
      onPress={() => navigate(customerRoutes.homepage.getPath())}
      endIcon='UserCircle'
    >
      <Trans>My profile</Trans>
    </NoddiButton>
  );

  const SignInButton = () => (
    <NoddiButton size={isMd ? 'small' : 'default'} variant='ghost' onPress={goToLogin} endIcon='UserCircle'>
      <Trans>Log in</Trans>
    </NoddiButton>
  );

  return (
    <NavWrapper>
      <div>
        <div className='flex w-full items-center gap-6'>
          <div className='hidden xxs:block'>
            <button className='p-2' onClick={() => navigate(customerRoutes.homepage.getPath())}>
              <NoddiLogo width={67} height={19} />
            </button>
          </div>

          <NoddiLanguageSelector
            supportedLanguages={getSupportedLocales()}
            currentLanguage={currentLanguage}
            setCurrentLanguage={(locale) => {
              tracking.track('languageChanged', { language: locale });
              setCurrentLanguage(locale);
            }}
          />

          {!isSummaryStep && !isMemberShipsPage && (
            <div className='ml-auto flex gap-3'>
              {isLoginPage ? <NewBookingButton /> : <>{isLoggedIn ? <MyProfileButton /> : <SignInButton />}</>}
            </div>
          )}
        </div>
      </div>
    </NavWrapper>
  );
};
