import { t, Trans } from '@lingui/macro';
import { Stack } from '@mui/system';
import { useAuthContext } from 'noddi-provider';
import { NoddiDialog } from 'noddi-ui';
import { NoddiButton } from 'noddi-ui-common';
import { useState } from 'react';
import ChooseImpersonateUser from './ChooseSuperUser';

const ImpersonateUserButton = ({
  handleClearDataWhenImpersonating
}: {
  handleClearDataWhenImpersonating?: () => void;
}) => {
  const { isSuperUser } = useAuthContext();
  const [isSuperUserModalOpen, setIsSuperUserModalOpen] = useState(false);

  if (!isSuperUser) {
    return null;
  }

  return (
    <>
      <Stack padding={1}>
        <div className='mx-4'>
          <NoddiButton size='small' variant='secondary' onPress={() => setIsSuperUserModalOpen(true)} startIcon='User'>
            <Trans>Impersonate user</Trans>
          </NoddiButton>
        </div>
      </Stack>
      <NoddiDialog
        open={isSuperUserModalOpen}
        onClose={() => setIsSuperUserModalOpen(false)}
        title={t`Impersonate user`}
        maxWidth='xl'
      >
        <ChooseImpersonateUser
          handleClose={() => setIsSuperUserModalOpen(false)}
          handleClearDataWhenImpersonating={handleClearDataWhenImpersonating}
        />
      </NoddiDialog>
    </>
  );
};

export default ImpersonateUserButton;
