import { t } from '@lingui/macro';
import { URLKeys, noddiAsync } from 'noddi-async';

import { SALES_ITEM_TYPE } from 'noddi-async/src/types';
import { useNavigate } from 'react-router-dom';
import { ErrorPageWithTranslations } from '../../../../components/ErrorPageWithTrans';
import { NoddiLoadingScreenWithTrans } from '../../../../components/NoddiLoadingScreenWithTrans';
import { useBookingCars } from '../../BookingStore';
import { BookingScreen } from '../../components/BookingScreen';
import { CarIdentifierWithIcon } from '../../components/CarIdentifierWithIcon';
import { NewBookingErrorPage } from '../../components/NewBookingErrorPage';
import { SalesItem } from '../../components/salesItems/SalesItem';
import { timeWindowStepPath } from '../../helpers/bookingFlowPaths';
import { useIsStepValid } from '../../helpers/useStepCompletion';

export const CancelTireHotel = () => {
  const navigate = useNavigate();

  const { isSalesItemStepValid } = useIsStepValid();

  const selectCars = useBookingCars();

  const {
    data: cancelOptions,
    isPending,
    error
  } = noddiAsync.useGet({
    type: URLKeys.getTierHotelCancelSalesItemsOptions
  });

  if (error) {
    return <ErrorPageWithTranslations />;
  }

  if (isPending) {
    return <NoddiLoadingScreenWithTrans />;
  }

  if (!selectCars.length) {
    return <NewBookingErrorPage />;
  }

  return (
    <BookingScreen
      onNextClicked={() => navigate(timeWindowStepPath)}
      disableNextButton={!isSalesItemStepValid}
      title={t`How you want to return your tires?`}
    >
      <div className='flex flex-col gap-8'>
        {selectCars.map((car) => (
          <div key={car.licensePlate.number} className='flex flex-col gap-6'>
            <CarIdentifierWithIcon hideTireHotelInfo car={car} />

            {cancelOptions.map((option) => (
              <div key={option.salesItemId}>
                <SalesItem
                  type={SALES_ITEM_TYPE.TIRE_HOTEL_CANCEL}
                  licensePlate={car.licensePlate}
                  key={option.name}
                  salesItem={option}
                />
              </div>
            ))}
          </div>
        ))}
      </div>
    </BookingScreen>
  );
};
